/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, FormGroup, Label } from "reactstrap";
import { v4 as uuid } from "uuid";
import Content from "../../layout/content/Content";
import DevNavTab from "../../pages/Development/DevNavTab";
import { deleteFile, deleteMultipleFiles, uploadFile } from "../../redux/Apislices/awsSlice";
import { getcity } from "../../redux/Apislices/citySlice";
import {
  addNewDevelopment,
  editDevelopment,
  getDevelopmentBySlug,
  handleAddDevelopment,
  handleDevAddReset,
  handleDevAws,
} from "../../redux/Apislices/developmentSlice";
import {
  Block,
  BlockBetween,
  BlockContent,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Row,
} from "../Component";
import Main from "../Draggable/Main";
import styles from "./dev.module.scss";
import FooterBtn from "../common/FooterBtn";
import { IoImagesOutline } from "react-icons/io5";

const Dev3 = ({ toggleNext, toggleBack, toggleIconTab, activeIconTab }) => {
  const { type, id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { development, profile, city, homeTypeData } = useSelector((state) => ({
    development: state?.development,
    city: state?.city,
    profile: state?.profile,
    homeTypeData: state?.homeType,
  }));

  const { developmentById, addDevelopment } = development;

  const [devData, setDevData] = useState(false);

  const [mediaArray, setMediaArray] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [vTour, setVTour] = useState("");

  const [coverImg, setCoverImg] = useState("");
  const [deleteArray, setDeleteArray] = useState([]);

  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (trigger) {
      // setTimeout(() => {
      setTrigger(false);
      // }, 500);
    }
  }, [trigger]);

  const handleDelete = async (path) => {
    let toastId = toast.loading("Deleting...");
    setTimeout(() => {
      toast.dismiss(toastId);
    }, 10000);
    dispatch(
      deleteFile(
        { path },
        (res) => {
          if (type === "edit" || type === "duplicate") {
            let data = {
              images: devData?.images?.filter((li) => li !== path),
              type: "presale",
            };
            dispatch(
              editDevelopment(
                id,
                data,
                (res) => {
                  dispatch(getDevelopmentBySlug(res?.data?.slug));
                  toast.dismiss(toastId);
                  toast.success("Image Deleted");
                },
                (res) => {
                  toast.error(res?.message);
                }
              )
            );
          } else {
            let a = mediaArray.findIndex((li) => li === path);
            if (a !== -1) {
              mediaArray.splice(a, 1);
              setMediaArray([...mediaArray]);
              dispatch(handleAddDevelopment({ ...addDevelopment, "dev-aws": mediaArray }));
            }
            toast.dismiss(toastId);
            toast.success("Image Deleted");
          }
        },
        () => {}
      )
    );
  };

  const handleMultiple = (e) => {
    if (e.target.checked) {
      setDeleteArray([...deleteArray, e.target.value]);
    } else {
      let a = deleteArray?.filter((li) => li !== e.target.value);
      setDeleteArray(a);
    }
  };

  const deleteMultiples = async () => {
    let toastId = toast.loading("Deleting...");
    setTimeout(() => {
      toast.dismiss(toastId);
    }, 10000);
    dispatch(
      deleteMultipleFiles(
        { paths: deleteArray },
        (res) => {
          if (type === "edit" || type === "duplicate") {
            let data = {
              images: devData?.images?.filter((li) => !deleteArray?.includes(li)),
              type: "presale",
            };
            dispatch(
              editDevelopment(
                id,
                data,
                (res) => {
                  dispatch(getDevelopmentBySlug(res?.data?.slug));
                  toast.dismiss(toastId);
                  setDeleteArray([]);
                  toast.success("Images Deleted");
                },
                (res) => {
                  toast.error(res?.message);
                }
              )
            );
          } else {
            let ab = mediaArray?.filter((li) => !deleteArray?.includes(li));
            setMediaArray([...ab]);
            dispatch(handleAddDevelopment({ ...addDevelopment, "dev-aws": ab }));
            toast.dismiss(toastId);
            toast.success("Images Deleted");
            setDeleteArray([]);
          }
        },
        () => {
          toast.dismiss(toastId);
        }
      )
    );
  };

  const uploadImage = async (file) => {
    let toastId = toast.loading("Uploading...");
    setTimeout(() => {
      toast.dismiss(toastId);
      setTrigger(true);
    }, 10000);
    dispatch(
      uploadFile(
        file,
        (res) => {
          if (type === "edit" || type === "duplicate") {
            let img = [];
            if (res?.data) {
              img = res?.data?.map((li) => li?.url);
            }
            let images = [...devData?.images];
            let data = {
              type: "presale",
              images: [...images, ...img],
            };
            dispatch(
              editDevelopment(
                id,
                data,
                (result) => {
                  setTrigger(true);
                  dispatch(getDevelopmentBySlug(result?.data?.slug));
                  toast.dismiss(toastId);
                  toast.success("Image(s) Uploaded");
                },
                (res) => {
                  toast.error(res?.message);
                }
              )
            );
          } else if (addDevelopment?.["dev-aws"]) {
            let image = addDevelopment?.["dev-aws"];
            image = [...image, ...res?.data?.map((li) => li?.url)];
            dispatch(handleAddDevelopment({ ...addDevelopment, "dev-aws": image }));
            toast.dismiss(toastId);
            setMediaArray(image);
            setTrigger(true);
            toast.success("Image(s) Uploaded");
          } else if (!addDevelopment?.["dev-aws"]) {
            if (!mediaArray?.length) {
              dispatch(handleAddDevelopment({ ...addDevelopment, "dev-aws": [...res?.data?.map((li) => li?.url)] }));
              setMediaArray([...res?.data?.map((li) => li?.url)]);
            } else {
              dispatch(
                handleAddDevelopment({ ...addDevelopment, "dev-aws": [...mediaArray, res?.data?.map((li) => li?.url)] })
              );
              setMediaArray([...mediaArray, res?.data?.map((li) => li?.url)]);
            }
            setTrigger(true);
            toast.dismiss(toastId);
            toast.success("Image(s) Uploaded");
          }
        },
        (res) => {
          toast.error(res?.message);
          toast.dismiss(toastId);
        }
      )
    );
  };

  const handleCoverImg = (item, index) => {
    setCoverImg(item);
    const updatedArray = [...mediaArray];
    const elementToMove = updatedArray[index];
    updatedArray.splice(index, 1);
    updatedArray.splice(0, 0, elementToMove);
    setMediaArray(updatedArray);

    if (type === "edit" || type === "duplicate") {
      let data = {
        ...devData,
        images: updatedArray,
        type: "presale",
      };
      if (videoUrl) {
        data = { ...data, video: videoUrl };
      }
      if (vTour) {
        data = { ...data, vtour: vTour };
      }
      dispatch(
        editDevelopment(
          id,
          data,
          (result) => {
            setDevData(result?.data);
            toast.success("Cover Image Set");
          },
          (res) => {
            toast.error(res?.message);
          }
        )
      );
    } else {
      dispatch(handleAddDevelopment({ ...addDevelopment, "dev-aws": updatedArray }));
      toast.success("Cover Image Set");
    }
  };

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            toast.error("File is larger than 20 MB");
          }
          if (err.code === "file-invalid-type") {
            toast.error(err.message);
          }
        });
      });

      const newImages = acceptedFiles.map((file, index) => {
        let devName = devData?.name?.split(" ")?.join("-");

        let cityName = city?.city
          ?.find((li) => li?._id === devData?.address?.city)
          ?.name?.split(" ")
          ?.join("-");

        let homeTypeName = devData?.homeTypes?.[0]
          ? `-${homeTypeData?.homeType
              ?.find((li) => li?._id === devData?.homeTypes?.[0])
              ?.name?.split(" ")
              ?.join("-")}`
          : "";

        const newName = `${devName ? devName : "development"}-${cityName ? cityName : "city"}${
          homeTypeName ? homeTypeName : ""
        }-${uuid()?.slice(0, 8)}-${!mediaArray?.length ? index + 1 : mediaArray?.length + index + 1}.${file.name
          .split(".")
          .pop()}`;
        console.log("newName: ", newName);
        return new File([file], newName, { type: file.type });
      });

      const formData = new FormData();
      newImages.forEach((file) => {
        formData.append("file", file);
      });
      formData.append("folder", "development");
      formData.append("width", 1440);
      formData.append("height", 900);
      uploadImage(formData);
    },
    [
      city?.city,
      devData?.address?.city,
      devData?.homeTypes,
      devData?.name,
      homeTypeData?.homeType,
      mediaArray?.length,
      uploadImage,
    ]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 20,
    accept: {
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    },
    maxSize: 20971520,
  });

  useEffect(() => {
    if (addDevelopment?.["dev-aws"]) setMediaArray(addDevelopment?.["dev-aws"]);
  }, [addDevelopment]);

  const handleBack = () => {
    toggleBack();
  };

  const handleAddUpdate = (CB) => {
    let data = { ...devData, images: mediaArray, video: videoUrl, vtour: vTour };
    if (type === "edit" || type === "duplicate") {
      data.status = devData?.status;
    } else {
      data.status = "pending";
    }
    data.type = "presale";

    if (type === "edit" || type === "duplicate") {
      dispatch(
        editDevelopment(
          id,
          data,
          (res) => {
            dispatch(handleDevAddReset());
            CB({ success: true });
          },
          (res) => {
            toast.error(res?.message);
          }
        )
      );
    } else {
      dispatch(handleAddDevelopment({ ...addDevelopment, data, "dev-aws": null }));
      CB({ success: true });
    }
  };

  const handleNext = () => {
    handleAddUpdate((res) => {
      if (res?.success) {
        toggleNext();
      }
    });
  };

  const addToDraft = () => {
    let editData = {
      ...devData,
      images: mediaArray,
      video: videoUrl,
      vtour: vTour,
    };

    editData.type = "presale";
    if (type === "edit" || type === "duplicate") {
      editData.status = devData?.status;
    } else {
      editData.status = "draft";
    }

    if (profile?.profile?.role === "associate") {
      editData = {
        ...editData,
        company: {
          ...editData?.company,
          associate: {
            associate: profile?.profile?._id,
            byAssociate: true,
          },
        },
      };
    }

    console.log("editData: ", editData);

    if (type === "edit" || type === "duplicate") {
      dispatch(
        editDevelopment(
          id,
          editData,
          (res) => {
            toast.success("Development updated");
            dispatch(handleDevAddReset());
            navigate("/development");
          },
          (res) => {
            toast.error(res?.message);
          }
        )
      );
    } else {
      dispatch(
        addNewDevelopment(
          editData,
          (res) => {
            toast.success("Development added as draft");
            dispatch(handleDevAddReset());
            navigate("/development");
          },
          (res) => {
            toast.error(res?.message);
          }
        )
      );
    }
  };

  useEffect(() => {
    dispatch(getcity());
  }, [dispatch]);

  useEffect(() => {
    if (mediaArray?.length) setCoverImg(mediaArray?.[0]);
  }, [mediaArray, coverImg]);

  useEffect(() => {
    if (developmentById?.data) setDevData(developmentById?.data);
    else if (addDevelopment?.data) setDevData(addDevelopment?.data);
  }, [developmentById?.data, addDevelopment?.data]);

  useEffect(() => {
    if (devData) {
      console.log("devData3: ", devData);
      setVideoUrl(devData?.video || videoUrl);
      setVTour(devData?.vtour || vTour);
      setMediaArray(devData?.images);
    }
  }, [devData]);

  return (
    <>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                {devData?.name ? devData?.name : type === "add" ? "Add Development" : "Edit Development"}
              </BlockTitle>
            </BlockHeadContent>

            <BlockHeadContent>
              <Button
                color="light"
                outline
                className="bg-white d-inline-flex"
                onClick={(ev) => {
                  ev.preventDefault();
                  addToDraft();
                }}
              >
                <span>{type === "edit" ? "Update Development" : "Save as Draft"}</span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Card className="card-bordered">
            <div className="card-aside-wrap" id="user-detail-block">
              <div className="card-content">
                <DevNavTab toggleIconTab={toggleIconTab} activeIconTab={activeIconTab} update={handleAddUpdate} />

                <div className="card-inner">
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Media</BlockTitle>
                    </BlockHead>

                    <BlockContent>
                      <Row>
                        <Col sm={"12"} md={"12"} lg={"12"}>
                          <div className={clsx(styles.bannerWhite)}>
                            <h1 className="text-sm-start text-center">
                              Drag and drop the images to customize the image gallery order
                            </h1>
                            <div {...getRootProps({ className: styles.bannerDiv })}>
                              <IoImagesOutline />
                              <input
                                type="file"
                                id="profile1"
                                name="profile1"
                                style={{ display: "none" }}
                                {...getInputProps()}
                                readOnly
                              />
                              <p className={clsx(styles.para1)}>Drag and drop the images the gallery images here</p>
                              <p className={clsx(styles.para2)}>(Maximum size 1440x900)</p>
                              <button type="file" id="myfile" name="myfile">
                                Select and Upload
                              </button>
                            </div>

                            <p className={("text-sm-start text-center mt-4 mb-5", styles.para2)}>
                              Click on the star icon to select the cover image
                            </p>
                            {!trigger ? (
                              <Main
                                cards={mediaArray}
                                setCards={setMediaArray}
                                deleteArray={deleteArray}
                                coverImg={coverImg}
                                setDeleteArray={setDeleteArray}
                                handleCoverImg={handleCoverImg}
                                handleDelete={handleDelete}
                                handleMultiple={handleMultiple}
                              />
                            ) : null}

                            {deleteArray?.length ? (
                              <div className={clsx(styles.deleteBtn)}>
                                <button onClick={deleteMultiples}>Delete Selected</button>
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>

                  <br />
                  <br />

                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Video Tour</BlockTitle>
                    </BlockHead>

                    <BlockContent>
                      <Row className="gy-4">
                        {/* Video URL */}
                        <Col sm={"12"} md={"12"} lg={"12"}>
                          <FormGroup>
                            <Label htmlFor="name" className="form-label">
                              Video URL
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                className={`form-control `}
                                type="text"
                                id="videoUrl"
                                placeholder={"Enter Video URL such as YouTube, Vimeo, etc."}
                                value={videoUrl}
                                autoComplete="off"
                                name="videoUrl"
                                onChange={(e) => {
                                  setVideoUrl(e.target.value);
                                }}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>

                  <br />
                  <br />

                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">360 Virtual Tour (3D Walkthrough)</BlockTitle>
                    </BlockHead>

                    <BlockContent>
                      <Row className="gy-4">
                        {/* 360 Virtual Tour */}
                        <Col sm={"12"} md={"12"} lg={"12"}>
                          <FormGroup>
                            <Label htmlFor="name" className="form-label">
                              360 Virtual Tour
                            </Label>
                            <div className="form-control-wrap">
                              <textarea
                                className={`form-control `}
                                type="text"
                                id="vTour"
                                placeholder="Enter virtual tour embedded code. Example: https//"
                                value={vTour}
                                rows={4}
                                autoComplete="off"
                                name="vTour"
                                onChange={(e) => {
                                  setVTour(e.target.value);
                                }}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>
                </div>
              </div>
            </div>
          </Card>

          <div style={{ margin: "15px 0", display: "flex", gap: "1rem" }}>
            <FooterBtn text={"Cancel"} color={"secondary"} outline onClick={handleBack} />
            <FooterBtn text={"Next"} color={"info"} onClick={handleNext} />
          </div>
        </Block>
      </Content>
    </>
  );
};

export default Dev3;
