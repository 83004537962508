import { Editor } from "@tinymce/tinymce-react";
import React, { useRef } from "react";

const TinyMceEditor = ({ value, getValue }) => {
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  return (
    <>
      <Editor
        apiKey={process.env.REACT_APP_TINY_MCE_KEY}
        init={{
          plugins:
            "anchor autolink charmap codesample emoticons blocks image link lists media searchreplace table visualblocks wordcount linkchecker",
          toolbar:
            "undo redo | blocks sizeselect fontselect fontsizeselect | bold italic underline strikethrough link | image media table mergetags | numlist bullist | spellcheckdialog typography | align lineheight | indent outdent | emoticons charmap | removeformat",
          fontsize_formats: "8pt 10pt 12pt 14pt 16pt 18pt 20pt 24pt 36pt",
          mergetags_list: [
            { value: "First.Name", title: "First Name" },
            { value: "Email", title: "Email" },
          ],
          // ai_request: (request, respondWith) =>
          //   respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
        }}
        onEditorChange={(newValue, editor) => {
          getValue(newValue);
        }}
        value={value}
      />
    </>
  );
};

export default TinyMceEditor;
