import classnames from "classnames";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Dropzone from "react-dropzone";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import PlaceholderImg from "../../assets/placeholder-img.png";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  RSelect,
  Row,
} from "../../components/Component";
import ErrorComponent from "../../components/error/ErrorComponent";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { deleteFile, uploadFile } from "../../redux/Apislices/awsSlice";
import { addCity, deleteCity, getcity, updateCity } from "../../redux/Apislices/citySlice";
import { getprovince } from "../../redux/Apislices/provinceSlice";
// import { removeSession, setSession } from "../../utils/Storage";
import { RxOpenInNewWindow } from "react-icons/rx";
import { getneighbourhoodByCity } from "../../redux/Apislices/neighbourhoodSlice";
import { convertToSlug } from "../../utils/Utils";
import JoditPreview from "../components/forms/rich-editor/JoditPreview";
import styles from "./location.module.scss";
import { QuillComponent } from "../../components/partials/rich-editor/QuillComponent";
import TinyMceEditor from "../components/forms/rich-editor/TinyMceEditor";

const City = () => {
  const dispatch = useDispatch();
  const {
    cities: { loading, success, data, error, total },
    provinces,
    neighbourhoods,
    permission,
    profile,
  } = useSelector((state) => ({
    cities: state?.city?.cities,
    provinces: state?.province?.provinces,
    neighbourhoods: state?.neighbourhood?.neighbourhoodByCity,
    permission: state?.auth?.profile?.permissions?.city,
    profile: state?.auth?.profile,
  }));
  const [sm, updateSm] = useState(false);

  const [formData, setFormData] = useState({ name: "", slug: "", province: "", vipCost: "", associateCost: "" });
  let { name, slug, province, vipCost, associateCost } = formData;

  const [bannerTitle, setBannerTitle] = useState("");
  const [bannerImg, setBannerImg] = useState("");
  const [trigger, setTrigger] = useState(false);

  const [neighbourhood, setNeighbourhood] = useState("");
  const [topNeighbourhoods, setTopNeighbourhoods] = useState([]);

  const [detailsTitle, setDetailsTitle] = useState("");
  const [detailsContent, setDetailsContent] = useState("");

  const [faqTitle, setFaqTitle] = useState("");
  const [faqDescription, setFaqDescription] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [faqEditIndex, setFaqEditIndex] = useState(null);
  const [faq, setFaq] = useState([]);

  const [onSearchText, setSearchText] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(25);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [sort, setSort] = useState("");

  const [view, setView] = useState({ edit: false, add: false, details: null });
  const [editId, setEditedId] = useState(null);

  const { errors, register, handleSubmit } = useForm();

  const onInputChange = (e) => {
    if (e.target.name === "name") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        slug: convertToSlug(
          `new homes ${e.target.value} ${
            province ? `${provinces?.data?.find((li) => li?._id === province?.value)?.shortSymbol}` : ""
          }`
        ),
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to close the form modal
  const onFormCancel = () => {
    setView({ edit: false, add: false, details: false });
    resetForm();
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({ name: "", slug: "", province: "", vipCost: "" });
    setBannerTitle("");
    setBannerImg("");
    setDetailsTitle("");
    setDetailsContent("");
    setFaqTitle("");
    setFaqDescription("");
    setQuestion("");
    setAnswer("");
    setFaq([]);
    setTopNeighbourhoods([]);
  };

  // toggle function to view product details
  const toggle = (type) => {
    setView({
      edit: type === "edit" ? true : false,
      add: type === "add" ? true : false,
      details: type === "details" ? true : false,
    });
  };

  // category change
  const onCategoryChange = (value) => {
    setFormData({
      ...formData,
      province: value,
      slug: convertToSlug(`new homes ${name} ${provinces?.data?.find((li) => li?._id === value?.value)?.shortSymbol}`),
    });
  };

  // function to submit the form
  const onFormSubmit = (e) => {
    let data = {
      name,
      slug,
      province: typeof province === "object" && province?.value ? province?.value : "",
      details: { title: detailsTitle, content: detailsContent },
      faq: { title: faqTitle, description: faqDescription, content: faq },
      banner: { title: bannerTitle, image: bannerImg },
    };
    if (vipCost) data = { ...data, vipCost: +vipCost };
    if (associateCost) data = { ...data, associateCost: +associateCost };

    if (!province || (typeof province === "object" && province?.value === ""))
      return toast.error("Province is required");

    dispatch(
      addCity(
        data,
        (res) => {
          onFormCancel();
          dispatch(getcity({ page: currentPage, limit: itemPerPage, search: onSearchText, sort }));
          toast.success(res?.message);
        },
        (res) => {
          toast.error(res?.message);
        }
      )
    );
  };

  const onEditSubmit = () => {
    let data = {
      name,
      slug,
      province: typeof province === "object" && province?.value ? province?.value : "",
      details: { title: detailsTitle, content: detailsContent },
      faq: { title: faqTitle, description: faqDescription, content: faq },
      banner: { title: bannerTitle, image: bannerImg },
      topNeighbourhood: topNeighbourhoods?.map((li) => li.value),
    };
    if (vipCost) data = { ...data, vipCost: +vipCost };
    if (associateCost) data = { ...data, associateCost: +associateCost };

    if (!province || (typeof province === "object" && province?.value === ""))
      return toast.error("Province is required");

    dispatch(
      updateCity(
        editId,
        data,
        (res) => {
          onFormCancel();
          dispatch(getcity({ page: currentPage, limit: itemPerPage, search: onSearchText, sort }));
          toast.success(res?.message);
        },
        (res) => {
          toast.error(res?.message);
        }
      )
    );

    setEditedId(null);
    resetForm();
    setView({ edit: false, add: false, details: false });
  };

  // function that loads the want to editted data
  const onEditClick = (id) => {
    data.forEach((item) => {
      if (item._id === id) {
        setFormData({
          name: item.name,
          slug: item.slug,
          province: { label: item.province?.name, value: item.province?._id },
          vipCost: item.vipCost,
          associateCost: item.associateCost,
        });
        setBannerTitle(item.banner?.title);
        setBannerImg(item.banner?.img);
        setDetailsTitle(item.details?.title);
        setDetailsContent(item.details?.content);
        setFaqTitle(item.faq?.title);
        setFaqDescription(item?.faq?.description);
        setFaq(item.faq?.content ? item.faq?.content : []);
        setTopNeighbourhoods(
          item.topNeighbourhood?.length ? item.topNeighbourhood?.map((li) => ({ label: li.name, value: li._id })) : []
        );
      }
    });
    setEditedId(id);
    setView({ add: false, edit: true, details: false });
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deleteCity(
            id,
            (res) => {
              dispatch(getcity({ page: currentPage, limit: itemPerPage, search: onSearchText, sort }));
              toast.success(res?.message);
            },
            (res) => {
              toast.error(res?.message);
            }
          )
        );
      }
    });
  };

  const handleDropChange = (acceptedFiles) => {
    const newImages = acceptedFiles.map((file, index) => {
      let fileName = name ? convertToSlug(name) : "city";
      const newName = `${fileName}-${uuidv4()?.slice(0, 8)}.${file.name.split(".").pop()}`;
      return new File([file], newName, { type: file.type });
    });
    const formData = new FormData();
    newImages.forEach((file) => {
      formData.append("file", file);
    });
    formData.append("path", "admin/city");
    formData.append("width", 900);
    formData.append("height", 351);
    handleUpload(formData);
  };

  const handleUpload = (file) => {
    dispatch(
      uploadFile(
        file,
        (res) => {
          setTimeout(() => {
            setTrigger(true);
          }, 3000);
          setBannerImg(res?.data?.[0]?.url);
        },
        (res) => {
          toast.error(res?.message);
        }
      )
    );
  };

  const handleDelete = (path) => {
    dispatch(
      deleteFile(
        { path },
        (res) => {
          setBannerImg("");
        },
        (res) => {
          toast.error(res?.message);
        }
      )
    );
  };

  useEffect(() => {
    if (trigger) {
      setTimeout(() => {
        setTrigger(false);
      }, 2000);
    }
  }, [trigger]);

  useEffect(() => {
    const timer = setTimeout(
      () => {
        dispatch(getcity({ page: currentPage, limit: itemPerPage, search: onSearchText, sort }));
      },
      onSearchText ? 800 : 100
    );

    return () => clearTimeout(timer);
  }, [currentPage, dispatch, itemPerPage, onSearchText, sort]);

  useEffect(() => {
    dispatch(getneighbourhoodByCity(editId));
  }, [editId, dispatch]);

  useEffect(() => {
    dispatch(getprovince());
  }, []);

  return (
    <React.Fragment>
      <Head title="City"></Head>

      {/* Table View */}
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                City
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>
                  List of the total {total?.total} {total?.total === 1 ? "city" : "cities"}
                </p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v" />
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <div className="form-control-wrap">
                        <div className="form-icon form-icon-right">
                          <Icon name="search"></Icon>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="default-04"
                          placeholder="Search by name..."
                          onChange={(e) => onFilterChange(e)}
                        />
                      </div>
                    </li>

                    <li className="nk-block-tools-opt">
                      <Button
                        color="primary"
                        onClick={() => {
                          toggle("add");
                        }}
                        disabled={permission && !permission?.add}
                      >
                        <Icon name="plus" />
                        <span>Add City</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Card className="card-bordered">
            <div className="card-inner-group">
              {loading ? (
                <div
                  style={{
                    height: "200px",
                    width: "100%",
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  <Spinner type="grow" color="dark" />
                </div>
              ) : (
                <div className="card-inner p-0">
                  <DataTableBody>
                    <DataTableHead>
                      <DataTableRow>
                        <span
                          onClick={() => {
                            setSort(sort === "asc" ? "desc" : sort === "desc" ? "asc" : "desc");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Name
                          <Icon name={sort === "asc" ? "sort-up-fill" : sort === "desc" ? "sort-down-fill" : "sort"} />
                        </span>
                      </DataTableRow>
                      <DataTableRow>
                        <span>Slug</span>
                      </DataTableRow>
                      <DataTableRow>
                        <span
                          onClick={() => {
                            setSort(
                              sort === "prov-asc" ? "prov-desc" : sort === "prov-desc" ? "prov-asc" : "prov-desc"
                            );
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Province
                          <Icon
                            name={
                              sort === "prov-asc" ? "sort-up-fill" : sort === "prov-desc" ? "sort-down-fill" : "sort"
                            }
                          />
                        </span>
                      </DataTableRow>
                      <DataTableRow className="nk-tb-col-tools text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle color="tranparent" className="btn btn-trigger btn-icon dropdown-toggle">
                            <Icon name="setting"></Icon>
                          </DropdownToggle>
                          <DropdownMenu right className="dropdown-menu-xs">
                            <ul className="link-check">
                              <li>
                                <span>Show</span>
                              </li>
                              <li className={itemPerPage === 25 ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setItemPerPage(25);
                                  }}
                                >
                                  25
                                </DropdownItem>
                              </li>
                              <li className={itemPerPage === 50 ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setItemPerPage(50);
                                  }}
                                >
                                  50
                                </DropdownItem>
                              </li>
                              <li className={itemPerPage === 100 ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setItemPerPage(100);
                                  }}
                                >
                                  100
                                </DropdownItem>
                              </li>
                            </ul>
                            <ul className="link-check">
                              <li>
                                <span>Order</span>
                              </li>
                              <li className={sort === "asc" ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setSort("asc");
                                  }}
                                >
                                  ASC
                                </DropdownItem>
                              </li>
                              <li className={sort === "desc" ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setSort("desc");
                                  }}
                                >
                                  DESC
                                </DropdownItem>
                              </li>
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </DataTableRow>
                    </DataTableHead>
                    {success && data?.length ? (
                      data?.map((item) => {
                        return (
                          <DataTableItem key={item?._id}>
                            <DataTableRow>
                              <div className="tb-product">
                                <img
                                  src={
                                    item.banner?.image
                                      ? `${process.env.REACT_APP_IMGURL}${item.banner?.image}`
                                      : PlaceholderImg
                                  }
                                  alt="product"
                                  className="thumb"
                                />
                                <div className="title">
                                  <span
                                    onClick={() => {
                                      onEditClick(item._id);
                                      toggle("details");
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {item?.name}
                                  </span>
                                  <a
                                    href={`${process.env.REACT_APP_USER_WEB_URL}location/${item?.slug}`}
                                    target="_blank"
                                    style={{ marginLeft: "5px", cursor: "pointer" }}
                                    rel="noreferrer"
                                  >
                                    <RxOpenInNewWindow style={{ color: "#00aeff", fontSize: "18px" }} />
                                  </a>
                                </div>
                              </div>
                            </DataTableRow>
                            <DataTableRow>
                              <span className="tb-sub">{item?.slug}</span>
                            </DataTableRow>
                            <DataTableRow>
                              <span className="tb-sub">{item?.province?.name}</span>
                            </DataTableRow>
                            <DataTableRow className="nk-tb-col-tools text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  href="#more"
                                  onClick={(ev) => ev.preventDefault()}
                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                >
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <ul className="link-list-opt no-bdr">
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#view"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          onEditClick(item._id);
                                          toggle("details");
                                        }}
                                      >
                                        <Icon name="file-text"></Icon>
                                        <span>View Details</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          onEditClick(item._id);
                                          toggle("edit");
                                        }}
                                        disabled={permission && !permission?.edit}
                                      >
                                        <Icon name="edit"></Icon>
                                        <span>Edit</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#remove"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          onDelete(item._id);
                                        }}
                                        disabled={permission && !permission?.delete}
                                      >
                                        <Icon name="trash"></Icon>
                                        <span>Delete</span>
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </DataTableRow>
                          </DataTableItem>
                        );
                      })
                    ) : success && !data?.length ? (
                      <div
                        className="text-center"
                        style={{
                          padding: "20px",
                          fontSize: "1rem",
                          fontWeight: "600",
                        }}
                      >
                        <span className="text-silent">No Cities found</span>
                      </div>
                    ) : error ? (
                      <ErrorComponent error={error} />
                    ) : null}
                  </DataTableBody>
                  <div className="card-inner">
                    {data?.length ? (
                      <PaginationComponent
                        itemPerPage={total?.items_per_page || 25}
                        totalItems={total?.total}
                        paginate={paginate}
                        currentPage={currentPage || 1}
                      />
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          </Card>
        </Block>
      </Content>

      {/* Add & Edit */}
      <Modal
        isOpen={view.add || view.edit}
        toggle={() => {
          onFormCancel();
          if (view.edit) resetForm();
        }}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a href="#cancel" className="close">
            <Icon
              name="cross-sm"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
            ></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">{view.edit ? "Edit City" : "Add City"}</h5>
            <div className="mt-4">
              <form onSubmit={handleSubmit(view.edit ? onEditSubmit : onFormSubmit)}>
                <Row className="g-3">
                  <Col size="12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="product-title">
                        Name*
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={name}
                          onChange={(e) => onInputChange(e)}
                          ref={register({
                            required: "This field is required",
                          })}
                        />
                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                      </div>
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="sale-price">
                        Slug*
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control"
                          name="slug"
                          value={slug}
                          onChange={(e) => onInputChange(e)}
                          ref={register({ required: "This is required" })}
                        />
                        {errors.slug && <span className="invalid">{errors.slug.message}</span>}
                      </div>
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="regular-price">
                        Province*
                      </label>
                      <div className="form-control-wrap">
                        <RSelect
                          name="province"
                          value={province}
                          options={
                            provinces?.data?.length
                              ? provinces?.data?.map((li) => ({
                                  label: li.name,
                                  value: li._id,
                                }))
                              : {
                                  label: "No province found",
                                  value: "",
                                }
                          }
                          isLoading={provinces?.loading}
                          placeholder="Search & select a province..."
                          onChange={onCategoryChange}
                          ref={register({ required: "This is required" })}
                        />
                        {errors.category && <span className="invalid">{errors.category.message}</span>}
                      </div>
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="regular-price">
                        VIP Agent Cost*{" "}
                        {profile?.role !== "superadmin" ? (
                          <span className="ml-1" style={{ fontSize: "12px" }}>
                            (Note: Only for Superadmin)
                          </span>
                        ) : null}
                      </label>
                      <div className={`form-control-wrap`}>
                        <div className="input-group input-group">
                          <div className="input-group-prepend">
                            <span className={`input-group-text`} id="inputGroup-sizing-md">
                              $
                            </span>
                          </div>
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            className={"form-control"}
                            name="vipCost"
                            value={vipCost}
                            onChange={(e) => onInputChange(e)}
                            // ref={register({
                            //   required: "This field is required",
                            // })}
                            disabled={profile?.role !== "superadmin"}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="regular-price">
                        Asscoiate Cost*{" "}
                        {profile?.role !== "superadmin" ? (
                          <span className="ml-1" style={{ fontSize: "12px" }}>
                            (Note: Only for Superadmin)
                          </span>
                        ) : null}
                      </label>
                      <div className={`form-control-wrap`}>
                        <div className="input-group input-group">
                          <div className="input-group-prepend">
                            <span className={`input-group-text`} id="inputGroup-sizing-md">
                              $
                            </span>
                          </div>
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            className={"form-control"}
                            name="associateCost"
                            value={associateCost}
                            onChange={(e) => onInputChange(e)}
                            // ref={register({
                            //   required: "This field is required",
                            // })}
                            disabled={profile?.role !== "superadmin"}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>

                  {neighbourhoods?.data?.length && view?.edit ? (
                    <Col md="12">
                      <div className="form-group">
                        <label className="form-label" htmlFor="regular-price">
                          Top Neighbourhoods
                        </label>
                        <div className="form-control-wrap">
                          <RSelect
                            name="neighbourhood"
                            value={neighbourhood}
                            options={
                              neighbourhoods?.data?.length
                                ? neighbourhoods?.data
                                    ?.filter((li) => {
                                      return !topNeighbourhoods?.some((item) => item.value === li._id);
                                    })
                                    ?.map((li) => ({
                                      label: li.name,
                                      value: li._id,
                                    }))
                                : {
                                    label: "No neighbourhood found",
                                    value: "",
                                  }
                            }
                            isLoading={neighbourhoods?.loading}
                            placeholder="Search & select a neighbourhood..."
                            onChange={(value) => {
                              setTopNeighbourhoods([...topNeighbourhoods, value]);
                              setNeighbourhood(null);
                            }}
                          />
                        </div>

                        <div className={styles.topLists}>
                          {topNeighbourhoods?.length
                            ? topNeighbourhoods?.map((li, i) => (
                                <div key={i} className={styles.topList}>
                                  <div>{li.label}</div>
                                  <div
                                    onClick={() => {
                                      setTopNeighbourhoods(topNeighbourhoods.filter((item, index) => index !== i));
                                    }}
                                    className={styles.close}
                                  >
                                    <Icon name="cross" />
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    </Col>
                  ) : null}

                  <hr style={{ width: "100%", height: "1px", background: "#a0a0a0", color: "#a0a0a0" }} />

                  <Col md="12">
                    <div className="form-group">
                      <label style={{ fontSize: "18px" }} className="form-label" htmlFor="regular-price">
                        Banner
                      </label>
                      <Row className={"g-3"}>
                        <Col md="12">
                          <label className="form-label" htmlFor="regular-price">
                            {`<H1>`} Title
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              className="form-control"
                              value={bannerTitle}
                              name="bannerTitle"
                              onChange={(e) => setBannerTitle(e.target.value)}
                            />
                          </div>
                        </Col>

                        <Col md="12">
                          <label className="form-label" htmlFor="regular-price">
                            Content
                          </label>
                          <div className="form-control-wrap">
                            {/* <JoditPreview
                              initialValue={detailsContent}
                              getValue={setDetailsContent}
                              placeholder={"Enter the details"}
                            />
                            <QuillComponent
                              value={detailsContent}
                              getValue={setDetailsContent}
                              placeholder={"Enter the details"}
                            /> */}
                            <TinyMceEditor
                              value={detailsContent}
                              getValue={setDetailsContent}
                              placeholder={"Enter the details"}
                            />
                          </div>
                        </Col>
                        {/* <Col md="12">
                          <label className="form-label" htmlFor="regular-price">
                            Image
                          </label>
                          <div className="form-control-wrap">
                            {bannerImg ? (
                              <div className={styles.dropzonePreview}>
                                <img src={`${process.env.REACT_APP_IMGURL}${bannerImg}`} alt="preview" />
                                <div>
                                  <Icon name="cross" onClick={() => handleDelete(bannerImg)} />
                                </div>
                              </div>
                            ) : (
                              <Dropzone onDrop={(acceptedFiles) => handleDropChange(acceptedFiles)} maxFiles={1}>
                                {({ getRootProps, getInputProps }) => (
                                  <section>
                                    <div
                                      {...getRootProps()}
                                      className="dropzone upload-zone dz-clickable"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <input {...getInputProps()} />

                                      <div className="dz-message">
                                        <span className="dz-message-text">Drag and drop file</span>
                                        <span className="dz-message-or">or</span>
                                        <Button color="primary" type={"button"}>
                                          SELECT
                                        </Button>
                                      </div>
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                            )}
                          </div>
                        </Col> */}
                      </Row>
                    </div>
                  </Col>

                  <hr style={{ width: "100%", height: "1px", background: "#a0a0a0", color: "#a0a0a0" }} />

                  <Col md="12">
                    <div className="form-group">
                      {/* <label style={{ fontSize: "18px" }} className="form-label" htmlFor="regular-price">
                        Details
                      </label> */}
                      <Row className={"g-3"}>
                        <Col md="12">
                          <label className="form-label" htmlFor="regular-price">
                            Listings Carousal Title {`<H2>`}
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              className="form-control"
                              name="detailsTitle"
                              value={detailsTitle}
                              onChange={(e) => setDetailsTitle(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col md="12">
                          <label className="form-label" htmlFor="regular-price">
                            Listings Sitemap Title {`<H2>`}
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              className="form-control"
                              name="faqTitle"
                              value={faqTitle}
                              onChange={(e) => setFaqTitle(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col md="12">
                          <label className="form-label" htmlFor="regular-price">
                            Content
                          </label>
                          <div className="form-control-wrap">
                            <TinyMceEditor
                              value={faqDescription}
                              getValue={setFaqDescription}
                              placeholder={"Enter the details"}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <hr style={{ width: "100%", height: "1px", background: "#a0a0a0", color: "#a0a0a0" }} />

                  <Col md="12">
                    <div className="form-group">
                      <label style={{ fontSize: "18px" }} className="form-label" htmlFor="regular-price">
                        Accordion
                      </label>
                      <Row className={"g-3"}>
                        <Col md="12">
                          <label className="form-label" htmlFor="regular-price">
                            Title {`<H3>`}
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              className="form-control"
                              name="question"
                              value={question}
                              onChange={(e) => setQuestion(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col md="12">
                          <label className="form-label" htmlFor="regular-price">
                            Content
                          </label>
                          <div className="form-control-wrap">
                            {/* <JoditPreview placeholder={"Enter the details"} /> */}
                            <TinyMceEditor value={answer} getValue={setAnswer} placeholder={"Enter the details"} />
                          </div>
                        </Col>
                        <Col md="12">
                          {faqEditIndex !== null ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1rem",
                              }}
                            >
                              <Button
                                color="primary"
                                onClick={() => {
                                  if (!question || !answer) return toast.error("Title and content both are required");
                                  let newFaq = [...faq];
                                  let data = { question, answer };
                                  newFaq?.splice(faqEditIndex, 0, data);
                                  setFaq(newFaq);
                                  setQuestion("");
                                  setAnswer("");
                                  setFaqEditIndex(null);
                                }}
                                type="button"
                              >
                                <span>Update</span>
                              </Button>
                              <Button
                                color="primary"
                                onClick={() => {
                                  let newFaq = [...faq];
                                  let data = { question, answer };
                                  newFaq?.splice(faqEditIndex, 0, data);
                                  setFaq(newFaq);
                                  setQuestion("");
                                  setAnswer("");
                                  setFaqEditIndex(null);
                                }}
                                type="button"
                              >
                                <span>Cancel</span>
                              </Button>
                            </div>
                          ) : (
                            <Button
                              color="primary"
                              onClick={() => {
                                // console.log("question: ", question);
                                // console.log("answer: ", answer);
                                if (!question || !answer) return toast.error("Title and content both are required");
                                setFaq([...faq, { question, answer }]);
                                setQuestion("");
                                setAnswer("");
                              }}
                              type="button"
                            >
                              <span>Add</span>
                            </Button>
                          )}
                        </Col>
                      </Row>

                      {faq?.length ? (
                        <Accordion className={styles.a} preExpanded={[1]}>
                          {faq?.map((li, i) => {
                            return (
                              <AccordionItem key={i} uuid={i + 1}>
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    <div>
                                      <div>{parse(li?.question || "")}</div>
                                      <div className={styles.CTASection}>
                                        <div
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setQuestion(li?.question);
                                            setAnswer(li?.answer);
                                            setFaqEditIndex(i);
                                            setFaq(faq.filter((item, index) => index !== i));
                                          }}
                                          className={styles.edit}
                                        >
                                          <Icon name="edit" />
                                        </div>
                                        <div
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setFaq(faq.filter((item, index) => index !== i));
                                          }}
                                          className={styles.close}
                                        >
                                          <Icon name="cross" />
                                        </div>
                                      </div>
                                    </div>
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <div className="answerContent">{parse(li?.answer || "")}</div>
                                </AccordionItemPanel>
                              </AccordionItem>
                            );
                          })}
                        </Accordion>
                      ) : null}
                    </div>
                  </Col>

                  <hr style={{ width: "100%", height: "1px", background: "#a0a0a0", color: "#a0a0a0" }} />
                  <Col size="12">
                    <Button color="primary" type="submit">
                      <span>{view.edit ? "Save" : "Save"}</span>
                    </Button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* Detail View */}
      <Modal
        isOpen={view.details}
        toggle={() => {
          onFormCancel();
        }}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a href="#cancel" className="close">
            <Icon
              name="cross-sm"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
            ></Icon>
          </a>
          <div className="p-2">
            <div className="card-inner">
              <Block>
                <BlockHead>
                  <BlockBetween>
                    <BlockHeadContent>
                      <BlockTitle tag="h3">City Information</BlockTitle>
                    </BlockHeadContent>
                    <BlockHeadContent>
                      <Button
                        color="primary"
                        outline
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle("edit");
                        }}
                      >
                        <Icon name="edit"></Icon>
                        <span>Edit</span>
                      </Button>
                    </BlockHeadContent>
                  </BlockBetween>
                </BlockHead>
                {bannerImg ? (
                  <img
                    src={bannerImg ? `${process.env.REACT_APP_IMGURL}${bannerImg}` : PlaceholderImg}
                    alt="banner"
                    style={{ width: "100%", height: "350px", objectFit: "cover", marginBottom: "10px" }}
                  />
                ) : null}
                <div className="profile-ud-list">
                  <div className="profile-ud-item">
                    <div className="profile-ud wider">
                      <span className="profile-ud-label">Name</span>
                      <span className="profile-ud-value">{name}</span>
                    </div>
                  </div>
                  <div className="profile-ud-item">
                    <div className="profile-ud wider">
                      <span className="profile-ud-label">Slug</span>
                      <span className="profile-ud-value">{slug}</span>
                    </div>
                  </div>
                  <div className="profile-ud-item">
                    <div className="profile-ud wider">
                      <span className="profile-ud-label">Province</span>
                      <span className="profile-ud-value">{province?.label}</span>
                    </div>
                  </div>
                  {vipCost ? (
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Vip Agent Cost</span>
                        <span className="profile-ud-value">{vipCost}</span>
                      </div>
                    </div>
                  ) : null}
                  {associateCost ? (
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Associate Cost</span>
                        <span className="profile-ud-value">{associateCost}</span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </Block>

              <hr style={{ width: "100%", height: "1px", background: "#a0a0a0", color: "#a0a0a0" }} />

              <Block>
                <BlockHead className="nk-block-head-line">
                  <BlockTitle tag="h6" className="overline-title text-base">
                    Top Neighbourhoods
                  </BlockTitle>
                </BlockHead>
                {topNeighbourhoods?.length ? (
                  <div className={styles.topLists}>
                    {topNeighbourhoods?.map((li, i) => (
                      <div key={i} className={styles.topList}>
                        <div>{li.label}</div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="profile-ud-value">No Top Neighbourhoods Found</p>
                )}
              </Block>

              <hr style={{ width: "100%", height: "1px", background: "#a0a0a0", color: "#a0a0a0" }} />

              <Block>
                <BlockHead className="nk-block-head-line">
                  <BlockTitle tag="h6" className="overline-title text-base">
                    Details
                  </BlockTitle>
                </BlockHead>
                {detailsTitle ? (
                  <p
                    className="profile-ud-value"
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    {parse(detailsTitle || "")}
                  </p>
                ) : null}
                {detailsContent ? (
                  <p className="profile-ud-value">{parse(detailsContent || "")}</p>
                ) : (
                  <p className="profile-ud-value">No Details Found</p>
                )}
              </Block>

              <hr style={{ width: "100%", height: "1px", background: "#a0a0a0", color: "#a0a0a0" }} />

              <Block>
                <BlockHead className="nk-block-head-line">
                  <BlockTitle tag="h6" className="overline-title text-base">
                    Accordion
                  </BlockTitle>
                </BlockHead>
                {faqTitle ? (
                  <p
                    className="profile-ud-value"
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    {parse(faqTitle || "")}
                  </p>
                ) : null}
                {faq?.length ? (
                  <Accordion className={styles.a} preExpanded={[1]}>
                    {faq?.map((li, i) => {
                      return (
                        <AccordionItem key={i} uuid={i + 1}>
                          <AccordionItemHeading>
                            <AccordionItemButton>{parse(li?.question || "")}</AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>{parse(li?.answer || "")}</AccordionItemPanel>
                        </AccordionItem>
                      );
                    })}
                  </Accordion>
                ) : (
                  <p className="profile-ud-value">No Accordion Found</p>
                )}
              </Block>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default City;
