/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import parse from "html-react-parser";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  RSelect,
  Row,
} from "../../components/Component";
import { useNavigate, useParams } from "react-router-dom";
import { Card, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, UncontrolledDropdown } from "reactstrap";
import clsx from "clsx";
import styles from "./blogs.module.scss";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { addNewBlog, getBlogCategories, getSingleBlog, updateBlog } from "../../redux/Apislices/blogSlice";
import FooterBtn from "../../components/common/FooterBtn";
import JoditPreview from "../components/forms/rich-editor/JoditPreview";
import { toast } from "react-toastify";
import { deleteFile, uploadFile } from "../../redux/Apislices/awsSlice";
import { getSession, setSession } from "../../utils/Storage";
import { v4 as uuid } from "uuid";
import { capitalizeFirstLetter, convertToSlug, strippedContent } from "../../utils/Utils";
import { useDropzone } from "react-dropzone";
import { AiOutlineCloseCircle } from "react-icons/ai";
import CustomCreatable from "../../components/input/custom/CustomCreatable";
import CreatableSelect from "react-select/creatable";
import Swal from "sweetalert2";
import { addNewBlogCategory } from "../../redux/Apislices/blogCategoryslice";
import { addNewTags, getAllTags } from "../../redux/Apislices/tagsSlice";

const statusOptions = [
  { value: "published", label: "Published" },
  { value: "on hold", label: "On Hold" },
  { value: "pending", label: "Pending" },
];

const AddBlog = () => {
  const navigate = useNavigate();
  const { type, id } = useParams();
  const dispatch = useDispatch();
  const {
    blogs: { categories, blog },
    tags: { tags },
  } = useSelector((state) => ({ blogs: state?.blogs, tags: state?.tags }));
  console.log("tags: ", tags);

  const [sm, updateSm] = useState(false);

  const [blogData, setBlogData] = useState(null);

  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [status, setStatus] = useState("");
  const [changeSlug, setChangeSlug] = useState(false);
  const [category, setCategory] = useState("");
  const [categoryArray, setCategoryArray] = useState([]);
  const [tag, setTag] = useState("");
  const [tagArray, setTagArray] = useState([]);
  console.log("tagArray: ", tagArray);
  const [content, setContent] = useState("");
  const [mediaArray, setMediaArray] = useState([]);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    dispatch(getBlogCategories());
    dispatch(getAllTags());
  }, []);

  useEffect(() => {
    if (type === "edit" && id) dispatch(getSingleBlog(id));
  }, [type, id, dispatch]);

  useEffect(() => {
    if (type === "edit" && id && blog?.data) {
      setBlogData(blog?.data);
      setTitle(blog?.data?.title);
      setSlug(blog?.data?.slug);
      setStatus(statusOptions?.find((li) => li?.value === blog?.data?.status));
      setContent(blog?.data?.content);
      setSession("blog-images", blog?.data?.images);
    }
  }, [type, id, dispatch, blog]);

  useEffect(() => {
    if (categories?.data?.length && blog?.data?.blogCategory?.length) {
      let array = [];
      blog?.data?.blogCategory?.forEach((item) => {
        let obj = categories?.data?.find((li) => li?._id === item);
        array.push({ value: obj?._id, label: obj?.name });
      });
      setCategoryArray(array);
    }
  }, [categories, blog]);

  useEffect(() => {
    if (tags?.data?.length && blog?.data?.tags?.length) {
      let array = [];
      blog?.data?.tags?.forEach((item) => {
        let obj = tags?.data?.find((li) => li?._id === item);
        array.push({ value: obj?._id, label: obj?.name });
      });
      setTagArray(array);
    }
  }, [tags, blog]);

  useEffect(() => {
    if (getSession("blog-images")) setMediaArray([...getSession("blog-images")]);
  }, []);

  useEffect(() => {
    if (trigger) setTrigger(false);
  }, [trigger]);

  const handleDelete = async (path) => {
    let toastId = toast.loading("Deleting...");
    setTimeout(() => {
      toast.dismiss(toastId);
    }, 10000);
    dispatch(
      deleteFile(
        { path },
        (res) => {
          if (getSession("blog-images")) {
            let images = getSession("blog-images");
            images = images.filter((li) => li?.url !== path);
            setSession("blog-images", images);
            setMediaArray(images);
          }
          toast.dismiss(toastId);
          toast.success("Deleted Successfully");
          toast.dismiss(toastId);
          toast.success("Deleted Successfully");
        },
        (res) => {
          toast.error(res?.message);
          toast.dismiss(toastId);
        }
      )
    );
  };

  const uploadImage = async (file) => {
    let toastId = toast.loading("Uploading...");
    setTimeout(() => {
      toast.dismiss(toastId);
      setTrigger(true);
    }, 10000);
    dispatch(
      uploadFile(
        file,
        (res) => {
          if (getSession("blog-images")) {
            let images = getSession("blog-images");
            images = [
              ...images,
              ...res?.data?.map((li) => ({ url: li?.url, title: li?.originalName, alt: li?.originalName })),
            ];
            setSession("blog-images", images);
            setMediaArray(images);
          } else {
            setSession("blog-images", [
              ...res?.data?.map((li) => ({ url: li?.url, title: li?.originalName, alt: li?.originalName })),
            ]);
            setMediaArray([
              ...res?.data?.map((li) => ({ url: li?.url, title: li?.originalName, alt: li?.originalName })),
            ]);
          }
          toast.dismiss(toastId);
          toast.success("Uploaded Successfully");
        },
        (res) => {
          toast.error(res?.message);
          toast.dismiss(toastId);
        }
      )
    );
  };

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            toast.error("File is larger than 20 MB");
          }
          if (err.code === "file-invalid-type") {
            toast.error(err.message);
          }
        });
      });

      const newImages = acceptedFiles.map((file, index) => {
        const newName = `${title ? convertToSlug(title) : "blog"}-${uuid()?.slice(0, 8)}-${
          !mediaArray?.length ? index + 1 : mediaArray?.length + index + 1
        }.${file.name.split(".").pop()}`;
        console.log("newName: ", newName);
        return new File([file], newName, { type: file.type });
      });

      const formData = new FormData();
      newImages.forEach((file) => {
        formData.append("file", file);
      });
      formData.append("folder", "blog");
      formData.append("width", 1440);
      formData.append("height", 900);
      uploadImage(formData);
    },
    [title, mediaArray?.length, uploadImage]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 10,
    accept: { "image/jpg": [".jpg"], "image/jpeg": [".jpeg"], "image/png": [".png"] },
    maxSize: 20971520,
  });

  const handleBack = () => navigate(-1);

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {};
    if (blogData) data = { ...data, ...blogData };
    data = {
      ...data,
      title,
      slug: slug ? slug : convertToSlug(title),
      status: status && typeof status === "object" ? status?.value : "pending",
      blogCategory: categoryArray?.map((li) => li?.value),
      tags: tagArray?.map((li) => li?.value),
      content: content,
      images: mediaArray,
    };
    console.log("data: ", data);

    if (title && strippedContent(content) && categoryArray?.length) {
      if (type === "edit" && id)
        dispatch(
          updateBlog(
            id,
            data,
            (res) => {
              toast.success("Blog Updated Successfully");
              navigate("/blogs");
            },
            (res) => {
              toast.error(res?.message);
            }
          )
        );
      else
        dispatch(
          addNewBlog(
            data,
            (res) => {
              toast.success("Blog Added Successfully");
              navigate("/blogs");
            },
            (res) => {
              toast.error(res?.message);
            }
          )
        );
    } else {
      toast.error("Please fill all the required fields");
    }
  };

  return (
    <React.Fragment>
      <Head title="Blogs"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>{type === "edit" && id ? "Edit" : "Add"} Blog</BlockTitle>
              <BlockDes className="text-soft"></BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand mr-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    updateSm(!sm);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button className="toggle btn-icon d-md-none" onClick={handleBack}>
                        <Icon name="arrow-long-left"></Icon>
                      </Button>

                      <Button
                        className="toggle d-none btn-outline-primary btn-dim d-md-inline-flex"
                        onClick={handleBack}
                      >
                        <Icon name="arrow-long-left"></Icon>
                        <span>Back</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card className="card-bordered px-3">
            <div className="card-aside-wrap" id="user-detail-block">
              <form onSubmit={handleSubmit} className="card-content">
                <Row>
                  <Col lg={12}>
                    <FormGroup className={clsx("", styles.inputDiv)}>
                      <label className="form-label" htmlFor="blog-title">
                        Blog Title*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="blog-title"
                        placeholder="Blog Title"
                        value={title}
                        name="title"
                        onChange={(e) => {
                          setTitle(capitalizeFirstLetter(e.target.value));
                          if (!changeSlug) setSlug(convertToSlug(e.target.value));
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup className={clsx("", styles.inputDiv)}>
                      <label className="form-label" htmlFor="slug">
                        Slug{" "}
                        <span onClick={() => setChangeSlug(!changeSlug)} style={{ color: "#00aeff" }}>
                          (Change Slug)
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="slug"
                        placeholder="{title}"
                        value={slug}
                        name="slug"
                        disabled={!changeSlug}
                        onChange={(e) => {
                          setSlug(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup className={clsx("", styles.inputDiv)}>
                      <label className="form-label" htmlFor="blog-status">
                        Blog Status
                      </label>
                      <RSelect id="blog-status" options={statusOptions} value={status} onChange={(e) => setStatus(e)} />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup className={clsx("", styles.inputDiv)}>
                      <label className="form-label" htmlFor="blog-category">
                        Blog Category
                      </label>
                      <CreatableSelect
                        options={
                          categories?.data?.length
                            ? categories?.data?.map((item) =>
                                categoryArray?.map((li) => li?.value)?.includes(item?._id)
                                  ? {
                                      value: item?._id,
                                      label: item?.name,
                                      isDisabled: true,
                                    }
                                  : {
                                      value: item?._id,
                                      label: item?.name,
                                    }
                              )
                            : []
                        }
                        value={category}
                        onChange={(e) => {
                          setCategoryArray([...categoryArray, e]);
                          setCategory("");
                        }}
                        onCreateOption={(e) => {
                          console.log("e: ", e);
                          Swal.fire({
                            title: "Are you sure?",
                            text: `You want to add "${e}" as new blog category!`,
                            icon: "question",
                            showCancelButton: true,
                            confirmButtonText: "Add",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              dispatch(
                                addNewBlogCategory(
                                  { name: e, slug: convertToSlug(e) },
                                  (res) => {
                                    if (res?.success) {
                                      // dispatch(getBlogCategories());
                                      let a = { value: res?.data?._id, label: res?.data?.name };
                                      setCategoryArray([...categoryArray, a]);
                                      setCategory("");
                                    }
                                  },
                                  (err) => {
                                    Swal.fire("Error!", "Something went wrong!", "error");
                                    toast.error(err?.message);
                                  }
                                )
                              );
                            } else {
                              setCategory("");
                            }
                          });
                        }}
                      />
                      <div style={{ textAlign: "end" }}>Select or add new blog category</div>
                    </FormGroup>

                    <div className={styles.arrayDiv}>
                      {categoryArray?.length
                        ? categoryArray?.map((li, i) => (
                            <div
                              key={i}
                              onClick={() => {
                                const array = categoryArray.filter((item) => item !== li);
                                setCategoryArray(array);
                              }}
                            >
                              <Icon name={"cross-circle"} />
                              <span>{li?.label}</span>
                            </div>
                          ))
                        : null}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <FormGroup className={clsx("", styles.inputDiv)}>
                      <label className="form-label" htmlFor="blog-category">
                        Blog tags
                      </label>
                      <CreatableSelect
                        options={
                          tags?.data?.length
                            ? tags?.data?.map((item) =>
                                tagArray?.map((li) => li?.value)?.includes(item?._id)
                                  ? {
                                      value: item?._id,
                                      label: item?.name,
                                      isDisabled: true,
                                    }
                                  : {
                                      value: item?._id,
                                      label: item?.name,
                                    }
                              )
                            : []
                        }
                        value={tag}
                        onChange={(e) => {
                          setTagArray([...tagArray, e]);
                          setTag("");
                        }}
                        onCreateOption={(e) => {
                          Swal.fire({
                            title: "Are you sure?",
                            text: `You want to add "${e}" as new tag!`,
                            icon: "question",
                            showCancelButton: true,
                            confirmButtonText: "Add",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              dispatch(
                                addNewTags(
                                  { name: e, slug: convertToSlug(e) },
                                  (res) => {
                                    if (res?.success) {
                                      // dispatch(getAllTags());
                                      let a = { value: res?.data?._id, label: res?.data?.name };
                                      setTagArray([...tagArray, a]);
                                      setTag("");
                                    }
                                  },
                                  (err) => {
                                    Swal.fire("Error!", "Something went wrong!", "error");
                                    toast.error(err?.message);
                                  }
                                )
                              );
                            } else {
                              setCategory("");
                            }
                          });
                        }}
                      />
                    </FormGroup>
                    <div className={styles.arrayDiv}>
                      {tagArray?.length
                        ? tagArray?.map((li, i) => (
                            <div
                              key={li?.value}
                              onClick={() => {
                                const array = tagArray.filter((item) => item !== li);
                                setTagArray(array);
                              }}
                            >
                              <Icon name={"cross-circle"} />
                              <span>{li?.label}</span>
                            </div>
                          ))
                        : null}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <FormGroup className={clsx("", styles.inputDiv)}>
                      <label className="form-label" htmlFor="blog-image">
                        Blog Image
                      </label>
                      <div {...getRootProps()} className={styles.uploadInput}>
                        <p>Select the floor plan image (png or jpeg)</p>
                        <input className={clsx("form-control")} type="file" {...getInputProps()} />
                      </div>
                    </FormGroup>
                    <div className={clsx("customScrollbar", styles.imageGallery)}>
                      {mediaArray?.length
                        ? mediaArray?.map((item) => (
                            <div key={`${Math.random()}`}>
                              <div className={styles.imgDiv}>
                                <img src={`${process.env.REACT_APP_IMGURL}${item?.url}`} alt={item?.alt} />
                                <div className={styles.overlay}>
                                  <div
                                    className={styles.delete}
                                    onClick={() => {
                                      handleDelete(item?.url);
                                    }}
                                  >
                                    <AiOutlineCloseCircle /> Delete
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <FormGroup className={clsx("", styles.inputDiv)}>
                      <label className="form-label" htmlFor="blog-content">
                        Blog Content
                      </label>
                      <JoditPreview initialValue={content} getValue={setContent} placeholder={"Blog Content"} />
                    </FormGroup>
                  </Col>
                </Row>
                <div style={{ margin: "25px 0", display: "flex", gap: "1rem" }}>
                  <FooterBtn text={"Cancel"} color={"secondary"} outline onClick={handleBack} />
                  <FooterBtn type={"submit"} text={"Submit"} color={"info"} />
                </div>
              </form>
            </div>
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default AddBlog;
