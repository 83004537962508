import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ImNewTab } from "react-icons/im";
import { LuArchiveRestore } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Spinner, UncontrolledDropdown } from "reactstrap";
import Swal from "sweetalert2";
import PlaceholderImg from "../../assets/profile_avatar.png";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  ProjectCard,
  RSelect,
  Row,
  UserAvatar,
} from "../../components/Component";
import ErrorComponent from "../../components/error/ErrorComponent";
import DotPagination from "../../components/pagination/DotPaginate";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  archiveBlog,
  deleteBlog,
  getAllBlogs,
  getBlogCategories,
  handleBlogReset,
  restoreBlog,
} from "../../redux/Apislices/blogSlice";
import { findUpper, getScreenWidth } from "../../utils/Utils";
import styles from "./blogs.module.scss";

const statusOptions = [
  { value: "", label: "All" },
  { value: "published", label: "Published" },
  { value: "pending", label: "Pending" },
  { value: "draft", label: "Draft" },
  { value: "on hold", label: "On Hold" },
];

const itemsPerPageOptions = [
  { value: 25, label: "25" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];

const BlogsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    blogs: { blogs, categories },
    permission,
  } = useSelector((state) => ({
    blogs: state.blogs,
    permission: state?.auth?.profile?.permissions?.blogs,
  }));

  const [selectedData, setSelectedData] = useState([]);
  const [screenWidth, setScreenWidth] = useState(getScreenWidth());
  const [sm, updateSm] = useState(false);

  const [category, setCategory] = useState(null);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [limit, setLimit] = useState({ value: 25, label: "25" });
  const [sort, setSort] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(25);
  const [status, setStatus] = useState(null);

  const handleGetBlogs = () => {
    dispatch(
      getAllBlogs({
        page: currentPage,
        limit: itemPerPage,
        search: onSearchText,
        sort,
        status: status && typeof status === "object" ? status?.value : "",
        category: category && typeof category === "object" ? category?.value : "",
      })
    );
  };

  //debounce getDevelopmentList for search
  useEffect(() => {
    const timer = setTimeout(
      () => {
        handleGetBlogs();
      },
      onSearchText ? 800 : 200
    );
    return () => clearTimeout(timer);
  }, [dispatch, currentPage, itemPerPage, onSearchText, sort, status, category]);

  useEffect(() => {
    if (typeof limit === "object" && limit?.value) setItemPerPage(limit?.value);
    else setItemPerPage(25);
  }, [limit]);

  useEffect(() => {
    dispatch(getBlogCategories());
  }, []);

  // selects all the products
  const selectorCheck = (e) => {
    if (e.currentTarget.checked) setSelectedData(blogs?.data?.map((item) => item._id));
    else setSelectedData([]);
  };

  // selects one product
  const onSelectChange = (e, id) => {
    if (e.currentTarget.checked) setSelectedData([...selectedData, id]);
    else setSelectedData(selectedData.filter((item) => item !== id));
  };

  // toggle search input
  const toggle = () => setonSearch(!onSearch);

  // onChange function for searching name
  const onFilterChange = (e) => setSearchText(e.target.value);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleArchive = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Your blog will be archived!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Archive",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          archiveBlog(
            item?._id,
            (res) => {
              if (res?.success) {
                dispatch(handleGetBlogs());
              }
              Swal.fire("Archived!", "Blog has been archived.", "success");
            },
            (err) => {
              Swal.fire("Error!", "Something went wrong!", "error");
              toast.error(err?.message);
            }
          )
        );
      }
    });
  };

  const handleRestore = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You blog will be restored!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Restore",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          restoreBlog(
            item?._id,
            (res) => {
              if (res?.success) {
                dispatch(handleGetBlogs());
              }
              Swal.fire("Archived!", "Blog has been restored.", "success");
            },
            (err) => {
              Swal.fire("Error!", "Something went wrong!", "error");
              toast.error(err?.message);
            }
          )
        );
      }
    });
  };

  const handleDelete = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deleteBlog(
            item?._id,
            (res) => {
              if (res?.success) {
                dispatch(handleGetBlogs());
              }
              Swal.fire("Deleted!", "Blog has been deleted.", "success");
            },
            (err) => {
              Swal.fire("Error!", "Something went wrong!", "error");
              toast.error(err?.message);
            }
          )
        );
      }
    });
  };

  return (
    <React.Fragment>
      <Head title="Blogs"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>Blogs</BlockTitle>
              <BlockDes className="text-soft">
                {blogs?.data?.length && blogs?.success ? <p>You have total {blogs?.total?.total} blogs.</p> : null}
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand mr-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    updateSm(!sm);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>

                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button
                        className="toggle btn-icon d-md-none"
                        onClick={() => {
                          navigate("/blog/add");
                        }}
                      >
                        <Icon name="plus"></Icon>
                      </Button>

                      <Button
                        className="toggle d-none btn-outline-primary btn-dim d-md-inline-flex"
                        onClick={() => {
                          navigate("/blog/add");
                        }}
                        disabled={permission && !permission?.add}
                      >
                        <Icon name="plus"></Icon>
                        <span>Add Blog</span>
                      </Button>
                    </li>

                    {(typeof status === "object" && status?.value === "archive") || status === "archive" ? (
                      <li className="nk-block-tools-opt">
                        <Button
                          className="toggle btn-icon btn-outline-info d-md-none"
                          color="primary"
                          onClick={() => {
                            setStatus(null);
                          }}
                        >
                          <Icon name="arrow-long-left"></Icon>
                        </Button>

                        <Button
                          className="toggle btn-outline-info btn-dim d-none d-md-inline-flex"
                          onClick={() => {
                            setStatus(null);
                          }}
                        >
                          <Icon name="arrow-long-left"></Icon>
                          <span>All Blogs</span>
                        </Button>
                      </li>
                    ) : (
                      <li className="nk-block-tools-opt">
                        <Button className="toggle btn-icon d-md-none" color="primary" onClick={() => {}}>
                          <Icon name="archive"></Icon>
                        </Button>

                        <Button
                          className="toggle btn-outline-danger btn-dim d-none d-md-inline-flex"
                          onClick={() => {
                            setStatus({ value: "archive", label: "Archive" });
                          }}
                        >
                          <Icon name="archive"></Icon>
                          <span>Archived Blogs</span>
                        </Button>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row
            className={""}
            style={{ border: "1px solid #d2d3ec", margin: "0 0 10px 0px", padding: "10px 5px", borderRadius: "5px" }}
          >
            <Col sm={"12"} md={"6"} lg={"6"}>
              <Row>
                <Col sm={"12"} md={"6"} lg={"6"}>
                  <div className="">
                    <RSelect
                      options={categories?.data?.map((item) => ({ value: item?.slug, label: item?.name }))}
                      isClearable
                      placeholder="Categories"
                      onChange={(e) => setCategory(e)}
                      isLoading={categories?.loading}
                      value={category}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col sm={"12"} md={"12"} lg={"6"} className={"card-tools mr-n1"}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                <ul className="btn-toolbar gx-1">
                  {/* Search */}
                  <li>
                    <div
                      className="search-content"
                      style={{
                        border: "1px solid #dbdfea",
                        borderRadius: "4px",
                      }}
                    >
                      <Button
                        className="search-back btn-icon toggle-search active"
                        onClick={() => {
                          setSearchText("");
                          toggle();
                        }}
                      >
                        <Icon name="search"></Icon>
                      </Button>
                      <input
                        type="text"
                        className="border-transparent form-focus-none form-control"
                        placeholder="Search by user or email"
                        value={onSearchText}
                        onChange={(e) => onFilterChange(e)}
                      />
                      {onSearchText ? (
                        <Button
                          className="search-submit btn-icon"
                          onClick={() => {
                            setSearchText("");
                          }}
                        >
                          <Icon name="cross"></Icon>
                        </Button>
                      ) : null}
                    </div>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <li>
                    <UncontrolledDropdown>
                      <DropdownToggle tag="a">
                        <Button color={"primary"}>
                          <Icon className={"mr-1"} name="filter-fill"></Icon>
                          Filter
                        </Button>
                      </DropdownToggle>
                      <DropdownMenu right className="filter-wg dropdown-menu-xl" style={{ overflow: "visible" }}>
                        <div className="dropdown-head">
                          <span className="sub-title dropdown-title">Filter Blogs</span>
                        </div>
                        <div className="dropdown-body dropdown-body-rg">
                          <Row className="gx-6 gy-3">
                            <Col size="6">
                              <FormGroup>
                                <label className="overline-title overline-title-alt">Status</label>
                                <RSelect
                                  options={statusOptions}
                                  onChange={(value) => {
                                    setStatus(value);
                                  }}
                                  value={status}
                                  placeholder="Select"
                                />
                              </FormGroup>
                            </Col>
                            <Col size="6">
                              <FormGroup>
                                <label className="overline-title overline-title-alt">Items Per Page</label>
                                <RSelect
                                  options={itemsPerPageOptions}
                                  onChange={(value) => {
                                    setLimit(value);
                                  }}
                                  value={limit}
                                  placeholder="Select"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                        <div className="dropdown-foot between">
                          <span></span>
                          <a
                            href="#reset"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setStatus("");
                              // setSaleStatus("");
                            }}
                            className="clickable"
                          >
                            Reset Filter
                          </a>
                        </div>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <li>
                    <UncontrolledDropdown>
                      <DropdownToggle color="tranparent" className="btn btn-trigger btn-icon dropdown-toggle">
                        <Icon name="sort-line"></Icon>
                      </DropdownToggle>
                      <DropdownMenu right>
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setSort("recently-updated");
                              }}
                              className={sort === "recently-updated" ? "text text-primary" : ""}
                            >
                              <span>Recently Updated</span>
                            </DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setSort("recently-added");
                              }}
                              className={sort === "recently-added" ? "text text-primary" : ""}
                            >
                              <span>Recently Added</span>
                            </DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setSort("oldest");
                              }}
                              className={sort === "oldest" ? "text text-primary" : ""}
                            >
                              <span>Oldest First</span>
                            </DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setSort("asc");
                              }}
                              className={sort === "asc" ? "text text-primary" : ""}
                            >
                              <span>0-9, A-Z</span>
                            </DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setSort("desc");
                              }}
                              className={sort === "desc" ? "text text-primary" : ""}
                            >
                              <span>9-0, Z-A</span>
                            </DropdownItem>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className="g-gs">
            {blogs?.loading ? (
              <div
                style={{
                  height: "200px",
                  width: "100%",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <Spinner type="grow" color="dark" />
              </div>
            ) : blogs?.data?.length && blogs?.success ? (
              blogs?.data.map((item, idx) => {
                return (
                  <Col sm="6" lg="4" xxl="3" key={item._id}>
                    <ProjectCard>
                      <div className={styles.blogDiv}>
                        <div>
                          <div className="project-head">
                            <div className="project-title">
                              <UserAvatar className="sq" theme={item.avatarClass} text={findUpper(item.title)} />
                              <div className="project-info">
                                <h6 className={clsx("title", styles.titleDiv)}>
                                  <a
                                    href={`${process.env.REACT_APP_USER_WEB_URL}blogs/${item?.slug}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {item.title}
                                    <ImNewTab style={{ color: "#00aeff", marginLeft: "5px", fontSize: "14px" }} />
                                  </a>
                                </h6>
                                <span className={clsx("sub-text", styles.categoryDiv)}>
                                  {item.blogCategory?.map((li) => li?.name)?.join(", ")}
                                </span>
                              </div>
                            </div>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                tag="a"
                                className="dropdown-toggle btn btn-sm btn-icon btn-trigger mt-n1 mr-n1"
                              >
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu right>
                                <ul className="link-list-opt no-bdr">
                                  {(typeof status === "object" && status?.value === "archive") ||
                                  status === "archive" ? (
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#view"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          handleRestore(item);
                                        }}
                                        disabled={permission && !permission?.edit}
                                      >
                                        <LuArchiveRestore
                                          style={{ fontSize: "18px", marginRight: "15px", color: "#526484" }}
                                        />
                                        <span>Restore</span>
                                      </DropdownItem>
                                    </li>
                                  ) : (
                                    <>
                                      <li>
                                        <DropdownItem
                                          tag="a"
                                          href="#edit"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            dispatch(handleBlogReset());
                                            navigate(`/blog/edit/${item?.slug}`);
                                          }}
                                          disabled={permission && !permission?.edit}
                                        >
                                          <Icon name="edit"></Icon>
                                          <span>Edit this Blog</span>
                                        </DropdownItem>
                                      </li>
                                      {["published", "on hold", "pending"]?.includes(item?.status) && (
                                        <li>
                                          <DropdownItem
                                            tag="a"
                                            href="#view"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                              // handleActiveDevelopment(item);
                                            }}
                                            disabled={permission && !permission?.edit}
                                          >
                                            <Icon name="eye"></Icon>
                                            <span>{item?.status === "published" ? "Put On Hold" : "Publish It"}</span>
                                          </DropdownItem>
                                        </li>
                                      )}
                                      <li>
                                        <DropdownItem
                                          tag="a"
                                          href="#view"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            handleArchive(item);
                                          }}
                                          disabled={permission && !permission?.archive}
                                        >
                                          <Icon name="archived-fill"></Icon>
                                          <span>Archive</span>
                                        </DropdownItem>
                                      </li>
                                    </>
                                  )}
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href="#view"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        handleDelete(item);
                                      }}
                                      disabled={permission && !permission?.delete}
                                    >
                                      <Icon name="trash"></Icon>
                                      <span>Delete Permanently</span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                          <div className={clsx("", styles.tagsDiv)}>
                            <span>
                              <Icon name="tags"></Icon>
                              <span> Tags:</span>
                            </span>{" "}
                            <span className={clsx("", styles.tags)}>
                              {item.tags?.map((li) => li?.name)?.join(", ")}
                            </span>
                          </div>
                        </div>
                        <hr />
                        <div className={styles.footerDiv}>
                          <div className={styles.footerLeft}>
                            <UserAvatar
                              theme="light"
                              className="sm"
                              text={`${item?.user?.firstName}${item?.user?.lastName ? ` ${item?.user?.lastName}` : ""}`}
                              image={
                                item?.user?.profilePhoto
                                  ? `${process.env.REACT_APP_IMGURL}${item?.user?.profilePhoto}`
                                  : PlaceholderImg
                              }
                            />
                            <div className={styles.detail}>
                              <span>
                                {`${item?.user?.firstName}${item?.user?.lastName ? ` ${item?.user?.lastName}` : ""}`}
                              </span>
                              <span>{`(${item?.user?.role})`}</span>
                            </div>
                          </div>
                          <div className={styles.footerRight}>
                            <span>{moment(item.publishDate || item?.createdAt).format("MMM DD, YYYY")}</span>
                          </div>
                        </div>
                      </div>
                    </ProjectCard>
                  </Col>
                );
              })
            ) : blogs?.success && !blogs?.data?.length ? (
              <div
                className="text-center"
                style={{
                  padding: "20px",
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                }}
              >
                <span className="text-silent">No blogs found</span>
              </div>
            ) : blogs?.error ? (
              <ErrorComponent error={blogs?.error} />
            ) : null}
          </Row>
          <div className="mt-3">
            {blogs?.success && blogs?.data?.length ? (
              <DotPagination
                itemPerPage={blogs?.total?.items_per_page}
                totalItems={blogs?.total?.total}
                paginate={paginate}
                currentPage={currentPage}
                siblingCount={2}
                screenWidth={screenWidth}
              />
            ) : null}
          </div>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default BlogsPage;
