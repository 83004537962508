import { StandaloneSearchBox, useLoadScript } from "@react-google-maps/api";
import axios from "axios";
import clsx from "clsx";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { AiOutlineCloseCircle, AiOutlinePlus } from "react-icons/ai";
import { MdArrowDropDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { components } from "react-select";
import { toast } from "react-toastify";
import { Card, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import Content from "../../layout/content/Content";
import { cityReset, getcityByProvince } from "../../redux/Apislices/citySlice";
import { getConstruction } from "../../redux/Apislices/constructionSlice";
import { countryReset, getCountry } from "../../redux/Apislices/countrySlice";
import { addNewDevelopment, getDevelopmentList } from "../../redux/Apislices/developmentSlice";
import { getDevelopmentType } from "../../redux/Apislices/developmentTypeSlice";
import { getHomeStyleByType } from "../../redux/Apislices/homeStyleSlice";
import { getHomeType } from "../../redux/Apislices/homeTypeSlice";
import {
  addListing,
  checkMls,
  editListing,
  getCommunityForListing,
  getDevelopmentForListing,
  handleResetListing,
} from "../../redux/Apislices/listingSlice";
import { getneighbourhoodByCity, neighbourhoodReset } from "../../redux/Apislices/neighbourhoodSlice";
import { getprovinceByCountry, provinceReset } from "../../redux/Apislices/provinceSlice";
import { getUnitPreference } from "../../redux/Apislices/unitPreferenceSlice";
import { getSession, removeSession, setSession } from "../../utils/Storage";
import { USDollar, convertToSlug, debounce } from "../../utils/Utils";
import { getLocationData } from "../../utils/location";
import { allMonths, yearsAfter, yearsBefore, yearsBeforeAfter } from "../../utils/moment";
import {
  Block,
  BlockBetween,
  BlockContent,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  RSelect,
  Row,
} from "../Component";
import FooterBtn from "../common/FooterBtn";
import CustomCreatable from "../input/custom/CustomCreatable";
import MapComponent from "../map/MapComponent";
import AssignNavTab from "./AssignNavTab";
import { assignmentData as assignData } from "./Data/data";
import SoldModal from "./SoldModal";
import styles from "./assignment.module.scss";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {/* <CaretDownIcon /> */}
      <img src="/images/svg/Group 5559 (1).svg" alt="icon" />
    </components.DropdownIndicator>
  );
};

const indicatorSeparatorStyle = {
  alignSelf: "stretch",
  marginBottom: 8,
  marginTop: 8,
  width: 0,
};

const IndicatorSeparator = ({ innerProps }) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />;
};

const libraries = ["places"];

const Assignment1 = ({ formStep, nextFormStep, prevFormStep, stepGoTo }) => {
  const { isLoaded, loadError } = useLoadScript({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY, libraries });

  const navigate = useNavigate();
  const { type, id } = useParams();
  const pathArray = window.location.pathname.split("/");
  const mainPath = pathArray[1];
  const dispatch = useDispatch();
  const {
    homeType: homeTypeObj,
    country: countryObj,
    province: provinceObj,
    city: cityObj,
    neighbourhood: neighbourhoodObj,
    construction: constructionObj,
    unitPreference: unitPreferenceObj,
    developmentType: developmentTypeObj,
    development,
    homeStyle: homeStyleObj,
    listing,
  } = useSelector((state) => state);

  const {
    listingById: { data: assignment },
    development: { data: devData },
    mls: { loading: mlsLoading, data: mlsMessage, success: mlsSuccess, error: mlsError },
    community: { data: developmentData },
  } = listing;
  const { homeType: homeTypeData } = homeTypeObj;
  const {
    countries: { data: countryData },
  } = countryObj;
  const {
    provinceByCountry: { data: provinceData,loading:devLoading },
  } = provinceObj;
  const {
    cityByProvince: { data: cityData },
  } = cityObj;
  const {
    neighbourhoodByCity: { data: neighbourhoodData },
  } = neighbourhoodObj;
  const { construction: constructionData } = constructionObj;
  const { unitPreference: unitPreferenceData } = unitPreferenceObj;
  const { developmentType: developmentTypeData } = developmentTypeObj;
  // const {
  //   developments: { data: developmentData },
  // } = development;
  const { homeStyle: homeStyleData } = homeStyleObj;

  const [occupancy, setOccupancy] = useState("");

  const [open, setOpen] = useState({ modalOpen: false, modalType: "" });
  const handleOpen = (type) => {
    setOpen({ modalOpen: true, modalType: type });
    dispatch(countryReset());
    dispatch(provinceReset());
    dispatch(cityReset());
    dispatch(neighbourhoodReset());
  };
  const handleClose = () => setOpen({ modalOpen: false, modalType: "" });

  const [assignmentData, setAssignmentData] = useState(false);

  const [assignMandDev, setAssignMandDev] = useState(false);
  const [assignMandAddress, setAssignMandAddress] = useState(false);
  const [assignMandProvince, setAssignMandProvince] = useState(false);
  const [assignMandCity, setAssignMandCity] = useState(false);
  const [assignMandUnitNumber, setAssignMandUnitNumber] = useState(false);
  const [assignMandHomeType, setAssignMandHomeType] = useState(false);
  const [assignMandBuildingType, setAssignMandBuildingType] = useState(false);
  const [assignMandHomeStyle, setAssignMandHomeStyle] = useState(false);
  const [assignMandConstStatus, setAssignMandConstStatus] = useState(false);
  const [assignMandcompYear, setAssignMandcompYear] = useState(false);
  const [assignMandcompMonth, setAssignMandcompMonth] = useState(false);

  const [saleType, setSaleType] = useState("");
  const [dom, setDom] = useState(null);
  const [status, setStatus] = useState("active");

  const [modalShow, setModalShow] = React.useState({ open: false, data: null });

  const [price, setPrice] = useState("");
  const [priceError, setPriceError] = useState(false);
  const [date, setDate] = useState(null);
  const [dateError, setDateError] = useState(false);
  const [soldDetails, setSoldDetails] = useState({ soldPrice: null, soldDate: null });

  const [changeStatus, setChangeStatus] = useState(true);
  const [partOfDevelopment, setPartOfDevelopment] = useState(false);
  const [developmentDetails, setDevelopmentDetails] = useState("");
  const [developmentName, setdevelopmentName] = useState("");

  const [addressCheck, setAddressCheck] = useState(false);
  const [address, setAddress] = useState("");
  const [geoAddress, setGeoAddress] = useState("");
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [neighbourhood, setNeighbourhood] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [marker, setMarker] = useState({ lat: null, lng: null });
  const [zoom, setZoom] = useState(8);
  const [locationData, setLocationData] = useState(false);

  const [homeTypeByDev, setHomeTypeByDev] = useState([]);
  const [homeTypeName, setHomeTypeName] = useState("");

  const [mlsCheck, setMlsCheck] = useState(false);
  const [mlsNumber, setMlsNumber] = useState("");
  const [isMlsError, setIsMlsError] = useState(false);

  const [unitNumber, setUnitNumber] = useState("");
  const [buildingNameArray, setBuildingNameArray] = useState([]);
  const [buildingName, setBuildingName] = useState("");
  const [addNewName, setAddNewName] = useState(false);
  const [homeType, setHomeType] = useState("");

  const [buildingType, setBuildingType] = useState("");
  const [buildingTypeByDev, setBuildingTypeByDev] = useState([]);

  const [homeStyle, setHomeStyle] = useState("");
  const [sellerInterest, setSellerInterest] = useState("");
  const [constructionStatus, setConstructionStatus] = useState("");
  const [completeYear, setCompleteYear] = useState("");
  const [completeMonth, setCompleteMonth] = useState("");
  const [unitPreference, setUnitPreference] = useState("");
  const [unitPreferenceArray, setUnitPreferenceArray] = useState([]);
  const [slug, setSlug] = useState("");
  const [changeSlug, setChangeSlug] = useState(false);

  const [newDevName, setNewDevName] = useState("");
  const [developmentAddress, setDevelopmentAddress] = useState("");
  const [developmentGeoAddress, setDevelopmentGeoAddress] = useState("");
  const [devLat, setDevLat] = useState("");
  const [devLng, setDevLng] = useState("");
  const [devNeighbourhood, setDevNeighbourhood] = useState("");
  const [devCity, setDevCity] = useState("");
  const [devProvince, setDevProvince] = useState("");
  const [devCountry, setDevCountry] = useState("");
  const [devZipcode, setDevZipcode] = useState("");
  const [devLocationData, setDevLocationData] = useState(false);

  const devRef = useRef(null);
  const addressRef = useRef(null);
  const countryRef = useRef(null);
  const provinceRef = useRef(null);
  const cityRef = useRef(null);
  const unitNumberRef = useRef(null);
  const homeTypeRef = useRef(null);
  const homeStyleRef = useRef(null);
  const constructionStatusRef = useRef(null);
  const completeYearRef = useRef(null);
  const completeMonthRef = useRef(null);
  const [devMandName, setDevMandName] = useState(false);
  const [devMandAddress, setDevMandAddress] = useState(false);
  const [devMandCountry, setDevMandCountry] = useState(false);
  const [devMandProvince, setDevMandProvince] = useState(false);
  const [devMandCity, setDevMandCity] = useState(false);
  const devNameRef = useRef(null);
  const devAddressRef = useRef(null);
  const devCountryRef = useRef(null);
  const devProvinceRef = useRef(null);
  const devCityRef = useRef(null);

  const closeSoldModal = () => {
    setModalShow({ open: false, data: null });
  };

  const submitModal = () => {
    let data = { soldPrice: +price, soldDate: new Date(date).toISOString() };
    if (price && date) {
      setSoldDetails(data);
      closeSoldModal();
    } else {
      if (!price) setPriceError(true);
      if (!date) setDateError(true);
      if (!price) document.getElementById("price").focus();
      else if (!date) document.getElementById("date").focus();
    }
  };

  const debounceMlsCheck = useCallback(
    debounce((query) => dispatch(checkMls(query)), 1000),
    [dispatch]
  );
  useEffect(() => {
    if (mlsCheck) {
      debounceMlsCheck({ mls: mlsNumber });
    }
  }, [mlsNumber, mlsCheck]);

  useEffect(() => {
    if (mainPath === "assignment") setAddressCheck(true);
  }, [mainPath]);

  useEffect(() => {
    if (
      mlsNumber &&
      mlsNumber !== assignmentData?.mls &&
      mlsSuccess &&
      mlsMessage === "This MLS number already exists."
    )
      setIsMlsError(true);
    else setIsMlsError(false);
  }, [mlsNumber, assignmentData, mlsMessage, mlsSuccess]);

  const handlePlaceChanged = (places, searchBox) => {
    if (places?.length) {
      setGeoAddress(places[0].formatted_address);
      setZoom(18);
      setMarker({ lat: places?.[0]?.geometry?.location?.lat(), lng: places?.[0]?.geometry?.location?.lng() });
      let data = getLocationData(places[0]);
      setAddress(data?.address);
      setZipcode(data?.zipcode);
      setLocationData(data);
    }
  };

  const handlePlaceChanged2 = (places, searchBox) => {
    if (places?.length) {
      setDevelopmentGeoAddress(places[0].formatted_address);
      setDevLat(places?.[0]?.geometry?.location?.lat());
      setDevLng(places?.[0]?.geometry?.location?.lng());
      let data = getLocationData(places[0]);
      setDevelopmentAddress(data?.address);
      setDevZipcode(data?.zipcode);
      setDevLocationData(data);
    }
  };

  useEffect(() => {
    if (mainPath === "new-homes" && partOfDevelopment) setAddressCheck(true);
    else if (mainPath === "new-homes" && !partOfDevelopment) setAddressCheck(false);
  }, [partOfDevelopment, mainPath]);

  const handlePayload = () => {
    let data = {};
    if (assignmentData) data = { ...data, ...assignmentData };
    data = {
      ...data,
      projectType: saleType?.toLocaleLowerCase(),
      assignmentStatus: status,
      address: {
        unitNumber: unitNumber,
        firstLine: address,
        neighbourhood: neighbourhood ? neighbourhood : null,
        city: city,
        province: province,
        country: country,
        zipcode: zipcode,
        location: { type: "Point", coordinates: [marker?.lng, marker?.lat] },
      },
      title: `${unitNumber ? `#${unitNumber} ` : ""}${address ? address?.split(",")?.[0] : ""}${
        buildingName ? `, ${buildingName}` : ""
      }`,
      construction: { constructionStatus: constructionStatus, end: { year: completeYear, month: completeMonth } },
      soldDetails,
    };
    if (slug) data = { ...data, slug: slug };
    if (changeSlug) data = { ...data, slugByAdmin: changeSlug };
    if (mlsNumber) data = { ...data, mls: mlsNumber };
    if (mainPath === "new-homes") {
      if (partOfDevelopment) {
        if (typeof developmentDetails === "object") data = { ...data, development: developmentDetails?.value };
      } else data = { ...data, development: null };
    } else if (typeof developmentDetails === "object") data = { ...data, development: developmentDetails?.value };
    if (buildingName) data = { ...data, buildingName: buildingName };
    else data = { ...data, buildingName: "" };
    if (homeType) data = { ...data, homeType: homeType };
    else data = { ...data, homeType: null };
    if (buildingType) data = { ...data, buildingType: buildingType };
    else data = { ...data, buildingType: null };
    if (homeStyle) data = { ...data, homeStyle: homeStyle };
    else data = { ...data, homeStyle: null };
    if (sellerInterest) data = { ...data, sellersInterest: sellerInterest };
    else data = { ...data, sellersInterest: "" };
    if (unitPreferenceArray?.length) data = { ...data, unitPreferences: unitPreferenceArray };
    else data = { ...data, unitPreferences: [] };
    return data;
  };

  const handleAddUpdate = (successCB) => {
    let data = handlePayload();
    if (type === "edit") data = { ...data, status: data?.status };
    else data = { ...data, status: "pending" };
    console.log("data: ", data);

    if (mainPath === "assignment") {
      if (
        typeof developmentDetails === "object" &&
        developmentDetails?.value &&
        address &&
        country &&
        province &&
        city &&
        unitNumber &&
        homeType &&
        homeStyle &&
        constructionStatus &&
        completeYear &&
        // completeMonth &&
        !isMlsError
      ) {
        if (addressCheck && developmentDetails?.address !== address) {
          toast.error("Development address and assignment address cannot be different");
        } else {
          if (
            (country && developmentDetails?.country && developmentDetails?.country !== country) ||
            (province && developmentDetails?.province && developmentDetails?.province !== province) ||
            (city && developmentDetails?.city && developmentDetails?.city !== city) ||
            (neighbourhood && developmentDetails?.neighbourhood && developmentDetails?.neighbourhood !== neighbourhood)
          ) {
            if (developmentDetails?.country !== country)
              toast.error("Development country and listing country cannot be different");
            else if (developmentDetails?.province !== province)
              toast.error("Development province and listing province cannot be different");
            else if (developmentDetails?.city !== city)
              toast.error("Development city and listing city cannot be different");
            else if (developmentDetails?.neighbourhood !== neighbourhood)
              toast.error("Development neighbourhood and listing neighbourhood cannot be different");
          } else {
            if (type === "edit") {
              dispatch(
                editListing(
                  id,
                  data,
                  (res) => {
                    if (type === "edit" && res?.data?.slug !== id) navigate(`/assignment/${res?.data?.slug}`);

                    successCB({ success: true });
                  },
                  (res) => {
                    // toast?.error(res?.message);
                  }
                )
              );
            } else {
              if (getSession("assignment")) {
                let assignmentInfo = getSession("assignment");
                let currData = { ...assignmentInfo, ...data };
                setSession("assignment", currData);
              } else {
                setSession("assignment", data);
              }
              successCB({ success: true });
            }
          }
        }
      } else {
        toast.error("Please complete all the mandatory fields.");
        if (typeof developmentDetails !== "object") setAssignMandDev(true);
        if (!address) setAssignMandAddress(true);
        if (!province) setAssignMandProvince(true);
        if (!city) setAssignMandCity(true);
        if (!unitNumber) setAssignMandUnitNumber(true);
        if (!homeType) setAssignMandHomeType(true);
        if (!homeStyle) setAssignMandHomeStyle(true);
        if (!constructionStatus) setAssignMandConstStatus(true);
        if (!completeYear) setAssignMandcompYear(true);
        if (!address) addressRef?.current?.focus();
        else if (!province) provinceRef?.current?.focus();
        else if (!city) cityRef?.current?.focus();
        else if (!unitNumber) unitNumberRef?.current?.focus();
        else if (!homeType) homeTypeRef?.current?.focus();
        else if (!homeStyle) homeStyleRef?.current?.focus();
        else if (!constructionStatus) constructionStatusRef?.current?.focus();
        else if (!completeYear) completeYearRef?.current?.focus();
      }
    } else if (mainPath === "new-homes") {
      data = { ...data, occupancy };
      if (
        address &&
        country &&
        province &&
        city &&
        homeType &&
        homeStyle &&
        constructionStatus &&
        completeYear &&
        !isMlsError
      ) {
        if (addressCheck === true && developmentDetails?.address !== address) {
          toast.error("Development address and assignment address cannot be different");
        } else {
          if (
            (country && developmentDetails?.country && developmentDetails?.country !== country) ||
            (province && developmentDetails?.province && developmentDetails?.province !== province) ||
            (city && developmentDetails?.city && developmentDetails?.city !== city) ||
            (neighbourhood && developmentDetails?.neighbourhood && developmentDetails?.neighbourhood !== neighbourhood)
          ) {
            if (developmentDetails?.country !== country)
              toast.error("Development country and listing country cannot be different");
            else if (developmentDetails?.province !== province)
              toast.error("Development province and listing province cannot be different");
            else if (developmentDetails?.city !== city)
              toast.error("Development city and listing city cannot be different");
            else if (developmentDetails?.neighbourhood !== neighbourhood)
              toast.error("Development neighbourhood and listing neighbourhood cannot be different");
          } else {
            if (type === "edit") {
              dispatch(
                editListing(
                  id,
                  data,
                  (res) => {
                    if (type === "edit" && res?.data?.slug !== id) navigate("/new-homes/" + res?.data?.slug);
                    successCB({ success: true });
                  },
                  (res) => {
                    // toast?.error(res?.message);
                  }
                )
              );
            } else {
              if (getSession("assignment")) {
                let assignmentInfo = getSession("assignment");
                let currData = { ...assignmentInfo, ...data };
                setSession("assignment", currData);
              } else {
                setSession("assignment", data);
              }
              successCB({ success: true });
            }
          }
        }
      } else {
        toast.error("Please complete all the mandatory fields.");
        if (!address) setAssignMandAddress(true);
        if (!province) setAssignMandProvince(true);
        if (!city) setAssignMandCity(true);
        if (!homeType) setAssignMandHomeType(true);
        if (!homeStyle) setAssignMandHomeStyle(true);
        if (!constructionStatus) setAssignMandConstStatus(true);
        if (!completeYear) setAssignMandcompYear(true);
        if (!address) addressRef?.current?.focus();
        else if (!province) provinceRef?.current?.focus();
        else if (!city) cityRef?.current?.focus();
        else if (!homeType) homeTypeRef?.current?.focus();
        else if (!homeStyle) homeStyleRef?.current?.focus();
        else if (!constructionStatus) constructionStatusRef?.current?.focus();
        else if (!completeYear) completeYearRef?.current?.focus();
      }
    }
  };

  const handleNext = () => {
    handleAddUpdate((res) => {
      if (res?.success) nextFormStep();
    });
  };

  const handleAddDevelopment = async () => {
    let data = {
      name: newDevName,
      type: "project",
      address: {
        firstLine: developmentAddress,
        zipcode: devZipcode,
        country: devCountry,
        province: devProvince,
        city: devCity,
        neighbourhood: devNeighbourhood ? devNeighbourhood : null,
        location: {
          type: "Point",
          coordinates: [+devLng, +devLat],
        },
      },
      status: "pending",
    };

    if (setNewDevName && developmentAddress && devZipcode && devCountry && devProvince && devCity && devLat && devLng) {
      dispatch(
        addNewDevelopment(
          data,
          (res) => {
            handleClose();
            setDevelopmentDetails({
              label: res?.data?.name,
              value: res?.data?._id,
              data: res?.data,
              address: res?.data?.address?.firstLine,
              neighbourhood: res?.data?.address?.neighbourhood,
              city: res?.data?.address?.city,
              province: res?.data?.address?.province,
              country: res?.data?.address?.country,
              coordinates: res?.data?.address?.location?.coordinates,
            });
            if (addressCheck) setAddress(res?.data?.address?.firstLine);
            setNewDevName("");
            setDevelopmentAddress("");
            setDevZipcode("");
            setDevCountry("");
            setDevProvince("");
            setDevCity("");
            setDevLat("");
            setDevLng("");
            setBuildingName("");
            setDevNeighbourhood("");
            setDevLocationData(false);
          },
          (res) => {
            toast.error(res?.message);
          }
        )
      );
    } else {
      toast.error("Please complete all the mandatory fields.");
      if (!newDevName) setDevMandName(true);
      if (!developmentAddress) setDevMandAddress(true);
      if (!devCountry) setDevMandCountry(true);
      if (!devProvince) setDevMandProvince(true);
      if (!devCity) setDevMandCity(true);
      if (!newDevName) devNameRef.current.focus();
      else if (!developmentAddress) devAddressRef.current.focus();
      else if (!devCountry) devCountryRef.current.focus();
      else if (!devProvince) devProvinceRef.current.focus();
      else if (!devCity) devCityRef.current.focus();
    }
  };

  const handleDraft = async () => {
    let data = handlePayload();
    if (type === "edit") data = { ...data, status: data?.status };
    else data = { ...data, status: "draft" };
    console.log("data: ", data);
    if (mainPath === "assignment") {
      if (
        typeof developmentDetails === "object" &&
        developmentDetails?.value &&
        address &&
        country &&
        province &&
        city &&
        unitNumber &&
        homeType &&
        homeStyle &&
        constructionStatus &&
        completeYear &&
        !isMlsError
      ) {
        if (addressCheck === true && developmentDetails?.address !== address) {
          toast.error("Development address and assignment address cannot be different");
        } else {
          if (
            (country && developmentDetails?.country && developmentDetails?.country !== country) ||
            (province && developmentDetails?.province && developmentDetails?.province !== province) ||
            (city && developmentDetails?.city && developmentDetails?.city !== city) ||
            (neighbourhood && developmentDetails?.neighbourhood && developmentDetails?.neighbourhood !== neighbourhood)
          ) {
            if (developmentDetails?.country !== country)
              toast.error("Development country and listing country cannot be different");
            else if (developmentDetails?.province !== province)
              toast.error("Development province and listing province cannot be different");
            else if (developmentDetails?.city !== city)
              toast.error("Development city and listing city cannot be different");
            else if (developmentDetails?.neighbourhood !== neighbourhood)
              toast.error("Development neighbourhood and listing neighbourhood cannot be different");
          } else {
            if (type === "edit") {
              dispatch(
                editListing(
                  id,
                  data,
                  (res) => {
                    toast.success("Listing Updated");
                    dispatch(handleResetListing());
                    dispatch(handleResetListing());
                    navigate(mainPath === "assignment" ? "/assignment" : "/new-homes");
                  },
                  (res) => {
                    toast?.error(res?.message);
                  }
                )
              );
            } else {
              dispatch(
                addListing(
                  data,
                  (res) => {
                    removeSession("assignment");
                    dispatch(handleResetListing());
                    navigate(mainPath === "assignment" ? "/assignment" : "/new-homes");
                    toast.success("Saved as draft");
                  },
                  (res) => {
                    toast.error(res?.message);
                  }
                )
              );
            }
          }
        }
      } else {
        toast.error("Please complete all the mandatory fields.");
        if (typeof developmentDetails !== "object") setAssignMandDev(true);
        if (!address) setAssignMandAddress(true);
        if (!province) setAssignMandProvince(true);
        if (!city) setAssignMandCity(true);
        if (!unitNumber) setAssignMandUnitNumber(true);
        if (!homeType) setAssignMandHomeType(true);
        if (!homeStyle) setAssignMandHomeStyle(true);
        if (!constructionStatus) setAssignMandConstStatus(true);
        if (!completeYear) setAssignMandcompYear(true);
        if (!address) addressRef?.current?.focus();
        else if (!province) provinceRef?.current?.focus();
        else if (!city) cityRef?.current?.focus();
        else if (!unitNumber) unitNumberRef?.current?.focus();
        else if (!homeType) homeTypeRef?.current?.focus();
        else if (!homeStyle) homeStyleRef?.current?.focus();
        else if (!constructionStatus) constructionStatusRef?.current?.focus();
        else if (!completeYear) completeYearRef?.current?.focus();
      }
    } else if (mainPath === "new-homes") {
      data = { ...data, occupancy };
      if (
        address &&
        country &&
        province &&
        city &&
        homeType &&
        homeStyle &&
        constructionStatus &&
        completeYear &&
        !isMlsError
      ) {
        if (addressCheck && developmentDetails?.address !== address) {
          toast.error("Development address and assignment address cannot be different");
        } else {
          if (
            (country && developmentDetails?.country && developmentDetails?.country !== country) ||
            (province && developmentDetails?.province && developmentDetails?.province !== province) ||
            (city && developmentDetails?.city && developmentDetails?.city !== city) ||
            (neighbourhood && developmentDetails?.neighbourhood && developmentDetails?.neighbourhood !== neighbourhood)
          ) {
            if (developmentDetails?.country !== country)
              toast.error("Development country and listing country cannot be different");
            else if (developmentDetails?.province !== province)
              toast.error("Development province and listing province cannot be different");
            else if (developmentDetails?.city !== city)
              toast.error("Development city and listing city cannot be different");
            else if (developmentDetails?.neighbourhood !== neighbourhood)
              toast.error("Development neighbourhood and listing neighbourhood cannot be different");
          } else {
            if (type === "edit") {
              dispatch(
                editListing(
                  id,
                  data,
                  (res) => {
                    toast.success("Listing Updated");
                    dispatch(handleResetListing());
                    navigate(mainPath === "assignment" ? "/assignment" : "/new-homes");
                  },
                  (res) => {
                    // toast?.error(res?.message);
                  }
                )
              );
            } else {
              dispatch(
                addListing(
                  data,
                  (res) => {
                    removeSession("assignment");
                    dispatch(handleResetListing());
                    navigate(mainPath === "assignment" ? "/assignment" : "/new-homes");
                    toast.success("Saved as draft");
                  },
                  (res) => {
                    toast.error(res?.message);
                  }
                )
              );
            }
          }
        }
      } else {
        toast.error("Please complete all the mandatory fields.");
        if (!address) setAssignMandAddress(true);
        if (!province) setAssignMandProvince(true);
        if (!city) setAssignMandCity(true);
        if (!homeType) setAssignMandHomeType(true);
        if (!homeStyle) setAssignMandHomeStyle(true);
        if (!constructionStatus) setAssignMandConstStatus(true);
        if (!completeYear) setAssignMandcompYear(true);
        if (!address) addressRef?.current?.focus();
        else if (!province) provinceRef?.current?.focus();
        else if (!city) cityRef?.current?.focus();
        else if (!homeType) homeTypeRef?.current?.focus();
        else if (!homeStyle) homeStyleRef?.current?.focus();
        else if (!constructionStatus) constructionStatusRef?.current?.focus();
        else if (!completeYear) completeYearRef?.current?.focus();
      }
    }
  };

  const handleBackFun = () => {
    removeSession("assignment");
    dispatch(handleResetListing());
    navigate(mainPath === "assignment" ? "/assignment" : "/new-homes");
  };

  // if route is assignment then set sale type = assignment and if route is new homes then set sale type = resale
  useEffect(() => {
    if (mainPath === "assignment") {
      setSaleType("Assignment");
      setSellerInterest("Assignment of Contract");
    } else if (mainPath === "new-homes") {
      setSaleType("Resale");
    }
  }, [mainPath]);

  // get all data from API
  useEffect(() => {
    dispatch(getHomeType());
    dispatch(getUnitPreference());
    dispatch(getConstruction());
    dispatch(getDevelopmentType());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCountry());
  }, [dispatch, open]);

  const debouncedSearchDevelopment = useCallback(
    debounce((query) => dispatch(getCommunityForListing(query)), 1000),
    [dispatch]
  );
  useEffect(() => {
    if (
      (typeof developmentDetails === "object" && developmentDetails?.label) ||
      (typeof developmentDetails && developmentDetails)
    )
      debouncedSearchDevelopment({
        search: typeof developmentDetails === "object" ? developmentDetails?.label : developmentDetails,
      });
  }, [developmentDetails]);

  // setting Canada as default country
  useEffect(() => {
    if (countryData?.length && !open?.modalOpen) setCountry("63d76e99b005363c54606967");
    else if (countryData?.length && open?.modalOpen) setDevCountry("63d76e99b005363c54606967");
  }, [countryData, open]);

  // get province list from country
  useEffect(() => {
    if (country && !open?.modalOpen) dispatch(getprovinceByCountry(country));
    else if (devCountry && open?.modalOpen) dispatch(getprovinceByCountry(devCountry));
  }, [country, devCountry, dispatch, open]);

  // get city list from province
  useEffect(() => {
    if (province && !open?.modalOpen) dispatch(getcityByProvince(province));
    else if (devProvince && open?.modalOpen) dispatch(getcityByProvince(devProvince));
  }, [province, devProvince, dispatch, open]);

  // get neighbourhood list from city
  useEffect(() => {
    if (city && !open?.modalOpen) dispatch(getneighbourhoodByCity(city));
    else if (devCity && open?.modalOpen) dispatch(getneighbourhoodByCity(devCity));
  }, [city, devCity, dispatch, open]);

  // fetch address details
  const fetchAddress = async (lat, lng) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`
      );
      const addresses = response.data.results[0];
      let data = getLocationData(addresses);
      if (!address) setAddress(data?.address);
      if (!zipcode) setZipcode(data?.zipcode);
      if (developmentDetails && typeof developmentDetails === "object")
        setLocationData({ route: data?.route, street: data?.street });
      else setLocationData(data);
    } catch (error) {
      console.error(error);
    }
  };

  // if marker then fetch address details
  useEffect(() => {
    if (
      typeof developmentDetails === "object" &&
      developmentDetails?.coordinates?.[0] &&
      developmentDetails?.coordinates?.[1]
    ) {
      setMarker({ lat: developmentDetails?.coordinates?.[1], lng: developmentDetails?.coordinates?.[0] });
    } else if (marker?.lat && marker?.lng) fetchAddress(marker?.lat, marker?.lng);
    else setLocationData(false);
  }, [marker?.lat, marker?.lng, developmentDetails]);

  useEffect(() => {
    if (typeof developmentDetails === "object" && developmentDetails.zipcode) setZipcode(developmentDetails.zipcode);
  }, [developmentDetails]);

  // set country by address or marker
  useEffect(() => {
    if (typeof developmentDetails === "object" && countryData?.length) {
      setCountry(developmentDetails?.country);
    } else {
      if (locationData?.country && countryData?.length) {
        let a = countryData?.filter((li) => li?.name === locationData?.country);
        if (a?.length) setCountry(a[0]?._id);
        else setCountry("");
      }
    }
  }, [locationData?.country, countryData, developmentDetails]);

  // set province by address or marker
  useEffect(() => {
    if (typeof developmentDetails === "object" && provinceData?.length) {
      setProvince(developmentDetails?.province);
    } else {
      if (locationData?.province && provinceData?.length) {
        let a = provinceData?.filter((li) => li?.name === locationData?.province);
        if (a?.length) setProvince(a[0]?._id);
        else setProvince("");
      }
    }
  }, [locationData?.province, provinceData, developmentDetails]);

  // set city by address or marker
  useEffect(() => {
    if (typeof developmentDetails === "object" && cityData?.length) {
      setCity(developmentDetails?.city);
    } else {
      if (locationData?.city && cityData?.length) {
        let a = cityData?.filter((li) => li?.name === locationData?.city);
        if (a?.length) setCity(a[0]?._id);
        else setCity("");
      }
    }
  }, [locationData?.city, cityData, developmentDetails]);

  // set neighbourhood by address or marker
  useEffect(() => {
    if (typeof developmentDetails === "object" && neighbourhoodData?.length) {
      setNeighbourhood(developmentDetails?.neighbourhood ? developmentDetails?.neighbourhood : neighbourhood);
    }
  }, [developmentDetails, neighbourhoodData, neighbourhood]);

  // set development country by address or marker
  useEffect(() => {
    if (devLocationData?.country && countryData?.length) {
      let a = countryData?.filter((li) => li?.name === devLocationData?.country);
      if (a?.length) setDevCountry(a[0]?._id);
      else setDevCountry("");
    }
  }, [devLocationData?.country, countryData]);

  // set development province by address or marker
  useEffect(() => {
    if (devLocationData?.province && provinceData?.length) {
      let a = provinceData?.filter((li) => li?.name === devLocationData?.province);
      if (a?.length) setDevProvince(a[0]?._id);
      else setDevProvince("");
    }
  }, [devLocationData?.province, provinceData]);

  // get homeType name from _id
  useEffect(() => {
    if (homeType) setHomeTypeName(homeTypeData?.filter((li) => li?._id === homeType)?.[0]?.name);
  }, [homeType, homeTypeData]);

  useEffect(() => {
    if (typeof developmentDetails === "object" && homeTypeData?.length) {
      if (developmentDetails?.data?.homeTypes?.length) {
        setHomeTypeByDev(
          typeof developmentDetails?.data?.homeTypes?.[0] === "object"
            ? developmentDetails?.data?.homeTypes
            : homeTypeData?.filter((li) => developmentDetails?.data?.homeTypes?.includes(li?._id))
        );
      } else setHomeTypeByDev(homeTypeData);
    } else if (homeTypeData?.length) {
      setHomeTypeByDev(homeTypeData);
    }
  }, [developmentDetails, homeTypeData]);

  useEffect(() => {
    if (typeof developmentDetails === "object" && developmentTypeData?.length) {
      if (developmentDetails?.data?.developmentType)
        setBuildingTypeByDev(
          developmentTypeData?.filter((li) => developmentDetails?.data?.developmentType === li?._id)
        );
      else setBuildingTypeByDev(developmentTypeData);
    } else if (developmentTypeData?.length) {
      setBuildingTypeByDev(developmentTypeData);
    }
  }, [developmentDetails, developmentTypeData]);

  // get home style by home type
  useEffect(() => {
    if (homeType) dispatch(getHomeStyleByType(homeType));
    else dispatch(getHomeStyleByType());
  }, [homeType, dispatch]);

  // get building name if development has any
  useEffect(() => {
    if (developmentDetails && typeof developmentDetails === "object") {
      if (developmentDetails?.data?.buildingNames?.length)
        setBuildingNameArray(developmentDetails?.data?.buildingNames);
      else setBuildingNameArray(["No building names found"]);
    }
  }, [developmentDetails]);

  useEffect(() => {
    if (buildingName) setAddNewName(buildingNameArray?.includes(buildingName) ? false : true);
    else setAddNewName(false);
  }, [buildingNameArray, buildingName]);

  useEffect(() => {
    if (assignment) {
      setAssignmentData(assignment);
    } else {
      if (getSession("assignment")) setAssignmentData(getSession("assignment"));
    }
  }, [assignment]);

  useEffect(() => {
    function capitalizeFirstLetterOfEveryWord(sentence) {
      return sentence
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }
    if (assignmentData) {
      console.log("assignmentData 1: ", assignmentData);
      setSaleType(capitalizeFirstLetterOfEveryWord(assignmentData?.projectType) || saleType);
      setDom(moment(Date.now()).diff(moment(assignmentData?.publishDate), "days") || dom);
      setPartOfDevelopment(assignmentData?.development ? true : false);
      setStatus(assignmentData?.assignmentStatus || status);
      if (assignment?.assignmentStatus === "sold")
        setSoldDetails({
          soldDate: assignmentData?.soldDetails?.soldDate,
          soldPrice: assignmentData?.soldDetails?.soldPrice,
        });
      setMarker({
        lat: assignmentData?.address?.location?.coordinates?.[1],
        lng: assignmentData?.address?.location?.coordinates?.[0],
      });
      setAddress(assignmentData?.address?.firstLine);
      setZipcode(assignmentData?.address?.zipcode);
      setZoom(18);
      setLocationData({
        country: assignmentData?.address?.country,
        province: assignmentData?.address?.province,
        city: assignmentData?.address?.city,
      });
      setSlug(assignmentData?.slug);
      setNeighbourhood(assignmentData?.address?.neighbourhood);
      setMlsCheck(assignmentData?.mls ? true : false);
      setMlsNumber(assignmentData?.mls);
      setUnitNumber(assignmentData?.address?.unitNumber);
      setBuildingName(assignmentData?.buildingName);
      setHomeType(assignmentData?.homeType);
      setBuildingType(assignmentData?.buildingType);
      setHomeStyle(assignmentData?.homeStyle || homeStyle);
      setSellerInterest(assignmentData?.sellersInterest || sellerInterest);
      setConstructionStatus(assignmentData?.construction?.constructionStatus);
      setCompleteYear(assignmentData?.construction?.end?.year);
      setCompleteMonth(assignmentData?.construction?.end?.month);
      setUnitPreferenceArray(assignmentData?.unitPreferences?.map((li) => li) || unitPreferenceArray);
      setOccupancy(assignmentData?.occupancy);
    }
  }, [assignmentData]);

  useEffect(() => {
    if (typeof developmentDetails === "object" && address)
      setAddressCheck(developmentDetails?.address === address ? true : false);
  }, [developmentDetails, address]);

  useEffect(() => {
    if (assignmentData?.development) dispatch(getDevelopmentForListing(assignmentData?.development));
  }, [assignmentData?.development, dispatch]);

  useEffect(() => {
    if (devData)
      setDevelopmentDetails({
        label: devData?.name,
        value: devData?._id,
        country: devData?.address?.country ? devData?.address?.country : "",
        province: devData?.address?.province ? devData?.address?.province : "",
        city: devData?.address?.city ? devData?.address?.city : "",
        neighbourhood: devData?.address?.neighbourhood ? devData?.address?.neighbourhood : "",
        address: devData?.address?.firstLine,
        zipcode: devData?.address?.zipcode,
        coordinates: devData?.address?.location?.coordinates,
        data: devData,
      });
  }, [devData]);

  useEffect(() => {
    if (homeType !== "63c66c47cec8665e878ce4df" && homeType !== "63c683e55178561202fd7ee7") setBuildingType("");
  }, [homeType]);

  // set development city by address or marker
  useEffect(() => {
    if (devLocationData?.city && cityData?.length) {
      let a = cityData?.filter((li) => li?.name === devLocationData?.city);
      if (a?.length) setDevCity(a[0]?._id);
      else setDevCity("");
    }
  }, [devLocationData?.city, cityData]);

  // set development neighbourhood by address or marker
  useEffect(() => {
    if (devLocationData?.neighbourhood && neighbourhoodData?.length) {
      let a = neighbourhoodData?.filter((li) => li?.name === devLocationData?.neighbourhood);
      if (a?.length) setDevNeighbourhood(a[0]?._id);
      else setDevNeighbourhood("");
    }
  }, [devLocationData?.neighbourhood, neighbourhoodData]);

  useEffect(() => {
    if (developmentDetails && developmentData) {
      setMarker({
        lat: developmentData?.filter((li) => li?._id === developmentDetails?.value)?.[0]?.address?.location
          ?.coordinates?.[1],
        lng: developmentData?.filter((li) => li?._id === developmentDetails?.value)?.[0]?.address?.location
          ?.coordinates?.[0],
      });
      if (!neighbourhood)
        setNeighbourhood(
          developmentData?.filter((li) => li?._id === developmentDetails?.value)?.[0]?.address?.neighbourhood
        );
    }
  }, [developmentDetails, developmentData, neighbourhood]);

  useEffect(() => {
    if (!partOfDevelopment) setDevelopmentDetails("");
  }, [partOfDevelopment]);

  useEffect(() => {
    if (address) setAssignMandAddress(false);
    if (province) setAssignMandProvince(false);
    if (city) setAssignMandCity(false);
  }, [address, province, city]);

  if (loadError) return "Error...";
  if (!isLoaded) {
    return (
      <div className="loadingDiv">
        <Spinner animation="grow" variant="info" style={{ width: "50px", height: "50px" }} />
      </div>
    );
  }

  return (
    <>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Add {mainPath === "assignment" ? "Assignment" : "New Home"}
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="light"
                outline
                className="bg-white d-inline-flex"
                onClick={(ev) => {
                  ev.preventDefault();
                  handleDraft();
                }}
              >
                <span>{type === "edit" ? "Update Development" : "Save as Draft"}</span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card className="card-bordered">
            <div className="card-aside-wrap" id="user-detail-block">
              <div className="card-content">
                <AssignNavTab stepGoTo={stepGoTo} update={handleAddUpdate} />
                <div className="card-inner">
                  <Block>
                    <BlockContent>
                      <Row>
                        <Col sm={"12"} md={"6"} lg={"4"}>
                          <div className={clsx(styles.inputDiv)}>
                            <p className={clsx(styles.para1)}>Sale Type</p>
                            <input
                              type="text"
                              className={clsx("form-control", styles.textField)}
                              name="saleType"
                              value={saleType}
                              onChange={(e) => setSaleType(e.target.value)}
                              disabled
                            />
                          </div>
                        </Col>
                        <Col sm={"12"} md={"6"} lg={"4"}>
                          <div className={clsx(styles.inputDiv)}>
                            <p className={clsx(styles.para1)}>DOM</p>
                            <input
                              type={"number"}
                              className={clsx("form-control", styles.textField)}
                              value={dom || 0}
                              name="dom"
                              onChange={(e) => setDom(e.target.value)}
                              disabled
                            />
                          </div>
                        </Col>
                        <Col sm={"12"} md={"6"} lg={"4"}>
                          <div className={clsx(styles.inputDiv)}>
                            <p className={clsx(styles.para1)}>
                              Assignment Status{" "}
                              <span className={styles.statusChange} onClick={() => setChangeStatus(false)}>
                                (Click here to change.)
                              </span>{" "}
                            </p>
                            <select
                              className={clsx("", styles.selectTag, assignMandProvince ? "error" : "")}
                              style={{ padding: "7px 15px" }}
                              value={status}
                              name="status"
                              onChange={(e) => {
                                setStatus(e.target.value);
                                setChangeStatus(true);
                                if (e.target.value === "sold") setModalShow({ open: true, data: null });
                                else setSoldDetails({ soldDate: null, soldPrice: null });
                              }}
                              disabled={changeStatus}
                            >
                              <option value="">Select</option>
                              <option value="active">Active</option>
                              <option value="on hold">On Hold</option>
                              <option value="under contract">Under Contract</option>
                              <option value="sold">Sold</option>
                            </select>
                            {status === "sold" && soldDetails?.soldDate && soldDetails?.soldPrice ? (
                              <div className={clsx("", styles.soldDetails)}>
                                <p className={clsx("", styles.soldPrice)}>
                                  Sold Price: {USDollar(soldDetails?.soldPrice)}
                                </p>
                                <p className={clsx("", styles.soldDate)}>
                                  Sold Date: {moment(soldDetails?.soldDate).format("Do MMMM YYYY")}
                                </p>
                              </div>
                            ) : null}
                          </div>
                        </Col>
                        <SoldModal
                          isOpen={modalShow.open}
                          toggle={() => setModalShow({ open: false, data: null })}
                          handleSubmit={submitModal}
                          price={price}
                          setPrice={setPrice}
                          priceError={priceError}
                          setPriceError={setPriceError}
                          date={date}
                          setDate={setDate}
                          dateError={dateError}
                          setDateError={setDateError}
                        />
                      </Row>
                    </BlockContent>
                  </Block>
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Development Project Information</BlockTitle>
                    </BlockHead>
                    <BlockContent>
                      <Row className="gy-4">
                        <Col sm={"12"} md={"12"} lg={"6"}>
                          {mainPath === "new-homes" ? (
                            <div
                              style={{
                                margin: "5px 0",
                                fontSize: "16px",
                                display: "flex",
                                alignItems: "center",
                                color: "#3d3d3d",
                              }}
                            >
                              <input
                                type="checkbox"
                                name="partOfDevelopment"
                                checked={partOfDevelopment}
                                onChange={(e) => {
                                  setPartOfDevelopment(e.target.checked);
                                }}
                              />
                              <label
                                htmlFor="partOfDevelopment"
                                className="mx-2 mb-0"
                                onClick={() => setPartOfDevelopment(!partOfDevelopment)}
                              >
                                This listing is located in a development community.
                              </label>
                            </div>
                          ) : null}
                          {mainPath === "assignment" || partOfDevelopment ? (
                            <div className={clsx(styles.inputDiv)}>
                              <p className={clsx(styles.para1)}>Development/Project Name* </p>
                              <CustomCreatable
                                creatable={true}
                                data={developmentData?.map((li) => {
                                  return {
                                    label: `${li?.name}`,
                                    value: li?._id,
                                    subLabel: `${
                                      li?.address?.neighbourhood?.name ? `${li?.address?.neighbourhood?.name}, ` : ""
                                    }${li?.address?.city?.name}, ${li?.address?.province?.shortSymbol}`,
                                    optional: `(${li?.type === "presale" ? "Development" : "Community"})`,
                                    data: li,
                                  };
                                })}
                                value={developmentDetails}
                                handleChange={(value) => {
                                  setDevelopmentDetails(value);
                                  setMarker({
                                    lat: value?.coordinates?.[1],
                                    lng: value?.coordinates?.[0],
                                  });
                                  setZoom(18);
                                  setAddress(value?.address);
                                  setAssignMandDev(false);
                                  setHomeType("");
                                  setBuildingName("");
                                }}
                                onCreateNew={(v) => {
                                  setdevelopmentName(v?.label);
                                  setNewDevName(v);
                                  handleOpen("development");
                                }}
                                error={assignMandDev}
                                ref={devRef}
                                loading={devLoading}
                              />
                              <p className={clsx(styles.para2)}>Select from the list or add a new development</p>
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                      <Row className={clsx("mt-4")}>
                        <Col sm={"12"} md={"12"} lg={"6"}>
                          <div className={clsx("", styles.inputDiv)}>
                            <MapComponent
                              marker={marker}
                              setMarker={setMarker}
                              zoom={zoom}
                              addressCheck={addressCheck}
                            />
                            <p className={clsx(styles.para2)}>Drag and drop the pin on map to find exact location</p>
                          </div>
                        </Col>
                        <Col sm={"12"} md={"12"} lg={"6"}>
                          <Row className={""}>
                            {mainPath === "assignment" || (mainPath === "new-homes" && partOfDevelopment) ? (
                              <Col sm={"12"}>
                                <div className={clsx(styles.checkDiv)}>
                                  <input
                                    type="checkbox"
                                    name="addressCheck"
                                    id="addressCheck"
                                    value={addressCheck}
                                    checked={addressCheck}
                                    onChange={(e) => {
                                      if (e.target.checked) setAddressCheck(true);
                                      else setAddressCheck(false);
                                    }}
                                  />
                                  <label htmlFor="addressCheck">
                                    {`The listing address is the same as development address`}
                                  </label>
                                </div>
                              </Col>
                            ) : null}
                            <Col sm={"12"}>
                              <div className={clsx(styles.inputDiv)}>
                                <p className={clsx(styles.para1)}>Address*</p>
                                <StandaloneSearchBox
                                  onLoad={(searchBox) => {
                                    searchBox.addListener("places_changed", () =>
                                      handlePlaceChanged(searchBox.getPlaces(), searchBox)
                                    );
                                  }}
                                >
                                  <input
                                    type="text"
                                    ref={addressRef}
                                    placeholder="Enter Your Property Address"
                                    className={clsx("form-control", styles.textField, assignMandAddress ? "error" : "")}
                                    value={address}
                                    onChange={(e) => {
                                      setAddress(e.target.value);
                                      if (e.target.value) setAssignMandAddress(false);
                                    }}
                                    required="required"
                                    data-error="Text is required."
                                    disabled={addressCheck}
                                  />
                                </StandaloneSearchBox>
                              </div>
                            </Col>
                            <Col sm={"12"} md={"6"}>
                              <div className={clsx(styles.inputDiv)}>
                                <p className={clsx(styles.para1)}>Postal Code</p>
                                <input
                                  type="text"
                                  placeholder={"Enter postal code"}
                                  className={clsx("form-control", styles.textField)}
                                  value={zipcode}
                                  name="zipcode"
                                  onChange={(e) => setZipcode(e.target.value)}
                                  disabled={addressCheck}
                                />
                              </div>
                            </Col>
                            <Col sm={"12"} md={"6"}>
                              <div className={clsx(styles.inputDiv)}>
                                <p className={clsx(styles.para1)}>Country*</p>
                                <select
                                  className={clsx("", styles.selectTag)}
                                  style={{
                                    padding: "7px 15px",
                                    background: country === "63d76e99b005363c54606967" ? "#e0e0e0" : "",
                                  }}
                                  ref={countryRef}
                                  value={country}
                                  name="country"
                                  onChange={(e) => setCountry(e.target.value)}
                                  disabled
                                >
                                  <option value="">Select</option>
                                  {countryData?.length
                                    ? countryData?.map((item) => (
                                        <option key={`${Math.random()}-${item?._id}`} value={item?._id}>
                                          {item?.name}
                                        </option>
                                      ))
                                    : null}
                                </select>
                              </div>
                            </Col>
                            <Col sm={"12"} md={"6"}>
                              <div className={clsx(styles.inputDiv)}>
                                <p className={clsx(styles.para1)}>Province*</p>
                                <select
                                  className={clsx("", styles.selectTag, assignMandProvince ? "error" : "")}
                                  style={{ padding: "7px 15px" }}
                                  ref={provinceRef}
                                  value={province}
                                  name="province"
                                  onChange={(e) => setProvince(e.target.value)}
                                  disabled
                                >
                                  <option value="">Select</option>
                                  {provinceData?.length ? (
                                    provinceData?.map((item) => (
                                      <option key={`${Math.random()}-${item?._id}`} value={item?._id}>
                                        {item?.name}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="">Please select country first</option>
                                  )}
                                </select>
                              </div>
                            </Col>
                            <Col sm={"12"} md={"6"}>
                              <div className={clsx(styles.inputDiv)}>
                                <p className={clsx(styles.para1)}>City*</p>
                                <select
                                  className={clsx("", styles.selectTag, assignMandCity ? "error" : "")}
                                  style={{ padding: "7px 15px" }}
                                  ref={cityRef}
                                  value={city}
                                  name="city"
                                  onChange={(e) => {
                                    setCity(e.target.value);
                                    if (e.target.value) setAssignMandCity(false);
                                  }}
                                  disabled
                                >
                                  <option value="">Select</option>
                                  {cityData?.length ? (
                                    cityData?.map((item) => (
                                      <option key={`${Math.random()}-${item?._id}`} value={item?._id}>
                                        {item?.name}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="">Please select province first</option>
                                  )}
                                </select>
                              </div>
                            </Col>
                            <Col sm={"12"} md={"6"}>
                              <div className={clsx(styles.inputDiv)}>
                                <p className={clsx(styles.para1)}>Neighbourhood</p>
                                <select
                                  className={clsx("", styles.selectTag)}
                                  style={{ padding: "7px 15px" }}
                                  value={neighbourhood}
                                  name="neighbourhood"
                                  onChange={(e) => setNeighbourhood(e.target.value)}
                                  disabled={
                                    typeof developmentDetails === "object" && developmentDetails?.neighbourhood
                                      ? true
                                      : false
                                  }
                                >
                                  <option value="">Select</option>
                                  {neighbourhoodData?.length ? (
                                    neighbourhoodData?.map((item) => (
                                      <option key={`${Math.random()}-${item?._id}`} value={item?._id}>
                                        {item?.name}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="">Please select city first</option>
                                  )}
                                </select>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>
                  <br />
                  <br />
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Listing Details</BlockTitle>
                    </BlockHead>
                    <BlockContent>
                      <Row className="gy-4">
                        <Col sm={"12"} md={"12"} lg={"12"}>
                          <div className={("form-check", styles.checkDiv)}>
                            <input
                              className={clsx("form-check", styles.textField)}
                              type="checkbox"
                              name="mlsCheck"
                              onChange={(e) => setMlsCheck(e.target.checked)}
                              checked={mlsCheck}
                              id="checkBox1"
                            />
                            <label className="form-check-label" htmlFor="checkBox1">
                              Listed on MLS
                            </label>
                          </div>
                        </Col>
                        {mlsCheck ? (
                          <Col sm={"12"} md={"12"} lg={"12"}>
                            <Row>
                              <Col sm={"12"} md={"6"} lg={"6"}>
                                <div className={clsx(styles.inputDiv)}>
                                  <p className={clsx(styles.para1)}>MLS#</p>
                                  <input
                                    type="text"
                                    id="mls"
                                    placeholder={"Enter MLS number"}
                                    className={clsx("form-control", styles.textField)}
                                    style={{
                                      border:
                                        mlsNumber &&
                                        mlsNumber !== assignmentData?.mls &&
                                        mlsSuccess &&
                                        mlsMessage === "This MLS number already exists."
                                          ? "2px solid #ce6048"
                                          : "1px solid #1d1d1d",
                                    }}
                                    value={mlsNumber}
                                    name="mlsNumber"
                                    onChange={(e) => {
                                      const inputRegex = /^[a-zA-Z0-9]+$/;
                                      const inputValue = e.target.value;
                                      if (inputRegex.test(inputValue) || inputValue === "") setMlsNumber(inputValue);
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            {mlsNumber && mlsNumber !== assignmentData?.mls && mlsLoading ? (
                              <span>
                                <Spinner />
                              </span>
                            ) : mlsNumber &&
                              mlsNumber !== assignmentData?.mls &&
                              mlsSuccess &&
                              mlsMessage === "This MLS number already exists." ? (
                              <span style={{ color: "#ce6048", paddingLeft: "5px" }}>{mlsMessage}</span>
                            ) : null}
                          </Col>
                        ) : null}
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <div className={clsx(styles.inputDiv)}>
                            <p className={clsx(styles.para1)}>
                              Unit Number
                              {mainPath === "new-homes" ? "" : "*"}
                            </p>
                            <input
                              type="text"
                              placeholder={"Enter unit number"}
                              className={clsx("form-control", styles.textField, assignMandUnitNumber ? "error" : null)}
                              ref={unitNumberRef}
                              value={unitNumber}
                              name="unitNumber"
                              onChange={(e) => {
                                setUnitNumber(e.target.value);
                                if (e.target.value) setAssignMandUnitNumber(false);
                              }}
                            />
                          </div>
                        </Col>
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <div className={clsx(styles.inputDiv)}>
                            <p className={clsx(styles.para1)}>Home Type*</p>
                            <select
                              className={clsx("arrows", styles.selectTag, assignMandHomeType ? "error" : "")}
                              style={{ padding: "7px 15px" }}
                              ref={homeTypeRef}
                              value={homeType}
                              name="homeType"
                              onChange={(e) => {
                                setHomeType(e.target.value);
                                if (
                                  e.target.value !== "63c66c47cec8665e878ce4df" ||
                                  e.target.value !== "63c683e55178561202fd7ee7"
                                )
                                  setBuildingName("");
                                setHomeStyle("");
                                if (e.target.value) setAssignMandHomeType(false);
                              }}
                            >
                              <option value="">Select</option>
                              {homeTypeByDev?.length
                                ? homeTypeByDev?.map((item) => (
                                    <option key={item?._id} value={item?._id}>
                                      {item?.name}
                                    </option>
                                  ))
                                : homeTypeData?.map((item) => (
                                    <option key={item?._id} value={item?._id}>
                                      {item?.name}
                                    </option>
                                  ))}
                            </select>
                          </div>
                        </Col>
                        {homeType === "63c66c47cec8665e878ce4df" || homeType === "63c683e55178561202fd7ee7" ? (
                          <>
                            <Col sm={"12"} md={"6"} lg={"6"}>
                              <div className={clsx(styles.inputDiv)}>
                                <p className={clsx(styles.para1, styles.buildingNameAdd)}>
                                  <span>Building Name (if applicable)</span>
                                  {addNewName ? (
                                    <span onClick={() => setAddNewName(false)}>
                                      <MdArrowDropDown /> Select from list
                                    </span>
                                  ) : (
                                    <span onClick={() => setAddNewName(true)}>
                                      <AiOutlinePlus /> Add New
                                    </span>
                                  )}
                                </p>
                                {addNewName ? (
                                  <input
                                    type="text"
                                    className={clsx(
                                      "form-control",
                                      styles.textField,
                                      assignMandUnitNumber ? "error" : null
                                    )}
                                    value={buildingName}
                                    name="buildingName"
                                    onChange={(e) => setBuildingName(e.target.value)}
                                    placeholder="Add Building Name"
                                  />
                                ) : (
                                  <select
                                    className={clsx("arrows", styles.selectTag)}
                                    style={{ padding: "7px 15px" }}
                                    value={buildingName}
                                    name="buildingName"
                                    onChange={(e) => setBuildingName(e.target.value)}
                                  >
                                    <option value="">Select from the list or add new</option>
                                    {buildingNameArray?.map((item) => (
                                      <option key={item} value={item} disabled={item === "No building names found"}>
                                        {item}
                                      </option>
                                    ))}
                                  </select>
                                )}
                              </div>
                            </Col>
                            <Col lg={"6"} md={"6"} sm={"12"}>
                              <div className={clsx(styles.inputDiv)}>
                                <p className={clsx(styles.para1)}>Building Type</p>
                                <select
                                  className={clsx(styles.selectTag, assignMandBuildingType ? "error" : null)}
                                  style={{ padding: "7px 15px" }}
                                  value={buildingType}
                                  name="buildingType"
                                  onChange={(e) => {
                                    setBuildingType(e.target.value);
                                    if (e.target.value) setAssignMandBuildingType(false);
                                  }}
                                >
                                  <option value="">Select</option>
                                  {homeType === "63c683e55178561202fd7ee7"
                                    ? buildingTypeByDev?.map((item) => {
                                        return (
                                          <option key={`${item?._id}`} value={item?._id}>
                                            {item?.name}
                                          </option>
                                        );
                                      })
                                    : buildingTypeByDev
                                        ?.filter((obj) => obj?._id !== "63eb65d7155f0437cef7dba5")
                                        ?.map((item) => {
                                          return (
                                            <option key={`${item?._id}`} value={item?._id}>
                                              {item?.name}
                                            </option>
                                          );
                                        })}
                                </select>
                              </div>
                            </Col>
                          </>
                        ) : null}
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <div className={clsx(styles.inputDiv)}>
                            <p className={clsx(styles.para1)}>Home Style*</p>
                            <select
                              className={clsx("arrows", styles.selectTag, assignMandHomeStyle ? "error" : "")}
                              style={{ padding: "7px 15px" }}
                              ref={homeStyleRef}
                              value={homeStyle}
                              name="homeStyle"
                              onChange={(e) => {
                                setHomeStyle(e.target.value);
                                if (e.target.value) setAssignMandHomeStyle(false);
                              }}
                            >
                              <option value="">Select</option>
                              {homeStyleData?.length ? (
                                homeStyleData?.map((item) => (
                                  <option key={item?._id} value={item?._id}>
                                    {item?.name}
                                  </option>
                                ))
                              ) : (
                                <option value="">Select home type first</option>
                              )}
                            </select>
                          </div>
                        </Col>
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <div className={clsx(styles.inputDiv)}>
                            <p className={clsx(styles.para1)}>{`Seller's Interest`}</p>
                            <select
                              className={clsx("arrows", styles.selectTag)}
                              style={{ padding: "7px 15px" }}
                              value={sellerInterest}
                              name="sellerInterest"
                              onChange={(e) => setSellerInterest(e.target.value)}
                              disabled={mainPath === "assignment"}
                            >
                              <option value="">Select</option>
                              <option value="Registered Owner">Registered Owner</option>
                              <option value="Assignment of Contract">Assignment of Contract</option>
                              <option value="Committee/Trustee">Committee/Trustee</option>
                              <option value="Court Ordered Sale">Court Ordered Sale</option>
                              <option value="Estate Sale">Estate Sale</option>
                              <option value="Power of Attorney">Power of Attorney</option>
                            </select>
                          </div>
                        </Col>
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <div className={clsx(styles.inputDiv)}>
                            <p className={clsx(styles.para1)}>Construction Status*</p>
                            <select
                              className={clsx(styles.selectTag, assignMandConstStatus ? "error" : "")}
                              style={{ padding: "7px 15px" }}
                              ref={constructionStatusRef}
                              value={constructionStatus}
                              name="constructionStatus"
                              onChange={(e) => {
                                setConstructionStatus(e.target.value);
                                if (e.target.value) setAssignMandConstStatus(false);
                              }}
                            >
                              <option value="">Select</option>
                              {constructionData?.map((item) => (
                                <option key={item?._id} value={item?._id}>
                                  {item?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <div className={clsx(styles.inputDiv)}>
                            <p className={clsx(styles.para1)}>
                              {constructionStatus === "63cbb662860eaa6e22264e88"
                                ? `Completed*`
                                : `Expected Completion*`}
                            </p>
                            <Row>
                              <Col sm={"6"}>
                                <select
                                  className={clsx(styles.selectTag, assignMandcompYear ? "error" : "")}
                                  style={{ padding: "7px 15px" }}
                                  ref={completeYearRef}
                                  value={completeYear}
                                  name="completeYear"
                                  onChange={(e) => {
                                    setCompleteYear(e.target.value);
                                    if (e.target.value) setAssignMandcompYear(false);
                                  }}
                                >
                                  <option value="">Select year</option>
                                  {constructionStatus === "63cbb662860eaa6e22264e88"
                                    ? yearsBefore(5)?.map((item) => (
                                        <option key={item} value={item}>
                                          {item}
                                        </option>
                                      ))
                                    : yearsAfter(5)?.map((item) => (
                                        <option key={item} value={item}>
                                          {item}
                                        </option>
                                      ))}
                                </select>
                              </Col>
                              <Col sm={"6"}>
                                <select
                                  className={clsx(styles.selectTag, assignMandcompMonth ? "error" : null)}
                                  style={{ padding: "7px 15px" }}
                                  ref={completeMonthRef}
                                  value={completeMonth}
                                  name="completeMonth"
                                  onChange={(e) => {
                                    setCompleteMonth(e.target.value);
                                    if (e.target.value) setAssignMandcompMonth(false);
                                  }}
                                >
                                  <option value="">Season/Month</option>
                                  {["Spring", "Fall", "Summer", "Winter", ...allMonths]?.map((item) => (
                                    <option key={item} value={item}>
                                      {item}
                                    </option>
                                  ))}
                                </select>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <div className={clsx(styles.inputDiv)}>
                            <p className={clsx(styles.para1)}>Unit Preferences </p>
                            <RSelect
                              options={
                                unitPreferenceData?.length
                                  ? unitPreferenceData?.map((item) =>
                                      !unitPreferenceArray?.includes(item?._id)
                                        ? { label: item?.name, value: item?._id }
                                        : { label: item?.name, value: item?._id, isDisabled: true }
                                    )
                                  : []
                              }
                              className="searchSelect"
                              value={unitPreference}
                              onChange={(value) => {
                                setUnitPreferenceArray([...unitPreferenceArray, value.value]);
                                setUnitPreference("");
                              }}
                            />
                            <div className={styles.arrayItemDiv}>
                              {unitPreferenceArray?.length
                                ? unitPreferenceArray?.map((item, index) => {
                                    return (
                                      <p
                                        key={`${Math.random()}-${index}`}
                                        className={styles.para2}
                                        onClick={() => {
                                          let a = unitPreferenceArray?.indexOf(item);
                                          if (a > -1) {
                                            unitPreferenceArray?.splice(a, 1);
                                            setUnitPreferenceArray([...unitPreferenceArray]);
                                          }
                                        }}
                                      >
                                        <AiOutlineCloseCircle className={styles.arrayCloseIcon} />
                                        <span>{unitPreferenceData?.filter((li) => li?._id === item)?.[0]?.name}</span>
                                      </p>
                                    );
                                  })
                                : null}
                            </div>
                          </div>
                        </Col>
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <div className={clsx(styles.inputDiv)}>
                            <p className={clsx(styles.para1)}>
                              Slug{" "}
                              <span
                                onClick={() => setChangeSlug(true)}
                                style={{ color: "#00aeff", cursor: "pointer" }}
                              >{`(Change Slug)`}</span>
                            </p>
                            <input
                              type="text"
                              className={clsx("form-control", styles.textField)}
                              name="slug"
                              value={slug}
                              placeholder={`#{unit number}-{address}-{building name}`}
                              onChange={(e) => setSlug(e.target.value)}
                              disabled={!changeSlug ? true : false}
                              onBlur={(e) => setSlug(convertToSlug(e.target.value))}
                            />
                          </div>
                        </Col>
                        {mainPath === "new-homes" ? (
                          <Col sm={"12"} md={"6"} lg={"6"}>
                            <div className={clsx(styles.inputDiv)}>
                              <p className={clsx(styles.para1)}>Occupancy </p>
                              <select
                                className={clsx(styles.selectTag, assignMandHomeType ? "error" : "")}
                                style={{ padding: "7px 15px" }}
                                value={occupancy}
                                onChange={(e) => {
                                  setOccupancy(e.target.value);
                                }}
                              >
                                <option value="">Select</option>
                                {assignData?.occupancy?.length
                                  ? assignData?.occupancy?.map((item, i) => (
                                      <option key={i} value={item}>
                                        {item}
                                      </option>
                                    ))
                                  : null}
                              </select>
                            </div>
                          </Col>
                        ) : null}
                      </Row>
                    </BlockContent>
                  </Block>
                  <Modal isOpen={open?.modalOpen} size="lg" centered toggle={handleClose}>
                    <ModalHeader toggle={handleClose}>Add new community</ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col sm={"12"}>
                          <div className={clsx(styles.inputDiv)}>
                            <p className={clsx(styles.heightPara)}>Project Name *</p>
                            <input
                              type="text"
                              placeholder={"Enter the community name"}
                              className={clsx("form-control", styles.textField, devMandName ? "error" : "")}
                              ref={devNameRef}
                              value={newDevName}
                              name="newDevName"
                              onChange={(e) => {
                                setNewDevName(e.target.value);
                                if (e.target.value) setDevMandName(false);
                              }}
                            />
                          </div>
                        </Col>
                        <Col sm={"6"}>
                          <div className={clsx(styles.inputDiv)}>
                            <p className={clsx(styles.heightPara)}>Project Address *</p>
                            <StandaloneSearchBox
                              onLoad={(searchBox) => {
                                searchBox.addListener("places_changed", () =>
                                  handlePlaceChanged2(searchBox.getPlaces(), searchBox)
                                );
                              }}
                            >
                              <input
                                type="text"
                                placeholder="Enter Your Property Address"
                                className={clsx("form-control", styles.textField, devMandAddress ? "error" : "")}
                                ref={devAddressRef}
                                value={developmentAddress}
                                onChange={(e) => {
                                  setDevelopmentAddress(e.target.value);
                                  if (e.target.value) setDevMandAddress(false);
                                }}
                                required="required"
                                data-error="Text is required."
                              />
                            </StandaloneSearchBox>
                          </div>
                        </Col>
                        <Col sm={"6"}>
                          <div className={clsx(styles.inputDiv)}>
                            <p className={clsx(styles.heightPara)}>Postal Code</p>
                            <input
                              type="text"
                              placeholder={"Enter Postal Code"}
                              className={clsx("form-control", styles.textField)}
                              value={devZipcode}
                              name="devZipcode"
                              onChange={(e) => setDevZipcode(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col sm={"6"}>
                          <div className={clsx(styles.inputDiv)}>
                            <p className={clsx(styles.heightPara)}>Country *</p>
                            <select
                              className={clsx("arrows", styles.selectTag, devMandCountry ? "error" : "")}
                              style={{
                                padding: "7px 15px",
                                background: devCountry === "63d76e99b005363c54606967" ? "#e0e0e0" : "",
                              }}
                              ref={devCountryRef}
                              value={devCountry}
                              name="devCountry"
                              onChange={(e) => {
                                setDevCountry(e.target.value);
                                if (e.target.value) setDevMandCountry(false);
                              }}
                            >
                              <option value="">Select</option>
                              {countryData?.length
                                ? countryData?.map((item) => (
                                    <option key={`${Math.random()}-${item?._id}`} value={item?._id}>
                                      {item?.name}
                                    </option>
                                  ))
                                : null}
                            </select>
                          </div>
                        </Col>
                        <Col sm={"6"}>
                          <div className={clsx(styles.inputDiv)}>
                            <p className={clsx(styles.heightPara)}>Province *</p>
                            <select
                              className={clsx("arrows", styles.selectTag, devMandProvince ? "error" : "")}
                              style={{ padding: "7px 15px" }}
                              ref={devProvinceRef}
                              value={devProvince}
                              name="devProvince"
                              onChange={(e) => {
                                setDevProvince(e.target.value);
                                if (e.target.value) setDevMandProvince(false);
                              }}
                            >
                              <option value="">Select</option>
                              {provinceData?.length ? (
                                provinceData?.map((item) => (
                                  <option key={`${Math.random()}-${item?._id}`} value={item?._id}>
                                    {item?.name}
                                  </option>
                                ))
                              ) : (
                                <option value="">Please select country first</option>
                              )}
                            </select>
                          </div>
                        </Col>
                        <Col sm={"6"}>
                          <div className={clsx(styles.inputDiv)}>
                            <p className={clsx(styles.heightPara)}>City *</p>
                            <select
                              className={clsx("arrows", styles.selectTag, devMandCity ? "error" : "")}
                              style={{ padding: "7px 15px" }}
                              ref={devCityRef}
                              value={devCity}
                              name="devCity"
                              onChange={(e) => {
                                setDevCity(e.target.value);
                                if (e.target.value) setDevMandCity(false);
                              }}
                            >
                              <option value="">Select</option>
                              {cityData?.length ? (
                                cityData?.map((item) => (
                                  <option key={`${Math.random()}-${item?._id}`} value={item?._id}>
                                    {item?.name}
                                  </option>
                                ))
                              ) : (
                                <option value="">Please select province first</option>
                              )}
                            </select>
                          </div>
                        </Col>
                        <Col sm={"6"}>
                          <div className={clsx(styles.inputDiv)}>
                            <p className={clsx(styles.heightPara)}>Neighbourhood</p>
                            <select
                              className={clsx("arrows", styles.selectTag)}
                              style={{ padding: "7px 15px" }}
                              value={devNeighbourhood}
                              name="devNeighbourhood"
                              onChange={(e) => setDevNeighbourhood(e.target.value)}
                            >
                              <option value="">Select</option>
                              {neighbourhoodData?.length ? (
                                neighbourhoodData?.map((item) => (
                                  <option key={`${Math.random()}-${item?._id}`} value={item?._id}>
                                    {item?.name}
                                  </option>
                                ))
                              ) : (
                                <option value="">Please select city first</option>
                              )}
                            </select>
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button onClick={() => handleAddDevelopment()} variant="contained">
                        Add
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
              </div>
            </div>
          </Card>
          <div style={{ margin: "15px 0", display: "flex", gap: "1rem" }}>
            <FooterBtn text={"Cancel"} color={"secondary"} outline onClick={handleBackFun} />
            <FooterBtn text={"Next"} color={"info"} onClick={handleNext} />
          </div>
        </Block>
      </Content>
    </>
  );
};

export default Assignment1;
