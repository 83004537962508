import { StandaloneSearchBox, useLoadScript } from "@react-google-maps/api";
import axios from "axios";
import clsx from "clsx";
import parse from "html-react-parser";
import React, { useCallback, useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import Content from "../../layout/content/Content";
import DevNavTab from "../../pages/Development/DevNavTab";
import JoditPreview from "../../pages/components/forms/rich-editor/JoditPreview";
import { cityReset, getcityByProvince } from "../../redux/Apislices/citySlice";
import { addCommunity, getCommunity, searchCommunity } from "../../redux/Apislices/communitySlice";
import {
  addCompany,
  checkCompanyEmail,
  getBuilder,
  getMarketingCompany,
  resetCheck,
  searchBuilder,
  searchMarketing,
} from "../../redux/Apislices/companySlice";
import { getconstructionMethod } from "../../redux/Apislices/constructionMethodSlice";
import { countryReset, getCountry } from "../../redux/Apislices/countrySlice";
import {
  addNewDevelopment,
  editDevelopment,
  getDevelopmentById,
  handleAddDevelopment,
  handleDevAddReset,
} from "../../redux/Apislices/developmentSlice";
import { getDevelopmentType } from "../../redux/Apislices/developmentTypeSlice";
import { getHomeType } from "../../redux/Apislices/homeTypeSlice";
import { getLandTitle } from "../../redux/Apislices/landTitleSlice";
import { getneighbourhoodByCity, neighbourhoodReset } from "../../redux/Apislices/neighbourhoodSlice";
import { getprovinceByCountry, provinceReset } from "../../redux/Apislices/provinceSlice";
import { convertToSlug, debounce } from "../../utils/Utils";
import { getLocationData } from "../../utils/location";
import {
  Block,
  BlockBetween,
  BlockContent,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  RSelect,
  Row,
} from "../Component";
import CustomCreatable from "../input/custom/CustomCreatable";
import MapComponent from "../map/MapComponent";
import styles from "./dev.module.scss";
import FooterBtn from "../common/FooterBtn";
import { editProject } from "../../redux/Apislices/projectSlice";

const libraries = ["places"];

const Dev1 = ({ toggleNext, toggleBack, toggleIconTab, activeIconTab }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries,
  });
  const { type, id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    homeType: homeTypeObj,
    country: countryObj,
    province: provinceObj,
    city: cityObj,
    neighbourhood: neighbourhoodObj,
    landTitle: landTitleObj,
    developmentType: developmentTypeObj,
    constructionMethod: constructionMethodObj,
    company: companyObj,
    community,
    profile,
    development,
  } = useSelector((state) => state);

  const { developmentById, addDevelopment } = development;
  const { homeType: homeTypeData } = homeTypeObj;
  const {
    countries: { data: countryData },
  } = countryObj;
  const {
    provinceByCountry: { data: provinceData },
  } = provinceObj;
  const {
    cityByProvince: { data: cityData },
  } = cityObj;
  const {
    neighbourhoodByCity: { data: neighbourhoodData },
  } = neighbourhoodObj;
  const { landTitle: landTitleData } = landTitleObj;
  const { developmentType: developmentTypeData } = developmentTypeObj;
  const { constructionMethod: constructionMethodData } = constructionMethodObj;

  const [devData, setDevData] = useState(false);

  const [MandDevelopmentName, setMandDevelopmentName] = useState(false);
  const [MandHomeTypes, setMandHomeTypes] = useState(false);
  const [mandBuildingType, setMandBuildingType] = useState(false);
  const [MandAddress, setMandAddress] = useState(false);
  const [addressErrorMessage, setAddressErrorMessage] = useState(false);
  const [MandCountry, setMandCountry] = useState(false);
  const [MandProvince, setMandProvince] = useState(false);
  const [MandCity, setMandCity] = useState(false);
  const nameRef = useRef(null);
  const homeTypeRef = useRef(null);
  const buildingTypeRef = useRef(null);
  const addressRef = useRef(null);
  const countryRef = useRef(null);
  const provinceRef = useRef(null);
  const cityRef = useRef(null);

  const [developmentName, setDevelopmentName] = useState("");
  const [slug, setSlug] = useState("");
  const [slugChange, setSlugChange] = useState(false);
  const [buildingType, setBuildingType] = useState("");
  const [construction, setConstruction] = useState("");
  const [units, setUnits] = useState("");
  const [floors, setFloors] = useState("");
  const [checkBox, setCheckBox] = useState("");
  const [address, setAddress] = useState("");

  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [neighbourhood, setNeighbourhood] = useState("");

  const [communityCheck, setCommunityCheck] = useState(false);
  const [communityName, setCommunityName] = useState("");
  const [newCommunityName, setNewCommunityName] = useState("");
  const [communityAddress, setCommunityAddress] = useState("");
  const [comPostalCode, setComPostalCode] = useState("");
  const [comCountry, setComCountry] = useState("");
  const [comProvince, setComProvince] = useState("");
  const [comCity, setComCity] = useState("");
  const [comNeighbourhood, setComNeighbourhood] = useState("");
  const [comLocationData, setComLocationData] = useState(false);

  const [comMandName, setComMandName] = useState(false);
  const [comMandCounrty, setComMandCounrty] = useState(false);
  const [comMandProvince, setComMandProvince] = useState(false);
  const [comMandCity, setComMandCity] = useState(false);
  const comNameRef = useRef(null);
  const comCountryRef = useRef(null);
  const comProvinceRef = useRef(null);
  const comCityRef = useRef(null);

  const [devMandName, setDevMandName] = useState(false);
  const [devMandAddress, setDevMandAddress] = useState(false);
  const [devMandMobile, setDevMandMobile] = useState(false);
  const [devMandEmail, setDevMandEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const devNameRef = useRef(null);
  const devAddressRef = useRef(null);
  const devMobileRef = useRef(null);
  const devEmailRef = useRef(null);

  const [marketMandName, setMarketMandName] = useState(false);
  const [marketMandAddress, setMarketMandAddress] = useState(false);
  const [marketMandMobile, setMarketMandMobile] = useState(false);
  const [marketMandEmail, setMarketMandEmail] = useState(false);
  const [errorMessage1, setErrorMessage1] = useState(false);
  const marketNameRef = useRef(null);
  const marketAddressRef = useRef(null);
  const marketMobileRef = useRef(null);
  const marketEmailRef = useRef(null);

  const [buildingName, setBuildingName] = useState("");
  const [buildingNameArray, setBuildingNameArray] = useState([]);

  const [homeType, setHomeType] = useState();
  const [homeTypeArray, setHomeTypeArray] = useState([]);

  const [titleToLand, setTitleToLand] = useState();

  const [developer, setDeveloper] = useState("");
  const [developerArray, setDeveloperArray] = useState([]);

  const [newDeveloperName, setNewDeveloperName] = useState("");
  const [newDeveloperAddress, setNewDeveloperAddress] = useState("");
  const [newDeveloperMobile, setNewDeveloperMobile] = useState("");
  const [newDeveloperEmail, setNewDeveloperEmail] = useState("");
  const [newDeveloperWebsite, setNewDeveloperWebsite] = useState("");

  const [marketing, setMarketing] = useState({});
  const [marketingArray, setMarketingArray] = useState([]);

  const [newMarketingName, setNewMarketingName] = useState("");
  const [newMarketingAddress, setNewMarketingAddress] = useState("");
  const [newMarketingMobile, setNewMarketingMobile] = useState("");
  const [newMarketingEmail, setNewMarketingEmail] = useState("");
  const [newMarketingWebsite, setNewMarketingWebsite] = useState("");

  const [assignedUser, setAssignedUser] = useState(null);

  const [marker, setMarker] = useState({});
  const [zipcode, setZipcode] = useState("");
  const [zoom, setZoom] = useState(10);
  const [locationData, setLocationData] = useState(false);
  const [summary, setSummary] = useState("");

  const [open, setOpen] = React.useState({ modalOpen: false, modalType: "" });
  const handleOpen = (type) => {
    setOpen({ modalOpen: true, modalType: type });
    if (type === "community") {
      dispatch(countryReset());
      dispatch(provinceReset());
      dispatch(cityReset());
      dispatch(neighbourhoodReset());
    }
  };
  const handleClose = () => {
    setOpen({ modalOpen: false, modalType: "" });
    dispatch(resetCheck());
  };

  useEffect(() => {
    if ((developerArray?.length || marketingArray?.length) && !checkBox) setCheckBox("developer");
  }, [developerArray, marketingArray, checkBox]);

  const handlePlaceChanged = (places, searchBox) => {
    if (places?.length) {
      setAddress(places[0].formatted_address);
      setZoom(15);
      setMarker({ lat: places?.[0]?.geometry?.location?.lat(), lng: places?.[0]?.geometry?.location?.lng() });
      let data = getLocationData(places[0]);
      setZipcode(data?.zipcode);
      setLocationData(data);
    }
  };

  const handlePlaceChanged2 = (places, searchBox) => {
    if (places?.length) setNewDeveloperAddress(places[0].formatted_address);
  };

  const handlePlaceChanged3 = (places, searchBox) => {
    if (places?.length) setNewMarketingAddress(places[0].formatted_address);
  };

  const handleBack = async () => {
    dispatch(handleDevAddReset());
    navigate("/development");
  };

  const fetchAddress = async (lat, lng) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`
      );
      const addresses = response.data.results[0];
      let data = getLocationData(addresses);
      if (!address) {
        setAddress(data?.address);
        setLocationData(data);
      }
      if (!zipcode) {
        setZipcode(data?.zipcode);
      }
      setZoom(18);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    if (marker?.lat && marker?.lng) {
      fetchAddress(marker?.lat, marker?.lng);
    } else {
      setLocationData(false);
    }
  }, [marker?.lat, marker?.lng]);

  // dispatching all the data needed for page
  useEffect(() => {
    dispatch(getHomeType());
    dispatch(getDevelopmentType());
    dispatch(getLandTitle());
    dispatch(getconstructionMethod());
    dispatch(getBuilder());
    dispatch(getMarketingCompany());
    dispatch(getCommunity());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCountry());
  }, [dispatch, open]);

  // if data is saved in session storage then retrieving it
  useEffect(() => {
    if (developmentById?.data) setDevData(developmentById?.data);
    else if (addDevelopment?.data) setDevData(addDevelopment?.data);
  }, [developmentById?.data, addDevelopment?.data]);

  useEffect(() => {
    if (devData) {
      if (devData?.buildingNames?.length) setBuildingNameArray([...devData?.buildingNames]);
      if (devData?.homeTypes?.length) setHomeTypeArray([...devData?.homeTypes]);
      if (devData?.titleToLand) setTitleToLand(devData?.titleToLand);
      if (devData?.name) setDevelopmentName(devData?.name);
      if (devData?.name) setSlug(devData?.slug);
      if (devData?.developmentType) setBuildingType(devData?.developmentType);
      if (devData?.constructionMethod) setConstruction(devData?.constructionMethod);
      if (devData?.units) setUnits(devData?.units);
      if (devData?.stories) setFloors(devData?.stories);
      if (devData?.company?.update) setCheckBox(devData?.company?.update);
      if (devData?.description) setSummary(devData?.description);
      if (devData?.company?.company?.length) setDeveloperArray([...devData?.company?.company]);
      if (devData?.company?.marketing?.length) setMarketingArray([...devData?.company?.marketing]);
      if (devData?.address?.firstLine) setAddress(devData?.address?.firstLine);
      if (devData?.address?.country) setCountry(devData?.address?.country);
      if (devData?.address?.province) setProvince(devData?.address?.province);
      if (devData?.address?.city) setCity(devData?.address?.city);
      if (devData?.address?.neighbourhood) setNeighbourhood(devData?.address?.neighbourhood);
      if (devData?.address?.zipcode) setZipcode(devData?.address?.zipcode);
      if (+devData?.address?.location?.coordinates?.[0] && +devData?.address?.location?.coordinates?.[1])
        setMarker({
          lat: +devData?.address?.location?.coordinates?.[1],
          lng: +devData?.address?.location?.coordinates?.[0],
        });
    }
  }, [devData]);

  // useEffect(() => {
  //   if(!slugChange) {
  //       setSlug(convertToSlug(`${developmentName} ${cityData?.filter((li) => li?._id === city)?.[0]?.name} ${homeTypeArray?.length === 1 ? homeTypeData?.filter((li) => li?._id === homeTypeArray?.[0])?.[0]?.name : "homes"}`));
  //   }
  // }, [city, cityData, developmentName, homeTypeArray, homeTypeData, slugChange]);

  useEffect(() => {
    if (devData?.user && (companyObj?.builder?.length || companyObj?.marketing?.length)) {
      let a = companyObj?.builder?.find((li) => li?._id === devData?.user);
      let b = companyObj?.marketing?.find((li) => li?._id === devData?.user);
      if (a) setAssignedUser({ label: a?.name, value: a?._id });
      else if (b) setAssignedUser({ label: b?.name, value: b?._id });
    }
  }, [devData, companyObj?.builder, companyObj?.marketing]);

  useEffect(() => {
    if (devData) {
      console.log("devData 1: ", devData);
      if (devData?.community && community?.communities?.data?.length) {
        setCommunityCheck(true);
        setCommunityName(
          community?.communities?.data
            ?.filter((li) => li?._id === devData?.community)
            ?.map((li) => ({
              label: li?.name,
              value: li?._id,
              country: li?.address?.country ? li?.address?.country?._id : "",
              province: li?.address?.province ? li?.address?.province?._id : "",
              city: li?.address?.city ? li?.address?.city?._id : "",
              neighbourhood: li?.address?.neighbourhood ? li?.address?.neighbourhood?._id : "",
            }))?.[0]
        );
      }
    }
  }, [devData, community?.communities?.data]);

  // setting Canada as default country
  useEffect(() => {
    if (countryData?.length && !open?.modalOpen) setCountry("63d76e99b005363c54606967");
    else if (countryData?.length && open?.modalOpen) setComCountry("63d76e99b005363c54606967");
  }, [countryData, open]);

  // get province list from country
  useEffect(() => {
    if (country && !open?.modalOpen) dispatch(getprovinceByCountry(country || comCountry));
    else if (country && open?.modalOpen) dispatch(getprovinceByCountry(comCountry));
  }, [country, comCountry, dispatch, open]);

  // get city list from province
  useEffect(() => {
    if (province && !open?.modalOpen) dispatch(getcityByProvince(province || comProvince));
    else if (comProvince && open?.modalOpen) dispatch(getcityByProvince(comProvince));
  }, [province, comProvince, dispatch, open]);

  // get neighbourhood list from city
  useEffect(() => {
    if (city && !open?.modalOpen) dispatch(getneighbourhoodByCity(city || comCity));
    else if (comCity && open?.modalOpen) dispatch(getneighbourhoodByCity(comCity));
  }, [city, comCity, dispatch, open]);

  // set country by address or marker
  useEffect(() => {
    if (typeof communityName === "object" && countryData?.length) {
      setCountry(communityName?.country);
    } else if (locationData?.country && countryData?.length) {
      let a = countryData?.filter((li) => li?.name === locationData?.country);
      if (a?.length) setCountry(a[0]?._id);
      else setCountry("");
    }
  }, [locationData?.country, countryData, communityName]);

  // set province by address or marker
  useEffect(() => {
    if (typeof communityName === "object" && provinceData?.length) {
      if (communityName?.province) setProvince(communityName?.province);
    } else if (locationData?.province && provinceData?.length) {
      let a = provinceData?.filter((li) => li?.name === locationData?.province);
      if (a?.length) setProvince(a[0]?._id);
      else setProvince("");
    }
  }, [locationData?.province, provinceData, communityName]);

  // set city by address or marker
  useEffect(() => {
    if (typeof communityName === "object" && cityData?.length) {
      if (communityName?.city) setCity(communityName?.city);
    } else if (locationData?.city && cityData?.length) {
      let a = cityData?.filter((li) => li?.name === locationData?.city);
      if (a?.length) setCity(a[0]?._id);
      else setCity("");
    }
  }, [locationData?.city, cityData, communityName]);

  // set neighbourhood by address or marker
  useEffect(() => {
    if (typeof communityName === "object" && neighbourhoodData?.length)
      if (communityName?.neighbourhood) setNeighbourhood(communityName?.neighbourhood);
  }, [neighbourhoodData, communityName]);

  // set community country by address or marker
  useEffect(() => {
    if (typeof communityName === "object" && countryData?.length) {
      let a = community?.communities?.data?.filter((li) => li?._id === communityName?.value)?.[0];
      if (a?.length) setComCountry(a[0]?.address?.country);
    } else if (comLocationData?.country && countryData?.length) {
      let a = countryData?.filter((li) => li?.name === comLocationData?.country);
      if (a?.length) setComCountry(a[0]?._id);
      else setComCountry("");
    }
  }, [communityName, community?.communities, comLocationData?.country, countryData]);

  // set community province by address or marker
  useEffect(() => {
    if (typeof communityName === "object" && provinceData?.length) {
      let a = community?.communities?.data?.filter((li) => li?._id === communityName?.value)?.[0];
      if (a?.length) setComProvince(a[0]?.address?.province);
    } else if (comLocationData?.province && provinceData?.length) {
      let a = provinceData?.filter((li) => li?.name === comLocationData?.province);
      if (a?.length) setComProvince(a[0]?._id);
      else setComProvince("");
    }
  }, [comLocationData?.province, provinceData, community?.communities]);

  // set community city by address or marker
  useEffect(() => {
    if (typeof communityName === "object" && cityData?.length) {
      let a = community?.communities?.data?.filter((li) => li?._id === communityName?.value)?.[0];
      if (a?.length) setComCity(a[0]?.address?.city);
    } else if (comLocationData?.city && cityData?.length) {
      let a = cityData?.filter((li) => li?.name === comLocationData?.city);
      if (a?.length) setComCity(a[0]?._id);
      else setComCity("");
    }
  }, [comLocationData?.city, cityData, community?.communities]);

  // set community neighbourhood by address or marker
  useEffect(() => {
    if (typeof communityName === "object" && neighbourhoodData?.length) {
      let a = community?.communities?.data?.filter((li) => li?._id === communityName?.value)?.[0];
      if (a?.length) setNeighbourhood(a[0]?.address?.neighbourhood);
    }
  }, [communityName, community?.communities, neighbourhoodData]);

  const handlePayload = () => {
    let data = { ...devData };
    if (address) data = { ...data, address: { ...data?.address, firstLine: address } };
    if (neighbourhood) data = { ...data, address: { ...data?.address, neighbourhood: neighbourhood } };
    else data = { ...data, address: { ...data?.address, neighbourhood: null } };
    if (city) data = { ...data, address: { ...data?.address, city: city } };
    if (province) data = { ...data, address: { ...data?.address, province: province } };
    if (country) data = { ...data, address: { ...data?.address, country: country } };
    if (zipcode) data = { ...data, address: { ...data?.address, zipcode: zipcode } };
    else data = { ...data, address: { ...data?.address, zipcode: null } };
    if (marker && marker.lng && marker.lat)
      data = {
        ...data,
        address: { ...data?.address, location: { type: "Point", coordinates: [+marker.lng, +marker.lat] } },
      };
    if (developerArray?.length) data = { ...data, company: { ...data?.company, company: developerArray } };
    else data = { ...data, company: { ...data?.company, company: [] } };
    if (marketingArray?.length) data = { ...data, company: { ...data?.company, marketing: marketingArray } };
    if (assignedUser && typeof assignedUser === "object") data = { ...data, user: assignedUser?.value };
    else data = { ...data, company: { ...data?.company, marketing: [] } };
    if (checkBox) data = { ...data, company: { ...data?.company, update: checkBox } };
    if (communityCheck && typeof communityName === "object") data.community = communityName?.value;
    else data.community = null;
    if (developmentName) data.name = developmentName;
    if (slug) data.slug = convertToSlug(slug);
    if (slugChange) data.slugByAdmin = true;
    if (summary) data.description = summary;
    if (homeTypeArray) data.homeTypes = homeTypeArray;
    if (floors) data.stories = +floors;
    if (buildingType) data.developmentType = buildingType;
    if (units) data.units = +units;
    if (buildingNameArray) data.buildingNames = buildingNameArray;
    if (type === "edit" || type === "duplicate") data.status = devData?.status;
    else data.status = "pending";
    if (titleToLand) data.titleToLand = titleToLand;
    if (construction) data.constructionMethod = construction;
    data.type = "presale";
    if (developmentName && homeTypeArray && developerArray && city && province) {
      let getNamesString = (array, mainArray, toLowerCase = false) => {
        const names = array.map((id) => {
          const person = mainArray?.find((p) => p?._id === id);
          return person ? (toLowerCase ? person?.name?.toLowerCase() : person?.name) : "";
        });
        let result = "";
        if (names?.length > 1) {
          const lastPerson = names?.pop();
          result = names?.join(", ") + " & " + lastPerson;
        } else {
          result = names?.[0];
        }
        return result;
      };
      data = {
        ...data,
        descriptionTitle: `${developmentName}: A new${
          homeTypeArray?.includes("63c66c47cec8665e878ce4df") && buildingType
            ? ` ${developmentTypeData?.filter((li) => li?._id === buildingType)?.[0]?.name?.toLowerCase()}`
            : ``
        } ${getNamesString(homeTypeArray, homeTypeData, true)} development ${
          developerArray?.length
            ? `by ${getNamesString(
                developerArray?.filter((li) => li !== "6495480320a309393ee5da26"),
                companyObj?.builder
              )}`
            : ""
        } in ${neighbourhood ? `${neighbourhoodData?.filter((li) => li?._id === neighbourhood)?.[0]?.name}, ` : ""}${
          city ? `${cityData?.filter((li) => li?._id === city)?.[0]?.name} ` : ""
        } ${province ? `${provinceData?.filter((li) => li?._id === province)?.[0]?.shortSymbol}` : ""}.`,
      };
    }
    return data;
  };

  const handleError = () => {
    if (!developmentName) setMandDevelopmentName(true);
    if (homeTypeArray?.length === 0) setMandHomeTypes(true);
    if (!address) setMandAddress(true);
    if (!country) setMandCountry(true);
    if (!province) setMandProvince(true);
    if (!city) setMandCity(true);
    if (!developmentName) nameRef?.current?.focus();
    else if (homeTypeArray?.length === 0) homeTypeRef?.current?.focus();
    else if (!address) addressRef?.current?.focus();
    else if (!country) countryRef?.current?.focus();
    else if (!province) provinceRef?.current?.focus();
    else if (!city) cityRef?.current?.focus();
    toast.error("Please fill all mandatory data");
  };

  const handleAddUpdate = (successCB) => {
    let data = handlePayload();
    if (developmentName && homeTypeArray?.length && address && province && city) {
      console.log("data: ", data);
      if (
        (homeTypeArray?.includes("63c66c47cec8665e878ce4df") || homeTypeArray?.includes("63c683e55178561202fd7ee7")) &&
        !buildingType
      ) {
        toast.error("Please fill all mandatory data");
        if (!buildingType) {
          setMandBuildingType(true);
        }
        if (!buildingType) {
          buildingTypeRef?.current?.focus();
        }
      } else {
        let a = cityData?.find((li) => li?._id === city);
        if (locationData && a?.name !== locationData?.city) {
          setMandAddress(true);
          addressRef.current.focus();
          toast.error("Address and city doesn't match");
        } else {
          if (type === "edit" || type === "duplicate") {
            dispatch(
              editDevelopment(
                id,
                data,
                (res) => {
                  navigate(`/development/edit/${res?.data?.slug}`);
                  successCB({ success: true });
                },
                (res) => {
                  toast.error(res?.message);
                }
              )
            );
          } else {
            dispatch(handleAddDevelopment({ ...addDevelopment, data }));
            successCB({ success: true });
          }
        }
      }
    } else {
      handleError();
    }
  };

  const handleNext = () => {
    handleAddUpdate((res) => {
      if (res?.success) toggleNext();
    });
  };

  // Save as draft
  const addToDraft = async () => {
    let editData = handlePayload();
    if (type === "edit") editData.status = devData?.status;
    else editData.status = "draft";
    if (profile?.profile?.role === "associate")
      editData = {
        ...editData,
        company: { ...editData?.company, associate: { associate: profile?.profile?._id, byAssociate: true } },
      };

    console.log("editData: ", editData);
    if (developmentName && homeTypeArray?.length && address && province && city) {
      if (
        (homeTypeArray?.includes("63c66c47cec8665e878ce4df") || homeTypeArray?.includes("63c683e55178561202fd7ee7")) &&
        !buildingType
      ) {
        toast.error("Please fill all mandatory data");
        if (!buildingType) {
          setMandBuildingType(true);
        }
        if (!buildingType) {
          buildingTypeRef?.current?.focus();
        }
      } else {
        if (type === "edit" || type === "duplicate") {
          dispatch(
            editDevelopment(
              id,
              editData,
              (res) => {
                toast.success("Development updated");
                dispatch(handleDevAddReset());
                navigate("/development");
              },
              (res) => {
                toast.error(res?.message);
              }
            )
          );
        } else {
          dispatch(
            addNewDevelopment(
              editData,
              async (res) => {
                toast.success("Development added as draft");
                dispatch(handleDevAddReset());
                navigate("/development");
              },
              (res) => {
                toast.error(res?.message);
              }
            )
          );
        }
      }
    } else {
      handleError();
    }
  };

  // add community
  const handleAddCommunity = async () => {
    let data = {};
    if (newCommunityName) data = { ...data, name: newCommunityName };
    if (comNeighbourhood) data = { ...data, address: { ...data?.address, neighbourhood: comNeighbourhood } };
    if (comCity) data = { ...data, address: { ...data?.address, city: comCity } };
    if (comProvince) data = { ...data, address: { ...data?.address, province: comProvince } };
    if (comCountry) data = { ...data, address: { ...data?.address, country: comCountry } };
    if (newCommunityName && comCountry && comProvince && comCity) {
      dispatch(
        addCommunity(
          data,
          (res) => {
            toast.success("New Community Added");
            handleClose();
            getCommunity();
            setCommunityName({
              label: res?.data?.name,
              value: res?.data?._id,
              country: res?.data?.address?.country ? res?.data?.address?.country : "",
              province: res?.data?.address?.province ? res?.data?.address?.province : "",
              city: res?.data?.address?.city ? res?.data?.address?.city : "",
              neighbourhood: res?.data?.address?.neighbourhood ? res?.data?.address?.neighbourhood : "",
            });
            setNewCommunityName("");
            setCommunityAddress("");
            setComPostalCode("");
            setComCountry("");
            setComProvince("");
            setComCity("");
            setComNeighbourhood("");
          },
          (res) => {
            toast.error(res?.message || "Something Went Wrong");
          }
        )
      );
    } else {
      if (!newCommunityName) setComMandName(true);
      if (!comCountry) setComMandCounrty(true);
      if (!comProvince) setComMandProvince(true);
      if (!comCity) setComMandCity(true);
      if (!newCommunityName) comNameRef?.current?.focus();
      else if (!comCountry) comCountryRef?.current?.focus();
      else if (!comProvince) comProvinceRef?.current?.focus();
      else if (!comCity) comCityRef?.current?.focus();
      toast.error("Please fill all mandatory data");
    }
  };

  // add developer/builder
  const handleAddDeveloper = async () => {
    let data = {};
    if (newDeveloperName) data = { ...data, name: newDeveloperName };
    if (newDeveloperAddress) data = { ...data, address: { ...data?.address, firstLine: newDeveloperAddress } };
    if (newDeveloperMobile) data = { ...data, phone: newDeveloperMobile };
    if (newDeveloperEmail) data = { ...data, businessEmail: newDeveloperEmail };
    if (newDeveloperWebsite) data = { ...data, website: newDeveloperWebsite };
    data = { ...data, companyType: "builder" };
    if (newDeveloperName && newDeveloperAddress && newDeveloperEmail && newDeveloperMobile) {
      dispatch(
        addCompany(
          data,
          (res) => {
            toast.success("New Developer/Builder Added");
            dispatch(getBuilder());
            setDeveloperArray([...developerArray, res?.data?._id]);
            handleClose();
            setNewDeveloperAddress("");
            setNewDeveloperEmail("");
            setDeveloper("");
            setNewDeveloperMobile("");
            setNewDeveloperName("");
            setNewDeveloperWebsite("");
          },
          (res) => {
            toast.error(res?.message || "Something Went Wrong");
          }
        )
      );
    } else {
      if (!newDeveloperName) setDevMandName(true);
      if (!newDeveloperAddress) setDevMandAddress(true);
      if (!newDeveloperMobile) setDevMandMobile(true);
      if (!newDeveloperEmail) setDevMandEmail(true);
      if (!newDeveloperName) devNameRef?.current?.focus();
      else if (!newDeveloperAddress) devAddressRef?.current?.focus();
      else if (!newDeveloperMobile) devMobileRef?.current?.focus();
      else if (!newDeveloperEmail) devEmailRef?.current?.focus();
      toast.error("Please fill all mandatory data");
    }
  };

  // add marketing company
  const handleAddMarketing = async () => {
    let data = {};
    if (newMarketingName) data = { ...data, name: newMarketingName };
    if (newMarketingAddress) data = { ...data, address: { ...data?.address, firstLine: newMarketingAddress } };
    if (newMarketingMobile) data = { ...data, phone: newMarketingMobile };
    if (newMarketingEmail) data = { ...data, businessEmail: newMarketingEmail };
    if (newMarketingWebsite) data = { ...data, website: newMarketingWebsite };
    data = { ...data, companyType: "marketing" };
    if (newMarketingName && newMarketingAddress && newMarketingMobile && newMarketingEmail) {
      dispatch(
        addCompany(
          data,
          (res) => {
            toast.success("New Marketing Company Added");
            dispatch(getMarketingCompany());
            setMarketingArray([...marketingArray, res?.data?._id]);
            handleClose();
            setNewMarketingAddress("");
            setNewMarketingEmail("");
            setNewMarketingMobile("");
            setNewMarketingName("");
            setMarketing("");
            setNewMarketingWebsite("");
          },
          (res) => {
            toast.error(res?.message || "Something Went Wrong");
          }
        )
      );
    } else {
      if (!newMarketingName) setMarketMandName(true);
      if (!newMarketingAddress) setMarketMandAddress(true);
      if (!newMarketingMobile) setMarketMandMobile(true);
      if (!newMarketingEmail) setMarketMandEmail(true);
      if (!newMarketingName) marketNameRef?.current?.focus();
      else if (!newMarketingAddress) marketAddressRef?.current?.focus();
      else if (!newMarketingMobile) marketMobileRef?.current?.focus();
      else if (!newMarketingEmail) marketEmailRef?.current?.focus();
      toast.error("Please fill all mandatory data");
    }
  };

  const debouncedSearch = useCallback(
    debounce((query) => dispatch(checkCompanyEmail(query)), 800),
    [dispatch]
  );
  useEffect(() => {
    if (newDeveloperEmail?.includes("@") && newDeveloperEmail?.includes(".")) debouncedSearch(newDeveloperEmail);
  }, [newDeveloperEmail]);

  const debouncedSearch2 = useCallback(
    debounce((query) => dispatch(checkCompanyEmail(query)), 800),
    [dispatch]
  );
  useEffect(() => {
    if (newMarketingEmail?.includes("@") && newMarketingEmail?.includes(".")) debouncedSearch2(newMarketingEmail);
  }, [newMarketingEmail]);

  const debouncedSearchCommunity = useCallback(
    debounce((query) => dispatch(searchCommunity(query)), 1000),
    [dispatch]
  );
  useEffect(() => {
    debouncedSearchCommunity({
      search: typeof communityName === "object" ? communityName?.label : communityName,
    });
  }, [communityName]);

  const debouncedSearchBuilder = useCallback(
    debounce((query) => dispatch(searchBuilder(query)), 1000),
    [dispatch]
  );
  useEffect(() => {
    debouncedSearchBuilder({
      search: typeof developer === "object" ? developer?.label : developer,
    });
  }, [developer]);

  const debouncedSearchMarketing = useCallback(
    debounce((query) => dispatch(searchMarketing(query)), 1000),
    [dispatch]
  );
  useEffect(() => {
    debouncedSearchMarketing({
      search: typeof marketing === "object" ? marketing?.label : marketing,
    });
  }, [marketing]);

  useEffect(() => {
    if (developmentName) setMandDevelopmentName(false);
    if (homeTypeArray?.length) setMandHomeTypes(false);
    if (address) setMandAddress(false);
    if (country) setMandCountry(false);
    if (province) setMandProvince(false);
    if (city) setMandCity(false);
  }, [address, city, country, developmentName, homeTypeArray, province]);

  useEffect(() => {
    if (homeTypeArray?.includes("63c683e55178561202fd7ee7") && !homeTypeArray?.includes("63c66c47cec8665e878ce4df"))
      setBuildingType("63eb65d7155f0437cef7dba5");
  }, [homeTypeArray]);

  if (loadError) return "Error...";
  if (!isLoaded)
    return (
      <div className="loadingDiv" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Spinner type="grow" color="dark" style={{ width: "50px", height: "50px" }} />
      </div>
    );

  return (
    <>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                {devData?.name ? devData?.name : type === "add" ? "Add Development" : "Edit Development"}
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="light"
                outline
                className="bg-white d-inline-flex"
                onClick={(ev) => {
                  ev.preventDefault();
                  addToDraft();
                }}
              >
                <span>{type === "edit" ? "Update Development" : "Save as Draft"}</span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card className="card-bordered">
            <div className="card-aside-wrap" id="user-detail-block">
              <div className="card-content">
                <DevNavTab toggleIconTab={toggleIconTab} activeIconTab={activeIconTab} update={handleAddUpdate} />
                <div className="card-inner">
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Summary</BlockTitle>
                    </BlockHead>
                    <BlockContent>
                      <Row className="gy-4">
                        {/* Community */}
                        <Col sm={"12"} md={"12"} lg={"12"}>
                          <Row>
                            <Col sm={"12"} md={"6"} lg={"6"}>
                              <div className="preview-block mb-1">
                                <div className="custom-control custom-control-sm custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input form-control error"
                                    id="communityCheck"
                                    value={communityCheck}
                                    onChange={(e) => {
                                      if (e.target.checked) setCommunityCheck(true);
                                      else setCommunityCheck(false);
                                    }}
                                    checked={communityCheck}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="communityCheck"
                                    style={{ fontSize: "14px" }}
                                  >
                                    This development is a part of a community
                                  </label>
                                </div>
                              </div>
                              {communityCheck ? (
                                <FormGroup>
                                  <Label htmlFor="communityName" className="form-label">
                                    Community Name
                                  </Label>
                                  <div className="form-control-wrap">
                                    <CustomCreatable
                                      creatable={true}
                                      data={community?.communitySearch?.map((li) => ({
                                        label: li?.name,
                                        value: li?._id,
                                        subLabel: `${
                                          li?.address?.neighbourhood ? `${li?.address?.neighbourhood?.name},` : ""
                                        } ${li?.address?.city?.name} ${li?.address?.province?.shortSymbol}`,
                                        data: li,
                                      }))}
                                      placeholder="Enter community name"
                                      value={communityName}
                                      handleChange={(value) => setCommunityName(value)}
                                      onCreateNew={(value) => {
                                        setNewCommunityName(value);
                                        handleOpen("community");
                                        setCity("");
                                        setProvince("");
                                        setCity("");
                                        setNeighbourhood("");
                                      }}
                                      loading={community?.loading}
                                    />
                                  </div>
                                </FormGroup>
                              ) : null}
                            </Col>
                          </Row>
                        </Col>

                        {/* Development */}
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <FormGroup>
                            <Label htmlFor="developmentName" className="form-label">
                              Development Name*
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                className={`form-control ${MandDevelopmentName ? "error" : ""}`}
                                type="text"
                                ref={nameRef}
                                id="developmentName"
                                placeholder="Input placeholder"
                                value={developmentName}
                                autoComplete="off"
                                name="developmentName"
                                onChange={(e) => {
                                  setDevelopmentName(e.target.value);
                                  if (developmentName) setMandDevelopmentName(false);
                                }}
                              />
                            </div>
                          </FormGroup>
                        </Col>

                        {/* Slug */}
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <FormGroup>
                            <Label htmlFor="slug" className="form-label">
                              Slug{" "}
                              {!slugChange ? (
                                <span
                                  style={{ color: "#00aeff", cursor: "pointer" }}
                                  onClick={(e) => {
                                    setSlugChange(!slugChange);
                                  }}
                                >
                                  ({!slugChange ? "Add Manually" : "Auto"})
                                </span>
                              ) : (
                                ""
                              )}
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                className={`form-control`}
                                type="text"
                                // ref={nameRef}
                                id="slug"
                                placeholder="{name}-{city}-{hometype}"
                                value={slug}
                                autoComplete="off"
                                name="slug"
                                disabled={!slugChange}
                                onChange={(e) => {
                                  setSlug(e.target.value);
                                }}
                              />
                            </div>
                          </FormGroup>
                        </Col>

                        {/* Home Type(s)* */}
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <FormGroup>
                            <Label htmlFor="homeType" className="form-label">
                              Home Type(s)*
                            </Label>
                            <div className="form-control-wrap">
                              <div className="form-control-select">
                                <Input
                                  className={`form-control ${MandHomeTypes ? "error" : ""}`}
                                  type="select"
                                  id="homeType"
                                  ref={homeTypeRef}
                                  value={homeType}
                                  name="homeType"
                                  onChange={(e) => {
                                    setHomeTypeArray([...homeTypeArray, e.target.value]);
                                    setMandHomeTypes(false);
                                    setBuildingType("");
                                  }}
                                >
                                  <option value={""}>Select</option>
                                  {homeTypeData?.map((item) => {
                                    return !homeTypeArray?.includes(item?._id) ? (
                                      <option key={`${item?._id}`} value={item?._id}>
                                        {item?.name}
                                      </option>
                                    ) : null;
                                  })}
                                </Input>
                              </div>
                            </div>
                            <div className={styles.arrayItemDiv}>
                              {homeTypeArray?.length
                                ? homeTypeArray?.map((item, index) => {
                                    return (
                                      <p
                                        key={`${Math.random()}-${index}`}
                                        className={styles.para2}
                                        onClick={() => {
                                          let a = homeTypeArray?.indexOf(item);
                                          if (a > -1) {
                                            homeTypeArray?.splice(a, 1);
                                            setHomeTypeArray([...homeTypeArray]);
                                          }
                                        }}
                                      >
                                        <em className="icon ni ni-cross-circle"></em>
                                        <span>{homeTypeData?.filter((li) => li?._id === item)[0]?.name}</span>
                                      </p>
                                    );
                                  })
                                : null}
                            </div>
                          </FormGroup>
                        </Col>

                        {/* Building Name(s) */}
                        {homeTypeArray?.includes("63c66c47cec8665e878ce4df") ? (
                          <Col sm={"12"} md={"6"} lg={"6"}>
                            <FormGroup>
                              <Label htmlFor="buildingName" className="form-label">
                                Building Name(s)
                              </Label>
                              <Row>
                                <Col sm={"9"} md={"9"} lg={"9"}>
                                  <div className="form-control-wrap">
                                    <input
                                      className="form-control"
                                      type="text"
                                      id="buildingName"
                                      placeholder="Input placeholder"
                                      value={buildingName}
                                      name="buildingName"
                                      onChange={(e) => {
                                        setBuildingName(e.target.value);
                                      }}
                                      autoComplete="off"
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          if (buildingNameArray?.includes(buildingName)) {
                                            toast.error("Building name already exists!");
                                          } else {
                                            if (buildingName) {
                                              setBuildingNameArray([...buildingNameArray, buildingName]);
                                              setBuildingName("");
                                            }
                                          }
                                        }
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col sm={"3"} md={"3"} lg={"3"}>
                                  <button
                                    className={styles.addBtn}
                                    onClick={() => {
                                      if (buildingNameArray?.includes(buildingName)) {
                                        toast.error("Building name already exists!");
                                      } else {
                                        if (buildingName) {
                                          setBuildingNameArray([...buildingNameArray, buildingName]);
                                          setBuildingName("");
                                        }
                                      }
                                    }}
                                  >
                                    <span>Add</span>
                                  </button>
                                </Col>
                              </Row>
                              <div className={styles.arrayItemDiv}>
                                {buildingNameArray?.length
                                  ? buildingNameArray?.map((item, index) => {
                                      return (
                                        <p
                                          key={`${Math.random()}-${index}`}
                                          className={styles.para2}
                                          onClick={() => {
                                            let a = buildingNameArray?.indexOf(item);
                                            if (a > -1) {
                                              buildingNameArray?.splice(a, 1);
                                              setBuildingNameArray([...buildingNameArray]);
                                            }
                                          }}
                                        >
                                          <em className="icon ni ni-cross-circle"></em>
                                          <span>{item}</span>
                                        </p>
                                      );
                                    })
                                  : null}
                              </div>
                            </FormGroup>
                          </Col>
                        ) : null}

                        {/* Title to Land */}
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <FormGroup>
                            <Label htmlFor="TitleToLand" className="form-label">
                              Title to Land
                            </Label>
                            <div className="form-control-wrap">
                              <div className="form-control-select">
                                <Input
                                  type="select"
                                  id="TitleToLand"
                                  value={titleToLand}
                                  name="titleToLand"
                                  onChange={(e) => setTitleToLand(e.target.value)}
                                >
                                  <option value={""}>Select</option>
                                  {landTitleData?.map((item) => {
                                    return (
                                      <option key={`${item?._id}`} value={item?._id}>
                                        {item?.name}
                                      </option>
                                    );
                                  })}
                                </Input>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>

                        {/* Building Type* */}
                        {homeTypeArray?.includes("63c66c47cec8665e878ce4df") ||
                        homeTypeArray?.includes("63c683e55178561202fd7ee7") ? (
                          <Col sm={"12"} md={"6"} lg={"6"}>
                            <FormGroup>
                              <Label htmlFor="buildingType" className="form-label">
                                Building Type*
                              </Label>
                              <div className="form-control-wrap">
                                <div className="form-control-select">
                                  <Input
                                    className={`form-control ${mandBuildingType ? "error" : ""}`}
                                    type="select"
                                    id="buildingType"
                                    ref={buildingTypeRef}
                                    value={buildingType}
                                    name="buildingType"
                                    onChange={(e) => {
                                      setBuildingType(e.target.value);
                                      setMandBuildingType(false);
                                    }}
                                    disabled={
                                      homeTypeArray?.includes("63c683e55178561202fd7ee7") &&
                                      !homeTypeArray?.includes("63c66c47cec8665e878ce4df")
                                    }
                                  >
                                    {homeTypeArray?.includes("63c683e55178561202fd7ee7") &&
                                    !homeTypeArray?.includes("63c66c47cec8665e878ce4df") ? null : (
                                      <option value="">Select</option>
                                    )}
                                    {homeTypeArray?.includes("63c66c47cec8665e878ce4df") &&
                                    homeTypeArray?.includes("63c683e55178561202fd7ee7")
                                      ? developmentTypeData
                                          ?.filter((obj) => obj?._id !== "63eb65d7155f0437cef7dba5")
                                          ?.map((item) => {
                                            return (
                                              <option key={`${item?._id}`} value={item?._id}>
                                                {item?.name}
                                              </option>
                                            );
                                          })
                                      : homeTypeArray?.includes("63c683e55178561202fd7ee7")
                                      ? developmentTypeData
                                          ?.filter((obj) => obj?._id === "63eb65d7155f0437cef7dba5")
                                          ?.map((item) => {
                                            return (
                                              <option key={`${item?._id}`} value={item?._id} defaultValue={item?._id}>
                                                {item?.name}
                                              </option>
                                            );
                                          })
                                      : developmentTypeData
                                          ?.filter((obj) => obj?._id !== "63eb65d7155f0437cef7dba5")
                                          ?.map((item) => {
                                            return (
                                              <option key={`${item?._id}`} value={item?._id}>
                                                {item?.name}
                                              </option>
                                            );
                                          })}
                                  </Input>
                                </div>
                              </div>
                            </FormGroup>
                          </Col>
                        ) : null}

                        {/* Construction */}
                        <Col sm={"6"} md={"3"} lg={"3"} size={"6"}>
                          <FormGroup>
                            <Label htmlFor="construction" className="form-label">
                              Construction
                            </Label>
                            <div className="form-control-wrap">
                              <div className="form-control-select">
                                <Input
                                  type="select"
                                  id="construction"
                                  value={construction}
                                  name="construction"
                                  onChange={(e) => setConstruction(e.target.value)}
                                >
                                  <option value="">Select</option>
                                  {constructionMethodData?.map((li) => (
                                    <option key={li?._id} value={li?._id}>
                                      {li?.name}
                                    </option>
                                  ))}
                                </Input>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>

                        {/* Units */}
                        <Col sm={"6"} md={"3"} lg={"3"} size={"6"}>
                          <FormGroup>
                            <Label htmlFor="units" className="form-label">
                              Units
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                className="form-control"
                                type="text"
                                id="units"
                                placeholder="No. of units"
                                autoComplete="off"
                                value={units}
                                name="units"
                                onChange={(e) => setUnits(e.target.value)}
                                onWheel={(e) => e.target.blur()}
                              />
                            </div>
                          </FormGroup>
                        </Col>

                        {/* Floors */}
                        <Col sm={"6"} md={"3"} lg={"3"} size={"6"}>
                          <FormGroup>
                            <Label htmlFor="floors" className="form-label">
                              Floors
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                className="form-control"
                                type="text"
                                id="floors"
                                autoComplete="off"
                                placeholder="No. of stories"
                                value={floors}
                                name="floors"
                                onChange={(e) => setFloors(e.target.value)}
                                onWheel={(e) => e.target.blur()}
                              />
                            </div>
                          </FormGroup>
                        </Col>

                        {/* Project Summary */}
                        <Col sm={"12"} md={"12"} lg={"12"} size={"12"}>
                          <FormGroup>
                            <Label htmlFor="projectSummary" className="form-label">
                              Project Summary
                            </Label>
                            <div className="form-control-wrap">
                              <JoditPreview
                                initialValue={summary}
                                getValue={setSummary}
                                placeholder={"Concise project Summary with highlights..."}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>
                  <br />
                  <br />
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Team</BlockTitle>
                    </BlockHead>
                    <BlockContent>
                      <Row className="gy-4">
                        {/* Developer(s) */}
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <FormGroup>
                            <Label htmlFor="Developer" className="form-label">
                              Developer(s)
                            </Label>
                            <div className="form-control-wrap">
                              <CustomCreatable
                                creatable={true}
                                data={companyObj?.builderSearch
                                  ?.map((li) => ({ label: li?.name, value: li?._id, subLabel: li?.address?.firstLine }))
                                  ?.filter((li) => !developerArray?.includes(li?.value))}
                                value={developer}
                                placeholder="Enter developer name"
                                handleChange={(value) => {
                                  if (typeof value === "object") {
                                    setDeveloperArray([...developerArray, value?.value]);
                                    setDeveloper("");
                                  } else {
                                    setDeveloper(value);
                                  }
                                }}
                                onCreateNew={(value) => {
                                  setNewDeveloperName(value);
                                  handleOpen("builder");
                                }}
                                loading={companyObj?.loading}
                                disabled={type === "edit" && devData?.user !== profile?.profile?.company?._id}
                              />
                            </div>
                            <p style={{ fontSize: "12px", textAlign: "end" }}>
                              *Select from the list or add a new developer
                            </p>
                          </FormGroup>
                          <div className={clsx(styles.teamFlexDiv)}>
                            {developerArray?.length
                              ? developerArray?.map((li, i) => (
                                  <div key={i}>
                                    <em
                                      className="icon ni ni-cross-circle"
                                      onClick={() => {
                                        let a = developerArray?.indexOf(li);
                                        if (a > -1) {
                                          developerArray?.splice(a, 1);
                                          setDeveloperArray([...developerArray]);
                                        }
                                      }}
                                    ></em>
                                    <div>
                                      {parse(
                                        companyObj?.builderSelectOptions?.filter((list) => list?.value === li)[0]
                                          ?.label || ""
                                      )}
                                    </div>
                                  </div>
                                ))
                              : null}
                          </div>
                        </Col>

                        {/* Marketing By */}
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <FormGroup>
                            <Label htmlFor="marketing" className="form-label">
                              Marketing By
                            </Label>
                            <div className="form-control-wrap">
                              <CustomCreatable
                                creatable={true}
                                data={companyObj?.marketingSearch
                                  ?.map((li) => ({ label: li?.name, value: li?._id, subLabel: li?.address?.firstLine }))
                                  ?.filter((li) => !marketingArray?.includes(li?.value))}
                                value={marketing}
                                placeholder="Enter marketing company name"
                                handleChange={(value) => {
                                  if (typeof value === "object") {
                                    setMarketingArray([...marketingArray, value?.value]);
                                    setMarketing("");
                                  } else {
                                    setMarketing(value);
                                  }
                                }}
                                onCreateNew={(value) => {
                                  setNewMarketingName(value);
                                  handleOpen("marketing");
                                }}
                                loading={companyObj?.loading}
                                disabled={type === "edit" && devData?.user !== profile?.profile?.company?._id}
                              />
                            </div>
                            <p style={{ fontSize: "12px", textAlign: "end" }}>
                              *Select from the list or add a new developer
                            </p>

                            <div className={clsx(styles.teamFlexDiv)}>
                              {marketingArray?.length
                                ? marketingArray?.map((li, i) => (
                                    <div key={i}>
                                      <em
                                        className="icon ni ni-cross-circle"
                                        onClick={() => {
                                          let a = marketingArray?.indexOf(li);
                                          if (a > -1) {
                                            marketingArray?.splice(a, 1);
                                            setMarketingArray([...marketingArray]);
                                          }
                                        }}
                                      ></em>
                                      <div>
                                        {parse(
                                          companyObj?.marketingSelectOptions?.filter((list) => list?.value === li)[0]
                                            ?.label || ""
                                        )}
                                      </div>
                                    </div>
                                  ))
                                : null}
                            </div>
                          </FormGroup>
                        </Col>

                        {/* Developer and Marketing Checkbox */}
                        {developerArray?.length || marketingArray?.length ? (
                          <Col sm={"12"} md={"12"} lg={"12"}>
                            <div className="preview-block">
                              <div className="custom-control custom-control-sm custom-radio">
                                <input
                                  type="radio"
                                  className="custom-control-input form-control"
                                  name="dev"
                                  id="onlyDev"
                                  value={"developer"}
                                  checked={checkBox === "developer"}
                                  onChange={(e) => {
                                    setCheckBox(e.target.value);
                                  }}
                                />
                                <label className="custom-control-label" htmlFor="onlyDev">
                                  {`Only "${
                                    assignedUser && typeof assignedUser === "object"
                                      ? assignedUser?.label
                                      : developerArray?.length && companyObj?.builder?.length
                                      ? companyObj?.builder?.find((list) => list?._id === developerArray?.[0])?.name
                                      : marketingArray?.length && companyObj?.marketing?.length
                                      ? companyObj?.marketing?.find((list) => list?._id === marketingArray?.[0])?.name
                                      : "developer"
                                  }" can update this development details.`}
                                </label>
                              </div>
                            </div>
                            <div className="preview-block">
                              <div className="custom-control custom-control-sm custom-radio">
                                <input
                                  type="radio"
                                  className="custom-control-input form-control"
                                  name="dev"
                                  id="both"
                                  value={"both"}
                                  checked={checkBox === "both"}
                                  onChange={(e) => {
                                    setCheckBox(e.target.value);
                                  }}
                                />
                                <label className="custom-control-label" htmlFor="both">
                                  {`Both developer(s) and marketing company(ies) can
                            update this development details.`}
                                </label>
                              </div>
                            </div>
                          </Col>
                        ) : null}

                        {developerArray?.length || marketingArray?.length ? (
                          <Col sm={"12"} md={"6"} lg={"6"}>
                            <FormGroup>
                              <Label htmlFor="marketing" className="form-label">
                                Assign Primary User
                              </Label>
                              <div className="form-control-wrap">
                                <RSelect
                                  options={[
                                    {
                                      label: "Developer(s)",
                                      options:
                                        developerArray?.length && companyObj?.builder?.length
                                          ? companyObj?.builder
                                              ?.filter((list) => developerArray?.includes(list?._id))
                                              ?.map((li) => ({ label: li?.name, value: li?._id }))
                                          : [],
                                    },
                                    {
                                      label: "Marketing Company",
                                      options:
                                        marketingArray?.length && companyObj?.marketing?.length
                                          ? companyObj?.marketing
                                              ?.filter((list) => marketingArray?.includes(list?._id))
                                              ?.map((li) => ({ label: li?.name, value: li?._id }))
                                          : [],
                                    },
                                  ]}
                                  value={assignedUser}
                                  onChange={(e) => setAssignedUser(e)}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        ) : null}
                      </Row>
                    </BlockContent>
                  </Block>
                  <br />
                  <br />
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Development Site Location</BlockTitle>
                    </BlockHead>
                    <BlockContent>
                      <Row className="gy-4">
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <MapComponent zoom={zoom} marker={marker} setMarker={setMarker} />
                          <p>Drag and drop the pin on map to find exact location</p>
                        </Col>
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <Row className={"gy-4"}>
                            {/* Address* */}
                            <Col sm={"12"} md={"12"} lg={"12"}>
                              <FormGroup>
                                <Label htmlFor="address" className="form-label">
                                  Address*
                                </Label>
                                <div className="form-control-wrap">
                                  <StandaloneSearchBox
                                    onLoad={(searchBox) => {
                                      searchBox.addListener("places_changed", () =>
                                        handlePlaceChanged(searchBox.getPlaces(), searchBox)
                                      );
                                    }}
                                  >
                                    <input
                                      type="text"
                                      placeholder="Development address"
                                      className={`form-control ${MandAddress || addressErrorMessage ? "error" : ""}
                          `}
                                      ref={addressRef}
                                      value={address}
                                      onChange={(e) => {
                                        setAddress(e.target.value);
                                        setMandAddress(false);
                                      }}
                                      required="required"
                                      data-error="Text is required."
                                    />
                                  </StandaloneSearchBox>
                                </div>
                              </FormGroup>
                            </Col>

                            {/* Postal Code */}
                            <Col sm={"12"} md={"6"} lg={"6"}>
                              <FormGroup>
                                <Label htmlFor="postalCode" className="form-label">
                                  Postal Code
                                </Label>
                                <div className="form-control-wrap">
                                  <input
                                    className="form-control"
                                    autoComplete="off"
                                    type="text"
                                    id="postalCode"
                                    placeholder={"Postal code"}
                                    value={zipcode}
                                    name="zipcode"
                                    onChange={(e) => setZipcode(e.target.value)}
                                  />
                                </div>
                              </FormGroup>
                            </Col>

                            {/* Country */}
                            <Col sm={"12"} md={"6"} lg={"6"}>
                              <FormGroup>
                                <Label htmlFor="country" className="form-label">
                                  Country
                                </Label>
                                <div className="form-control-wrap">
                                  <div className="form-control-select">
                                    <Input
                                      type="select"
                                      className={`form-control ${MandCountry ? "error" : ""}`}
                                      id="country"
                                      style={{
                                        background: country === "63d76e99b005363c54606967" ? "#e0e0e0" : "",
                                      }}
                                      ref={countryRef}
                                      value={country}
                                      name="country"
                                      onChange={(e) => setCountry(e.target.value)}
                                      disabled={
                                        communityCheck && typeof communityName === "object" && communityName?.country
                                      }
                                    >
                                      <option value="">Select</option>
                                      {countryData?.length
                                        ? countryData?.map((item) => (
                                            <option key={`${Math.random()}-${item?._id}`} value={item?._id}>
                                              {item?.name}
                                            </option>
                                          ))
                                        : null}
                                    </Input>
                                  </div>
                                </div>
                              </FormGroup>
                            </Col>

                            {/* Province* */}
                            <Col sm={"12"} md={"6"} lg={"6"}>
                              <FormGroup>
                                <Label htmlFor="Province*" className="form-label">
                                  Province*
                                </Label>
                                <div className="form-control-wrap">
                                  <div className="form-control-select">
                                    <Input
                                      type="select"
                                      id="Province*"
                                      className={`form-control ${MandProvince ? "error" : ""}`}
                                      ref={provinceRef}
                                      value={province}
                                      name="province"
                                      onChange={(e) => {
                                        setProvince(e.target.value);
                                        setMandProvince(false);
                                      }}
                                      disabled={
                                        communityCheck && typeof communityName === "object" && communityName?.province
                                      }
                                    >
                                      <option value="">Select</option>
                                      {provinceData?.length ? (
                                        provinceData?.map((item) => (
                                          <option key={`${Math.random()}-${item?._id}`} value={item?._id}>
                                            {item?.name}
                                          </option>
                                        ))
                                      ) : (
                                        <option value="">Please select country first</option>
                                      )}
                                    </Input>
                                  </div>
                                </div>
                              </FormGroup>
                            </Col>

                            {/* City* */}
                            <Col sm={"12"} md={"6"} lg={"6"}>
                              <FormGroup>
                                <Label htmlFor="City*" className="form-label">
                                  City*
                                </Label>
                                <div className="form-control-wrap">
                                  <div className="form-control-select">
                                    <Input
                                      type="select"
                                      className={`form-control ${MandCity ? "error" : ""}`}
                                      id="City*"
                                      ref={cityRef}
                                      value={city}
                                      name="city"
                                      onChange={(e) => {
                                        setCity(e.target.value);
                                        setMandCity(false);
                                      }}
                                      disabled={
                                        communityCheck && typeof communityName === "object" && communityName?.city
                                      }
                                    >
                                      <option value="">Select</option>
                                      {cityData?.length ? (
                                        cityData?.map((item) => (
                                          <option key={`${Math.random()}-${item?._id}`} value={item?._id}>
                                            {item?.name}
                                          </option>
                                        ))
                                      ) : (
                                        <option value="">Please select province first</option>
                                      )}
                                    </Input>
                                  </div>
                                </div>
                              </FormGroup>
                            </Col>

                            {/* Neighbourhood */}
                            <Col sm={"12"} md={"12"} lg={"12"}>
                              <FormGroup>
                                <Label htmlFor="Neighbourhood*" className="form-label">
                                  Neighbourhood
                                </Label>
                                <div className="form-control-wrap">
                                  <div className="form-control-select">
                                    <Input
                                      type="select"
                                      id="Neighbourhood*"
                                      value={neighbourhood}
                                      name="neighbourhood"
                                      onChange={(e) => setNeighbourhood(e.target.value)}
                                      disabled={
                                        communityCheck &&
                                        typeof communityName === "object" &&
                                        communityName?.neighbourhood
                                      }
                                    >
                                      <option value="">Select</option>
                                      {neighbourhoodData?.length ? (
                                        neighbourhoodData?.map((item) => (
                                          <option key={`${Math.random()}-${item?._id}`} value={item?._id}>
                                            {item?.name}
                                          </option>
                                        ))
                                      ) : (
                                        <option value="">Please select city first</option>
                                      )}
                                    </Input>
                                  </div>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>

                  <Modal isOpen={open?.modalOpen} toggle={handleClose}>
                    <ModalHeader
                      toggle={handleClose}
                      close={
                        <button className="close" onClick={handleClose}>
                          <Icon name="cross" />
                        </button>
                      }
                    >
                      {open?.modalType === "community"
                        ? "Add Community"
                        : open?.modalType === "builder"
                        ? "Add Developer/Builder"
                        : open?.modalType === "marketing"
                        ? "Add Marketing Company"
                        : ""}
                    </ModalHeader>
                    <ModalBody>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          open?.modalType === "builder"
                            ? handleAddDeveloper(e)
                            : open?.modalType === "marketing"
                            ? handleAddMarketing(e)
                            : handleAddCommunity(e);
                        }}
                      >
                        {open?.modalType === "community" ? (
                          <Row className="gy-2">
                            {/* Community Name* */}
                            <Col sm="12">
                              <FormGroup>
                                <Label htmlFor="communityName" className="form-label">
                                  Community Name*
                                </Label>
                                <div className="form-control-wrap">
                                  <input
                                    className={`form-control ${comMandName ? "error" : ""}`}
                                    type="text"
                                    id="communityName"
                                    placeholder="Enter community name"
                                    ref={comNameRef}
                                    value={newCommunityName}
                                    name="newCommunityName"
                                    onChange={(e) => {
                                      setNewCommunityName(e.target.value);
                                      if (newCommunityName) setComMandName(false);
                                    }}
                                  />
                                </div>
                              </FormGroup>
                            </Col>

                            {/* Country* */}
                            <Col sm="6">
                              <FormGroup>
                                <label htmlFor="country" className="form-label">
                                  Country*
                                </label>
                                <div className="form-control-wrap">
                                  <div className="form-control-select">
                                    <Input
                                      className={`custom-input ${comMandCounrty ? "error" : ""}`}
                                      type="select"
                                      id="country"
                                      style={{ background: comCountry === "63d76e99b005363c54606967" ? "#e0e0e0" : "" }}
                                      ref={comCountryRef}
                                      value={comCountry}
                                      name="comCountry"
                                      onChange={(e) => {
                                        setComCountry(e.target.value);
                                        if (comCountry) setComMandCounrty(false);
                                      }}
                                    >
                                      <option value="">Select</option>
                                      {countryData?.length
                                        ? countryData?.map((item) => (
                                            <option key={`${Math.random()}-${item?._id}`} value={item?._id}>
                                              {item?.name}
                                            </option>
                                          ))
                                        : null}
                                    </Input>
                                  </div>
                                </div>
                              </FormGroup>
                            </Col>

                            {/* Province* */}
                            <Col sm="6">
                              <FormGroup>
                                <label htmlFor="province" className="form-label">
                                  Province*
                                </label>
                                <div className="form-control-wrap">
                                  <div className="form-control-select">
                                    <Input
                                      className={`custom-input ${comMandProvince ? "error" : ""}`}
                                      type="select"
                                      id="province"
                                      ref={comProvinceRef}
                                      value={comProvince}
                                      name="comProvince"
                                      onChange={(e) => {
                                        setComProvince(e.target.value);
                                        if (e.target.value) setComMandProvince(false);
                                      }}
                                    >
                                      <option value="">Select</option>
                                      {comCountry && provinceData?.length ? (
                                        provinceData?.map((item) => (
                                          <option key={`${Math.random()}-${item?._id}`} value={item?._id}>
                                            {item?.name}
                                          </option>
                                        ))
                                      ) : (
                                        <option value="">Please select country first</option>
                                      )}
                                    </Input>
                                  </div>
                                </div>
                              </FormGroup>
                            </Col>

                            {/* City* */}
                            <Col sm="6">
                              <FormGroup>
                                <label htmlFor="city" className="form-label">
                                  City*
                                </label>
                                <div className="form-control-wrap">
                                  <div className="form-control-select">
                                    <Input
                                      className={`custom-input ${comMandCity ? "error" : ""}`}
                                      type="select"
                                      id="city"
                                      ref={comCityRef}
                                      value={comCity}
                                      name="comCity"
                                      onChange={(e) => {
                                        setComCity(e.target.value);
                                        if (e.target.value) setComMandCity(false);
                                      }}
                                    >
                                      <option value="">Select</option>
                                      {comProvince && cityData?.length ? (
                                        cityData?.map((item) => (
                                          <option key={`${Math.random()}-${item?._id}`} value={item?._id}>
                                            {item?.name}
                                          </option>
                                        ))
                                      ) : (
                                        <option value="">Please select province first</option>
                                      )}
                                    </Input>
                                  </div>
                                </div>
                              </FormGroup>
                            </Col>

                            {/* Neighbourhood */}
                            <Col sm="6">
                              <FormGroup>
                                <label htmlFor="neighbourhood" className="form-label">
                                  Neighbourhood
                                </label>
                                <div className="form-control-wrap">
                                  <div className="form-control-select">
                                    <Input
                                      className="custom-input"
                                      type="select"
                                      id="neighbourhood"
                                      value={comNeighbourhood}
                                      name="comNeighbourhood"
                                      onChange={(e) => setComNeighbourhood(e.target.value)}
                                    >
                                      <option value="">Select</option>
                                      {comCity ? (
                                        neighbourhoodData?.length ? (
                                          neighbourhoodData?.map((item) => (
                                            <option key={`${Math.random()}-${item?._id}`} value={item?._id}>
                                              {item?.name}
                                            </option>
                                          ))
                                        ) : (
                                          <option value="">Not Found</option>
                                        )
                                      ) : (
                                        <option value="">Please select city first</option>
                                      )}
                                    </Input>
                                  </div>
                                </div>
                              </FormGroup>
                            </Col>

                            {/* Add Btn */}
                            <Col sm={"12"}>
                              <FormGroup>
                                <Button color="primary" type="submit" size="lg">
                                  Add Community
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        ) : open?.modalType === "builder" ? (
                          <Row className="gy-2">
                            {/* Name* */}
                            <Col sm={"12"}>
                              <FormGroup>
                                <Label htmlFor="name" className="form-label">
                                  Name*
                                </Label>
                                <div className="form-control-wrap">
                                  <input
                                    className={`form-control ${devMandName ? "error" : ""}`}
                                    type="text"
                                    id="name"
                                    placeholder={"Enter the development name"}
                                    ref={devNameRef}
                                    value={newDeveloperName}
                                    name="newDeveloperName"
                                    onChange={(e) => {
                                      setNewDeveloperName(e.target.value);
                                      if (e.target.value) setDevMandName(false);
                                    }}
                                  />
                                </div>
                              </FormGroup>
                            </Col>

                            {/* Address */}
                            <Col sm={"12"}>
                              <FormGroup>
                                <Label htmlFor="address" className="form-label">
                                  Address*
                                </Label>
                                <div className="form-control-wrap">
                                  <StandaloneSearchBox
                                    onLoad={(searchBox) => {
                                      searchBox.addListener("places_changed", () =>
                                        handlePlaceChanged2(searchBox.getPlaces(), searchBox)
                                      );
                                    }}
                                  >
                                    <input
                                      type="text"
                                      id="address"
                                      placeholder="Enter Address"
                                      className={clsx(
                                        "form-control",
                                        styles.textField,
                                        devMandAddress ? styles.inputError : ""
                                      )}
                                      ref={devAddressRef}
                                      value={newDeveloperAddress}
                                      onChange={(e) => {
                                        setNewDeveloperAddress(e.target.value);
                                        if (e.target.value) setDevMandAddress(false);
                                      }}
                                      required="required"
                                      data-error="Text is required."
                                    />
                                  </StandaloneSearchBox>
                                </div>
                              </FormGroup>
                            </Col>

                            {/* Phone* */}
                            <Col sm={"12"}>
                              <FormGroup>
                                <Label htmlFor="phone" className="form-label">
                                  Phone*
                                </Label>
                                <div className="form-control-wrap">
                                  <PhoneInput
                                    country={"ca"}
                                    value={newDeveloperMobile}
                                    onChange={(e) => {
                                      setNewDeveloperMobile(e);
                                      if (e) setDevMandMobile(false);
                                    }}
                                    inputStyle={{ width: "100%" }}
                                    containerClass={devMandMobile ? "error" : ""}
                                  />
                                </div>
                              </FormGroup>
                            </Col>

                            {/* Email */}
                            <Col sm={"12"}>
                              <FormGroup>
                                <Label htmlFor="email" className="form-label">
                                  Email*
                                </Label>
                                <div className="form-control-wrap">
                                  <input
                                    className="form-control"
                                    type="email"
                                    id="email"
                                    placeholder={`Business Email, E.g "john@abc.com"`}
                                    ref={devEmailRef}
                                    value={newDeveloperEmail}
                                    name="newDeveloperEmail"
                                    onChange={(e) => {
                                      setNewDeveloperEmail(e.target.value);
                                      if (e.target.value) setDevMandEmail(false);
                                    }}
                                    style={{
                                      border:
                                        newDeveloperEmail && companyObj?.email === "available"
                                          ? "2px solid green"
                                          : newDeveloperEmail && companyObj?.email === "taken"
                                          ? "2px solid red"
                                          : null,
                                    }}
                                  />
                                  {errorMessage ? (
                                    <label htmlFor="email" style={{ color: "red", fontSize: "12px" }}>
                                      {errorMessage}
                                    </label>
                                  ) : newDeveloperEmail && companyObj?.loadingEmail ? (
                                    <Spinner style={{ width: "16px", height: "16px" }} />
                                  ) : newDeveloperEmail && companyObj?.email === "taken" ? (
                                    <label htmlFor="email" style={{ color: "red", fontSize: "12px" }}>
                                      Email has already taken, try another!
                                    </label>
                                  ) : null}
                                </div>
                              </FormGroup>
                            </Col>

                            {/* Website */}
                            <Col sm={"12"}>
                              <FormGroup>
                                <Label htmlFor="website" className="form-label">
                                  Business Website
                                </Label>
                                <div className="form-control-wrap">
                                  <input
                                    className="form-control"
                                    type="text"
                                    id="website"
                                    placeholder={`Business Website, E.g "abc.com"`}
                                    value={newDeveloperWebsite}
                                    name="newDeveloperWebsite"
                                    onChange={(e) => setNewDeveloperWebsite(e.target.value)}
                                  />
                                </div>
                              </FormGroup>
                            </Col>

                            <Col sm={"12"}>
                              <FormGroup>
                                <Button color="primary" type="submit" size="lg">
                                  Add Developer/Builder
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        ) : open?.modalType === "marketing" ? (
                          <Row className="gy-2">
                            {/* Name* */}
                            <Col sm={"12"}>
                              <FormGroup>
                                <Label htmlFor="name" className="form-label">
                                  Name*
                                </Label>
                                <div className="form-control-wrap">
                                  <input
                                    className={`form-control ${marketMandName ? "error" : ""}`}
                                    type="text"
                                    id="name"
                                    placeholder={"Enter name"}
                                    ref={marketNameRef}
                                    value={newMarketingName}
                                    name="newMarketingName"
                                    onChange={(e) => {
                                      setNewMarketingName(e.target.value);
                                      if (e.target.value) setMarketMandName(false);
                                    }}
                                  />
                                </div>
                              </FormGroup>
                            </Col>

                            {/* Address */}
                            <Col sm={"12"}>
                              <FormGroup>
                                <Label htmlFor="address" className="form-label">
                                  Address*
                                </Label>
                                <div className="form-control-wrap">
                                  <StandaloneSearchBox
                                    onLoad={(searchBox) => {
                                      searchBox.addListener("places_changed", () =>
                                        handlePlaceChanged3(searchBox.getPlaces(), searchBox)
                                      );
                                    }}
                                  >
                                    <input
                                      type="text"
                                      placeholder="Enter Address"
                                      className={clsx(
                                        "form-control",
                                        styles.textField,
                                        marketMandAddress ? styles.inputError : ""
                                      )}
                                      ref={marketAddressRef}
                                      value={newMarketingAddress}
                                      onChange={(e) => {
                                        setNewMarketingAddress(e.target.value);
                                        if (e.target.value) setMarketMandAddress(false);
                                      }}
                                      required="required"
                                      data-error="Text is required."
                                    />
                                  </StandaloneSearchBox>
                                </div>
                              </FormGroup>
                            </Col>

                            {/* Phone* */}
                            <Col sm={"12"}>
                              <FormGroup>
                                <Label htmlFor="phone" className="form-label">
                                  Phone*
                                </Label>
                                <div className="form-control-wrap">
                                  <PhoneInput
                                    country={"ca"}
                                    value={newMarketingMobile}
                                    onChange={(e) => {
                                      setNewMarketingMobile(e);
                                      if (e) setMarketMandMobile(false);
                                    }}
                                    inputStyle={{ width: "100%" }}
                                    containerClass={marketMandMobile ? "error" : ""}
                                  />
                                </div>
                              </FormGroup>
                            </Col>

                            {/* Email */}
                            <Col sm={"12"}>
                              <FormGroup>
                                <Label htmlFor="email" className="form-label">
                                  Email*
                                </Label>
                                <div className="form-control-wrap">
                                  <input
                                    className={`form-control ${marketMandEmail ? "error" : ""}`}
                                    type="email"
                                    id="email"
                                    placeholder={`Business Email, E.g "john@abc.com"`}
                                    ref={marketEmailRef}
                                    value={newMarketingEmail}
                                    name="newMarketingEmail"
                                    onChange={(e) => {
                                      setNewMarketingEmail(e.target.value);
                                      if (e.target.value) setMarketMandEmail(false);
                                    }}
                                    style={{
                                      border:
                                        newMarketingEmail && companyObj?.email === "available"
                                          ? "2px solid green"
                                          : newMarketingEmail && companyObj?.email === "taken"
                                          ? "2px solid red"
                                          : null,
                                    }}
                                  />
                                  {errorMessage1 ? (
                                    <label htmlFor="email" style={{ color: "red", fontSize: "12px" }}>
                                      {errorMessage1}
                                    </label>
                                  ) : newMarketingEmail && companyObj?.loadingEmail ? (
                                    <Spinner />
                                  ) : newMarketingEmail && companyObj?.email === "taken" ? (
                                    <label htmlFor="email" style={{ color: "red", fontSize: "12px" }}>
                                      Email has already taken, try another!
                                    </label>
                                  ) : null}
                                </div>
                              </FormGroup>
                            </Col>

                            {/* Website */}
                            <Col sm={"12"}>
                              <FormGroup>
                                <Label htmlFor="website" className="form-label">
                                  Business Website
                                </Label>
                                <div className="form-control-wrap">
                                  <input
                                    className="form-control"
                                    type="text"
                                    id="website"
                                    placeholder={`Business Domain Name, E.g "abc.com"`}
                                    value={newMarketingWebsite}
                                    name="newMarketingWebsite"
                                    onChange={(e) => setNewMarketingWebsite(e.target.value)}
                                  />
                                </div>
                              </FormGroup>
                            </Col>

                            <Col sm={"12"}>
                              <FormGroup>
                                <Button color="primary" type="submit" size="lg">
                                  Add Marketing Company
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        ) : null}
                      </form>
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            </div>
          </Card>
          <div style={{ margin: "15px 0", display: "flex", gap: "1rem" }}>
            <FooterBtn text={"Cancel"} color={"secondary"} outline onClick={handleBack} />
            <FooterBtn text={"Next"} color={"info"} onClick={handleNext} />
          </div>
        </Block>
      </Content>
    </>
  );
};

export default Dev1;
