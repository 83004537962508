import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addListing, editListing } from "../../redux/Apislices/listingSlice";
import { toast } from "react-toastify";
import { getSession, removeSession, setSession } from "../../utils/Storage";
import FooterBtn from "../common/FooterBtn";
import Content from "../../layout/content/Content";
import {
  Block,
  BlockBetween,
  BlockContent,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  OutlinedInput,
  Row,
} from "../Component";
import { Card } from "reactstrap";
import AssignNavTab from "./AssignNavTab";
import clsx from "clsx";
import styles from "./assignment.module.scss";
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from "react-icons/fa";
import { USDollar } from "../../utils/Utils";
import HotSaleIcon from "../../assets/images/logo/cross.svg";
import moment from "moment";
import { yearsBefore } from "../../utils/moment";

const Assignment2 = ({ formStep, nextFormStep, prevFormStep, stepGoTo }) => {
  const navigate = useNavigate();
  const { type, id } = useParams();
  const pathArray = window.location.pathname.split("/");
  const mainPath = pathArray[1];
  const dispatch = useDispatch();
  const { listing } = useSelector((state) => ({
    listing: state?.listing?.listingById?.data,
  }));

  const [assignmentData, setAssignmentData] = useState(false);

  const [askingPrice, setAskingPrice] = useState(null);
  const [askingMand, setAskingMand] = useState(false);
  const askingRef = useRef(null);

  const [gstMand, setGstMand] = useState(false);
  const [percentMand, setPercentMand] = useState(false);
  const [strataMand, setStrataMand] = useState(false);

  const [hotsale, setHotSale] = useState(false);
  const [changePrice, setChangePrice] = useState(false);

  const [priceChange, setPriceChange] = useState("");
  const [newPrice, setNewPrice] = useState(null);
  const [changeHistory, setChangeHistory] = useState([]);
  const [changeError, setChangeError] = useState({ error: false, message: "" });

  const [gst, setGst] = useState("");
  const [percent, setPercent] = useState(null);

  const [maintenance, setMaintenance] = useState(null);
  const [allowance, setAllowance] = useState(null);

  const [upgradesIncluded, setUpgradesIncluded] = useState("");
  console.log('upgradesIncluded: ', upgradesIncluded);
  const [homeWarranty, setHomeWarranty] = useState("Includes 2-5-10 years of new home warranty");

  const [taxesYear, setTaxesYear] = useState("");
  const [taxAmount, setTaxAmount] = useState("");
  const [rentalIncome, setRentalIncome] = useState("");

  const handleBackFun = () => {
    prevFormStep();
  };

  const handleAddUpdate = (successCB) => {
    let data = {
      ...assignmentData,
    };

    data = {
      ...data,
      homeWarranty: homeWarranty ? homeWarranty : null,
      price: {
        ...data?.price,
        askingPrice: askingPrice ? +askingPrice : null,
        hotSale: hotsale,
        gst: {
          cost: percent ? +percent : null,
          unit: gst ? gst : null,
        },
        strata: {
          cost: maintenance ? +maintenance : null,
          unit: "Month",
        },
      },
    };

    data = { ...data, upgradesIncluded };

    if (mainPath === "assignment") {
      data = {
        ...data,
        price: {
          ...data?.price,
          decoratingAllowance: allowance ? allowance : null,
        },
      };
    } else if (mainPath === "new-homes") {
      data = {
        ...data,
        price: {
          ...data?.price,
          rentalIncome: rentalIncome ? +rentalIncome : null,
          taxes: {
            year: taxesYear ? +taxesYear : null,
            price: taxAmount ? +taxAmount : null,
          },
        },
      };
    }

    if (type === "edit") {
      if (newPrice) {
        data = {
          ...data,
          price: {
            ...data?.price,
            askingPrice: +newPrice,
            priceChange: [
              ...data?.price?.priceChange,
              {
                newPrice: +newPrice,
                date: new Date(Date.now()).toISOString(),
              },
            ],
          },
        };
      }
    } else {
      data = {
        ...data,
        price: {
          ...data?.price,
          priceChange: [
            {
              newPrice: +askingPrice,
              date: new Date(Date.now()).toISOString(),
            },
          ],
        },
      };
    }

    console.log("data: ", data);

    if (
      (askingPrice && gst && maintenance && mainPath === "assignment") ||
      (askingPrice && gst && mainPath === "new-homes")
    ) {
      if ((gst === "Additional" && percent) || gst === "Included") {
        if (type === "edit") {
          dispatch(
            editListing(
              id,
              data,
              (res) => {
                successCB({ success: true });
              },
              (res) => {
                toast?.error(res?.message);
              }
            )
          );
        } else {
          if (getSession("assignment")) {
            let priceInfo = getSession("assignment");
            let currData = { ...priceInfo, ...data };
            setSession("assignment", currData);
          } else {
            setSession("assignment", {
              ...data,
            });
          }
          successCB({ success: true });
        }
      } else {
        if (!percent) {
          setPercentMand(true);
          document.getElementById("percent").focus();
          toast.error("Please complete all the mandatory fields.");
        }
      }
    } else {
      if (!askingPrice) {
        setAskingMand(true);
      }
      if (!gst) {
        setGstMand(true);
      }
      if (!maintenance && mainPath === "assignment") {
        setStrataMand(true);
      }

      if (!askingPrice) {
        askingRef.current.focus();
      } else if (!gst) {
        document.getElementById("gst").focus();
      } else if (!maintenance && mainPath === "assignment") {
        document.getElementById("strata").focus();
      }
      toast.error("Please complete all the mandatory fields.");
    }
  };

  const handleNext = () => {
    handleAddUpdate((res) => {
      if (res?.success) {
        nextFormStep();
      }
    });
  };

  const handleDraft = () => {
    let data = {
      ...assignmentData,
    };

    data = {
      ...data,
      homeWarranty: homeWarranty ? homeWarranty : null,
      price: {
        ...data?.price,
        askingPrice: askingPrice ? +askingPrice : null,
        hotSale: hotsale,
        gst: {
          cost: percent ? +percent : null,
          unit: gst ? gst : null,
        },
        strata: {
          cost: maintenance ? +maintenance : null,
          unit: "Month",
        },
      },
    };

    data = { ...data, upgradesIncluded };

    if (mainPath === "assignment") {
      data = {
        ...data,
        price: {
          ...data?.price,
          decoratingAllowance: allowance ? +allowance : null,
        },
      };
    } else if (mainPath === "new-homes") {
      data = {
        ...data,
        price: {
          ...data?.price,
          rentalIncome: rentalIncome ? +rentalIncome : null,
          taxes: {
            year: taxesYear ? +taxesYear : null,
            price: taxAmount ? +taxAmount : null,
          },
        },
      };
    }

    if (type === "edit") {
      if (newPrice) {
        data = {
          ...data,
          price: {
            ...data?.price,
            askingPrice: +newPrice,
            priceChange: [
              ...data?.price?.priceChange,
              {
                newPrice: +newPrice,
                date: new Date(Date.now()).toISOString(),
              },
            ],
          },
        };
      }
    } else {
      data = {
        ...data,
        price: {
          ...data?.price,
          priceChange: [
            {
              newPrice: +askingPrice,
              date: new Date(Date.now()).toISOString(),
            },
          ],
        },
      };
    }

    if (type === "edit") {
      data = { ...data, status: data?.status };
    } else {
      data = { ...data, status: "draft" };
    }
    console.log("data: ", data);

    if (
      (askingPrice && gst && maintenance && mainPath === "assignment") ||
      (askingPrice && gst && mainPath === "new-homes")
    ) {
      if ((gst === "Additional" && percent) || gst === "Included") {
        if (type === "edit") {
          dispatch(
            editListing(
              id,
              data,
              (res) => {
                navigate(mainPath === "assignment" ? "/assignment" : "/new-homes");
                toast.success("Listing Updated");
              },
              (res) => {
                toast?.error(res?.message);
              }
            )
          );
        } else {
          dispatch(
            addListing(
              data,
              (res) => {
                removeSession("assignment");
                navigate(mainPath === "assignment" ? "/assignment" : "/new-homes");
                toast.success("Saved as draft");
              },
              (res) => {
                toast.error(res?.message);
              }
            )
          );
        }
      } else {
        if (!percent) {
          setPercentMand(true);
          document.getElementById("percent").focus();
          toast.error("Please complete all the mandatory fields.");
        }
      }
    } else {
      if (!askingPrice) setAskingMand(true);
      if (!gst) setGstMand(true);
      if (!maintenance && mainPath === "assignment") setStrataMand(true);
      if (!askingPrice) askingRef.current.focus();
      else if (!gst) document.getElementById("gst").focus();
      else if (!maintenance && mainPath === "assignment") document.getElementById("strata").focus();
      toast.error("Please complete all the mandatory fields.");
    }
  };

  useEffect(() => {
    if (assignmentData) {
      console.log("assignmentData 2: ", assignmentData);
      let data = assignmentData;
      setAskingPrice(data?.price?.askingPrice || askingPrice);
      setHotSale(data?.price?.hotSale);
      setGst(data?.price?.gst?.unit || gst);
      setPercent(data?.price?.gst?.cost || percent);
      setMaintenance(data?.price?.strata?.cost || maintenance);
      setAllowance(data?.price?.decoratingAllowance || allowance);
      setChangeHistory(data?.price?.priceChange);
      setTaxesYear(data?.price?.taxes?.year || taxesYear);
      setTaxAmount(data?.price?.taxes?.price || taxAmount);
      setRentalIncome(data?.price?.rentalIncome || rentalIncome);
      if (data?.homeWarranty) {
        setHomeWarranty(data?.homeWarranty);
      }
      setUpgradesIncluded(data?.upgradesIncluded);
    }
  }, [assignmentData]);

  useEffect(() => {
    if (listing) {
      setAssignmentData(listing);
    } else {
      if (getSession("assignment")) {
        setAssignmentData(getSession("assignment"));
      }
    }
  }, [listing]);

  return (
    <>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Add {mainPath === "assignment" ? "Assignment" : "New Home"}
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="light"
                outline
                className="bg-white d-inline-flex"
                onClick={(ev) => {
                  ev.preventDefault();
                  handleDraft();
                }}
              >
                <span>{type === "edit" ? "Update Development" : "Save as Draft"}</span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card className="card-bordered">
            <div className="card-aside-wrap" id="user-detail-block">
              <div className="card-content">
                <AssignNavTab stepGoTo={stepGoTo} update={handleAddUpdate} />
                <div className="card-inner">
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Development Project Information</BlockTitle>
                    </BlockHead>
                    <BlockContent>
                      <Row className="">
                        <Col sm={"12"} md={"6"}>
                          <div className={clsx(styles.inputDiv)}>
                            <p className={clsx(styles.para1)}>Asking Price* </p>
                            <div className={`form-control-wrap`}>
                              <div className="input-group input-group">
                                <div className="input-group-prepend">
                                  <span className={`input-group-text`} id="inputGroup-sizing-md">
                                    $
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  className={clsx("form-control")}
                                  style={{
                                    border: askingMand ? "2px solid #ce6048" : "",
                                  }}
                                  name="askingPrice"
                                  ref={askingRef}
                                  value={askingPrice}
                                  onChange={(e) => {
                                    setAskingPrice(e.target.value);
                                    if (e.target.value) {
                                      setAskingMand(false);
                                    }
                                  }}
                                  disabled={type === "edit" && assignmentData?.price?.askingPrice}
                                />
                              </div>
                            </div>
                          </div>
                          {type === "edit" ? (
                            <div className={styles.change}>
                              <button onClick={() => setChangePrice(!changePrice)}>
                                <span>Change</span>
                              </button>
                            </div>
                          ) : null}
                        </Col>
                      </Row>

                      {changePrice || changeHistory?.length > 1 ? (
                        <Row>
                          {changePrice ? (
                            <Col md={"12"} lg={"6"}>
                              <div className={clsx(styles.inputDiv)}>
                                <p className={clsx(styles.para1)}>New price*</p>
                                <div className={`form-control-wrap`}>
                                  <div className="input-group input-group">
                                    <div className="input-group-prepend">
                                      <span className={`input-group-text`} id="inputGroup-sizing-md">
                                        $
                                      </span>
                                    </div>
                                    <input
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      className={clsx("form-control")}
                                      value={newPrice}
                                      name="newPrice"
                                      onChange={(e) => {
                                        setNewPrice(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Col>
                          ) : null}

                          {changeHistory?.length > 1 ? (
                            <Col md={"12"} lg={"6"} className={styles.priceDiv}>
                              <div className={styles.priceHistory}>
                                <p>Price History</p>
                                <div>
                                  {changeHistory?.map((li, i, arr) => (
                                    <div key={i} className={styles.priceDetail}>
                                      <p className={styles.price}>
                                        {USDollar(li?.newPrice)}{" "}
                                        {li?.newPrice < arr?.[i - 1]?.newPrice ? (
                                          <FaArrowAltCircleDown style={{ color: "#fc5c5c" }} />
                                        ) : li?.newPrice > arr?.[i - 1]?.newPrice ? (
                                          <FaArrowAltCircleUp style={{ color: "#77c720" }} />
                                        ) : null}
                                      </p>
                                      <p className={styles.time}>{moment(li?.date).format("D MMM, YYYY")}</p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </Col>
                          ) : null}
                        </Row>
                      ) : null}

                      {mainPath === "assignment" ? (
                        <Row className={"ml-1 mt-2"}>
                          <Col md={"12"}>
                            <div className={("form-check", styles.checkDiv)}>
                              <label
                                htmlFor="checkBox1"
                                style={{
                                  color: "#3d3d3d",
                                  fontWeight: "600",
                                  fontSize: "16px",
                                }}
                              >
                                <img
                                  src={HotSaleIcon}
                                  className="img-fluid"
                                  alt="icon"
                                  style={{ width: "18px", marginRight: "3px" }}
                                />
                                Hot Deal:{" "}
                              </label>
                              <input
                                className={clsx("form-check-input")}
                                type="checkbox"
                                name="mlsCheck"
                                onChange={(e) => setHotSale(e.target.checked)}
                                checked={hotsale}
                                id="checkBox1"
                              />
                              <label className="form-check-label" htmlFor="checkBox1">
                                I am selling at par or lower than the original purchase price
                              </label>
                            </div>
                          </Col>
                        </Row>
                      ) : null}

                      {mainPath === "assignment" ? (
                        <Row>
                          <Col sm={"12"} lg={"6"}>
                            <div className={clsx(styles.inputDiv)}>
                              <p className={clsx(styles.para1)}>Incentives at Completion </p>
                              <input
                                className={clsx("form-control")}
                                type="text"
                                value={allowance}
                                name="allowance"
                                onChange={(e) => setAllowance(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : null}

                      <Row>
                        <Col md={gst === "Additional" ? "6" : "12"} lg={gst === "Additional" ? "3" : "6"}>
                          <div className={clsx(styles.inputDiv)}>
                            <p className={clsx(styles.para1)}>GST*</p>
                            <select
                              className={clsx("arrows", styles.selectTag)}
                              style={{
                                padding: "7px 15px",
                                border: gstMand ? "2px solid #ce6048" : "0.2px solid #dddddd",
                              }}
                              id="gst"
                              value={gst}
                              name="gst"
                              onChange={(e) => {
                                setGstMand(false);
                                setGst(e.target.value);
                                if (e.target.value === "Included") {
                                  setPercent("");
                                }
                              }}
                            >
                              <option value="">Select</option>
                              <option value="Additional">Additional</option>
                              <option value="Included">Included</option>
                            </select>
                          </div>
                        </Col>

                        {/* Percent (%) */}
                        {gst === "Additional" ? (
                          <Col md={gst === "Additional" ? "6" : "12"} lg={gst === "Additional" ? "3" : "6"}>
                            <div className={clsx(styles.inputDiv)}>
                              <p className={clsx(styles.para1)}>Percent (%)*</p>
                              <input
                                type="number"
                                className={clsx("form-control", styles.textField)}
                                style={{
                                  padding: "7px 15px",
                                  border: percentMand ? "2px solid #ce6048" : "0.2px solid #dddddd",
                                }}
                                value={percent}
                                id="percent"
                                name="percent"
                                onChange={(e) => {
                                  setPercentMand(false);
                                  setPercent(e.target.value);
                                }}
                              />
                            </div>
                          </Col>
                        ) : null}
                      </Row>

                      <Row>
                        <Col lg={"6"} md={"12"}>
                          <div className={clsx(styles.inputDiv)}>
                            <p className={clsx(styles.para1)}>
                              Strata /Maintenance (per month)
                              {mainPath === "assignment" ? "*" : ""}
                            </p>
                            <div className={`form-control-wrap`}>
                              <div className="input-group input-group">
                                <div className="input-group-prepend">
                                  <span className={`input-group-text`} id="inputGroup-sizing-md">
                                    $
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  className={clsx("form-control")}
                                  value={maintenance}
                                  style={{
                                    border: strataMand ? "2px solid #ce6048" : "0.2px solid #dddddd",
                                  }}
                                  id="strata"
                                  name="maintenance"
                                  onChange={(e) => {
                                    setStrataMand(false);
                                    setMaintenance(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      {mainPath === "assignment" ? null : ( // {/* Incentives at Completion */}
                        <>
                          {/* Taxes (Year) */}
                          <Row>
                            <Col lg={"3"} md={"6"}>
                              <div className={clsx(styles.inputDiv)}>
                                <p className={clsx(styles.para1)}>Taxes (Year)</p>
                                <select
                                  className={clsx("arrows", styles.selectTag)}
                                  style={{
                                    padding: "7px 15px",
                                    border: "0.2px solid #dddddd",
                                  }}
                                  value={taxesYear}
                                  name="taxesYear"
                                  onChange={(e) => setTaxesYear(e.target.value)}
                                >
                                  <option value="">Select</option>
                                  {yearsBefore(1)?.map((li, i) => (
                                    <option key={i} value={li}>
                                      {li}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>

                            {/* Taxes (Amount) */}
                            <Col lg={"3"} md={"6"}>
                              <div className={clsx(styles.inputDiv)}>
                                <p className={clsx(styles.para1)}>Taxes (Amount)</p>
                                <input
                                  type="number"
                                  className={clsx("form-control", styles.textField)}
                                  value={taxAmount}
                                  name="taxAmount"
                                  onChange={(e) => setTaxAmount(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>

                          {/* Rental Income (per month) */}

                          <Row>
                            <Col lg={"6"} md={"12"}>
                              <div className={clsx(styles.inputDiv)}>
                                <p className={clsx(styles.para1)}>Rental Income (per month)</p>
                                <div className={`form-control-wrap`}>
                                  <div className="input-group input-group">
                                    <div className="input-group-prepend">
                                      <span className={`input-group-text`} id="inputGroup-sizing-md">
                                        $
                                      </span>
                                    </div>
                                    <input
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      className={clsx("form-control")}
                                      style={{
                                        border: "0.2px solid #dddddd",
                                      }}
                                      value={rentalIncome}
                                      name="rentalIncome"
                                      onChange={(e) => setRentalIncome(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}

                      {mainPath === "assignment" ? (
                        <Row>
                          <Col lg={"6"} md={"12"}>
                            <div className={clsx(styles.inputDiv)}>
                              <p className={clsx(styles.para1)}>Upgrades Included </p>
                              <input
                                className={clsx("form-control", styles.dollarInput)}
                                id="outlined-adornment-amount"
                                type="text"
                                value={upgradesIncluded}
                                name="upgradeIncluded"
                                onChange={(e) => setUpgradesIncluded(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                    </BlockContent>
                  </Block>
                </div>
              </div>
            </div>
          </Card>
          <div style={{ margin: "15px 0", display: "flex", gap: "1rem" }}>
            <FooterBtn text={"Cancel"} color={"secondary"} outline onClick={handleBackFun} />
            <FooterBtn text={"Next"} color={"info"} onClick={handleNext} />
          </div>
        </Block>
      </Content>
    </>
  );
};

export default Assignment2;
