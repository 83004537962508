import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { LuArchiveRestore } from "react-icons/lu";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import Swal from "sweetalert2";
import PlaceholderImg from "../../assets/profile_avatar.png";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  RSelect,
  Row,
} from "../../components/Component";
import ErrorComponent from "../../components/error/ErrorComponent";
import DotPagination from "../../components/pagination/DotPaginate";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  createUser,
  deleteMultipleUser,
  deleteMultipleUserPermanently,
  deleteUser,
  deleteUserPermanently,
  editUser,
  getUsers,
  restoreUser,
  restoremultipleUser,
} from "../../redux/Apislices/userSlice";
import { debounce, getScreenWidth } from "../../utils/Utils";
import { useForm } from "react-hook-form";
import { getcity } from "../../redux/Apislices/citySlice";
import { generateInvoice } from "../../redux/Apislices/paymentSlice";
import PhoneInput from "react-phone-input-2";
import CustomCreatable from "../../components/input/custom/CustomCreatable";
import { searchBrokerage } from "../../redux/Apislices/companySlice";

const statusOptions = [
  { value: "", label: "All" },
  { value: "active", label: "Active" },
  { value: "inative", label: "Inactive" },
  { value: "verified", label: "Verified" },
  { value: "unverified", label: "Unverified" },
];

const itemsPerPageOptions = [
  { value: 25, label: "25" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];

const bulkActionOptions = [
  { value: "", label: "Bulk Action" },
  { value: "archive", label: "Archive" },
  { value: "delete", label: "Delete" },
];

const bulkRestoreOptions = [
  { value: "", label: "Bulk Action" },
  { value: "restore", label: "Restore" },
  { value: "delete", label: "Delete" },
];

const Agents = () => {
  const dispatch = useDispatch();
  const {
    user,
    city: { cities },
    permission,
    profile,
    company,
  } = useSelector((state) => ({
    user: state.user,
    city: state.city,
    permission: state?.auth?.profile?.permissions?.agent,
    profile: state?.auth?.profile,
    company: state.company,
  }));
  console.log("company: ", company);

  const { users: userData } = user;

  const [loading, setLoading] = useState(false);

  const [selectedData, setSelectedData] = useState([]);
  const [screenWidth, setScreenWidth] = useState(getScreenWidth());
  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);

  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");

  const [limit, setLimit] = useState({ value: 25, label: "25" });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(25);

  const [sort, setSort] = useState("");
  const [status, setStatus] = useState("");

  const [bulkAction, setBulkAction] = useState("");

  const [view, setView] = useState({ vip: false, add: false, edit: false });
  const [editId, setEditId] = useState("");
  const [city, setCity] = useState(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [mobile, setMobile] = useState("");
  const [brokerage, setBrokerage] = useState(null);
  const [brokerageName, setBrokerageName] = useState("");

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setUsername("");
    setMobile("");
    setBrokerage(null);
    setBrokerageName("");
  };

  const toggleView = (type) => {
    if (type) setView({ ...view, [type]: !view[type] });
    else {
      setView({ vip: false, add: false, edit: false });
      resetForm();
    }
  };

  const onEditClick = (id) => {
    let data = userData?.data?.find((item) => item._id === id);
    setEditId(id);
    setFirstName(data?.firstName);
    setLastName(data?.lastName);
    setEmail(data?.email);
    setUsername(data?.username);
    setMobile(data?.mobile);
    setBrokerage({ value: data?.brokerage?._id, label: data?.brokerage?.name });
    setBrokerageName(data?.brokerage?.name);
    toggleView("edit");
  };

  const { errors, register, handleSubmit } = useForm();

  useEffect(() => {
    if (typeof limit === "object" && limit?.value) setItemPerPage(limit?.value);
    else setItemPerPage(25);
  }, [limit]);

  useEffect(() => {
    dispatch(getcity());
  }, [dispatch]);

  const handleGetData = async () => {
    dispatch(
      getUsers({
        status: typeof status === "object" ? status?.value : status,
        page: currentPage,
        limit: itemPerPage,
        search: onSearchText,
        sort,
        role: "agent",
      })
    );
  };

  //debounce getDevelopmentList for search
  useEffect(() => {
    const timer = setTimeout(
      () => {
        dispatch(
          getUsers({
            status: typeof status === "object" ? status?.value : status,
            page: currentPage,
            limit: itemPerPage,
            search: onSearchText,
            sort,
            role: "agent",
          })
        );
      },
      onSearchText ? 800 : 200
    );
    return () => clearTimeout(timer);
  }, [dispatch, currentPage, itemPerPage, onSearchText, sort, status]);

  // selects all the products
  const selectorCheck = (e) => {
    // add or remove all the items from development?.development?.data to selectedData
    if (e.currentTarget.checked) {
      setSelectedData(userData?.data?.map((item) => item._id));
    } else {
      setSelectedData([]);
    }
  };

  // selects one product
  const onSelectChange = (e, id) => {
    // add or remove the selected item from selectedData
    if (e.currentTarget.checked) {
      setSelectedData([...selectedData, id]);
    } else {
      setSelectedData(selectedData.filter((item) => item !== id));
    }
  };

  // toggle search input
  const toggle = () => setonSearch(!onSearch);

  // bulk action change
  const onActionText = (e) => {
    setBulkAction(e);
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  const debouncedSearchCommunity = useCallback(
    debounce((query) => dispatch(searchBrokerage(query)), 800),
    [dispatch]
  );
  useEffect(() => {
    let name = typeof brokerage === "object" ? brokerage?.label : brokerage;
    debouncedSearchCommunity({
      search: name,
    });
  }, [brokerage]);

  const handleActiveDevelopment = (data) => {
    let payload = {
      ...data,
      type: data?.type,
    };

    if (data?.status === "active") {
      payload.status = "on hold";
    } else if (data?.status === "on hold" || data?.status === "pending") {
      payload.status = "active";
    }

    // dispatch(
    //   editDevelopment(
    //     data?.slug,
    //     payload,
    //     (res) => {
    //       handleGetData();
    //     },
    //     (res) => {
    //       toast.error(res?.message);
    //     }
    //   )
    // );
  };

  const handleBulkAction = (e) => {
    if (bulkAction?.value === "archive") {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Archive",
      }).then((result) => {
        if (result.isConfirmed) {
          handleArchiveMultiple(
            () => {
              Swal.fire("Archived!", "Selected Agents has been archived.", "success");
            },
            () => {
              Swal.fire("Error!", "Something went wrong!", "error");
            }
          );
        }
      });
    } else if (bulkAction?.value === "delete") {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.isConfirmed) {
          handleDeleteMultiple(
            () => {
              Swal.fire("Deleted!", "Selected Agents has been deleted.", "success");
            },
            () => {
              Swal.fire("Error!", "Something went wrong!", "error");
            }
          );
        }
      });
    } else if (bulkAction?.value === "restore") {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Restore",
      }).then((result) => {
        if (result.isConfirmed) {
          handleRestoreMultiple(
            () => {
              Swal.fire("Restored!", "Selected Agents has been restored.", "success");
            },
            () => {
              Swal.fire("Error!", "Something went wrong!", "error");
            }
          );
        }
      });
    }
  };

  const handleArchiveMultiple = async (successCB, errorCB) => {
    dispatch(
      deleteMultipleUser(
        { ids: selectedData },
        (res) => {
          if (res?.success) {
            handleGetData();
            setBulkAction("");
            setSelectedData([]);
            successCB();
          }
        },
        (err) => {
          errorCB();
          toast.error(err?.message);
        }
      )
    );
  };

  const handleRestoreMultiple = async (successCB, errorCB) => {
    dispatch(
      restoremultipleUser(
        { ids: selectedData },
        (res) => {
          if (res?.success) {
            handleGetData();
            setBulkAction("");
            setSelectedData([]);
            successCB();
          }
        },
        (err) => {
          errorCB();
          toast.error(err?.message);
        }
      )
    );
  };

  const handleDeleteMultiple = async (successCB, errorCB) => {
    dispatch(
      deleteMultipleUserPermanently(
        { ids: selectedData },
        (res) => {
          if (res?.success) {
            handleGetData();
            setBulkAction("");
            setSelectedData([]);
            successCB();
          }
        },
        (err) => {
          errorCB();
          toast.error(err?.message);
        }
      )
    );
  };

  const handleArchive = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Archive",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deleteUser(
            item?._id,
            (res) => {
              console.log("res: ", res);
              if (res?.success) {
                handleGetData();
              }
              Swal.fire("Archived!", "Agent has been archived.", "success");
            },
            (err) => {
              Swal.fire("Error!", "Something went wrong!", "error");
              toast.error(err?.message);
            }
          )
        );
      }
    });
  };

  const handleRestore = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Restore",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          restoreUser(
            item?._id,
            (res) => {
              if (res?.success) {
                handleGetData();
              }
              Swal.fire("Archived!", "Agent has been restored.", "success");
            },
            (err) => {
              Swal.fire("Error!", "Something went wrong!", "error");
              toast.error(err?.message);
            }
          )
        );
      }
    });
  };

  const handleDelete = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deleteUserPermanently(
            item?._id,
            (res) => {
              if (res?.success) {
                handleGetData();
              }
              Swal.fire("Deleted!", "Agent has been deleted.", "success");
            },
            (err) => {
              Swal.fire("Error!", "Something went wrong!", "error");
              toast.error(err?.message);
            }
          )
        );
      }
    });
  };

  const handleVipAgent = async () => {
    let data = { agent: view, amount: city?.data?.vipCost, city, email: view?.email };
    console.log("data: ", data);
    if (!city?.data?.vipCost) {
      return toast.error(`${city?.data?.name} has no VIP cost added`);
    }
    setLoading(true);
    dispatch(
      generateInvoice(
        data,
        (res) => {
          console.log("res: ", res);
          setLoading(false);
          setView(false);
          setCity(null);
        },
        (err) => {
          console.log("err: ", err);
          setLoading(false);
        }
      )
    );
  };

  const handleAddAgent = (data) => {
    let payload = {
      firstName,
      lastName,
      email,
      mobile,
      brokerage: typeof brokerage === "object" ? brokerage?.value : brokerage,
      role: "agent",
    };
    if (username) payload.username = username;

    dispatch(
      createUser(
        "agent",
        payload,
        (res) => {
          handleGetData();
          toggleView();
          toast.success("Agent added successfully");
        },
        (err) => toast.error(err?.message)
      )
    );
  };

  const handleEditAgent = () => {
    let payload = {
      firstName,
      lastName,
      email,
      mobile,
      brokerage: typeof brokerage === "object" ? brokerage?.value : brokerage,
      role: "agent",
    };

    dispatch(
      editUser(
        editId,
        payload,
        (res) => {
          toast.success("Agent updated successfully");
          handleGetData();
          toggleView();
        },
        (res) => {
          toast.error(res?.message);
        }
      )
    );
  };

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <Head title="Agents"></Head>

      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>Agent</BlockTitle>
              <BlockDes className="text-soft">
                {userData?.data?.length && userData?.success ? (
                  <p>You have total {userData?.total?.total} agents.</p>
                ) : null}
              </BlockDes>
            </BlockHeadContent>

            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand mr-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    updateSm(!sm);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>

                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button
                        className="toggle btn-icon d-md-none"
                        onClick={() => {
                          toggleView("add");
                        }}
                      >
                        <Icon name="plus"></Icon>
                      </Button>

                      <Button
                        className="toggle d-none btn-outline-primary btn-dim d-md-inline-flex"
                        onClick={() => {
                          toggleView("add");
                        }}
                      >
                        <Icon name="plus"></Icon>
                        <span>Add Agent</span>
                      </Button>
                    </li>

                    {(typeof status === "object" && status?.value === "archive") || status === "archive" ? (
                      <li className="nk-block-tools-opt">
                        <Button
                          className="toggle btn-icon btn-outline-info d-md-none"
                          color="primary"
                          onClick={() => {
                            setStatus("");
                          }}
                        >
                          <Icon name="arrow-long-left"></Icon>
                        </Button>

                        <Button
                          className="toggle btn-outline-info btn-dim d-none d-md-inline-flex"
                          onClick={() => {
                            setStatus("");
                          }}
                        >
                          <Icon name="arrow-long-left"></Icon>
                          <span>All Agents</span>
                        </Button>
                      </li>
                    ) : (
                      <li className="nk-block-tools-opt">
                        <Button
                          className="toggle btn-icon d-md-none"
                          color="primary"
                          onClick={() => {
                            setStatus("archive");
                          }}
                        >
                          <Icon name="archive"></Icon>
                        </Button>

                        <Button
                          className="toggle btn-outline-danger btn-dim d-none d-md-inline-flex"
                          onClick={() => {
                            setStatus("archive");
                          }}
                        >
                          <Icon name="archive"></Icon>
                          <span>Archived</span>
                        </Button>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                {/* Bulk Actions */}
                {selectedData.length ? (
                  <div className="card-tools">
                    <div className="form-inline flex-nowrap gx-3">
                      <div className="form-wrap">
                        <RSelect
                          options={
                            (typeof status === "object" && status?.value === "archive") || status === "archive"
                              ? bulkRestoreOptions
                              : bulkActionOptions
                          }
                          className="w-130px"
                          placeholder="Bulk Action"
                          onChange={(e) => onActionText(e)}
                          value={bulkAction}
                        />
                      </div>
                      <div className="btn-wrap">
                        <span className="d-none d-md-block">
                          <Button
                            disabled={selectedData.length && bulkAction?.value ? false : true}
                            color="info"
                            outline
                            className="btn-dim"
                            onClick={(e) => {
                              if (selectedData.length && bulkAction?.value) {
                                handleBulkAction(e);
                              }
                            }}
                          >
                            Apply
                          </Button>
                        </span>
                        <span className="d-md-none">
                          <Button
                            color="info"
                            outline
                            disabled={selectedData.length && bulkAction?.value ? false : true}
                            className="btn-dim btn-icon"
                            onClick={(e) => {
                              if (selectedData.length && bulkAction?.value) {
                                handleBulkAction(e);
                              }
                            }}
                          >
                            <Icon name="arrow-right"></Icon>
                          </Button>
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="card-tools mr-n1">
                  <ul className="btn-toolbar gx-1">
                    {/* Search */}
                    <li>
                      <div
                        className="search-content"
                        style={{
                          border: "1px solid #dbdfea",
                          borderRadius: "4px",
                        }}
                      >
                        <Button
                          className="search-back btn-icon toggle-search active"
                          onClick={() => {
                            setSearchText("");
                            toggle();
                          }}
                        >
                          <Icon name="search"></Icon>
                        </Button>
                        <input
                          type="text"
                          className="border-transparent form-focus-none form-control"
                          placeholder="Search by name or address"
                          value={onSearchText}
                          onChange={(e) => onFilterChange(e)}
                        />
                        {onSearchText ? (
                          <Button
                            className="search-submit btn-icon"
                            onClick={() => {
                              setSearchText("");
                            }}
                          >
                            <Icon name="cross"></Icon>
                          </Button>
                        ) : null}
                      </div>
                    </li>

                    <li className="btn-toolbar-sep"></li>

                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>

                            {/* Filter */}
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  {/* <div className="dot dot-primary"></div> */}
                                  <Icon name="filter-fill"></Icon>
                                </DropdownToggle>
                                <DropdownMenu
                                  right
                                  className="filter-wg dropdown-menu-xl"
                                  style={{ overflow: "visible" }}
                                >
                                  <div className="dropdown-head">
                                    <span className="sub-title dropdown-title">Filter Agents</span>
                                  </div>
                                  <div className="dropdown-body dropdown-body-rg">
                                    <Row className="gx-6 gy-3">
                                      <Col size="6">
                                        <FormGroup>
                                          <label className="overline-title overline-title-alt">Status</label>
                                          <RSelect
                                            options={statusOptions}
                                            onChange={(value) => {
                                              setStatus(value);
                                            }}
                                            value={status}
                                            placeholder="Select"
                                          />
                                        </FormGroup>
                                      </Col>

                                      <Col size="6">
                                        <FormGroup>
                                          <label className="overline-title overline-title-alt">Items Per Page</label>
                                          <RSelect
                                            options={itemsPerPageOptions}
                                            onChange={(value) => {
                                              setLimit(value);
                                            }}
                                            value={limit}
                                            placeholder="Select"
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </div>

                                  <div className="dropdown-foot between">
                                    {/* <button type="button" className="btn btn-secondary">
                                      Filter
                                    </button> */}
                                    <span></span>

                                    <a
                                      href="#reset"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        setStatus("");
                                      }}
                                      className="clickable"
                                    >
                                      Reset Filter
                                    </a>
                                  </div>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                            {/* Sort */}
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle color="tranparent" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <Icon name="sort-line"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <ul className="link-list-opt no-bdr">
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSort("recently-updated");
                                        }}
                                        className={sort === "recently-updated" ? "text text-primary" : ""}
                                      >
                                        <span>Recently Updated</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSort("recently-added");
                                        }}
                                        className={sort === "recently-added" ? "text text-primary" : ""}
                                      >
                                        <span>Recently Added</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSort("oldest");
                                        }}
                                        className={sort === "oldest" ? "text text-primary" : ""}
                                      >
                                        <span>Oldest First</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSort("asc");
                                        }}
                                        className={sort === "asc" ? "text text-primary" : ""}
                                      >
                                        <span>0-9, A-Z</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSort("desc");
                                        }}
                                        className={sort === "desc" ? "text text-primary" : ""}
                                      >
                                        <span>9-0, Z-A</span>
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap `}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by user or email"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            {userData?.loading ? (
              <div
                style={{
                  height: "200px",
                  width: "100%",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <Spinner type="grow" color="dark" />
              </div>
            ) : userData?.success && userData?.data?.length ? (
              <DataTableBody>
                <DataTableHead>
                  <DataTableRow className="nk-tb-col-check">
                    <div className="custom-control custom-control-sm custom-checkbox notext">
                      <input
                        type="checkbox"
                        className="custom-control-input form-control"
                        id="uid_1"
                        checked={userData?.data?.map((item) => item._id).every((item) => selectedData.includes(item))}
                        onChange={(e) => selectorCheck(e)}
                      />
                      <label className="custom-control-label" htmlFor="uid_1"></label>
                    </div>
                  </DataTableRow>
                  <DataTableRow>
                    <span style={{ fontSize: "16px", color: "#5d5d5d", fontWeight: "600" }}>Name</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span style={{ fontSize: "16px", color: "#5d5d5d", fontWeight: "600" }}>Verified</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span style={{ fontSize: "16px", color: "#5d5d5d", fontWeight: "600" }}>Active</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span style={{ fontSize: "16px", color: "#5d5d5d", fontWeight: "600" }}>Email</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span style={{ fontSize: "16px", color: "#5d5d5d", fontWeight: "600" }}>Brokerage</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span style={{ fontSize: "16px", color: "#5d5d5d", fontWeight: "600" }}>Created On</span>
                  </DataTableRow>
                  <DataTableRow className="nk-tb-col-tools"></DataTableRow>
                </DataTableHead>
                {userData?.data?.length
                  ? userData?.data?.map((item) => {
                      return (
                        <DataTableItem key={item._id}>
                          <DataTableRow className="nk-tb-col-check">
                            <div className="custom-control custom-control-sm custom-checkbox notext">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id={item._id + "uid1"}
                                key={Math.random()}
                                checked={selectedData.includes(item._id)}
                                onChange={(e) => onSelectChange(e, item._id)}
                              />
                              <label className="custom-control-label" htmlFor={item._id + "uid1"}></label>
                            </div>
                          </DataTableRow>
                          <DataTableRow>
                            <span className="tb-product lazy-image">
                              <LazyLoadImage
                                src={
                                  item?.images?.length
                                    ? `${process.env.REACT_APP_IMGURL}${item.profilePhoto}`
                                    : PlaceholderImg
                                }
                                alt="development_image"
                                effect="blur"
                                className="rounded-circle"
                                placeholderSrc={PlaceholderImg}
                                style={{
                                  width: "32px",
                                  height: "32px",
                                  borderRadius: "100px",
                                  objectFit: "cover",
                                  marginRight: "10px",
                                }}
                              />
                              <p
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  fontSize: "12px",
                                }}
                              >
                                <span className="title">
                                  {item?.firstName}
                                  {item?.lastName ? ` ${item?.lastName}` : ""}
                                </span>
                              </p>
                            </span>
                          </DataTableRow>
                          <DataTableRow>
                            <p
                              className="tb-sub"
                              style={{
                                color: item?.isDeleted
                                  ? "#FF2A04"
                                  : item?.status === "active"
                                  ? "#00E200"
                                  : item?.status === "sold"
                                  ? "#FF2A04"
                                  : item?.status === "pending"
                                  ? "#FFAF3D"
                                  : item?.status === "draft"
                                  ? "#7B8089"
                                  : item?.status === "on hold"
                                  ? "#285766"
                                  : "",
                                display: "flex",
                                alignItems: "center",
                                gap: "0",
                                padding: "0",
                                margin: "0",
                                textTransform: "capitalize",
                              }}
                            >
                              <span>{item?.isDeleted ? "Archived" : item?.isVerified ? "Verified" : "Unverified"}</span>
                            </p>
                          </DataTableRow>
                          <DataTableRow>
                            <p
                              className="tb-sub"
                              style={{
                                color: item?.isDeleted
                                  ? "#FF2A04"
                                  : item?.status === "active"
                                  ? "#00E200"
                                  : item?.status === "sold"
                                  ? "#FF2A04"
                                  : item?.status === "pending"
                                  ? "#FFAF3D"
                                  : item?.status === "draft"
                                  ? "#7B8089"
                                  : item?.status === "on hold"
                                  ? "#285766"
                                  : "",
                                display: "flex",
                                alignItems: "center",
                                gap: "0",
                                padding: "0",
                                margin: "0",
                                textTransform: "capitalize",
                              }}
                            >
                              <span>{item?.isDeleted ? "Archived" : item?.isActive ? "Active" : "Inactive"}</span>
                            </p>
                          </DataTableRow>
                          <DataTableRow>
                            <span className="tb-sub">{item?.email}</span>
                          </DataTableRow>
                          <DataTableRow>
                            <span className="tb-sub">{item?.brokerage?.name}</span>
                          </DataTableRow>
                          <DataTableRow>
                            <span className="tb-sub">{moment(item?.createdAt).format("DD/MM/YYYY")}</span>
                          </DataTableRow>
                          <DataTableRow className="nk-tb-col-tools">
                            <ul className="nk-tb-actions gx-1 my-n1">
                              <li className="mr-n1">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    href="#more"
                                    onClick={(ev) => ev.preventDefault()}
                                    className="dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <ul className="link-list-opt no-bdr">
                                      {(typeof status === "object" && status?.value === "archive") ||
                                      status === "archive" ? (
                                        <li>
                                          <DropdownItem
                                            tag="a"
                                            href="#view"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                              handleRestore(item);
                                            }}
                                            disabled={permission && !permission?.edit}
                                          >
                                            <LuArchiveRestore
                                              style={{ fontSize: "18px", marginRight: "15px", color: "#526484" }}
                                            />
                                            <span>Restore</span>
                                          </DropdownItem>
                                        </li>
                                      ) : (
                                        <>
                                          {/* <li>
                                            <DropdownItem
                                              tag="a"
                                              href="#view"
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                                setView(item);
                                              }}
                                              disabled={
                                                profile?.role !== "superadmin" ||
                                                ["no", "rejected"]?.includes(profile?.vipAccess)
                                              }
                                            >
                                              <Icon name="archived-fill"></Icon>
                                              <span>Make a VIP Agnet</span>
                                            </DropdownItem>
                                          </li> */}
                                          <li>
                                            <DropdownItem
                                              tag="a"
                                              href="#view"
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                                onEditClick(item._id);
                                              }}
                                              disabled={permission && !permission?.edit}
                                            >
                                              <Icon name="edit-fill"></Icon>
                                              <span>Edit</span>
                                            </DropdownItem>
                                          </li>
                                          <li>
                                            <DropdownItem
                                              tag="a"
                                              href="#view"
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                                handleArchive(item);
                                              }}
                                              disabled={permission && !permission?.archive}
                                            >
                                              <Icon name="archived-fill"></Icon>
                                              <span>Archive</span>
                                            </DropdownItem>
                                          </li>
                                        </>
                                      )}
                                      <li>
                                        <DropdownItem
                                          tag="a"
                                          href="#view"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            handleDelete(item);
                                          }}
                                          disabled={permission && !permission?.delete}
                                        >
                                          <Icon name="trash"></Icon>
                                          <span>Delete Permanently</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </li>
                            </ul>
                          </DataTableRow>
                        </DataTableItem>
                      );
                    })
                  : null}
              </DataTableBody>
            ) : userData?.success && !userData?.data?.length ? (
              <div className="text-center" style={{ padding: "20px", fontSize: "1rem", fontWeight: "600" }}>
                <span className="text-silent">No Agents found</span>
              </div>
            ) : userData?.error ? (
              <ErrorComponent error={userData?.error} />
            ) : null}

            <div className="card-inner p-0">
              {userData?.success && userData?.data?.length ? (
                <DotPagination
                  itemPerPage={userData?.total?.items_per_page}
                  totalItems={userData?.total?.total}
                  paginate={paginate}
                  currentPage={currentPage}
                  siblingCount={2}
                  screenWidth={screenWidth}
                />
              ) : null}
            </div>
          </DataTable>

          <Modal
            isOpen={view?.add || view?.vip || view?.edit}
            toggle={() => toggleView()}
            className="modal-dialog-centered"
            size="lg"
          >
            <ModalBody>
              <a href="#cancel" className="close">
                <Icon name="cross-sm" onClick={() => toggleView()}></Icon>
              </a>
              {view?.add || view?.edit ? (
                <div className="p-2">
                  <h5 className="title">
                    <span>Add New Agent</span>
                  </h5>
                  <div className="mt-4">
                    <form onSubmit={handleSubmit(view?.add ? handleAddAgent : handleEditAgent)}>
                      <Row className="g-3">
                        <Col size="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="product-title">
                              First Name*
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                className="form-control"
                                name="firstName"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                ref={register({
                                  required: "This field is required",
                                })}
                              />
                              {errors.name && <span className="invalid">{errors.name.message}</span>}
                            </div>
                          </div>
                        </Col>
                        <Col size="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="product-title">
                              Last Name*
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                className="form-control"
                                name="lastName"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                ref={register({
                                  required: "This field is required",
                                })}
                              />
                              {errors.name && <span className="invalid">{errors.name.message}</span>}
                            </div>
                          </div>
                        </Col>
                        <Col size="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="product-title">
                              Username
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                className="form-control"
                                name="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                ref={register({
                                  // required: "This field is required",
                                })}
                              />
                              {errors.name && <span className="invalid">{errors.name.message}</span>}
                            </div>
                          </div>
                        </Col>
                        <Col size="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="product-title">
                              Email*
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                ref={register({
                                  required: "This field is required",
                                })}
                              />
                              {errors.name && <span className="invalid">{errors.name.message}</span>}
                            </div>
                          </div>
                        </Col>

                        <Col size="6">
                          <FormGroup>
                            <Label htmlFor="phone" className="form-label">
                              Phone*
                            </Label>
                            <div className="form-control-wrap">
                              <PhoneInput
                                country={"ca"}
                                value={mobile}
                                onChange={(value, data, event, formattedValue) => {
                                  setMobile(formattedValue);
                                  // if (e) setMandMobile(false);
                                }}
                                inputStyle={{ width: "100%" }}
                                // containerClass={mandMobile ? "error" : ""}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col size="6">
                          <FormGroup>
                            <Label htmlFor="communityName" className="form-label">
                              Brokerage
                            </Label>
                            <div className="form-control-wrap">
                              <CustomCreatable
                                placeholder={"Search Brokerage"}
                                data={company?.brokerageSearch?.map((li) => ({
                                  label: li?.name,
                                  value: li?._id,
                                  subLabel: li?.address?.firstLine,
                                  data: li,
                                }))}
                                required
                                style={{ width: "100%" }}
                                value={brokerage}
                                handleChange={(value) => {
                                  if (typeof value === "object") {
                                    setBrokerage({ label: value?.label, value: value?.value });
                                    setBrokerageName(value?.label);
                                  } else if (typeof value === "string") {
                                    setBrokerage(value);
                                    setBrokerageName(value);
                                  }
                                }}
                                loading={company?.loading}
                              />
                            </div>
                          </FormGroup>
                        </Col>

                        <hr style={{ width: "100%", height: "1px", background: "#a0a0a0", color: "#a0a0a0" }} />
                        <Col size="12">
                          <Button color="primary" type="submit">
                            <span>Save</span>
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </div>
              ) : view?.vip ? (
                <div className="p-2">
                  <h5 className="title">
                    <span>Make a VIP Agent</span>
                  </h5>
                  <div className="mt-4">
                    <form onSubmit={handleSubmit(handleVipAgent)}>
                      <Row className="g-3">
                        <Col sm={"12"}>
                          <FormGroup className="mb-1">
                            <label className="overline-title overline-title-alt mb-0">Select City</label>
                            <RSelect
                              options={cities?.data?.map((item) => {
                                return {
                                  value: item._id,
                                  label: `${item.name} (${item?.province?.name})${
                                    item?.vipCost ? ` - $${item?.vipCost}` : ""
                                  }`,
                                  data: item,
                                };
                              })}
                              onChange={(value) => {
                                console.log("value: ", value);
                                setCity(value);
                              }}
                              name={"city"}
                              value={city}
                              placeholder="Select city..."
                              isClearable
                              isLoading={cities?.loading}
                            />
                          </FormGroup>
                          {city && typeof city === "object" ? (
                            <div className="ml-1">
                              <span>
                                Agent Name: {`${view?.firstName}${view?.lastName ? ` ${view?.lastName}` : ""}`}
                              </span>
                              <br />
                              <span>City: {city?.data?.name}</span>
                              <br />
                              <span>Province: {city?.data?.province?.name}</span>
                              <br />
                              {city?.data?.vipCost ? <span>VIP Cost: ${city?.data?.vipCost}</span> : null}
                            </div>
                          ) : null}
                        </Col>
                        <Col sm={"12"} className={"text-center"}>
                          <Button
                            color="primary"
                            className="text-center"
                            style={{ fontSize: "16px" }}
                            disabled={!city || (city && typeof city !== "object") || loading}
                            type="submit"
                          >
                            {loading ? <Spinner size={"sm"} /> : "Generate Invoice"}
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </div>
              ) : null}
            </ModalBody>
          </Modal>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Agents;
