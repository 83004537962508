import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { addListing, editListing } from "../../redux/Apislices/listingSlice";
import { getSession, removeSession, setSession } from "../../utils/Storage";
import FooterBtn from "../common/FooterBtn";
import Content from "../../layout/content/Content";
import {
  Block,
  BlockBetween,
  BlockContent,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  RSelect,
  Row,
} from "../Component";
import DatePicker from "react-datepicker";
import { Card, FormGroup, Label, Spinner } from "reactstrap";
import AssignNavTab from "./AssignNavTab";
import clsx from "clsx";
import styles from "./assignment.module.scss";
import { intervals } from "../../utils/moment";
import { debounce } from "../../utils/Utils";
import { getMultipleAgents, searchAgents } from "../../redux/Apislices/agentsSlice";
import { getAdminSettings } from "../../redux/Apislices/settings";
import { getPaymentById } from "../../redux/Apislices/paymentSlice";
import { AiOutlineCloseCircle } from "react-icons/ai";
import moment from "moment";
import JoditPreview from "../../pages/components/forms/rich-editor/JoditPreview";
import CustomCreatable from "../input/custom/CustomCreatable";
import PhoneInput from "react-phone-input-2";

const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
  <div onClick={onClick} ref={ref}>
    <div className="form-icon form-icon-left">
      <Icon name="calendar"></Icon>
    </div>
    <input className="form-control date-picker" type="text" value={value} onChange={onChange} />
  </div>
));

const Assignment6 = ({ formStep, nextFormStep, prevFormStep, stepGoTo }) => {
  const navigate = useNavigate();
  const { type, id } = useParams();
  const pathArray = window.location.pathname.split("/");
  const mainPath = pathArray[1];
  const dispatch = useDispatch();
  const { agents, profile, listing, payment, settings } = useSelector((state) => ({
    agents: state?.agent,
    listing: state?.listing?.listingById?.data,
    profile: state?.profile?.profile,
    payment: state?.payment?.payment,
    settings: state?.settings,
  }));

  const initData = {
    remarkForAdmin: "",
    sellingAgentCommision: "",
    bonus: "",
    originalPrice: "",
    decorativeAllowance: "",
    realtorRemark: "",
    listingName: "",
    listingEmail: "",
    listingPhone: "",
    listingBrokerage: "",
    listingId: "",
  };

  const [assignmentDescription, setAssignmentDescription] = useState("");

  const [openHouseCheck, setOpenHouseCheck] = useState(false);
  const [openHouseDate, setOpenHouseDate] = useState("");
  const [openHouseTimeFrom, setOpenHouseTimeFrom] = useState("");
  const [openHouseTimeTo, setOpenHouseTimeTo] = useState("");
  const [openHouseArray, setopenHouseArray] = useState([]);
  const [agent, setAgent] = useState("");
  const [assignedUser, setAssignedUser] = useState("");
  const [devPermission, setDevPermission] = useState(false);
  const [step6Data, setStep6Data] = useState(initData);
  const [mandTnc, setMandTnc] = useState(false);
  const [loading, setLoading] = useState(false);

  let timeSlot = intervals("10:00", "19:30", 30);

  const {
    remarkForAdmin,
    sellingAgentCommision,
    bonus,
    originalPrice,
    decorativeAllowance,
    realtorRemark,
    listingName,
    listingEmail,
    listingPhone,
    listingBrokerage,
    listingId,
  } = step6Data;

  const [assignmentData, setAssignmentData] = useState(false);
  const [listingData, setListingData] = useState("");
  const [terms, setTerms] = useState(false);
  const [listingAgentsIdArray, setListingAgentsIdArray] = useState([]);

  useEffect(() => {
    if (type === "edit" && id) setTerms(true);
  }, [type, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (e.target.type === "checkbox") {
      if (e.target.checked) setStep6Data({ ...step6Data, [name]: value });
      else setStep6Data({ ...step6Data, [name]: !value });
    } else {
      setStep6Data({ ...step6Data, [name]: value });
    }
  };

  const handleBackFun = () => {
    prevFormStep();
  };

  function removeDuplicate(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  const handleAddUpdate = (successCB) => {
    let data = {
      ...assignmentData,
      description: assignmentDescription,
      remarksForAdmin: remarkForAdmin,
      realtorRemarks: {
        bonus,
        originalPurchasePrice: originalPrice,
        remark: realtorRemark,
        decoratingAllowance: decorativeAllowance,
        sellingAgentCommission: sellingAgentCommision,
      },
      devPermission,
    };
    if (assignedUser && typeof assignedUser === "object") data = { ...data, user: assignedUser?.value };
    if (assignmentData?.status)
      data = { ...data, status: assignmentData?.status === "draft" ? "pending" : assignmentData?.status };
    else data = { ...data, status: "pending" };

    if (listingAgentsIdArray?.length) {
      let a = removeDuplicate(listingAgentsIdArray);
      data = { ...data, listingAgent: a };
    }
    if (mainPath === "new-homes") {
      if (openHouseCheck) data = { ...data, openHouse: { openHouse: openHouseCheck, time: openHouseArray } };
      else data = { ...data, openHouse: { openHouse: openHouseCheck, time: openHouseArray } };
    }
    console.log("data: ", data);
    if (terms && listingAgentsIdArray?.length && assignedUser && typeof assignedUser === "object") {
      setLoading(true);
      if (type === "edit" && id) {
        dispatch(
          editListing(
            id,
            data,
            (res) => {
              successCB({ success: true, data: data, type: "edit" });
              setLoading(false);
            },
            (res) => {
              setLoading(false);
              toast.error("Something Went Wrong!");
            }
          )
        );
      } else {
        dispatch(
          addListing(
            data,
            (res) => {
              setLoading(false);
              removeSession("assignment");
              successCB({ success: true, data: res?.data, type: "add" });
            },
            (res) => {
              setLoading(false);
              toast.error("Something Went Wrong!");
            }
          )
        );
      }
    } else {
      if (!listingAgentsIdArray?.length) toast.error("Add atleast one listing agent");
      else if (!assignedUser && typeof assignedUser !== "object") toast.error("Assign primary user");
      else if (!terms) {
        toast.error("Accept Terms & Conditions");
        setMandTnc(true);
      }
    }
  };

  const handleNext = () => {
    handleAddUpdate(async (res) => {
      if (res?.success && payment && payment?.status === "success" && res?.type === "edit") {
        toast.success("Listing Updated!");
        navigate(mainPath === "assignment" ? "/assignment" : "/new-homes");
      } else {
        if (settings?.assignment?.showPayment && res?.type === "add") {
          console.log("add pay");
          toast.success("Listing Added Successfully!");
          nextFormStep();
          setSession("assign-step", 6);
          setSession("assign-max-step", 6);
          if (mainPath === "new-homes") navigate(`/new-homes/${res?.data?.slug}`);
          else navigate(`/assignment/${res?.data?.slug}`);
        } else if (res?.type === "edit" && settings?.assignment?.showPayment) {
          console.log("edit pay");
          toast.success("Listing Updated!");
          nextFormStep();
          setSession("assign-step", 6);
          setSession("assign-max-step", 6);
          if (mainPath === "new-homes") navigate(`/new-homes/${res?.data?.slug}`);
          else navigate(`/assignment/${res?.data?.slug}`);
        } else if (res?.type === "add" && !settings?.assignment?.showPayment) {
          console.log("add no pay");
          removeSession("assignment");
          toast.success("Listing Added Successfully!");
          navigate(mainPath === "assignment" ? "/assignment" : "/new-homes");
        } else if (res?.type === "edit" && !settings?.assignment?.showPayment) {
          console.log("edit no pay");
          toast.success("Listing Updated!");
          navigate(mainPath === "assignment" ? "/assignment" : "/new-homes");
        }
      }
    });
  };

  useEffect(() => {
    if (assignmentData) {
      console.log("assignmentData 6: ", assignmentData);
      let data = assignmentData;
      setAssignmentDescription(data?.description || assignmentDescription);
      setStep6Data({
        ...step6Data,
        bonus: data?.realtorRemarks?.bonus || bonus,
        originalPrice: data?.realtorRemarks?.originalPurchasePrice || originalPrice,
        realtorRemark: data?.realtorRemarks?.remark || realtorRemark,
        sellingAgentCommision: data?.realtorRemarks?.sellingAgentCommission || sellingAgentCommision,
        decorativeAllowance: data?.realtorRemarks?.decoratingAllowance || decorativeAllowance,
        remarkForAdmin: data?.remarksForAdmin,
      });
      setListingAgentsIdArray([...new Set(data?.listingAgent)] || listingAgentsIdArray);
      // setAssignedUser(data?.user);
      setOpenHouseCheck(data?.openHouse?.openHouse);
      setopenHouseArray(data?.openHouse?.time?.map((li) => li) || openHouseArray);
      setDevPermission(data?.devPermission);
    }
  }, [assignmentData]);

  useEffect(() => {
    if (assignmentData?.payment) dispatch(getPaymentById(assignmentData?.payment));
  }, [dispatch, assignmentData]);

  useEffect(() => {
    if (listing) {
      setAssignmentData(listing);
    } else {
      if (getSession("assignment")) {
        setAssignmentData(getSession("assignment"));
      }
    }
  }, [listing]);

  useEffect(() => {
    if (listingData && typeof listingData === "object") {
      setStep6Data({
        ...step6Data,
        listingName: listingData?.name,
        listingEmail: listingData?.email,
        listingPhone: listingData?.phone,
        listingBrokerage: listingData?.brokerage,
        listingId: listingData?.value,
      });
    }
  }, [listingData]);

  const debouncedAgentSearch = useCallback(
    debounce((query) => dispatch(searchAgents(query)), 1000),
    [dispatch]
  );
  useEffect(() => {
    debouncedAgentSearch({
      search: agent,
    });
  }, [agent]);

  useEffect(() => {
    if (agent && typeof agent === "object") {
      setStep6Data({
        listingName: `${agent?.data?.firstName}${agent?.data?.lastName ? ` ${agent?.data?.lastName}` : ""}`,
        listingEmail: agent?.data?.email,
        listingPhone: agent?.data?.mobile,
        listingBrokerage: agent?.data?.brokerage?.name,
        listingId: agent?.data?._id,
      });
    }
  }, [agent]);

  useEffect(() => {
    dispatch(getAdminSettings({ type: "assignment" }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMultipleAgents({ ids: listingAgentsIdArray }));
  }, [dispatch, listingAgentsIdArray]);

  useEffect(() => {
    if (assignmentData?.user && agents?.multipleAgents?.data?.length) {
      let a = agents?.multipleAgents?.data?.filter((li) => li?._id === assignmentData?.user);
      if (a?.length) {
        setAssignedUser({ value: a[0]?._id, label: `${a[0]?.firstName} ${a[0]?.lastName}` });
      }
    }
  }, [assignmentData, agents?.multipleAgents?.data]);

  return (
    <>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Add {mainPath === "assignment" ? "Assignment" : "New Home"}
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card className="card-bordered">
            <div className="card-aside-wrap" id="user-detail-block">
              <div className="card-content">
                <AssignNavTab stepGoTo={stepGoTo} update={handleAddUpdate} />
                <div className="card-inner">
                  {mainPath === "new-homes" ? (
                    <Block>
                      <BlockHead>
                        <BlockTitle tag="h4">Open House</BlockTitle>
                      </BlockHead>
                      <BlockContent>
                        <Row className="">
                          <Col sm={"12"} md={"12"}>
                            <div className={("form-check", styles.checkDiv)}>
                              <input
                                className={clsx("", styles.checkField)}
                                type="checkbox"
                                name="mlsCheck"
                                onChange={(e) => setOpenHouseCheck(e.target.checked)}
                                checked={openHouseCheck}
                                id="checkBox1"
                              />
                              <label className="form-check-label" htmlFor="checkBox1">
                                Open House
                              </label>
                            </div>
                          </Col>
                          <Col sm={"12"} md={"12"}>
                            {openHouseCheck ? (
                              <div className={clsx(styles.bannerWhite)}>
                                <Row>
                                  <Col sm={"12"} md={"3"} lg={"3"}>
                                    <FormGroup className={clsx(styles.askingPriceDiv)}>
                                      <Label className="mb-0">Select Date</Label>
                                      <div className="form-control-wrap">
                                        <div className="form-icon form-icon-left">
                                          <Icon name="calendar"></Icon>
                                        </div>
                                        <DatePicker
                                          selected={openHouseDate}
                                          className="form-control date-picker"
                                          onChange={setOpenHouseDate}
                                          customInput={<ExampleCustomInput />}
                                        />
                                      </div>
                                    </FormGroup>
                                  </Col>

                                  <Col sm={"6"} md={"6"} lg={"4"}>
                                    <div className={clsx(styles.askingPriceDiv)}>
                                      <p>{`Select Time`}</p>
                                      <Row>
                                        <Col sm={"6"} md={"6"} lg={"6"}>
                                          <select
                                            className={clsx("arrows", styles.selectTag)}
                                            style={{
                                              padding: "8px 7px",
                                              border: "0.2px solid #dddddd",
                                            }}
                                            value={openHouseTimeFrom}
                                            name="openHouseTimeFrom"
                                            onChange={(e) => setOpenHouseTimeFrom(e.target.value)}
                                          >
                                            <option value="">From</option>
                                            {timeSlot?.map((li, i) => (
                                              <option key={i} value={li}>
                                                {li}
                                              </option>
                                            ))}
                                          </select>
                                        </Col>

                                        <Col sm={"6"} md={"6"} lg={"6"}>
                                          <select
                                            className={clsx("arrows", styles.selectTag)}
                                            style={{
                                              padding: "8px 7px",
                                              border: "0.2px solid #dddddd",
                                            }}
                                            value={openHouseTimeTo}
                                            name="openHouseTimeTo"
                                            onChange={(e) => setOpenHouseTimeTo(e.target.value)}
                                          >
                                            <option value="">To</option>
                                            {timeSlot?.map((li, i) => (
                                              <option key={i} value={li}>
                                                {li}
                                              </option>
                                            ))}
                                          </select>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col sm={"12"} md={"3"} lg={"2"}>
                                    <div className={clsx(styles.askingPriceDiv)}>
                                      <Button
                                        color={"info"}
                                        onClick={() => {
                                          if (openHouseDate && openHouseTimeFrom && openHouseTimeTo) {
                                            setopenHouseArray([
                                              ...openHouseArray,
                                              {
                                                date: new Date(openHouseDate).toISOString(),
                                                time: {
                                                  start: openHouseTimeFrom,
                                                  end: openHouseTimeTo,
                                                },
                                              },
                                            ]);
                                            setOpenHouseDate(null);
                                            setOpenHouseTimeFrom("");
                                            setOpenHouseTimeTo("");
                                          }
                                        }}
                                      >
                                        {"Add"}
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>

                                <div className={styles.openHouseArray}>
                                  {openHouseArray?.map((li, i) => (
                                    <p
                                      key={i}
                                      onClick={() => {
                                        let a = openHouseArray?.indexOf(li);
                                        if (a > -1) {
                                          openHouseArray?.splice(a, 1);
                                          setopenHouseArray([...openHouseArray]);
                                        }
                                      }}
                                    >
                                      <AiOutlineCloseCircle />
                                      {moment(li?.date).format("DD MMM, YYYY")}: {li?.time?.start}-{li?.time?.end}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                      </BlockContent>
                    </Block>
                  ) : null}
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Description</BlockTitle>
                    </BlockHead>
                    <BlockContent>
                      <Row className="">
                        <Col sm={"12"} md={"12"}>
                          <FormGroup>
                            <Label htmlFor="projectSummary" className="form-label">
                              Listing Description
                            </Label>
                            <div className="form-control-wrap">
                              <JoditPreview initialValue={assignmentDescription} getValue={setAssignmentDescription} />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Realtor Remarks (Only visible to Agents)</BlockTitle>
                    </BlockHead>
                    <BlockContent>
                      <Row className="">
                        <Col sm={"6"} md={"4"}>
                          <FormGroup>
                            <Label htmlFor="projectSummary" className="form-label">
                              Selling Agent's Commission
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                value={sellingAgentCommision}
                                name="sellingAgentCommision"
                                onChange={handleChange}
                                className={clsx("form-control", styles.textField)}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm={"6"} md={"4"}>
                          <FormGroup>
                            <Label htmlFor="projectSummary" className="form-label">
                              Bonus/Incentive
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                value={bonus}
                                name="bonus"
                                onChange={handleChange}
                                className={clsx("form-control", styles.textField)}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        {mainPath === "assignment" ? (
                          <Col sm={"6"} md={"4"}>
                            <FormGroup>
                              <Label htmlFor="projectSummary" className="form-label">
                                Original Purchase Price
                              </Label>
                              <div className="form-control-wrap">
                                <input
                                  value={originalPrice}
                                  name="originalPrice"
                                  onChange={handleChange}
                                  className={clsx("form-control", styles.textField)}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        ) : null}
                        <Col sm={"12"} md={"12"} lg={"12"}>
                          <FormGroup className={clsx(styles.askingPriceDiv)}>
                            <Label htmlFor="projectSummary" className="form-label">
                              Enter remarks, if any
                            </Label>
                            <div className="form-control-wrap">
                              <textarea
                                value={realtorRemark}
                                name="realtorRemark"
                                onChange={handleChange}
                                className={clsx("form-control", styles.textField)}
                                rows="4"
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Listing Agent(s)</BlockTitle>
                    </BlockHead>
                    <BlockContent>
                      <Row className="">
                        <Col sm={"6"} md={"6"} lg={"3"}>
                          <FormGroup className={clsx(styles.askingPriceDiv)}>
                            <Label htmlFor="projectSummary" className="form-label">
                              Name
                            </Label>
                            <div className="form-control-wrap">
                              <CustomCreatable
                                creatable={false}
                                handleChange={(value) => {
                                  setAgent(value);
                                }}
                                value={agent}
                                data={
                                  agents?.agentSearch?.success && agents?.agentSearch?.data?.length
                                    ? agents?.agentSearch?.data
                                        ?.filter((li) => {
                                          return !listingAgentsIdArray?.includes(li?._id);
                                        })
                                        ?.map((li) => {
                                          return {
                                            label: `${li?.firstName} ${li?.lastName}`,
                                            value: li?._id,
                                            optional: li?.brokerage?.name ? `(${li?.brokerage?.name})` : "",
                                            subLabel: li?.brokerage?.address?.firstLine
                                              ? `(${li?.brokerage?.address?.firstLine})`
                                              : "",
                                            data: li,
                                          };
                                        })
                                    : agent && agents?.agentSearch?.success && !agents?.agentSearch?.data?.length
                                    ? [
                                        {
                                          label: "User does not exist",
                                          value: "",
                                          isDisabled: true,
                                          isError: true,
                                        },
                                      ]
                                    : []
                                }
                                loading={agents?.agentSearch?.loading}
                                placeholder="Search agent"
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm={"6"} md={"6"} lg={"3"}>
                          <FormGroup className={clsx(styles.askingPriceDiv)}>
                            <Label htmlFor="projectSummary" className="form-label">
                              Email
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                onChange={handleChange}
                                placeholder={"Enter email"}
                                value={listingEmail}
                                name="listingEmail"
                                className={clsx("form-control", styles.textField)}
                                disabled
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm={"6"} md={"6"} lg={"3"}>
                          <FormGroup className={clsx(styles.askingPriceDiv)}>
                            <Label htmlFor="projectSummary" className="form-label">
                              Phone
                            </Label>
                            <div className="form-control-wrap">
                              <PhoneInput
                                onlyCountries={["ca"]}
                                country={"ca"}
                                value={listingPhone}
                                onChange={(phone, data, event, formattedValue) => {
                                  setStep6Data({ ...step6Data, listingPhone: formattedValue });
                                }}
                                disabled
                                placeholder="Enter mobile number"
                                inputStyle={{
                                  width: "100%",
                                }}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm={"6"} md={"6"} lg={"3"}>
                          <FormGroup className={clsx(styles.askingPriceDiv)}>
                            <Label htmlFor="projectSummary" className="form-label">
                              Brokerage
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                placeholder={"Enter Brokerage"}
                                value={listingBrokerage}
                                name="listingBrokerage"
                                onChange={handleChange}
                                className={clsx("form-control", styles.textField)}
                                disabled
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        {listingAgentsIdArray?.length < 3 ? (
                          <Col sm={"6"} md={"6"} lg={"3"} className={"mt-2"}>
                            <Button
                              color={"info"}
                              onClick={() => {
                                if (listingName && listingEmail && listingBrokerage) {
                                  setListingAgentsIdArray([...listingAgentsIdArray, listingId]);
                                  setStep6Data({
                                    ...step6Data,
                                    listingName: "",
                                    listingEmail: "",
                                    listingPhone: "",
                                    listingBrokerage: "",
                                    listingId: "",
                                  });
                                  setAgent("");
                                }
                              }}
                            >
                              Add
                            </Button>
                          </Col>
                        ) : null}
                      </Row>
                      <div>
                        {agents?.multipleAgents?.loading ? (
                          <div
                            className={clsx("scrollbar", styles.dataContainer)}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "5px",
                            }}
                          >
                            <Spinner
                              style={{
                                color: "#00aeff",
                                width: "25px",
                                height: "25px",
                              }}
                              animation="grow"
                            />
                          </div>
                        ) : listingAgentsIdArray?.length && agents?.multipleAgents?.data?.length ? (
                          <table className={clsx("table table-bordered", styles.arrayTable)}>
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Brokerage</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {agents?.multipleAgents?.data?.map((row) => (
                                <tr key={`${row}-${Math.random()}`}>
                                  <td>{`${row?.firstName} ${row?.lastName}`}</td>
                                  <td>{`${row?.email}`}</td>
                                  <td>{`${row?.mobile || ""}`}</td>
                                  <td className={styles.closeDiv}>{`${row?.brokerage?.name}`}</td>
                                  <td style={{ textAlign: "center" }}>
                                    <AiOutlineCloseCircle
                                      className={styles.arrayCloseIcon}
                                      onClick={() => {
                                        let a = listingAgentsIdArray?.filter((li) => li !== row?._id);
                                        setListingAgentsIdArray(a);
                                        if (assignedUser && typeof assignedUser === "object") {
                                          if (assignedUser?.value === row?._id) setAssignedUser("");
                                        }
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : null}
                      </div>
                      {listingAgentsIdArray?.length ? (
                        <Row className={"mt-3"}>
                          <Col sm={"12"} md={"6"} lg={"6"}>
                            <FormGroup className={clsx(styles.askingPriceDiv)}>
                              <Label htmlFor="assignedUser" className="form-label">
                                Assign Primary User
                              </Label>
                              <div className="form-control-wrap">
                                <RSelect
                                  options={
                                    listingAgentsIdArray?.length && agents?.multipleAgents?.data?.length
                                      ? agents?.multipleAgents?.data?.map((li) => ({
                                          value: li?._id,
                                          label: `${li?.firstName} ${li?.lastName}`,
                                        }))
                                      : []
                                  }
                                  value={assignedUser}
                                  onChange={(e) => setAssignedUser(e)}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : null}
                    </BlockContent>
                  </Block>
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Any remarks for the admin?</BlockTitle>
                    </BlockHead>
                    <BlockContent>
                      <Row className="">
                        <Col sm={"12"} md={"12"}>
                          <FormGroup className={clsx(styles.askingPriceDiv)}>
                            <Label htmlFor="projectSummary" className="form-label">
                              Enter remarks, if any
                            </Label>
                            <div className="form-control-wrap">
                              <textarea
                                rows="5"
                                value={remarkForAdmin}
                                name="remarkForAdmin"
                                className={clsx("form-control")}
                                onChange={handleChange}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm={"12"} md={"12"}>
                          <div className={("form-check", styles.checkDiv)}>
                            <input
                              className={clsx("", styles.checkField)}
                              type="checkbox"
                              name="devPermission"
                              onChange={(e) => setDevPermission(e.target.checked)}
                              checked={devPermission}
                              id="devPermission"
                            />
                            <label className="form-check-label" htmlFor="devPermission">
                              I have the developer's permission to assign.
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>
                </div>
              </div>
            </div>
          </Card>
          <div className={styles.tnc}>
            <div className={("form-check", styles.checkDiv)}>
              <input
                className={clsx("", styles.checkField)}
                type="checkbox"
                name="termsAndConditions"
                onChange={(e) => {
                  setTerms(e.target.checked);
                  if (e.target.checked) setMandTnc(false);
                }}
                checked={terms}
                id="termsAndConditions"
              />
              <label className="form-check-label" htmlFor="termsAndConditions">
                I agree to{" "}
                <a href={`${process.env.USER_WEB_URL}terms-and-conditions`} target="_blank" rel="nofollow noreferrer">
                  Terms & Conditions
                </a>
              </label>
            </div>
          </div>
          <div style={{ margin: "15px 0", display: "flex", gap: "1rem" }}>
            <FooterBtn text={"Cancel"} color={"secondary"} outline onClick={handleBackFun} />
            <FooterBtn
              text={type === "edit" ? "Update Listings" : "Submit Listing"}
              color={"info"}
              onClick={handleNext}
              disabled={loading}
            />
          </div>
        </Block>
      </Content>
    </>
  );
};

export default Assignment6;
