/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, Spinner } from "reactstrap";
import Content from "../../layout/content/Content";
import DevNavTab from "../../pages/Development/DevNavTab";
import {
  Block,
  BlockBetween,
  BlockContent,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Row,
} from "../Component";
import FooterBtn from "../common/FooterBtn";
import { useDispatch } from "react-redux";
import { StandaloneSearchBox, useLoadScript } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { deleteFile, uploadFile } from "../../redux/Apislices/awsSlice";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { editDevelopment, handleAddDevelopment, handleDevAddReset } from "../../redux/Apislices/developmentSlice";
import { convertToSlug } from "../../utils/Utils";
import { useDropzone } from "react-dropzone";
import validator from "validator";
import { intervals } from "../../utils/moment";
import { getLocationData } from "../../utils/location";
import { getCountry } from "../../redux/Apislices/countrySlice";
import { getprovince } from "../../redux/Apislices/provinceSlice";
import { getcity } from "../../redux/Apislices/citySlice";
import { getneighbourhood } from "../../redux/Apislices/neighbourhoodSlice";
import styles from "./dev.module.scss";
import clsx from "clsx";
import JoditPreview from "../../pages/components/forms/rich-editor/JoditPreview";
import SpecialIncentivesSection from "./SpecialIncentives";
import PhoneInput from "react-phone-input-2";
import { AiOutlineCloseCircle } from "react-icons/ai";
import moment from "moment";

const libraries = ["places"];

let array = [
  { id: 1, label: "Facebook", value: "Facebook" },
  { id: 2, label: "Instagram", value: "Instagram" },
  { id: 3, label: "Twitter/x", value: "Twitter" },
  { id: 4, label: "TikTok", value: "TikTok" },
];

const Dev6 = ({ toggleNext, toggleBack, toggleIconTab, activeIconTab }) => {
  const { type, id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries,
  });

  const { country, province, city, neighbourhood, development } = useSelector((state) => ({
    country: state?.country?.countries?.data,
    province: state?.province?.provinces?.data,
    city: state?.city?.cities?.data,
    neighbourhood: state?.neighbourhood?.neighbourhoods?.data,
    development: state?.development,
    profile: state?.profile,
  }));
  const { developmentById, addDevelopment } = development;

  const [devData, setDevData] = useState(false);

  const [priceFromUnit, setPriceFromUnit] = useState("from");
  const [priceRangeFrom, setPriceRangeFrom] = useState("");
  const [priceToUnit, setPriceToUnit] = useState("to");
  const [priceRangeTo, setPriceRangeTo] = useState("");
  const [priceAuto, setPriceAuto] = useState(false);
  const [pricePerSqftFrom, setPricePerSqftFrom] = useState("");
  const [pricePerSqftTo, setPricePerSqftTo] = useState("");
  const [pricePerSqftAuto, setPricePerSqftAuto] = useState(false);
  const [maintenance, setMaintenance] = useState("");
  const [assignment, setAssignment] = useState("");
  const [assignmentFee, setAssignmentFee] = useState("");
  const [depositTotal, setDepositTotal] = useState("");
  const [depositDetail, setDepositDetail] = useState("");
  const [specialIncentive, setSpecialIncentive] = useState(false);
  const [incentiveDetail, setIncentiveDetail] = useState("");
  const [incentiveStart, setIncentiveStart] = useState(null);
  const [incentiveEnd, setIncentiveEnd] = useState(null);
  const [incentiveArray, setIncentiveArray] = useState([]);
  const [incentiveError, setIncentiveError] = useState(false);
  const [address, setAddress] = useState("");
  const [saleCentreAddress, setSaleCentreAddress] = useState({
    firstLine: "",
    country: "",
    province: "",
    city: "",
    neighbourhood: "",
    zipcode: "",
    lat: "",
    lng: "",
  });
  const [primaryContact, setPrimaryContact] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [email2, setEmail2] = useState("");
  const [primaryPhone, setPrimaryPhone] = useState("");
  const [secondaryPhone, setSecondaryPhone] = useState("");
  const [websiteName, setWebsiteName] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");
  const [developmentLogo, setDevelopmentLogo] = useState("");
  const [salesHour, setSalesHour] = useState("");
  const [daysFrom, setDaysFrom] = useState("");
  const [daysTo, setDaysTo] = useState("");
  const [timeFrom, setTimeFrom] = useState("");
  const [timeTo, setTimeTo] = useState("");
  const [salesOpenArray, setSalesOpenArray] = useState([]);
  const [appointment, setAppointment] = useState(false);
  const [socialArray, setSocialArray] = useState([]);
  const [socialName, setSocialName] = useState("");
  const [socialURL, setSocialURL] = useState("");
  const [mandContact, setMandContact] = useState(false);
  const [mandEmail, setMandEmail] = useState(false);
  const [mandMobile, setMandMobile] = useState(false);
  const incentStart = useRef(null);
  const contactRef = useRef(null);
  const emailRef = useRef(null);
  const mobileRef = useRef(null);

  let handlePayload = () => {
    let updateData = { ...devData };
    if (priceRangeFrom || priceRangeTo || priceFromUnit || priceToUnit)
      updateData.price = {
        price: {
          from: +priceRangeFrom,
          to: +priceRangeTo,
          fromLabel: priceFromUnit,
          toLabel: priceToUnit,
          autoUpdate: priceAuto,
        },
      };
    if (pricePerSqftFrom || pricePerSqftTo)
      updateData.price = {
        ...updateData.price,
        pricePerSqft: { from: +pricePerSqftFrom, to: +pricePerSqftTo, autoUpdate: pricePerSqftAuto },
      };
    if (assignment === "Allowed" && assignmentFee)
      updateData.price = { ...updateData.price, assignment: { allowed: assignment } };
    else if (assignment === "Not Allowed")
      updateData.price = { ...updateData.price, assignment: { allowed: assignment, percentage: null } };
    else updateData.price = { ...updateData.price, assignment: { allowed: null, percentage: null } };
    if (depositTotal || depositDetail)
      updateData.price = { ...updateData.price, deposit: { total: depositTotal, details: depositDetail } };
    else updateData.price = { ...updateData.price, deposit: { total: null, details: null } };
    if (maintenance) updateData.price = { ...updateData.price, maintenance: +maintenance };
    else updateData.price = { ...updateData.price, maintenance: null };
    let specialIncentives = [];
    if (incentiveArray?.length) {
      specialIncentives = [...incentiveArray];
      if (specialIncentive) {
        specialIncentives = [
          ...specialIncentives,
          {
            isSpecialIncentive: specialIncentive,
            values: incentiveDetail,
            start: incentiveStart,
            end: incentiveEnd,
          },
        ];
      }
    } else {
      if (specialIncentive) {
        specialIncentives = [
          {
            isSpecialIncentive: specialIncentive,
            values: incentiveDetail,
            start: incentiveStart,
            end: incentiveEnd,
          },
        ];
      }
    }
    if (specialIncentives?.length) updateData = { ...updateData, specialIncentives };
    else updateData = { ...updateData, specialIncentives: [] };
    let presentationCenter = {};
    if (saleCentreAddress?.firstLine)
      presentationCenter.address = { ...presentationCenter.address, firstLine: saleCentreAddress.firstLine };
    if (saleCentreAddress?.neighbourhood)
      presentationCenter.address = {
        ...presentationCenter.address,
        neighbourhood: saleCentreAddress.neighbourhood,
      };
    if (saleCentreAddress?.city)
      presentationCenter.address = { ...presentationCenter.address, city: saleCentreAddress.city };
    if (saleCentreAddress?.province)
      presentationCenter.address = { ...presentationCenter.address, province: saleCentreAddress.province };
    if (saleCentreAddress?.country)
      presentationCenter.address = { ...presentationCenter.address, country: saleCentreAddress.country };
    if (saleCentreAddress?.zipcode)
      presentationCenter.address = { ...presentationCenter.address, zipcode: saleCentreAddress.zipcode };
    if (saleCentreAddress?.lng && saleCentreAddress?.lat)
      presentationCenter.address = {
        ...presentationCenter.address,
        location: { type: "Point", coordinates: [saleCentreAddress.lng, saleCentreAddress.lat] },
      };
    if (primaryContact) presentationCenter.contact = primaryContact;
    if (email) presentationCenter.emailPrimary = email;
    if (email2) presentationCenter.emailSecondary = email2;
    else presentationCenter.emailSecondary = null;
    if (primaryPhone) presentationCenter.mobilePrimary = primaryPhone;
    if (secondaryPhone) presentationCenter.mobileSecondary = secondaryPhone;
    else presentationCenter.mobileSecondary = null;
    if (developmentLogo) presentationCenter.logo = developmentLogo;
    presentationCenter.website = { label: websiteName, url: websiteURL };
    if (socialArray?.length)
      presentationCenter.socialLink = socialArray.map((li) => ({ label: li?.label, url: li?.url }));
    else presentationCenter.socialLink = null;
    if (salesHour) presentationCenter.timelines = { openStatus: salesHour };
    if (appointment) presentationCenter.timelines = { ...presentationCenter.timelines, ByAppointment: appointment };
    if (salesOpenArray) presentationCenter.timelines = { ...presentationCenter.timelines, openTime: salesOpenArray };
    else presentationCenter.timelines = { ...presentationCenter.timelines, openTime: [] };
    if (presentationCenter) updateData = { ...updateData, presentationCenter };
    updateData.type = "presale";
    return updateData;
  };

  const uploadImage = (formData) => {
    let toastId = toast.loading("Uploading...");

    setTimeout(() => {
      toast.dismiss(toastId);
    }, 10000);

    dispatch(
      uploadFile(
        formData,
        (res) => {
          setDevelopmentLogo(res?.data?.[0]?.url);
          if (type === "edit") {
            let updateData = handlePayload();
            if (res?.success) updateData.presentationCenter.logo = res?.data?.[0]?.url;
            updateData.type = "presale";
            dispatch(
              editDevelopment(
                id,
                updateData,
                (res) => {
                  toast.dismiss(toastId);
                  toast.success("Logo uploaded");
                  setDevData(res?.data);
                },
                (res) => {
                  console.log("res: ", res);
                  toast.error(res?.message);
                }
              )
            );
          } else {
            dispatch(handleAddDevelopment({ ...addDevelopment, "dev-Saleslogo": res?.data?.[0]?.url }));
            toast.dismiss(toastId);
            toast.success("Logo uploaded");
          }
        },
        (res) => {
          toast.error(res?.message);
        }
      )
    );
  };

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            toast.error("File is larger than 20 MB");
          }
          if (err.code === "file-invalid-type") {
            toast.error(err.message);
          }
        });
      });

      // setDevelopmentLogo(acceptedFiles?.[0]);
      const newImages = acceptedFiles.map((file, index) => {
        let devName = convertToSlug(devData?.name);

        const newName = `${devName}-sales-logo.${file.name.split(".").pop()}`;
        return new File([file], newName, { type: file.type });
      });

      console.log("newImages: ", newImages);
      const formData = new FormData();
      newImages.forEach((image) => {
        formData.append("file", image);
        formData.append("folder", "development");
      });
      uploadImage(formData);
    },
    [devData, uploadImage]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: { "image/jpg": [".jpg"], "image/jpeg": [".jpeg"], "image/png": [".png"] },
    maxSize: 20971520,
  });

  const handleDeleteLogo = () => {
    let toastId = toast.loading("Removing...");
    setTimeout(() => {
      toast.dismiss(toastId);
    }, 10000);
    if (developmentLogo) {
      let saleLogo = developmentLogo;
      dispatch(
        deleteFile(
          { path: saleLogo },
          (res) => {
            setDevelopmentLogo("");
            if (type === "edit") {
              let updateData = handlePayload();
              if (res?.success) updateData.presentationCenter.logo = null;
              dispatch(
                editDevelopment(
                  id,
                  updateData,
                  (res) => {
                    toast.dismiss(toastId);
                    toast.success("Logo Removed");
                    setDevData(res?.data);
                  },
                  (res) => {
                    console.log("res: ", res);
                  }
                )
              );
            } else {
              toast.dismiss(toastId);
              dispatch(handleAddDevelopment({ ...addDevelopment, "dev-Saleslogo": null }));
              toast.success("Logo Removed");
            }
          },
          (res) => {
            toast.error(res?.message);
          }
        )
      );
    }
  };

  const handlePlaceChanged = () => {
    const places = address?.getPlaces();

    if (places?.length) {
      let data = getLocationData(places[0]);
      let lat = places[0].geometry.location.lat();
      let lng = places[0].geometry.location.lng();

      setSaleCentreAddress({
        ...saleCentreAddress,
        firstLine: data?.address,
        neighbourhood: neighbourhood?.filter((li) => li?.name === data?.neighbourhood)[0]?._id,
        city: city?.filter((li) => li?.name === data?.city)[0]?._id,
        province: province?.filter((li) => li?.name === data?.province)[0]?._id,
        country: country?.filter((li) => li?.name === data?.country)[0]?._id,
        lat,
        lng,
        zipcode: data?.zipcode,
      });
    }
  };

  useEffect(() => {
    if (devData?.floorPlans?.floorPlans) {
      let floorPlans = devData?.floorPlans?.floorPlans;
      let data = floorPlans?.filter((li) => li?.status === "available");
      // Create an array of all the prices
      const prices = data?.map((plan) => {
        if (plan?.price?.range) {
          if (plan?.price?.from !== 0 && plan?.price?.to !== 0) {
            return [Number(plan?.price?.from), Number(plan?.price?.to)];
          } else {
            return [0];
          }
        } else {
          if (plan?.price?.price) {
            return [Number(plan?.price?.price)];
          } else {
            return [0];
          }
        }
      });
      let flattenedPrices = [].concat(...prices);
      let filteredPrices = flattenedPrices.filter((price) => price !== 0);
      let min = Math.min(...filteredPrices);
      let max = Math.max(...filteredPrices);
      if (priceAuto) {
        setPriceRangeFrom(min);
        setPriceRangeTo(max);
      }
      let floorAreaMin = [];
      let floorAreaMax = [];
      for (let i = 0; i < data?.length; i++) {
        let minPrice;
        let minSize;
        let maxPrice;
        let maxSize;
        if (data?.[i]?.price?.price || data?.[i]?.price?.from)
          minPrice = data?.[i]?.price?.price || data?.[i]?.price?.from;
        else minPrice = +Infinity;
        if (data?.[i]?.size?.size || data?.[i]?.size?.from) minSize = data?.[i]?.size?.size || data?.[i]?.size?.from;
        else minSize = +Infinity;
        if (data?.[i]?.price?.price || data?.[i]?.price?.to) maxPrice = data?.[i]?.price?.price || data?.[i]?.price?.to;
        else maxPrice = -Infinity;
        if (data?.[i]?.size?.size || data?.[i]?.size?.to) maxSize = data?.[i]?.size?.size || data?.[i]?.size?.to;
        else maxSize = -Infinity;
        floorAreaMin.push(Math.round(minPrice / minSize));
        floorAreaMax.push(Math.round(maxPrice / maxSize));
      }
      if (pricePerSqftAuto) {
        setPricePerSqftFrom(Math.min(...floorAreaMin));
        setPricePerSqftTo(Math.max(...floorAreaMax));
      }
    }
  }, [devData, priceAuto, pricePerSqftAuto]);

  useEffect(() => {
    dispatch(getCountry());
    dispatch(getprovince());
    dispatch(getcity());
    dispatch(getneighbourhood());
  }, [dispatch]);

  const handleAddUpdate = (CB) => {
    let updateData = handlePayload();
    if (type === "edit") updateData.status = devData?.status;
    else updateData.status = "pending";
    console.log("updateData: ", updateData);
    if (specialIncentive && !incentiveDetail) {
      toast.error("Please add incentive details.");
      return;
    } else if (specialIncentive && !incentiveStart) {
      incentStart?.current?.focus();
      setIncentiveError(true);
      toast.error("Please add incentive start date.");
      return;
    } else {
      if (primaryContact && primaryPhone && email && validator.isEmail(email)) {
        if (type === "edit") {
          dispatch(
            editDevelopment(
              id,
              updateData,
              (res) => {
                dispatch(handleAddDevelopment({ ...addDevelopment, data: updateData, "dev-Saleslogo": null }));
                CB({ success: true });
              },
              (res) => {
                console.log("res: ", res);
              }
            )
          );
        } else {
          if (primaryContact && primaryPhone && email) {
            dispatch(handleAddDevelopment({ ...addDevelopment, data: updateData, "dev-Saleslogo": null }));
            CB({ success: true });
          } else {
            if (!primaryContact) setMandContact(true);
            if (!email) setMandEmail(true);
            if (!primaryPhone) setMandMobile(true);
            if (!primaryContact) contactRef?.current?.focus();
            else if (!email) emailRef?.current?.focus();
            else if (!primaryPhone) mobileRef?.current?.focus();
            toast.error("Please complete all the mandatory fields.");
          }
        }
      } else {
        if (!primaryContact) setMandContact(true);
        if (!email) setMandEmail(true);
        if (!primaryPhone) setMandMobile(true);
        if (email && !validator.isEmail(email)) {
          toast.error("Invalid Email address");
          emailRef?.current?.focus();
        } else {
          toast.error("Please complete all the mandatory fields.");
        }
        if (!primaryContact) contactRef?.current?.focus();
        else if (!email) emailRef?.current?.focus();
        else if (!primaryPhone) mobileRef?.current?.focus();
      }
    }
  };

  const handleNext = () => {
    handleAddUpdate((res) => {
      if (res?.success) toggleNext();
    });
  };

  const addToDraft = () => {
    let updateData = handlePayload();
    if (type === "edit") updateData.status = devData?.status;
    else updateData.status = "draft";
    console.log("updateData: ", updateData);
    if (specialIncentive && !incentiveDetail) {
      toast.error("Please add incentive details.");
      return;
    } else if (specialIncentive && !incentiveStart) {
      incentStart?.current?.focus();
      setIncentiveError(true);
      toast.error("Please add incentive start date.");
    } else {
      if (primaryContact && primaryPhone && email && validator.isEmail(email)) {
        if (type === "edit") {
          dispatch(
            editDevelopment(
              id,
              updateData,
              (res) => {
                toast.success("Development updated");
                dispatch(handleDevAddReset());
                navigate("/development");
              },
              (res) => {
                toast.error(res?.message);
              }
            )
          );
        } else {
          dispatch(
            addDevelopment(
              updateData,
              (res) => {
                toast.success("Development added as draft");
                dispatch(handleDevAddReset());
                navigate("/development");
              },
              (res) => {
                toast.error(res?.message);
              }
            )
          );
        }
      } else {
        if (!primaryContact) setMandContact(true);
        if (!email) setMandEmail(true);
        if (!primaryPhone) setMandMobile(true);
        if (email && !validator.isEmail(email)) {
          toast.error("Invalid Email address");
          emailRef?.current?.focus();
        } else {
          toast.error("Please complete all the mandatory fields.");
        }
        if (!primaryContact) contactRef?.current?.focus();
        else if (!email) emailRef?.current?.focus();
        else if (!primaryPhone) mobileRef?.current?.focus();
      }
    }
  };

  const handleBack = () => {
    toggleBack();
  };

  let timeSlot = intervals("10:00", "19:30", 30);

  // prefill data if available in session storage
  useEffect(() => {
    if (developmentById?.data) setDevData(developmentById?.data);
    else if (addDevelopment?.data) setDevData(addDevelopment?.data);
  }, [developmentById, addDevelopment]);

  useEffect(() => {
    if (devData) {
      console.log("devData 6: ", devData);
      if (devData?.price?.price?.autoUpdate === true) setPriceAuto(devData?.price?.price?.autoUpdate);
      else if (devData?.price?.price?.autoUpdate === false) setPriceAuto(devData?.price?.price?.autoUpdate);
      else setPriceAuto(priceAuto);
      setPriceRangeFrom(devData?.price?.price?.from || "");
      setPriceRangeTo(devData?.price?.price?.to || "");
      if (devData?.price?.pricePerSqft?.autoUpdate === true)
        setPricePerSqftAuto(devData?.price?.pricePerSqft?.autoUpdate);
      else if (devData?.price?.pricePerSqft?.autoUpdate === false)
        setPricePerSqftAuto(devData?.price?.pricePerSqft?.autoUpdate);
      else setPricePerSqftAuto(pricePerSqftAuto);
      setPricePerSqftFrom(devData?.price?.pricePerSqft?.from || "");
      setPricePerSqftTo(devData?.price?.pricePerSqft?.to || "");
      setMaintenance(devData?.price?.maintenance);
      if (devData?.price?.assignment) setAssignment(devData?.price?.assignment?.allowed);
      else setAssignment("");
      setAssignmentFee(devData?.price?.assignment?.percentage || assignmentFee);
      setDepositTotal(devData?.price?.deposit?.total || depositTotal);
      setDepositDetail(devData?.price?.deposit?.details || depositDetail);
      if (type === "edit") {
        if (devData?.specialIncentives?.length) setIncentiveArray([...devData?.specialIncentives]);
      } else {
        setSpecialIncentive(devData?.specialIncentives?.[devData?.specialIncentives?.length - 1]?.isSpecialIncentive);
        setIncentiveDetail(
          devData?.specialIncentives?.[devData?.specialIncentives?.length - 1]?.values || incentiveDetail
        );
        setIncentiveStart(
          devData?.specialIncentives?.[devData?.specialIncentives?.length - 1]?.start || incentiveStart
        );
        setIncentiveEnd(devData?.specialIncentives?.[devData?.specialIncentives?.length - 1]?.end || incentiveEnd);
      }
      setSaleCentreAddress(
        {
          ...saleCentreAddress,
          firstLine: devData?.presentationCenter?.address?.firstLine,
          country: devData?.presentationCenter?.address?.country,
          province: devData?.presentationCenter?.address?.province,
          city: devData?.presentationCenter?.address?.city,
          neighbourhood: devData?.presentationCenter?.address?.neighbourhood,
          zipcode: devData?.presentationCenter?.address?.zipcode,
          lat: devData?.presentationCenter?.address?.location?.coordinates[1],
          lng: devData?.presentationCenter?.address?.location?.coordinates[0],
        } || saleCentreAddress
      );
      setPrimaryContact(devData?.presentationCenter?.contact || primaryContact);
      setEmail(devData?.presentationCenter?.emailPrimary || email);
      setEmail2(devData?.presentationCenter?.emailSecondary || email2);
      setPrimaryPhone(devData?.presentationCenter?.mobilePrimary || primaryPhone);
      setSecondaryPhone(devData?.presentationCenter?.mobileSecondary || secondaryPhone);
      setWebsiteName(devData?.presentationCenter?.website?.label || websiteName);
      setWebsiteURL(devData?.presentationCenter?.website?.url || websiteURL);
      dispatch(
        handleAddDevelopment({
          ...addDevelopment,
          "dev-Saleslogo": devData?.presentationCenter?.logo || developmentLogo,
        })
      );
      setSalesHour(devData?.presentationCenter?.timelines?.openStatus || salesHour);
      setSalesOpenArray(
        devData?.presentationCenter?.timelines?.openTime?.length
          ? devData?.presentationCenter?.timelines?.openTime?.map((li) => li)
          : [] || salesOpenArray
      );
      setAppointment(devData?.presentationCenter?.timelines?.ByAppointment);
      setSocialArray(devData?.presentationCenter?.socialLink?.map((li) => li) || socialArray);
    }
  }, [devData]);

  useEffect(() => {
    if (addDevelopment?.["dev-Saleslogo"]) setDevelopmentLogo(addDevelopment?.["dev-Saleslogo"]);
  }, [addDevelopment]);

  useEffect(() => {
    if (socialName === "Facebook") setSocialURL(`https://facebook.com/`);
    else if (socialName === "Instagram") setSocialURL(`https://instagram.com/`);
    else if (socialName === "Twitter") setSocialURL(`https://twitter.com/`);
    else if (socialName === "TikTok") setSocialURL(`https://tiktok.com/`);
    else setSocialURL(``);
  }, [socialName]);

  if (loadError) return "Error...";
  if (!isLoaded)
    return (
      <div className="loadingDiv">
        <Spinner animation="grow" variant="info" style={{ width: "50px", height: "50px" }} />
      </div>
    );

  return (
    <>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                {devData?.name ? devData?.name : type === "add" ? "Add Development" : "Edit Development"}
              </BlockTitle>
            </BlockHeadContent>

            <BlockHeadContent>
              <Button
                color="light"
                outline
                className="bg-white d-inline-flex"
                onClick={(ev) => {
                  ev.preventDefault();
                  addToDraft();
                }}
              >
                <span>{type === "edit" ? "Update Development" : "Save as Draft"}</span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Card className="card-bordered">
            <div className="card-aside-wrap" id="user-detail-block">
              <div className="card-content">
                <DevNavTab toggleIconTab={toggleIconTab} activeIconTab={activeIconTab} update={handleAddUpdate} />

                <div className="card-inner">
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Price Details</BlockTitle>
                    </BlockHead>
                    <BlockContent>
                      <Row>
                        <Col sm={"12"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p className={clsx(styles.heightPara)}>Price Range of available units </p>
                            <Row>
                              <Col sm={"3"}>
                                <select
                                  className={clsx("arrows", styles.selectTag)}
                                  style={{
                                    padding: "7px 15px",
                                    border: "0.2px solid #dddddd",
                                  }}
                                  value={priceFromUnit}
                                  name="priceFromUnit"
                                  onChange={(e) => setPriceFromUnit(e.target.value)}
                                  disabled={priceAuto}
                                >
                                  <option value="From">From</option>
                                  <option value="From low">From low</option>
                                  <option value="From mid">From mid</option>
                                  <option value="From high">From high</option>
                                  <option value="From over">From over</option>
                                </select>
                              </Col>
                              <Col sm={"3"}>
                                <div className={`form-control-wrap`}>
                                  <div className="input-group input-group">
                                    <div className="input-group-prepend">
                                      <span className={`input-group-text`} id="inputGroup-sizing-md">
                                        $
                                      </span>
                                    </div>
                                    <input
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      className={clsx("form-control")}
                                      value={priceRangeFrom}
                                      name="priceRangeFrom"
                                      onChange={(e) => setPriceRangeFrom(e.target.value)}
                                      disabled={priceAuto}
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col sm={"3"}>
                                <select
                                  className={clsx("arrows", styles.selectTag)}
                                  style={{
                                    padding: "7px 15px",
                                    border: "0.2px solid #dddddd",
                                  }}
                                  value={priceToUnit}
                                  name="priceToUnit"
                                  onChange={(e) => setPriceToUnit(e.target.value)}
                                  disabled={priceAuto}
                                >
                                  <option value="To">To</option>
                                  <option value="To Low">To low</option>
                                  <option value="To mid">To mid</option>
                                  <option value="To high">To high</option>
                                  <option value="To over">To over</option>
                                </select>
                              </Col>
                              <Col sm={"3"}>
                                <div className={`form-control-wrap`}>
                                  <div className="input-group input-group">
                                    <div className="input-group-prepend">
                                      <span className={`input-group-text`} id="inputGroup-sizing-md">
                                        $
                                      </span>
                                    </div>
                                    <input
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      className={clsx("form-control")}
                                      value={priceRangeTo}
                                      name="priceRangeTo"
                                      onChange={(e) => setPriceRangeTo(e.target.value)}
                                      disabled={priceAuto}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <div className={styles.checkDiv}>
                              <input
                                type="checkbox"
                                id="priceAuto"
                                name="priceAuto"
                                onChange={(e) => setPriceAuto(e.target.checked)}
                                checked={priceAuto}
                              />
                              <label htmlFor="priceAuto" className="form-check-label">
                                Auto Update
                              </label>
                            </div>
                          </div>
                        </Col>
                        <Col sm={"12"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p className={clsx(styles.heightPara)}>{`Price 'per sqft' of available units`}</p>
                            <Row>
                              <Col sm={"6"} md={"3"}>
                                <div className={`form-control-wrap`}>
                                  <div className="input-group input-group">
                                    <div className="input-group-prepend">
                                      <span
                                        className={`input-group-text`}
                                        id="inputGroup-sizing-md"
                                        style={{ display: "flex", alignItems: "center" }}
                                      >
                                        <span className={styles.start}>From</span>
                                        <span
                                          style={{ color: "#00aeff", fontSize: "14px", margin: "0 8px" }}
                                          className={styles.divider}
                                        >
                                          |
                                        </span>
                                        <span className={styles.start}>$</span>
                                      </span>
                                    </div>
                                    <input
                                      type="number"
                                      value={pricePerSqftFrom}
                                      className={clsx("form-control")}
                                      name="pricePerSqftFrom"
                                      onChange={(e) => {
                                        let { value } = e.target;
                                        let fixed = value?.split(".")?.[1];
                                        if (fixed?.length > 2) {
                                          return;
                                        } else {
                                          setPricePerSqftFrom(e.target.value);
                                        }
                                      }}
                                      onWheel={(e) => e.target.blur()}
                                      disabled={pricePerSqftAuto}
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col sm={"6"} md={"3"}>
                                <div className={`form-control-wrap`}>
                                  <div className="input-group input-group">
                                    <div className="input-group-prepend">
                                      <span
                                        className={`input-group-text`}
                                        id="inputGroup-sizing-md"
                                        style={{ display: "flex", alignItems: "center" }}
                                      >
                                        <span className={styles.start}>To</span>
                                        <span
                                          style={{ color: "#00aeff", fontSize: "14px", margin: "0 8px" }}
                                          className={styles.divider}
                                        >
                                          |
                                        </span>
                                        <span className={styles.start}>$</span>
                                      </span>
                                    </div>
                                    <input
                                      type="number"
                                      value={pricePerSqftTo}
                                      className={clsx("form-control")}
                                      name="pricePerSqftTo"
                                      onChange={(e) => {
                                        let { value } = e.target;
                                        let fixed = value?.split(".")?.[1];
                                        if (fixed?.length > 2) {
                                          return;
                                        } else {
                                          setPricePerSqftTo(e.target.value);
                                        }
                                      }}
                                      onWheel={(e) => e.target.blur()}
                                      disabled={pricePerSqftAuto}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <div className={styles.checkDiv}>
                              <input
                                type="checkbox"
                                // className={clsx("form-control")}
                                id="priceSqftAuto"
                                name="priceSqftAuto"
                                onChange={(e) => setPricePerSqftAuto(e.target.checked)}
                                checked={pricePerSqftAuto}
                              />
                              <label htmlFor="priceSqftAuto" className="form-check-label">
                                Auto Update
                              </label>
                            </div>
                          </div>
                        </Col>
                        <Col sm={"12"}>
                          <Row>
                            <Col sm={"12"} md={"6"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p className={clsx(styles.heightPara)}>{`Strata/Maintenance (/sqft/month)`}</p>
                                <input
                                  type="number"
                                  className={clsx("form-control", styles.textField)}
                                  value={maintenance}
                                  name="maintenance"
                                  placeholder="Enter Strata /Maintenance"
                                  onChange={(e) => {
                                    let { value } = e.target;
                                    let fixed = value?.split(".")?.[1];
                                    if (fixed?.length > 2) {
                                      return;
                                    } else {
                                      setMaintenance(e.target.value);
                                    }
                                  }}
                                  onWheel={(e) => e.target.blur()}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm={"12"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p className={clsx(styles.heightPara)}>{`Full Assignment`}</p>
                            <Row>
                              <Col sm={assignment === "Allowed" ? "6" : "12"} md={assignment === "Allowed" ? "3" : "6"}>
                                <select
                                  className={clsx("arrows", styles.selectTag)}
                                  style={{
                                    padding: "7px 15px",
                                    border: "0.2px solid #dddddd",
                                  }}
                                  value={assignment}
                                  name="assignment"
                                  onChange={(e) => setAssignment(e.target.value)}
                                >
                                  <option value="">Select</option>
                                  <option value="Allowed">Allowed</option>
                                  <option value="Not Allowed">Not Allowed</option>
                                </select>
                              </Col>
                              {assignment === "Allowed" ? (
                                <Col sm={"6"} md={"3"}>
                                  <input
                                    placeholder={"Assignment Fee"}
                                    className={clsx("form-control", styles.textField)}
                                    value={assignmentFee}
                                    name="assignmentFee"
                                    onChange={(e) => setAssignmentFee(e.target.value)}
                                  />
                                </Col>
                              ) : null}
                            </Row>
                          </div>
                        </Col>
                        <Col sm={"12"}>
                          <Row>
                            <Col sm={"12"} md={"6"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p className={clsx(styles.heightPara)}>{`Deposit (Total)`}</p>
                                <input
                                  type="text"
                                  className={clsx("form-control", styles.textField)}
                                  value={depositTotal}
                                  name="depositTotal"
                                  onChange={(e) => setDepositTotal(e.target.value)}
                                  placeholder="Total deposit (% or $)"
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm={"12"}>
                          <Row>
                            <Col sm={"12"} md={"6"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p className={clsx(styles.heightPara)}>
                                  {`Deposit Details`} {/* &nbsp; */}
                                </p>
                                <JoditPreview
                                  initialValue={depositDetail}
                                  getValue={setDepositDetail}
                                  placeholder={"Enter deposit details (% or amount and their due date)"}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm={"12"}>
                          <SpecialIncentivesSection
                            specialIncentive={specialIncentive}
                            setSpecialIncentive={setSpecialIncentive}
                            incentiveDetail={incentiveDetail}
                            setIncentiveDetail={setIncentiveDetail}
                            incentiveStart={incentiveStart}
                            setIncentiveStart={setIncentiveStart}
                            incentiveEnd={incentiveEnd}
                            setIncentiveEnd={setIncentiveEnd}
                            incentiveArray={incentiveArray}
                            setIncentiveArray={setIncentiveArray}
                            incentStart={incentStart}
                            incentiveError={incentiveError}
                            setIncentiveError={setIncentiveError}
                          />
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>

                  <br />
                  <br />

                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Contact Sales Centre</BlockTitle>
                    </BlockHead>
                    <BlockContent>
                      <Row>
                        <Col sm={"6"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p className={clsx(styles.heightPara)}>Sale Centre Address </p>
                            <StandaloneSearchBox
                              onLoad={(ref) => setAddress(ref)}
                              onPlacesChanged={() => {
                                handlePlaceChanged();
                              }}
                            >
                              <input
                                type="text"
                                placeholder={"Enter sales centre address"}
                                className={clsx("form-control", styles.textField)}
                                name="saleCentreAddress"
                                value={saleCentreAddress?.firstLine}
                                onChange={(e) =>
                                  setSaleCentreAddress({
                                    ...saleCentreAddress,
                                    firstLine: e.target.value,
                                  })
                                }
                              />
                            </StandaloneSearchBox>
                          </div>
                        </Col>
                        <Col sm={"6"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p className={clsx(styles.heightPara)}>Primary Contact*</p>
                            <input
                              type="text"
                              ref={contactRef}
                              placeholder={"Sale centre contact name"}
                              className={clsx("form-control", styles.textField, mandContact ? "error" : "")}
                              value={primaryContact}
                              name="primaryContact"
                              onChange={(e) => {
                                setPrimaryContact(e.target.value);
                                if (e.target.value) {
                                  setMandContact(false);
                                }
                              }}
                            />
                          </div>
                        </Col>
                        <Col sm={"3"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p className={clsx(styles.heightPara)}>Email (Primary)* </p>
                            <input
                              type="text"
                              ref={emailRef}
                              placeholder={"Enter sale centre email"}
                              className={clsx("form-control", styles.textField, mandEmail ? "error" : "")}
                              value={email}
                              name="email"
                              onChange={(e) => {
                                setEmail(e.target.value);
                                if (e.target.value && !validator.isEmail(e.target.value)) {
                                  setErrorMessage("Invalid Email address");
                                } else {
                                  setErrorMessage(false);
                                }
                                if (e.target.value) {
                                  setMandEmail(false);
                                }
                              }}
                            />
                            <label
                              htmlFor=""
                              style={{
                                color: "tomato",
                                fontSize: "14px",
                                padding: "2px",
                              }}
                            >
                              {errorMessage ? errorMessage : ""}
                            </label>
                          </div>
                        </Col>
                        <Col sm={"3"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p className={clsx(styles.heightPara)}>Email (Secondary)</p>
                            <input
                              type="text"
                              placeholder={"Enter sale centre email"}
                              className={clsx("form-control", styles.textField)}
                              value={email2}
                              name="email2"
                              onChange={(e) => setEmail2(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col sm={"3"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p className={clsx(styles.heightPara)}>Phone (Primary)* </p>
                            <PhoneInput
                              onlyCountries={["ca"]}
                              // countryCodeEditable={false}
                              country={"ca"}
                              value={primaryPhone}
                              onChange={(phone, data, event, formattedValue) => {
                                setPrimaryPhone(formattedValue);
                                if (formattedValue) setMandMobile(false);
                              }}
                              inputStyle={{ width: "100%" }}
                              containerClass={mandMobile ? "error" : ""}
                            />
                          </div>
                        </Col>
                        <Col sm={"3"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p className={clsx(styles.heightPara)}>Phone (Secondary) </p>
                            <PhoneInput
                              onlyCountries={["ca"]}
                              // countryCodeEditable={false}
                              country={"ca"}
                              value={secondaryPhone}
                              onChange={(phone, data, event, formattedValue) => setSecondaryPhone(formattedValue)}
                              inputStyle={{ width: "100%" }}
                            />
                          </div>
                        </Col>
                        <Col sm={"3"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p className={clsx(styles.heightPara)}>Website Name</p>
                            <input
                              type="text"
                              placeholder={"Enter website name"}
                              className={clsx("form-control", styles.textField)}
                              value={websiteName}
                              name="websiteName"
                              onChange={(e) => setWebsiteName(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col sm={"3"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p className={clsx(styles.heightPara)}>Website URL</p>
                            <input
                              type="text"
                              placeholder={"http:"}
                              className={clsx("form-control", styles.textField)}
                              value={websiteURL}
                              name="websiteURL"
                              onChange={(e) => setWebsiteURL(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col sm={"6"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p className={clsx(styles.heightPara)}>
                              Development Logo (200pm max size - png or jpeg only)
                            </p>
                            {developmentLogo ? (
                              <div className={clsx("form-control", styles.uploadInput, styles.closeDiv)}>
                                <p>{developmentLogo?.split("/")?.[developmentLogo?.split("/")?.length - 1]}</p>
                                <AiOutlineCloseCircle onClick={handleDeleteLogo} />
                              </div>
                            ) : (
                              <div {...getRootProps()} className={clsx("form-control", styles.uploadInput)}>
                                <p>
                                  <span style={{ color: "#a0a0a0" }}>Upload Logo</span>
                                </p>
                                <input type="text" {...getInputProps()} />
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className={clsx("mt-4", styles.timelines)}>
                        <Col sm={"12"} md={"12"} lg={"6"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p className={clsx(styles.heightPara)}>Sales Centre Hours</p>
                            <Row>
                              <Col sm={"12"}>
                                <div className="preview-block" style={{ margin: "10px 0 0 10px" }}>
                                  <div className="custom-control custom-radio">
                                    <input
                                      type="radio"
                                      id="comingSoon"
                                      value="comingSoon"
                                      disabled={devData?.saleStatus?.saleStatus === "63dce41caea28f56b0aa6fa7"}
                                      name="salesHour"
                                      onChange={(e) => setSalesHour(e.target.value)}
                                      className="custom-control-input form-control"
                                    />
                                    <label className="custom-control-label" htmlFor="comingSoon">
                                      Coming Soon!
                                    </label>
                                  </div>
                                </div>
                              </Col>
                              <Col sm={"12"}>
                                <div className="preview-block" style={{ margin: "10px 0 0 10px" }}>
                                  <div className="custom-control custom-radio">
                                    <input
                                      type="radio"
                                      id="closedTemporarily"
                                      value="closedTemporarily"
                                      disabled={devData?.saleStatus?.saleStatus === "63dce41caea28f56b0aa6fa7"}
                                      name="salesHour"
                                      onChange={(e) => setSalesHour(e.target.value)}
                                      className="custom-control-input form-control"
                                    />
                                    <label className="custom-control-label" htmlFor="closedTemporarily">
                                      Closed Temporarily!
                                    </label>
                                  </div>
                                </div>
                              </Col>
                              <Col sm={"12"}>
                                <div className="preview-block" style={{ margin: "10px 0 0 10px" }}>
                                  <div className="custom-control custom-radio">
                                    <input
                                      type="radio"
                                      id="closedPermanently"
                                      value="closedPermanently"
                                      // disabled={devData?.saleStatus?.saleStatus === "63dce41caea28f56b0aa6fa7"}
                                      name="salesHour"
                                      onChange={(e) => setSalesHour(e.target.value)}
                                      className="custom-control-input form-control"
                                    />
                                    <label className="custom-control-label" htmlFor="closedPermanently">
                                      Closed Permanently!
                                    </label>
                                  </div>
                                </div>
                              </Col>
                              <Col sm={"12"}>
                                <div className="preview-block" style={{ margin: "10px 0 0 10px" }}>
                                  <div className="custom-control custom-radio">
                                    <input
                                      type="radio"
                                      id="appointment"
                                      value="appointment"
                                      disabled={devData?.saleStatus?.saleStatus === "63dce41caea28f56b0aa6fa7"}
                                      name="salesHour"
                                      onChange={(e) => setSalesHour(e.target.value)}
                                      className="custom-control-input form-control"
                                    />
                                    <label className="custom-control-label" htmlFor="appointment">
                                      Opening Hours
                                    </label>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        {salesHour === "appointment" ? (
                          <Col sm={"12"} md={"12"} lg={"6"}>
                            <Row>
                              <Col sm={"6"} md={"2"}>
                                <div className={clsx(styles.askingPriceDiv)}>
                                  <select
                                    className={clsx("arrows", styles.selectTag)}
                                    style={{ padding: "7px 7px", border: "0.2px solid #dddddd" }}
                                    value={daysFrom}
                                    name="daysFrom"
                                    onChange={(e) => setDaysFrom(e.target.value)}
                                  >
                                    <option value="">From</option>
                                    {moment.weekdays()?.map((li, i) => (
                                      <option key={i} value={li}>
                                        {li}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </Col>
                              <Col sm={"6"} md={"2"}>
                                <div className={clsx(styles.askingPriceDiv)}>
                                  <select
                                    className={clsx(styles.selectTag)}
                                    style={{ padding: "7px 7px", border: "0.2px solid #dddddd" }}
                                    value={daysTo}
                                    name="daysTo"
                                    onChange={(e) => setDaysTo(e.target.value)}
                                  >
                                    <option value="">To</option>
                                    {moment.weekdays()?.map((li, i) => (
                                      <option key={i} value={li}>
                                        {li}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </Col>
                              <Col sm={"12"} md={"1"}></Col>
                              <Col sm={"6"} md={"2"}>
                                <div className={clsx(styles.askingPriceDiv)}>
                                  <select
                                    className={clsx(styles.selectTag)}
                                    style={{ padding: "7px 7px", border: "0.2px solid #dddddd" }}
                                    value={timeFrom}
                                    name="timeFrom"
                                    onChange={(e) => setTimeFrom(e.target.value)}
                                  >
                                    <option value="">From</option>
                                    {timeSlot?.map((li, i) => (
                                      <option key={i} value={li}>
                                        {li}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </Col>
                              <Col sm={"6"} md={"2"}>
                                <div className={clsx(styles.askingPriceDiv)}>
                                  <select
                                    className={clsx(
                                      // "arrows",
                                      styles.selectTag
                                    )}
                                    style={{
                                      padding: "7px 7px",
                                      border: "0.2px solid #dddddd",
                                    }}
                                    value={timeTo}
                                    name="timeTo"
                                    onChange={(e) => setTimeTo(e.target.value)}
                                  >
                                    <option value="from">To</option>
                                    {timeSlot?.map((li, i) => (
                                      <option key={i} value={li}>
                                        {li}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </Col>
                              <Col sm={"6"} md={"2"}>
                                <div className={clsx(styles.askingPriceDiv)}>
                                  <Button
                                    color={"info"}
                                    fullWidth
                                    style={{ background: "#00aeff" }}
                                    onClick={() => {
                                      setSalesOpenArray([
                                        ...salesOpenArray,
                                        {
                                          days: { start: daysFrom, end: daysTo },
                                          hours: { start: timeFrom, end: timeTo },
                                        },
                                      ]);
                                      setDaysFrom("");
                                      setDaysTo("");
                                      setTimeFrom("");
                                      setTimeTo("");
                                    }}
                                  >
                                    Add
                                  </Button>
                                </div>
                              </Col>
                              <Col sm={"12"} md={"12"}>
                                <div className={styles.saleTimeArrDiv}>
                                  {salesOpenArray?.length
                                    ? salesOpenArray?.map((li, i) => (
                                        <div
                                          key={i}
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          <AiOutlineCloseCircle
                                            style={{
                                              color: "#00aeff",
                                              fontSize: "18px",
                                              marginRight: "3px",
                                              marginTop: "-2px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              let a = salesOpenArray?.indexOf(li);

                                              if (a > -1) {
                                                salesOpenArray?.splice(a, 1);
                                                setSalesOpenArray([...salesOpenArray]);
                                              }
                                            }}
                                          />
                                          {`${li?.days?.start} - ${li?.days?.end}: ${li?.hours?.start} - ${li?.hours?.end}`}
                                        </div>
                                      ))
                                    : null}
                                </div>
                              </Col>
                              <Col sm={"12"} md={"12"} className={clsx(styles.checkDiv)}>
                                <input
                                  type="checkbox"
                                  id="appointment"
                                  name="appointment"
                                  value={appointment}
                                  checked={appointment}
                                  onChange={(e) => setAppointment(e.target.checked)}
                                />
                                <label
                                  style={{
                                    fontSize: "14px",
                                    marginLeft: "5px",
                                  }}
                                  htmlFor="appointment"
                                  className="form-check-label"
                                >
                                  By Appointment
                                </label>
                              </Col>
                            </Row>
                          </Col>
                        ) : null}
                      </Row>
                    </BlockContent>
                  </Block>

                  <br />
                  <br />

                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Social Account</BlockTitle>
                    </BlockHead>
                    <BlockContent>
                      <Row>
                        <Col sm={"6"} md={"5"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p className={clsx(styles.heightPara)}>Social Name</p>
                            <select
                              className={clsx("form-control", styles.selectTag)}
                              style={{
                                padding: "8px 15px",
                                border: "0.2px solid #dddddd",
                              }}
                              value={socialName}
                              name="socialName"
                              onChange={(e) => setSocialName(e.target.value)}
                            >
                              <option value="">Select</option>
                              {array?.map((li, i) =>
                                !socialArray?.map((list) => list?.label)?.includes(li?.label) ? (
                                  <option key={li?.id} value={li?.value}>
                                    {li?.label}
                                  </option>
                                ) : null
                              )}
                            </select>
                          </div>
                        </Col>
                        <Col sm={"6"} md={"5"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p className={clsx(styles.heightPara)}>Social URL</p>
                            <input
                              type="text"
                              placeholder={"http:"}
                              className={clsx("form-control")}
                              style={{
                                padding: "8px 15px",
                                border: "0.2px solid #dddddd",
                              }}
                              value={socialURL}
                              name="socialURL"
                              onChange={(e) => setSocialURL(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col sm={"6"} md={"2"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p className={clsx(styles.heightPara)}>&nbsp;</p>
                            <Button
                              color={"info"}
                              onClick={() => {
                                if (socialName && socialURL) {
                                  setSocialArray([...socialArray, { label: socialName, url: socialURL }]);
                                  setSocialName("");
                                  setSocialURL("");
                                }
                              }}
                            >
                              Add
                            </Button>
                          </div>
                        </Col>
                        {socialArray?.length ? (
                          <div className={clsx(styles.arrayDetailDiv)}>
                            {socialArray?.map((li, i) => (
                              <p key={i} className={clsx(styles.socialDiv)}>
                                <AiOutlineCloseCircle
                                  onClick={() => {
                                    let a = socialArray?.indexOf(li);
                                    if (a > -1) {
                                      socialArray?.splice(a, 1);
                                      setSocialArray([...socialArray]);
                                    }
                                  }}
                                />
                                <span className={styles.socialName}>{li?.label}:</span>
                                <a href={li?.url} target="_blank" rel="noreferrer" className={styles.socialUrl}>
                                  {li?.url}
                                </a>
                              </p>
                            ))}
                          </div>
                        ) : null}
                      </Row>
                    </BlockContent>
                  </Block>
                </div>
              </div>
            </div>
          </Card>

          <div style={{ margin: "15px 0", display: "flex", gap: "1rem" }}>
            <FooterBtn text={"Cancel"} color={"secondary"} outline onClick={handleBack} />
            <FooterBtn text={"Next"} color={"info"} onClick={handleNext} />
          </div>
        </Block>
      </Content>
    </>
  );
};

export default Dev6;
