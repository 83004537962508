import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FiUpload } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { Card, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Content from "../../layout/content/Content";
import DevNavTab from "../../pages/Development/DevNavTab";
import JoditPreview from "../../pages/components/forms/rich-editor/JoditPreview";
import { getAmenities } from "../../redux/Apislices/amenitySlice";
import { deleteFile, uploadFile } from "../../redux/Apislices/awsSlice";
import { getcity } from "../../redux/Apislices/citySlice";
import {
  addNewDevelopment,
  editDevelopment,
  getDevelopmentById,
  getDevelopmentBySlug,
  handleAddDevelopment,
  handleDevAddReset,
} from "../../redux/Apislices/developmentSlice";
import { getHomeType } from "../../redux/Apislices/homeTypeSlice";
import { getUnitFeatures } from "../../redux/Apislices/unitFeaturesSlice";
import {
  Block,
  BlockBetween,
  BlockContent,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Row,
} from "../Component";
import styles from "./dev.module.scss";
import FooterBtn from "../common/FooterBtn";
const Dev4 = ({ toggleNext, toggleBack, toggleIconTab, activeIconTab }) => {
  const { type, id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    amenity: amenityObj,
    unitFeatures: unitFeaturesObj,
    development,
    city,
    homeType: homeTypeData,
    profile,
  } = useSelector((state) => state);

  const { developmentById, addDevelopment } = development;
  const { amenity: amenityData } = amenityObj;
  const { unitFeatures: unitFeaturesData } = unitFeaturesObj;

  const [devData, setDevData] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");

  const [amenities, setAmenities] = useState("");
  const [amenitiesArray, setAmenitiesArray] = useState([]);
  const [commonFeatures, setCommonFeatures] = useState("");
  const [commonFeaturesArray, setCommonFeaturesArray] = useState([]);

  const [homeWarranty, setHomeWarranty] = useState("2-5-10 year home warranty");

  const [mediaData, setMediaData] = useState("");
  const [mediaName, setMediaName] = useState("");
  const mediaRef = useRef();

  const [mediaArray, setMediaArray] = useState([]);

  const [isEditable, setIsEditable] = useState("");
  const [edit, setEdit] = useState("");

  const [featureSheet, setFeatureSheet] = useState("");

  const [parkingIncluded, setParkingIncluded] = useState({
    title: "PARKING INCLUDED",
    value: "",
  });
  const [additionalParking, setAdditionalParking] = useState({
    title: "ADDITIONAL PARKING",
    value: "",
  });
  const [storageIncluded, setStorageIncluded] = useState({
    title: "STORAGE INCLUDED",
    value: "",
  });
  const [additionalStorage, setAdditionalStorage] = useState({
    title: "ADDITIONAL STORAGE",
    value: "",
  });
  const [petsRestrictions, setPetsRestrictions] = useState({
    title: "PETS RESTRICTIONS",
    value: "",
  });
  const [rentalRestrictions, setRentalRestrictions] = useState({
    title: "RENTAL RESTRICTIONS",
    value: "Allowed",
  });
  const [ageRestrictions, setAgeRestrictions] = useState({
    title: "AGE RESTRICTIONS",
    value: "",
  });

  const [smokingRestrictions, setSmokingRestrictions] = useState({
    title: "SMOKING RESTRICTIONS",
    value: "",
  });
  const [ceilingHeightFrom, setCeilingHeightFrom] = useState({
    title: "CEILING HEIGHT FROM",
    value: "",
  });
  const [ceilingHeigthTo, setCeilingHeigthTo] = useState({
    title: "CEILING HEIGHT TO",
    value: "",
  });
  const [ceilingRange, setCeilingRange] = useState(false);
  const [bikeLocker, setBikeLocker] = useState({
    title: "BIKE LOCKERS INCLUDED",
    value: "",
  });
  const [otherDetailsTitle, setOtherDetailsTitle] = useState("");
  const [otherDetailsValue, setOtherDetailsValue] = useState("");
  const [otherDetailsArray, setOtherDetailsArray] = useState([]);

  const uploadImage = async (file) => {
    let toastId = toast.loading("Uploading");
    setTimeout(() => {
      toast.dismiss(toastId);
      // setTrigger(true);
    }, 10000);
    dispatch(
      uploadFile(
        file,
        (res) => {
          if (type === "edit") {
            let additionalData = {};
            if (parkingIncluded?.value) {
              additionalData = { ...additionalData, parkingIncluded };
            }
            if (additionalParking?.value) {
              additionalData = { ...additionalData, additionalParking };
            }
            if (storageIncluded?.value) {
              additionalData = { ...additionalData, storageIncluded };
            }
            if (additionalStorage?.value) {
              additionalData = { ...additionalData, additionalStorage };
            }
            if (petsRestrictions?.value) {
              additionalData = { ...additionalData, petsRestrictions };
            }
            if (rentalRestrictions?.value) {
              additionalData = { ...additionalData, rentalRestrictions };
            }
            if (ageRestrictions?.value) {
              additionalData = { ...additionalData, ageRestrictions };
            }
            if (smokingRestrictions?.value) {
              additionalData = { ...additionalData, smokingRestrictions };
            }
            if (ceilingHeightFrom?.value) {
              additionalData = { ...additionalData, ceilingHeightFrom };
            }
            if (ceilingHeigthTo?.value) {
              additionalData = { ...additionalData, ceilingHeigthTo };
            }
            if (bikeLocker?.value) {
              additionalData = { ...additionalData, bikeLocker };
            }
            let data = {
              ...devData,
              features: featureSheet,
              amenities: amenitiesArray,
              unitFeatures: commonFeaturesArray,
              informationFolder: [
                ...devData?.informationFolder,
                ...res?.data?.map((li) => ({
                  label: mediaName,
                  fileUrl: li?.url,
                })),
              ],
            };
            if (additionalData) {
              data = {
                ...data,
                additionalInformation: [
                  ...Object.entries(additionalData)?.map(([key, value]) => {
                    return value;
                  }),
                  {
                    title: "HOME WARRANTY",
                    value: homeWarranty,
                  },
                  ...otherDetailsArray,
                ]?.map((li) => {
                  return {
                    label: li?.title,
                    description: li?.value,
                  };
                }),
              };
            }
            data.type = "presale";
            console.log("data: ", data);
            dispatch(
              editDevelopment(
                id,
                data,
                (res) => {
                  dispatch(getDevelopmentBySlug(res?.data?.slug));
                  // dispatch(handleDevAddReset());
                  toast.dismiss(toastId);
                  toast.success("File(s) Uploaded");
                  setMediaData("");
                  setMediaName("");
                },
                (res) => {
                  toast.error(res?.message);
                }
              )
            );
          } else {
            if (addDevelopment?.["dev-info"]?.length) {
              let image = addDevelopment?.["dev-info"];
              image = [
                ...image,
                ...res?.data?.map((li, i) => ({
                  editId: `${i}${Math.random()}`,
                  label: mediaName,
                  fileUrl: li?.url,
                })),
              ];
              dispatch(
                handleAddDevelopment({
                  ...addDevelopment,
                  "dev-info": image,
                })
              );
            } else {
              dispatch(
                handleAddDevelopment({
                  ...addDevelopment,
                  "dev-info": res?.data?.map((li, i) => ({
                    editId: `${i}${Math.random()}`,
                    label: mediaName,
                    fileUrl: li?.url,
                  })),
                })
              );
            }
            if (addDevelopment?.["dev-info"]?.length) {
              setMediaArray(addDevelopment?.["dev-info"]);
            }
            toast.dismiss(toastId);
            toast.success("File(s) Uploaded");
            setMediaData("");
            setMediaName("");
          }
        },
        () => {
          toast.dismiss(toastId);
          toast.error("Something Went Wrong! Try Again");
        }
      )
    );
  };

  const handleDelete = async (path) => {
    const toastId = toast.loading("Deleting...");

    dispatch(
      deleteFile(
        { path },
        (res) => {
          if (type === "edit") {
            let additionalData = {};
            if (parkingIncluded?.value) {
              additionalData = { ...additionalData, parkingIncluded };
            }
            if (additionalParking?.value) {
              additionalData = { ...additionalData, additionalParking };
            }
            if (storageIncluded?.value) {
              additionalData = { ...additionalData, storageIncluded };
            }
            if (additionalStorage?.value) {
              additionalData = { ...additionalData, additionalStorage };
            }
            if (petsRestrictions?.value) {
              additionalData = { ...additionalData, petsRestrictions };
            }
            if (rentalRestrictions?.value) {
              additionalData = { ...additionalData, rentalRestrictions };
            }
            if (ageRestrictions?.value) {
              additionalData = { ...additionalData, ageRestrictions };
            }
            if (smokingRestrictions?.value) {
              additionalData = { ...additionalData, smokingRestrictions };
            }
            if (ceilingHeightFrom?.value) {
              additionalData = { ...additionalData, ceilingHeightFrom };
            }
            if (ceilingHeigthTo?.value) {
              additionalData = { ...additionalData, ceilingHeigthTo };
            }
            if (bikeLocker?.value) {
              additionalData = { ...additionalData, bikeLocker };
            }
            let data = {
              ...devData,
              features: featureSheet,
              amenities: amenitiesArray,
              unitFeatures: commonFeaturesArray,
              informationFolder: devData?.informationFolder?.filter((li) => li?.fileUrl !== path),
              type: "presale",
            };
            if (additionalData) {
              data = {
                ...data,
                additionalInformation: [
                  ...Object.entries(additionalData)?.map(([key, value]) => {
                    return value;
                  }),
                  {
                    title: "HOME WARRANTY",
                    value: homeWarranty,
                  },
                  ...otherDetailsArray,
                ]?.map((li) => {
                  return {
                    label: li?.title,
                    description: li?.value,
                  };
                }),
              };
            }
            console.log("data: ", data);
            dispatch(
              editDevelopment(
                id,
                data,
                (res) => {
                  dispatch(getDevelopmentBySlug(id));
                  // dispatch(handleDevAddReset());
                  toast.dismiss(toastId);
                  toast.success("File Deleted");
                },
                (res) => {
                  toast.error(res?.message);
                }
              )
            );
          } else {
            let a = mediaArray.findIndex((li) => li?.fileUrl === path);
            if (a !== -1) {
              mediaArray.splice(a, 1);
              setMediaArray([...mediaArray]);
              dispatch(
                handleAddDevelopment({
                  ...addDevelopment,
                  "dev-info": mediaArray,
                })
              );
            }
            toast.dismiss(toastId);
            toast.success("File Deleted");
          }
        },
        (res) => {
          toast.dismiss(toastId);
          toast.error(res?.message);
        }
      )
    );
  };

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            toast.error("File is larger than 5 MB");
          }
          if (err.code === "file-invalid-type") {
            toast.error(err.message);
          }
        });
      });

      setMediaData(acceptedFiles?.[0]);
      setMediaName(acceptedFiles?.[0]?.name);
      if (acceptedFiles?.[0]) {
        setTimeout(() => {
          mediaRef.current.focus();
        }, 500);
      }
    },
    [devData]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      "application/pdf": [".pdf"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    },
    maxSize: 5242880,
  });

  const handleAddUpdate = (CB) => {
    let additionalData = {};
    if (parkingIncluded?.value) {
      additionalData = { ...additionalData, parkingIncluded };
    }
    if (additionalParking?.value) {
      additionalData = { ...additionalData, additionalParking };
    }
    if (storageIncluded?.value) {
      additionalData = { ...additionalData, storageIncluded };
    }
    if (additionalStorage?.value) {
      additionalData = { ...additionalData, additionalStorage };
    }
    if (petsRestrictions?.value) {
      additionalData = { ...additionalData, petsRestrictions };
    }
    if (rentalRestrictions?.value) {
      additionalData = { ...additionalData, rentalRestrictions };
    }
    if (ageRestrictions?.value) {
      additionalData = { ...additionalData, ageRestrictions };
    }
    if (smokingRestrictions?.value) {
      additionalData = { ...additionalData, smokingRestrictions };
    }
    if (ceilingHeightFrom?.value) {
      additionalData = { ...additionalData, ceilingHeightFrom };
    }
    if (ceilingHeigthTo?.value) {
      additionalData = { ...additionalData, ceilingHeigthTo };
    }
    if (bikeLocker?.value) {
      additionalData = { ...additionalData, bikeLocker };
    }

    let data = {
      ...devData,
      features: featureSheet,
      amenities: amenitiesArray,
      unitFeatures: commonFeaturesArray,
      informationFolder: mediaArray,
      type: "presale",
    };
    if (additionalData) {
      data = {
        ...data,
        additionalInformation: [
          ...Object.entries(additionalData)?.map(([key, value]) => {
            return value;
          }),
          { title: "HOME WARRANTY", value: homeWarranty },
          ...otherDetailsArray,
        ]?.map((li) => {
          return {
            label: li?.title,
            description: li?.value,
          };
        }),
      };
    }

    if (type === "edit") {
      data.status = devData?.status;
    } else {
      data.status = "pending";
    }

    if (type === "edit") {
      dispatch(
        editDevelopment(
          id,
          data,
          (res) => {
            dispatch(handleDevAddReset());
            toast.success("Development updated");
            CB({ success: true });
          },
          (res) => {
            toast.error(res?.message);
          }
        )
      );
    } else {
      dispatch(handleAddDevelopment({ ...addDevelopment, data, "dev-info": null }));
      CB({ success: true });
    }
  };

  const handleNext = () => {
    handleAddUpdate((res) => {
      if (res?.success) {
        toggleNext();
      }
    });
  };

  const handleBack = () => {
    toggleBack();
  };

  const addToDraft = () => {
    let additionalData = {};
    if (parkingIncluded?.value) {
      additionalData = { ...additionalData, parkingIncluded };
    }
    if (additionalParking?.value) {
      additionalData = { ...additionalData, additionalParking };
    }
    if (storageIncluded?.value) {
      additionalData = { ...additionalData, storageIncluded };
    }
    if (additionalStorage?.value) {
      additionalData = { ...additionalData, additionalStorage };
    }
    if (petsRestrictions?.value) {
      additionalData = { ...additionalData, petsRestrictions };
    }
    if (rentalRestrictions?.value) {
      additionalData = { ...additionalData, rentalRestrictions };
    }
    if (ageRestrictions?.value) {
      additionalData = { ...additionalData, ageRestrictions };
    }
    if (smokingRestrictions?.value) {
      additionalData = { ...additionalData, smokingRestrictions };
    }
    if (ceilingHeightFrom?.value) {
      additionalData = { ...additionalData, ceilingHeightFrom };
    }
    if (ceilingHeigthTo?.value) {
      additionalData = { ...additionalData, ceilingHeigthTo };
    }
    if (bikeLocker?.value) {
      additionalData = { ...additionalData, bikeLocker };
    }

    let data = {
      ...devData,
      features: featureSheet,
      amenities: amenitiesArray,
      unitFeatures: commonFeaturesArray,
      informationFolder: mediaArray,
    };

    if (additionalData) {
      data = {
        ...data,
        additionalInformation: [
          ...Object.entries(additionalData)?.map(([key, value]) => {
            return value;
          }),
          {
            title: "HOME WARRANTY",
            value: homeWarranty,
          },
          ...otherDetailsArray,
        ]?.map((li) => {
          return {
            label: li?.title,
            description: li?.value,
          };
        }),
      };
    }

    data.type = "presale";
    if (type === "edit") {
      data.status = devData?.status;
    } else {
      data.status = "draft";
    }

    if (profile?.profile?.role === "associate") {
      data = {
        ...data,
        company: {
          ...data?.company,
          associate: {
            associate: profile?.profile?._id,
            byAssociate: true,
          },
        },
      };
    }

    if (type === "edit") {
      dispatch(
        editDevelopment(
          id,
          data,
          (res) => {
            toast.success("Development updated");
            dispatch(handleDevAddReset());
            navigate("/development");
          },
          (res) => {
            toast.error(res?.message);
          }
        )
      );
    } else {
      dispatch(
        addNewDevelopment(
          data,
          (res) => {
            dispatch(handleDevAddReset());
            toast.success("Development added as draft");
            navigate("/development");
          },
          (res) => {
            toast.error(res?.message);
          }
        )
      );
    }
  };

  useEffect(() => {
    dispatch(getAmenities());
    dispatch(getUnitFeatures());
    dispatch(getcity());
    dispatch(getHomeType());
  }, []);

  useEffect(() => {
    if (developmentById?.data) setDevData(developmentById?.data);
    else if (addDevelopment?.data) setDevData(addDevelopment?.data);
  }, [developmentById?.data, addDevelopment?.data]);

  useEffect(() => {
    if (devData) {
      console.log("devData4: ", devData);
      if (devData?.amenities?.length) setAmenitiesArray([...devData?.amenities] || amenitiesArray);
      if (devData?.unitFeatures?.length) setCommonFeaturesArray([...devData?.unitFeatures] || commonFeaturesArray);
      if (devData?.informationFolder?.length)
        dispatch(handleAddDevelopment({ ...addDevelopment, "dev-info": devData?.informationFolder }));
      setFeatureSheet(devData?.features);
      setParkingIncluded({
        ...parkingIncluded,
        value: devData?.additionalInformation?.filter((li) => li?.label === "PARKING INCLUDED")?.[0]?.description,
      });
      setAdditionalParking({
        ...additionalParking,
        value: devData?.additionalInformation?.filter((li) => li?.label === "ADDITIONAL PARKING")?.[0]?.description,
      });
      setStorageIncluded({
        ...storageIncluded,
        value: devData?.additionalInformation?.filter((li) => li?.label === "STORAGE INCLUDED")?.[0]?.description,
      });
      setAdditionalStorage({
        ...additionalStorage,
        value: devData?.additionalInformation?.filter((li) => li?.label === "ADDITIONAL STORAGE")?.[0]?.description,
      });
      setPetsRestrictions({
        ...petsRestrictions,
        value: devData?.additionalInformation?.filter((li) => li?.label === "PETS RESTRICTIONS")?.[0]?.description,
      });
      setRentalRestrictions({
        ...rentalRestrictions,
        value: devData?.additionalInformation?.filter((li) => li?.label === "RENTAL RESTRICTIONS")?.[0]?.description,
      });
      setAgeRestrictions({
        ...ageRestrictions,
        value: devData?.additionalInformation?.filter((li) => li?.label === "AGE RESTRICTIONS")?.[0]?.description,
      });
      setSmokingRestrictions({
        ...smokingRestrictions,
        value: devData?.additionalInformation?.filter((li) => li?.label === "SMOKING RESTRICTIONS")?.[0]?.description,
      });
      setCeilingHeightFrom({
        ...ceilingHeightFrom,
        value: devData?.additionalInformation?.filter((li) => li?.label === "CEILING HEIGHT FROM")?.[0]?.description,
      });
      setCeilingHeigthTo({
        ...ceilingHeigthTo,
        value: devData?.additionalInformation?.filter((li) => li?.label === "CEILING HEIGHT TO")?.[0]?.description,
      });
      if (ceilingHeigthTo?.value) setCeilingRange(true);
      else setCeilingRange(false);
      setBikeLocker({
        ...bikeLocker,
        value: devData?.additionalInformation?.filter((li) => li?.label === "BIKE LOCKERS INCLUDED")?.[0]?.description,
      });
      if (devData?.additionalInformation?.find((li) => li?.label === "HOME WARRANTY")) {
        setHomeWarranty(devData?.additionalInformation?.find((li) => li?.label === "HOME WARRANTY")?.description);
      }
      if (devData?.additionalInformation?.length)
        setOtherDetailsArray(
          [
            ...devData?.additionalInformation
              ?.filter(
                (li) =>
                  ![
                    "PARKING INCLUDED",
                    "ADDITIONAL PARKING",
                    "STORAGE INCLUDED",
                    "ADDITIONAL STORAGE",
                    "PETS RESTRICTIONS",
                    "RENTAL RESTRICTIONS",
                    "AGE RESTRICTIONS",
                    "SMOKING RESTRICTIONS",
                    "CEILING HEIGHT FROM",
                    "CEILING HEIGHT TO",
                    "BIKE LOCKERS INCLUDED",
                    "HOME WARRANTY",
                  ]?.includes(li?.label)
              )
              ?.map((li) => ({
                title: li?.label,
                value: li?.description,
              })),
          ] || otherDetailsArray
        );
    }
  }, [devData]);

  useEffect(() => {
    if (addDevelopment?.["dev-info"]?.length) setMediaArray(addDevelopment?.["dev-info"]);
  }, [addDevelopment]);

  return (
    <Content>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              {devData?.name ? devData?.name : type === "add" ? "Add Development" : "Edit Development"}
            </BlockTitle>
          </BlockHeadContent>

          <BlockHeadContent>
            <Button
              color="light"
              outline
              className="bg-white d-inline-flex"
              onClick={(ev) => {
                ev.preventDefault();
                addToDraft();
              }}
            >
              <span>{type === "edit" ? "Update Development" : "Save as Draft"}</span>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <Card className="card-bordered">
          <div className="card-aside-wrap" id="user-detail-block">
            <div className="card-content">
              <DevNavTab toggleIconTab={toggleIconTab} activeIconTab={activeIconTab} update={handleAddUpdate} />

              <div className="card-inner">
                <Block>
                  <BlockHead>
                    <BlockTitle tag="h4">Amenities</BlockTitle>
                  </BlockHead>
                  <BlockContent>
                    <Row>
                      <Col sm={"12"} md={"12"} lg={"6"}>
                        <div className={clsx(styles.askingPriceDiv)}>
                          <p className={clsx(styles.heightPara)}>Building Amenities </p>
                          <Select
                            options={
                              amenityData?.length
                                ? amenityData?.map((item) =>
                                    !amenitiesArray?.includes(item?._id)
                                      ? {
                                          label: item?.name,
                                          value: item?._id,
                                        }
                                      : {
                                          label: item?.name,
                                          value: item?._id,
                                          isDisabled: true,
                                        }
                                  )
                                : []
                            }
                            onInputChange={(e) => {
                              setInputValue(e);
                            }}
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            openMenuOnFocus={false}
                            openMenuOnClick={false}
                            menuIsOpen={inputValue}
                            className="searchSelect"
                            value={amenities}
                            id="amenities"
                            onChange={(value) => {
                              setAmenitiesArray([...amenitiesArray, value.value]);
                              setAmenities("");
                              document.getElementById("amenities").focus();
                            }}
                          />
                          <div className={styles.arrayItemDiv}>
                            {amenitiesArray?.length
                              ? amenitiesArray?.map((item, index) => {
                                  return (
                                    <p
                                      key={`${Math.random()}-${index}`}
                                      className={styles.para2}
                                      onClick={() => {
                                        let a = amenitiesArray?.indexOf(item);
                                        if (a > -1) {
                                          amenitiesArray?.splice(a, 1);
                                          setAmenitiesArray([...amenitiesArray]);
                                        }
                                      }}
                                    >
                                      <AiOutlineCloseCircle className={styles.arrayCloseIcon} />
                                      <span>{amenityData?.filter((li) => li?._id === item)?.[0]?.name}</span>
                                    </p>
                                  );
                                })
                              : null}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={"12"} md={"12"} lg={"6"}>
                        <div className={clsx(styles.askingPriceDiv)}>
                          <p className={clsx(styles.heightPara)}>Common Unit Features</p>
                          <Select
                            options={
                              unitFeaturesData?.length
                                ? unitFeaturesData?.map((item) =>
                                    !commonFeaturesArray?.includes(item?._id)
                                      ? {
                                          label: item?.name,
                                          value: item?._id,
                                        }
                                      : {
                                          label: item?.name,
                                          value: item?._id,
                                          isDisabled: true,
                                        }
                                  )
                                : []
                            }
                            onInputChange={(e) => {
                              setInputValue2(e);
                            }}
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            openMenuOnFocus={false}
                            openMenuOnClick={false}
                            menuIsOpen={inputValue2}
                            className="searchSelect"
                            value={commonFeatures}
                            onChange={(value) => {
                              setCommonFeaturesArray([...commonFeaturesArray, value.value]);
                              setCommonFeatures("");
                            }}
                          />
                          <div className={styles.arrayItemDiv}>
                            {commonFeaturesArray?.length
                              ? commonFeaturesArray?.map((item, index) => {
                                  return (
                                    <p
                                      key={`${Math.random()}-${index}`}
                                      className={styles.para2}
                                      onClick={() => {
                                        let a = commonFeaturesArray?.indexOf(item);
                                        if (a > -1) {
                                          commonFeaturesArray?.splice(a, 1);
                                          setCommonFeaturesArray([...commonFeaturesArray]);
                                        }
                                      }}
                                    >
                                      <AiOutlineCloseCircle className={styles.arrayCloseIcon} />
                                      <span>{unitFeaturesData?.filter((li) => li?._id === item)?.[0]?.name}</span>
                                    </p>
                                  );
                                })
                              : null}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </BlockContent>
                </Block>

                <br />
                <br />

                <Block>
                  <BlockHead>
                    <BlockTitle tag="h4">Information Folder</BlockTitle>
                  </BlockHead>{" "}
                  <BlockContent>
                    <Row>
                      <Col sm={"12"} md={"12"} lg={"12"}>
                        <div className={clsx(styles.bannerWhite)}>
                          <h1 className="text-sm-start" style={{ fontSize: "14px" }}>
                            You can attach Site plan, Location Map, Feature Sheet, Floor Plate, Brochure, etc. (pdf,
                            png, or jpeg only)
                          </h1>
                          {mediaData || mediaName ? (
                            <div className={clsx(styles.bannerDiv)}>
                              <p className={clsx(styles.para1)}>Change Name of the file uploaded</p>
                              <input
                                type="text"
                                id="mediaName"
                                name="profile1"
                                ref={mediaRef}
                                value={mediaName}
                                className={clsx(styles.uploadInput)}
                                onChange={(e) => {
                                  setMediaName(e.target.value);
                                }}
                                onFocus={(e) => e.target.select()}
                                onKeyUp={(e) => {
                                  if (e.key === "Enter") {
                                    if (mediaData) {
                                      let devName = devData?.name?.split(" ")?.join("-");

                                      let cityName = city?.city
                                        ?.find((li) => li?._id === devData?.address?.city)
                                        ?.name?.split(" ")
                                        ?.join("-");

                                      let homeTypeName = devData?.homeTypes?.[0]
                                        ? `-${homeTypeData?.homeType
                                            ?.find((li) => li?._id === devData?.homeTypes?.[0])
                                            ?.name?.split(" ")
                                            ?.join("-")}`
                                        : "";

                                      let ext = mediaName?.split(".")?.pop();

                                      let newName = `${devName}-${cityName}-${homeTypeName}-info-file-${mediaName
                                        ?.split(".")?.[0]
                                        ?.split(" ")
                                        ?.join("-")}-${mediaArray?.length + 1}.${ext}`;

                                      const updatedFile = new File([mediaData], newName, { type: mediaData.type });
                                      let formData = new FormData();
                                      formData.append("file", updatedFile);
                                      formData.append("folder", "development");
                                      uploadImage(formData);
                                    }
                                  } else if (e.key === "Escape") {
                                    setMediaData("");
                                    setMediaName("");
                                  }
                                }}
                              />
                              <button
                                type="file"
                                id="myfile"
                                className={clsx(styles.uploadBtn)}
                                name="myfile"
                                onClick={() => {
                                  if (mediaData) {
                                    let devName = devData?.name?.split(" ")?.join("-");

                                    let cityName = city?.city
                                      ?.find((li) => li?._id === devData?.address?.city)
                                      ?.name?.split(" ")
                                      ?.join("-");

                                    let homeTypeName = devData?.homeTypes?.[0]
                                      ? `-${homeTypeData?.homeType
                                          ?.find((li) => li?._id === devData?.homeTypes?.[0])
                                          ?.name?.split(" ")
                                          ?.join("-")}`
                                      : "";

                                    let ext = mediaName?.split(".")?.pop();

                                    let newName = `${devName}-${cityName}-${homeTypeName}-info-file-${mediaName
                                      ?.split(".")?.[0]
                                      ?.split(" ")
                                      ?.join("-")}-${mediaArray?.length + 1}.${ext}`;

                                    const updatedFile = new File([mediaData], newName, { type: mediaData.type });
                                    let formData = new FormData();
                                    formData.append("file", updatedFile);
                                    formData.append("folder", "development");
                                    uploadImage(formData);
                                  }
                                }}
                              >
                                Change and Upload
                              </button>
                              <Button
                                variant="text"
                                className={clsx(styles.cancelBtn)}
                                onClick={() => {
                                  setMediaData("");
                                  setMediaName("");
                                }}
                              >
                                Cancel
                              </Button>
                            </div>
                          ) : (
                            <div
                              // className={clsx(styles.bannerDiv)}
                              {...getRootProps({ className: styles.bannerDiv })}
                            >
                              <FiUpload className={styles.icon} />

                              <input
                                type="file"
                                id="profile1"
                                name="profile1"
                                style={{ display: "none" }}
                                {...getInputProps()}
                              />
                              <p className={clsx(styles.para1)}>Drag and drop the files here</p>
                              <p className={clsx(styles.para2)}>(Maximum size 5 MB)</p>
                              <button className={clsx(styles.uploadBtn)} type="file" id="myfile" name="myfile">
                                Select and Upload
                              </button>
                            </div>
                          )}

                          <p className={("text-sm-start text-center mt-4 mb-5", styles.para2)}>Uploaded Attachments</p>

                          <div className={styles.arrayUploadItemDiv}>
                            {mediaArray?.length
                              ? mediaArray?.map((item, index) => {
                                  return (
                                    <div key={`${Math.random()}-${index}`} className={styles.para2}>
                                      <div className={styles.inputDiv}>
                                        {index + 1}.{" "}
                                        <span
                                          className={clsx(styles.name)}
                                          onClick={() => {
                                            window.open(`${process.env.REACT_APP_IMGURL}${item?.fileUrl}`, "_blank");
                                          }}
                                        >
                                          {item?.label}
                                        </span>
                                      </div>

                                      <span>
                                        <BiEdit
                                          className={styles.arrayCloseIcon}
                                          style={{
                                            marginRight: "8px",
                                          }}
                                          onClick={() => {
                                            setIsEditable(item?._id || item?.editId);
                                            setEdit(item?.label);
                                          }}
                                        />
                                        <AiOutlineCloseCircle
                                          className={styles.arrayCloseIcon}
                                          onClick={() => {
                                            handleDelete(item?.fileUrl);
                                          }}
                                        />
                                      </span>
                                    </div>
                                  );
                                })
                              : null}
                          </div>

                          <Modal
                            isOpen={isEditable ? true : false}
                            toggle={() => {
                              setIsEditable(null);
                            }}
                            centered
                            className={styles.modal}
                          >
                            <ModalHeader
                              toggle={() => {
                                setIsEditable(null);
                              }}
                            >
                              <>Edit Label</>
                            </ModalHeader>
                            <ModalBody>
                              <div>
                                <label htmlFor="edit">New Label</label>
                                <input
                                  type="text"
                                  id={"edit"}
                                  value={edit}
                                  onChange={(e) => {
                                    setEdit(e.target.value);
                                  }}
                                  autoFocus
                                  onKeyUp={(e) => {
                                    if (e.key === "Enter" && e.target.value) {
                                      let a = mediaArray?.map((li) =>
                                        li?._id === isEditable || li?.editId === isEditable
                                          ? { ...li, label: edit }
                                          : li
                                      );
                                      setMediaArray(a);
                                      setIsEditable("");
                                      setEdit("");
                                    } else if (e.key === "Escape") {
                                      setIsEditable("");
                                      setEdit("");
                                    }
                                  }}
                                />
                              </div>
                            </ModalBody>
                            <ModalFooter>
                              <button
                                onClick={() => {
                                  setIsEditable("");
                                  setEdit("");
                                }}
                                className={styles.closeBtn}
                              >
                                Close
                              </button>
                              <button
                                className={styles.submitBtn}
                                onClick={() => {
                                  let a = mediaArray?.map((li) =>
                                    li?._id === isEditable || li?.editId === isEditable ? { ...li, label: edit } : li
                                  );
                                  setMediaArray(a);
                                  setIsEditable("");
                                  setEdit("");
                                }}
                              >
                                Save Changes
                              </button>
                            </ModalFooter>
                          </Modal>
                        </div>
                      </Col>
                    </Row>
                  </BlockContent>
                </Block>

                <br />
                <br />

                <Block>
                  <BlockHead>
                    <BlockTitle tag="h4">Feature Sheet</BlockTitle>
                  </BlockHead>

                  <BlockContent>
                    <Row>
                      <Col sm={"12"} md={"12"} lg={"12"}>
                        <div className={clsx(styles.askingPriceDiv)}>
                          <p className={clsx(styles.heightPara)}>Feature Sheet </p>
                          <div>
                            <JoditPreview initialValue={featureSheet} getValue={setFeatureSheet} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </BlockContent>
                </Block>

                <br />
                <br />

                <Block>
                  <BlockHead>
                    <BlockTitle tag="h4">Additional Information</BlockTitle>
                  </BlockHead>
                  <BlockContent>
                    <Row>
                      <Col sm={"6"} md={"6"} lg={"3"}>
                        <div className={clsx(styles.askingPriceDiv)}>
                          <p className={clsx(styles.heightPara)}>Parking Included</p>
                          <input
                            type="text"
                            className={clsx("form-control", styles.textField)}
                            value={parkingIncluded?.value}
                            name="parkingIncluded"
                            onChange={(e) =>
                              setParkingIncluded({
                                ...parkingIncluded,
                                value: e.target.value,
                              })
                            }
                          />
                        </div>
                      </Col>
                      <Col sm={"6"} md={"6"} lg={"3"}>
                        <div className={clsx(styles.askingPriceDiv)}>
                          <p className={clsx(styles.heightPara)}>Cost of Additional Parking</p>
                          <input
                            type="text"
                            className={clsx("form-control", styles.textField)}
                            value={additionalParking?.value}
                            name="additionalParking"
                            onChange={(e) =>
                              setAdditionalParking({
                                ...additionalParking,
                                value: e.target.value,
                              })
                            }
                          />
                        </div>
                      </Col>
                      <Col sm={"6"} md={"6"} lg={"3"}>
                        <div className={clsx(styles.askingPriceDiv)}>
                          <p className={clsx(styles.heightPara)}>Storage Include</p>
                          <input
                            type="text"
                            className={clsx("form-control", styles.textField)}
                            value={storageIncluded?.value}
                            name="storageIncluded"
                            onChange={(e) =>
                              setStorageIncluded({
                                ...storageIncluded,
                                value: e.target.value,
                              })
                            }
                          />
                        </div>
                      </Col>
                      <Col sm={"6"} md={"6"} lg={"3"}>
                        <div className={clsx(styles.askingPriceDiv)}>
                          <p className={clsx(styles.heightPara)}>Cost of Additional Storage</p>
                          <input
                            type="text"
                            className={clsx("form-control", styles.textField)}
                            value={additionalStorage?.value}
                            name="additionalStorage"
                            onChange={(e) =>
                              setAdditionalStorage({
                                ...additionalStorage,
                                value: e.target.value,
                              })
                            }
                          />
                        </div>
                      </Col>
                      <Col sm={"6"} md={"6"} lg={"3"}>
                        <div className={clsx(styles.askingPriceDiv)}>
                          <p className={clsx(styles.heightPara)}>Pets Restrictions</p>
                          <input
                            type="text"
                            className={clsx("form-control", styles.textField)}
                            value={petsRestrictions?.value}
                            name="petsRestrictions"
                            onChange={(e) =>
                              setPetsRestrictions({
                                ...petsRestrictions,
                                value: e.target.value,
                              })
                            }
                          />
                        </div>
                      </Col>
                      <Col sm={"6"} md={"6"} lg={"3"}>
                        <div className={clsx(styles.askingPriceDiv)}>
                          <p className={clsx(styles.heightPara)}>Rental Restrictions</p>
                          <input
                            type="text"
                            className={clsx("form-control", styles.textField)}
                            value={rentalRestrictions?.value}
                            name="rentalRestrictions"
                            onChange={(e) =>
                              setRentalRestrictions({
                                ...rentalRestrictions,
                                value: e.target.value,
                              })
                            }
                          />
                        </div>
                      </Col>
                      <Col sm={"6"} md={"6"} lg={"3"}>
                        <div className={clsx(styles.askingPriceDiv)}>
                          <p className={clsx(styles.heightPara)}>Age Restrictions</p>
                          <input
                            type="text"
                            className={clsx("form-control", styles.textField)}
                            value={ageRestrictions?.value}
                            name="ageRestrictions"
                            onChange={(e) =>
                              setAgeRestrictions({
                                ...ageRestrictions,
                                value: e.target.value,
                              })
                            }
                          />
                        </div>
                      </Col>
                      <Col sm={"6"} md={"6"} lg={"3"}>
                        <div className={clsx(styles.askingPriceDiv)}>
                          <p className={clsx(styles.heightPara)}>Smoking Restrictions</p>
                          <input
                            type="text"
                            className={clsx("form-control", styles.textField)}
                            value={smokingRestrictions?.value}
                            name="smokingRestrictions"
                            onChange={(e) =>
                              setSmokingRestrictions({
                                ...smokingRestrictions,
                                value: e.target.value,
                              })
                            }
                          />
                        </div>
                      </Col>
                      <Col sm={"6"} md={"6"} lg={"6"}>
                        <FormGroup className={clsx(styles.askingPriceDiv)}>
                          <Label htmlFor="bedroomRange" className={clsx("", styles.heightPara)}>
                            Ceiling Height
                          </Label>
                          <Row>
                            {ceilingRange ? (
                              <>
                                <Col sm={"6"}>
                                  <div className={`form-control-wrap`}>
                                    <div className="input-group input-group">
                                      <div className="input-group-prepend">
                                        <span className={`input-group-text`} id="inputGroup-sizing-md">
                                          From
                                        </span>
                                      </div>
                                      <input
                                        className="form-control"
                                        value={ceilingHeightFrom?.value}
                                        name="ceilingHeightFrom"
                                        onChange={(e) =>
                                          setCeilingHeightFrom({
                                            ...ceilingHeightFrom,
                                            value: e.target.value,
                                          })
                                        }
                                      />
                                      <div className="input-group-append">
                                        <span className="input-group-text" style={{ backgroundColor: "transparent" }}>
                                          Feet
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col sm={"6"}>
                                  <div className={`form-control-wrap`}>
                                    <div className="input-group input-group">
                                      <div className="input-group-prepend">
                                        <span className={`input-group-text`} id="inputGroup-sizing-md">
                                          To
                                        </span>
                                      </div>
                                      <input className="form-control" />
                                      <div className="input-group-append">
                                        <span className="input-group-text" style={{ backgroundColor: "transparent" }}>
                                          Sqft
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </>
                            ) : (
                              <Col sm={"12"}>
                                <div className={`form-control-wrap`}>
                                  <div className="input-group input-group">
                                    <input
                                      className="form-control"
                                      value={ceilingHeightFrom?.value}
                                      name="ceilingHeightFrom"
                                      onChange={(e) =>
                                        setCeilingHeightFrom({
                                          ...ceilingHeightFrom,
                                          value: e.target.value,
                                        })
                                      }
                                    />
                                    <div className="input-group-append">
                                      <span className="input-group-text" style={{ backgroundColor: "transparent" }}>
                                        Feet
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            )}
                          </Row>
                          <div className="preview-block mb-2">
                            <div className="custom-control custom-control-sm custom-checkbox">
                              <input
                                className="custom-control-input form-control error"
                                id="update"
                                type="checkbox"
                                value={ceilingRange}
                                checked={ceilingRange}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setCeilingRange(true);
                                  } else {
                                    setCeilingRange(false);
                                    setCeilingHeigthTo({
                                      ...ceilingHeigthTo,
                                      value: "",
                                    });
                                  }
                                }}
                              />
                              <label className="custom-control-label" htmlFor="update" style={{ fontSize: "14px" }}>
                                Range
                              </label>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm={"6"} md={"6"} lg={"3"}>
                        <div className={clsx(styles.askingPriceDiv)}>
                          <p className={clsx(styles.heightPara)}>Bike Lockers Included</p>
                          <input
                            type="text"
                            className={clsx("form-control", styles.textField)}
                            value={bikeLocker?.value}
                            name="bikeLocker"
                            onChange={(e) =>
                              setBikeLocker({
                                ...bikeLocker,
                                value: e.target.value,
                              })
                            }
                          />
                        </div>
                      </Col>
                      <Col sm={"12"} md={"12"} lg={"12"}>
                        <div
                          className={clsx(styles.askingPriceDiv)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "16px",
                            gap: "1rem",
                          }}
                        >
                          <input
                            type="checkbox"
                            value={homeWarranty}
                            style={{
                              width: "18px",
                              height: "18px",
                            }}
                            checked={homeWarranty === "2-5-10 year home warranty"}
                            name="homeWarranty"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setHomeWarranty("2-5-10 year home warranty");
                              } else {
                                setHomeWarranty("");
                              }
                            }}
                            id="homeWarranty"
                          />
                          <label htmlFor="homeWarranty" style={{ marginBottom: "0" }}>
                            All homes come with 2-5-10 years of new home warranty
                          </label>
                        </div>
                      </Col>

                      <Col sm={"12"} md={"12"} lg={"12"}>
                        <p className={clsx("")} style={{ margin: "15px 0 0 0", fontSize: "16px" }}>
                          Other Highlights
                        </p>
                        <Row>
                          <Col sm={"6"} md={"5"} lg={"5"}>
                            <div className={clsx(styles.askingPriceDiv)}>
                              <p className={clsx(styles.heightPara)}>Label </p>
                              <div>
                                <input
                                  type="text"
                                  className={clsx("form-control", styles.textField)}
                                  placeholder="Label"
                                  value={otherDetailsTitle}
                                  name="otherDetailsTitle"
                                  onChange={(e) => setOtherDetailsTitle(e.target.value)}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col sm={"6"} md={"5"} lg={"5"}>
                            <div className={clsx(styles.askingPriceDiv)}>
                              <p className={clsx(styles.heightPara)}>Value</p>
                              <div>
                                <input
                                  type="text"
                                  className={clsx("form-control", styles.textField)}
                                  placeholder="Value"
                                  value={otherDetailsValue}
                                  name="otherDetailsValue"
                                  onChange={(e) => setOtherDetailsValue(e.target.value)}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col sm={"6"} md={"2"} lg={"2"}>
                            <div className={clsx(styles.askingPriceDiv)}>
                              <p className={clsx(styles.heightPara)}>&nbsp;</p>
                              <Button
                                color={"info"}
                                style={
                                  {
                                    // fontSize: "16px",
                                    // padding: "5px 15px",
                                    // borderRadius: "5px",
                                    // background: "#00aeff",
                                    // width: "100%",
                                  }
                                }
                                onClick={() => {
                                  if (otherDetailsTitle && otherDetailsValue) {
                                    setOtherDetailsArray([
                                      ...otherDetailsArray,
                                      {
                                        title: otherDetailsTitle,
                                        value: otherDetailsValue,
                                      },
                                    ]);
                                    setOtherDetailsTitle("");
                                    setOtherDetailsValue("");
                                  }
                                }}
                              >
                                Add
                              </Button>
                            </div>
                          </Col>
                        </Row>

                        {otherDetailsArray?.length ? (
                          <div className={clsx(styles.arrayDetailDiv)}>
                            <table id="customers" className={clsx(styles.arrayTable)}>
                              <thead>
                                <tr>
                                  <th>Title</th>
                                  <th>Value</th>
                                </tr>
                              </thead>
                              <tbody>
                                {otherDetailsArray?.map((row) => (
                                  <tr key={`${row?.title}-${Math.random()}`}>
                                    <td>{row?.title}</td>
                                    <td className={styles.closeDiv}>
                                      {row?.value}
                                      <AiOutlineCloseCircle
                                        className={styles.arrayCloseIcon}
                                        onClick={() => {
                                          let a = otherDetailsArray?.indexOf(row);
                                          if (a > -1) {
                                            otherDetailsArray?.splice(a, 1);
                                            setOtherDetailsArray([...otherDetailsArray]);
                                          }
                                        }}
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  </BlockContent>
                </Block>
              </div>
            </div>
          </div>
        </Card>

        <div style={{ margin: "15px 0", display: "flex", gap: "1rem" }}>
          <FooterBtn text={"Cancel"} color={"secondary"} outline onClick={handleBack} />
          <FooterBtn text={"Next"} color={"info"} onClick={handleNext} />
        </div>
      </Block>
    </Content>
  );
};

export default Dev4;
