import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteFile, uploadFile } from "../../redux/Apislices/awsSlice";
import { getHomeFacts } from "../../redux/Apislices/homeFactsSlice";
import { addListing, editListing } from "../../redux/Apislices/listingSlice";
import { getParkingList } from "../../redux/Apislices/parkingSlice";
import { getSession, removeSession, setSession } from "../../utils/Storage";
import { getGarageLayoutList } from "../../redux/Apislices/garageLayoutSlice";
import { getUnitFeatures } from "../../redux/Apislices/unitFeaturesSlice";
import Content from "../../layout/content/Content";
import {
  Block,
  BlockBetween,
  BlockContent,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  RSelect,
  Row,
} from "../Component";
import { Card, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AssignNavTab from "./AssignNavTab";
import clsx from "clsx";
import styles from "./assignment.module.scss";
import FooterBtn from "../common/FooterBtn";
import { AiOutlineCheck, AiOutlineCloseCircle } from "react-icons/ai";
import { assignmentData as factData } from "./Data/data";
import { convertToSlug } from "../../utils/Utils";
import { FiUpload } from "react-icons/fi";
import { BiEdit } from "react-icons/bi";

const Assignment3 = ({ formStep, nextFormStep, prevFormStep, stepGoTo }) => {
  const navigate = useNavigate();
  const { type, id } = useParams();
  const pathArray = window.location.pathname.split("/");
  const mainPath = pathArray[1];
  const dispatch = useDispatch();
  const {
    homeFacts: homeFactsData,
    parking: parkingData,
    garageLayout: garageLayoutData,
    unitFeatures: unitFeaturesData,
    listing,
  } = useSelector((state) => ({
    homeFacts: state?.homeFacts?.homeFacts,
    parking: state?.parking?.parking,
    garageLayout: state?.garageLayout?.garageLayout,
    unitFeatures: state?.unitFeatures?.unitFeatures,
    listing: state?.listing?.listingById?.data,
  }));

  const formRef = useRef();

  const initData = {
    noOfParking: "",
    evCharger: "",
    garageType: "",
    garageLayout: "",
    garageOrientation: "",
    garageSize: "",
    bikeLocker: "",
    noOfBikeLocker: "",
    storage: "",
    width: "",
    depth: "",
  };

  const [assignmentData, setAssignmentData] = useState(false);

  const [step3Data, setStep3Data] = useState(initData);

  const {
    // parkingFacility,
    noOfParking,
    evCharger,
    garageLayout,
    garageOrientation,
    garageSize,
    storage,
    width,
    depth,
    bikeLocker,
    noOfBikeLocker,
  } = step3Data;

  const [bedrooms, setBedrooms] = useState("");
  const [flex, setFlex] = useState("");
  const [bathrooms, setBathrooms] = useState("");
  const [halfBathrooms, setHalfBathrooms] = useState("");
  const [kitchen, setKitchen] = useState("");
  const [rooftopDeck, setRooftopDeck] = useState("");
  const [colourScheme, setColourScheme] = useState("");

  const [parkingFacility, setParkingFacility] = useState("");
  const [parkingFacilityArray, setParkingFacilityArray] = useState([]);

  const [isCondo, setIsCondo] = useState(false);
  const [isTownHome, setIsTownHome] = useState(false);

  const [kitcheStoveType, setKitcheStoveType] = useState("");
  const [laundry, setLaundry] = useState("");
  const [fuelHeating, setFuelHeating] = useState("");
  const [fuelHeatingArray, setFuelHeatingArray] = useState([]);
  const [airConditioning, setAirConditioning] = useState("");
  const [centralizedVacuum, setCentralizedVacuum] = useState("");
  const [firePlace, setFirePlace] = useState("");
  const [firePlaceFuel, setFirePlaceFuel] = useState("");
  const [serviceConnected, setServiceConnected] = useState("");
  const [serviceConnectedArray, setServiceConnectedArray] = useState([]);
  const [flooring, setFlooring] = useState("");
  const [flooringArray, setFlooringArray] = useState([]);
  const [maintenanceIncludes, setMaintenanceIncludes] = useState("");
  const [maintenanceIncludesArray, setMaintenanceIncludesArray] = useState([]);
  const [byLaws, setByLaws] = useState("");
  const [byLawArray, setByLawArray] = useState([]);
  const [appliances, setAppliances] = useState("");
  const [appliancesArray, setAppliancesArray] = useState([]);
  const [unitFeatures, setUnitFeatures] = useState("");
  const [unitFeaturesArray, setUnitFeaturesArray] = useState([]);

  const [mediaData, setMediaData] = useState("");
  const [mediaName, setMediaName] = useState("");
  const mediaRef = useRef();

  const [mediaArray, setMediaArray] = useState([]);
  console.log("mediaArray: ", mediaArray);

  const [isEditable, setIsEditable] = useState("");
  const [edit, setEdit] = useState("");

  const [fullbedMand, setFullBedMand] = useState(false);
  const [fullBathMand, setFullBathMand] = useState(false);
  const [parkingNum, setParkingNum] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (e.target.type === "checkbox") {
      if (e.target.checked) {
        setStep3Data({
          ...step3Data,
          [name]: value,
        });
      } else {
        setStep3Data({
          ...step3Data,
          [name]: !value,
        });
      }
    } else {
      setStep3Data({
        ...step3Data,
        [name]: value,
      });
    }
  };

  let payloadData = () => {
    let data = { ...assignmentData };
    let homeFacts = {
      bedrooms,
      flex,
      bathrooms,
      halfBathrooms,
      kitchen,
      rooftopDeck,
      colourScheme,
    };
    function calculateTotal(bedrooms, flex) {
      let flexContribution = 0;
      if (flex) flexContribution = 0.5 * flex;
      const total = bedrooms + flexContribution;
      return total;
    }
    let totalBedrooms = calculateTotal(+bedrooms, +flex);
    let totalBathrooms = calculateTotal(+bathrooms, +halfBathrooms);
    data = { ...data, bedrooms: totalBedrooms };
    data = { ...data, bathrooms: totalBathrooms };
    homeFacts = {
      ...homeFacts,
      parking: {
        parkingFacility: parkingFacilityArray?.length ? parkingFacilityArray?.map((li) => li) : null,
        noOfParking: noOfParking,
      },
    };
    if (evCharger) homeFacts = { ...homeFacts, evCharging: evCharger };
    if (garageLayout || garageOrientation || garageSize)
      homeFacts = {
        ...homeFacts,
        garage: { garageLayout: garageLayout, garageOrientation: garageOrientation, garageSize: +garageSize },
      };
    homeFacts = {
      ...homeFacts,
      storage: { available: storage, size: { width: width, depth: depth } },
    };
    if (bikeLocker || noOfBikeLocker)
      homeFacts = { ...homeFacts, bikeLocker: { available: bikeLocker, noOfLocker: +noOfBikeLocker } };
    if (kitcheStoveType) homeFacts = { ...homeFacts, kitchenStoveType: kitcheStoveType };
    if (laundry) homeFacts = { ...homeFacts, laundry };
    if (fuelHeatingArray?.length) homeFacts = { ...homeFacts, fuelHeating: fuelHeatingArray };
    if (airConditioning) homeFacts = { ...homeFacts, airConditioning };
    if (centralizedVacuum) homeFacts = { ...homeFacts, centralizedVaccum: centralizedVacuum };
    if (firePlace) homeFacts = { ...homeFacts, firePlace: { firePlace: firePlace, firePlaceFuel: firePlaceFuel } };
    if (serviceConnectedArray?.length) homeFacts = { ...homeFacts, servicesConnected: serviceConnectedArray };
    if (flooringArray?.length) homeFacts = { ...homeFacts, flooring: flooringArray };
    if (maintenanceIncludesArray?.length) homeFacts = { ...homeFacts, maintenanceInclusions: maintenanceIncludesArray };
    if (byLawArray?.length) homeFacts = { ...homeFacts, byLawRestrictions: byLawArray };
    if (appliancesArray?.length) homeFacts = { ...homeFacts, appliances: appliancesArray };
    if (unitFeaturesArray?.length) homeFacts = { ...homeFacts, unitFeatures: unitFeaturesArray };
    if (homeFacts) data = { ...data, homeFacts };
    return data;
  };

  const uploadImage = async (file) => {
    let toastId = toast.loading("Uploading");
    setTimeout(() => {
      toast.dismiss(toastId);
    }, 10000);
    dispatch(
      uploadFile(
        file,
        (res) => {
          console.log("res: ", res);
          if (type === "edit") {
            let data = payloadData();
            data = {
              ...data,
              publicInformationFolder: [
                ...assignmentData?.publicInformationFolder,
                ...res?.data?.map((li) => ({
                  label: mediaName,
                  fileUrl: li?.url,
                })),
              ],
            };
            console.log("data: ", data);
            dispatch(
              editListing(
                id,
                data,
                (res) => {
                  setMediaArray([...mediaArray, ...res?.data?.publicInformationFolder]);
                  toast.dismiss(toastId);
                  toast.success("File(s) Uploaded");
                  setMediaData("");
                  setMediaName("");
                },
                (res) => {
                  console.log("res: ", res);
                }
              )
            );
          } else {
            if (getSession("assign-infoDoc")) {
              let image = getSession("assign-infoDoc");
              image = [
                ...image,
                {
                  editId: `${Math.random() * 1}`,
                  label: mediaName,
                  fileUrl: res?.data?.[0]?.url,
                },
              ];
              setSession("assign-infoDoc", image);
            } else {
              setSession("assign-infoDoc", [
                {
                  editId: `${Math.random() * 1}`,
                  label: mediaName,
                  fileUrl: res?.data?.[0]?.url,
                },
              ]);
            }
            if (getSession("assign-infoDoc")) {
              setMediaArray(getSession("assign-infoDoc"));
            }
            toast.dismiss(toastId);
            toast.success("File(s) Uploaded");
            setMediaData("");
            setMediaName("");
          }
        },
        (res) => {
          toast.dismiss(toastId);
          toast.error("Something Went Wrong! Try Again");
        }
      )
    );
  };

  const handleDelete = async (path) => {
    console.log("path: ", path);
    const toastId = toast.loading("Deleting...");
    dispatch(
      deleteFile(
        { path },
        (res) => {
          if (type === "edit") {
            let data = payloadData();
            data = { ...data, publicInformationFolder: mediaArray?.filter((li) => li?.fileUrl !== path) };
            dispatch(
              editListing(
                id,
                data,
                (res) => {
                  setMediaArray(res?.data?.publicInformationFolder);
                  setAssignmentData(res?.data);
                  toast.dismiss(toastId);
                  toast.success("File Deleted");
                },
                (res) => {
                  toast.error(res?.message);
                }
              )
            );
          } else {
            let a = mediaArray.findIndex((li) => li?.fileUrl === path);
            if (a !== -1) {
              mediaArray.splice(a, 1);
              setMediaArray([...mediaArray]);
              setSession("assign-infoDoc", mediaArray);
            }
            toast.dismiss(toastId);
            toast.success("File Deleted");
          }
        },
        (res) => {
          toast.dismiss(toastId);
          toast.error(res?.message);
        }
      )
    );
  };

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") toast.error("File is larger than 20 MB");
          if (err.code === "file-invalid-type") toast.error(err.message);
        });
      });

      // uploadImage(formData);
      setMediaData(acceptedFiles?.[0]);
      setMediaName(acceptedFiles?.[0]?.name);
      if (acceptedFiles?.[0])
        setTimeout(() => {
          mediaRef.current.focus();
        }, 500);
    },
    [assignmentData]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      "application/pdf": [".pdf"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    },
    maxSize: 20971520,
  });

  const handleBackFun = () => {
    prevFormStep();
  };

  const handleAddUpdate = (successCB) => {
    let data = payloadData();
    if (mediaArray?.length) data = { ...data, publicInformationFolder: mediaArray };
    console.log("data: ", data);
    if (bedrooms && bathrooms && noOfParking) {
      if (type === "edit") {
        dispatch(
          editListing(
            id,
            data,
            (res) => {
              removeSession("assign-infoDoc");
              successCB({ success: true });
            },
            (res) => {
              toast.error(res.message);
            }
          )
        );
      } else {
        if (getSession("assignment")) {
          let { ...homeFacts } = getSession("assignment");
          let currData = { ...homeFacts, ...data };
          setSession("assignment", currData);
        } else {
          setSession("assignment", { ...data });
        }
        removeSession("assign-infoDoc");
        successCB({ success: true });
      }
    } else {
      if (!bedrooms) setFullBedMand(true);
      if (!bathrooms) setFullBathMand(true);
      if (!noOfParking) setParkingNum(true);
      if (!bedrooms) document.getElementById("bedrooms").focus();
      else if (!bathrooms) document.getElementById("bathrooms").focus();
      else if (!noOfParking) document.getElementById("noOfParking").focus();
      toast.error("Please fill all the mandatory fields");
    }
  };

  const handleNext = () => {
    handleAddUpdate((res) => {
      if (res?.success) nextFormStep();
    });
  };

  const handleDraft = () => {
    let data = payloadData();
    if (mediaArray?.length) data = { ...data, publicInformationFolder: mediaArray };

    if (type === "edit") data = { ...data, status: data?.status };
    else data = { ...data, status: "draft" };
    console.log("data: ", data);
    if (bedrooms && bathrooms && noOfParking) {
      if (type === "edit") {
        dispatch(
          editListing(
            id,
            data,
            (res) => {
              navigate(mainPath === "assignment" ? "/assignment" : "/new-homes");
              removeSession("assignment");
              removeSession("assign-infoDoc");
              toast.success("Listing Updated");
            },
            (res) => {
              toast?.error(res?.message);
            }
          )
        );
      } else {
        dispatch(
          addListing(
            data,
            (res) => {
              removeSession("assignment");
              removeSession("assign-infoDoc");
              navigate(mainPath === "assignment" ? "/assignment" : "/new-homes");
              toast.success("Saved as draft");
            },
            (res) => {
              toast.error(res?.message);
            }
          )
        );
      }
    } else {
      if (!bedrooms) setFullBedMand(true);
      if (!bathrooms) setFullBathMand(true);
      if (!noOfParking) setParkingNum(true);
      if (!bedrooms) document.getElementById("bedrooms").focus();
      else if (!bathrooms) document.getElementById("bathrooms").focus();
      else if (!noOfParking) document.getElementById("noOfParking").focus();
      toast.error("Please fill all the mandatory fields");
    }
  };

  useEffect(() => {
    if (listing) {
      setAssignmentData(listing);
    } else {
      if (getSession("assignment")) {
        setAssignmentData(getSession("assignment"));
      }
    }
  }, [listing]);

  useEffect(() => {
    if (assignmentData) {
      console.log("assignmentData 3: ", assignmentData);
      let data = assignmentData;
      setParkingFacilityArray(data?.homeFacts?.parking?.parkingFacility?.map((li) => li) || parkingFacilityArray);
      setMediaArray(data?.publicInformationFolder?.map((li) => li));
      setStep3Data({
        ...step3Data,
        noOfParking: data?.homeFacts?.parking?.noOfParking || noOfParking,
        evCharger: data?.homeFacts?.evCharging,
        garageLayout: data?.homeFacts?.garage?.garageLayout || garageLayout,
        garageOrientation: data?.homeFacts?.garage?.garageOrientation || garageOrientation,
        garageSize: data?.homeFacts?.garage?.garageSize || garageSize,
        storage: data?.homeFacts?.storage?.available || storage,
        width: data?.homeFacts?.storage?.size?.width,
        depth: data?.homeFacts?.storage?.size?.depth,
        bikeLocker: data?.homeFacts?.bikeLocker?.available || bikeLocker,
        noOfBikeLocker: data?.homeFacts?.bikeLocker?.noOfLocker || noOfBikeLocker,
      });
      setBedrooms(data?.homeFacts?.bedrooms);
      setFlex(data?.homeFacts?.flex);
      setBathrooms(data?.homeFacts?.bathrooms);
      setHalfBathrooms(data?.homeFacts?.halfBathrooms);
      setKitchen(data?.homeFacts?.kitchen);
      setRooftopDeck(data?.homeFacts?.rooftopDeck);
      setColourScheme(data?.homeFacts?.colourScheme);
      setIsCondo(data?.homeType === "63c66c47cec8665e878ce4df" ? true : false);
      setIsTownHome(data?.homeType === "63c683e55178561202fd7ee7" ? true : false);
      setKitcheStoveType(data?.homeFacts?.kitchenStoveType || kitcheStoveType);
      setLaundry(data?.homeFacts?.laundry || laundry);
      setFuelHeatingArray(data?.homeFacts?.fuelHeating?.map((li) => li) || fuelHeatingArray);
      setAirConditioning(data?.homeFacts?.airConditioning || airConditioning);
      setCentralizedVacuum(data?.homeFacts?.centralizedVaccum || centralizedVacuum);
      setFirePlace(data?.homeFacts?.firePlace?.firePlace || firePlace);
      setFirePlaceFuel(data?.homeFacts?.firePlace?.firePlaceFuel);
      setServiceConnectedArray(data?.homeFacts?.servicesConnected?.map((li) => li) || serviceConnectedArray);
      setFlooringArray(data?.homeFacts?.flooring?.map((li) => li) || flooringArray);
      setMaintenanceIncludesArray(data?.homeFacts?.maintenanceInclusions?.map((li) => li) || maintenanceIncludesArray);
      setByLawArray(data?.homeFacts?.byLawRestrictions?.map((li) => li) || byLawArray);
      setAppliancesArray(data?.homeFacts?.appliances?.map((li) => li) || appliancesArray);
      setUnitFeaturesArray(data?.homeFacts?.unitFeatures?.map((li) => li) || unitFeaturesArray);
    }
  }, [assignmentData]);

  useEffect(() => {
    if (getSession("assign-infoDoc")) {
      setMediaArray(getSession("assign-infoDoc"));
    } else {
      if (assignmentData?.publicInformationFolder) {
        setMediaArray(assignmentData?.publicInformationFolder?.map((li) => li));
      }
    }
  }, [assignmentData]);

  useEffect(() => {
    dispatch(getHomeFacts());
    dispatch(getParkingList());
    dispatch(getGarageLayoutList());
    dispatch(getUnitFeatures());
  }, [dispatch]);

  return (
    <>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Add {mainPath === "assignment" ? "Assignment" : "New Home"}
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="light"
                outline
                className="bg-white d-inline-flex"
                onClick={(ev) => {
                  ev.preventDefault();
                  handleDraft();
                }}
              >
                <span>{type === "edit" ? "Update Development" : "Save as Draft"}</span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card className="card-bordered">
            <div className="card-aside-wrap" id="user-detail-block">
              <div className="card-content">
                <AssignNavTab stepGoTo={stepGoTo} update={handleAddUpdate} />
                <div className="card-inner">
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Home Facts & Features</BlockTitle>
                    </BlockHead>
                    <BlockContent>
                      <Row className="">
                        <Col sm={"6"} md={"4"} lg={"3"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Bedrooms (Full)*</p>
                            <select
                              className={clsx("arrows", styles.selectTag)}
                              style={{
                                padding: "7px 15px",
                                border: fullbedMand ? "2px solid #ce6048" : "0.2px solid #dddddd",
                              }}
                              id="bedrooms"
                              name="bedrooms"
                              value={bedrooms}
                              onChange={(e) => {
                                setFullBedMand(false);
                                setBedrooms(e.target.value);
                              }}
                            >
                              <option value="">Select</option>
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                            </select>
                          </div>
                        </Col>
                        <Col sm={"6"} md={"4"} lg={"3"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Flex/Den</p>
                            <select
                              className={clsx("arrows", styles.selectTag)}
                              style={{
                                padding: "7px 15px",
                                border: "0.2px solid #dddddd",
                              }}
                              value={flex}
                              onChange={(e) => {
                                setFlex(e.target.value);
                              }}
                            >
                              <option value="">Select</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                            </select>
                          </div>
                        </Col>
                        <Col sm={"6"} md={"4"} lg={"3"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Bathrooms (Full)*</p>
                            <select
                              className={clsx("arrows", styles.selectTag)}
                              style={{
                                padding: "7px 15px",
                                border: fullBathMand ? "2px solid #ce6048" : "0.2px solid #dddddd",
                              }}
                              id={"bathrooms"}
                              name={"bathrooms"}
                              value={bathrooms}
                              onChange={(e) => {
                                setFullBathMand(false);
                                setBathrooms(e.target.value);
                              }}
                            >
                              <option value="">Select</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                            </select>
                          </div>
                        </Col>
                        <Col sm={"6"} md={"4"} lg={"3"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Half Bathrooms/ Powder Rooms</p>
                            <select
                              className={clsx("arrows", styles.selectTag)}
                              style={{
                                padding: "7px 15px",
                                border: "0.2px solid #dddddd",
                              }}
                              value={halfBathrooms}
                              onChange={(e) => {
                                setHalfBathrooms(e.target.value);
                              }}
                            >
                              <option value="">Select</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                            </select>
                          </div>
                        </Col>
                        <Col sm={"6"} md={"4"} lg={"3"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Kitchen(s)</p>
                            <select
                              className={clsx("arrows", styles.selectTag)}
                              style={{
                                padding: "7px 15px",
                                border: "0.2px solid #dddddd",
                              }}
                              value={kitchen}
                              onChange={(e) => {
                                setKitchen(e.target.value);
                              }}
                            >
                              <option value="">Select</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                            </select>
                          </div>
                        </Col>
                        {isTownHome ? (
                          <Col sm={"6"} md={"4"} lg={"3"}>
                            <div className={clsx(styles.askingPriceDiv)}>
                              <p>Rooftop Deck</p>
                              <select
                                className={clsx("arrows", styles.selectTag)}
                                style={{
                                  padding: "7px 15px",
                                  border: "0.2px solid #dddddd",
                                }}
                                value={rooftopDeck}
                                onChange={(e) => {
                                  setRooftopDeck(e.target.value);
                                }}
                              >
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </Col>
                        ) : null}
                        <Col sm={"6"} md={"4"} lg={"3"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Colour Scheme</p>
                            <input
                              className={clsx("form-control", styles.textField)}
                              value={colourScheme}
                              name="colourScheme"
                              onChange={(e) => {
                                setColourScheme(e.target.value);
                              }}
                            />
                          </div>
                        </Col>
                      </Row>

                      <hr />

                      <Row>
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Parking Facility </p>
                            <select
                              className={clsx("arrows", styles.selectTag)}
                              style={{
                                padding: "7px 15px",
                                border: "0.2px solid #dddddd",
                              }}
                              value={parkingFacility}
                              name="parkingFacility"
                              onChange={(e) => {
                                setParkingFacilityArray([...parkingFacilityArray, e.target.value]);
                              }}
                            >
                              <option value={""}>Select</option>
                              {parkingData?.map((li, i) => (
                                <>
                                  {!parkingFacilityArray?.includes(li?._id) ? (
                                    <option key={`${i}`} value={li?._id}>
                                      {li?.name}
                                    </option>
                                  ) : null}
                                </>
                              ))}
                            </select>
                          </div>
                          <div className={clsx(styles.arrayItemDiv)}>
                            {parkingFacilityArray?.map((item, index) => (
                              <p
                                key={`${Math.random()}-${index}`}
                                className={styles.para2}
                                onClick={() => {
                                  let a = parkingFacilityArray?.indexOf(item);
                                  if (a > -1) {
                                    parkingFacilityArray?.splice(a, 1);
                                    setParkingFacilityArray([...parkingFacilityArray]);
                                  }
                                }}
                              >
                                <AiOutlineCloseCircle className={styles.arrayCloseIcon} />
                                <span>{parkingData?.find((li) => li?._id === item)?.name}</span>
                              </p>
                            ))}
                          </div>
                        </Col>
                        <Col sm={"6"} md={"3"} lg={"3"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p className={clsx(styles.parkingPara)}>No. of Parking Spaces*</p>
                            <select
                              className={clsx("arrows", styles.selectTag)}
                              style={{
                                padding: "7px 15px",
                                border: parkingNum ? "2px solid #ce6048" : "0.2px solid #dddddd",
                              }}
                              value={noOfParking}
                              id={"noOfParking"}
                              name={"noOfParking"}
                              onChange={(e) => {
                                setParkingNum(false);
                                handleChange(e);
                              }}
                            >
                              <option value="">Select</option>
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                            </select>
                          </div>
                        </Col>
                        <Col sm={"6"} md={"3"} lg={"3"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>EV Charging </p>
                            <select
                              className={clsx("arrows", styles.selectTag)}
                              style={{ padding: "7px 15px", border: "0.2px solid #dddddd" }}
                              value={evCharger}
                              name={"evCharger"}
                              onChange={handleChange}
                            >
                              <option value="">Select</option>
                              <option value="Roughed-In Only">Roughed-In Only</option>
                              <option value="Station Installed">Station Installed</option>
                            </select>
                          </div>
                        </Col>
                        {parkingFacilityArray?.includes("64410350000d159c246c0a4f") ||
                        parkingFacilityArray?.includes("64410350000d159c246c0a50") ? (
                          <>
                            {/* Garage Layout */}
                            {!isCondo ? (
                              <Col sm={"6"} md={"3"} lg={"3"}>
                                <div className={clsx(styles.askingPriceDiv)}>
                                  <p>Garage Layout</p>
                                  <select
                                    className={clsx("arrows", styles.selectTag)}
                                    style={{ padding: "7px 15px", border: "0.2px solid #dddddd" }}
                                    value={garageLayout}
                                    name={"garageLayout"}
                                    onChange={handleChange}
                                  >
                                    <option value="">Select</option>
                                    {garageLayoutData?.map((li, i) => (
                                      <option key={`${i}-${li?._id}`} value={li?._id}>
                                        {li?.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </Col>
                            ) : null}

                            {/* Garage Orientation */}
                            {!isCondo ? (
                              <Col sm={"6"} md={"3"} lg={"3"}>
                                <div className={clsx(styles.askingPriceDiv)}>
                                  <p>Garage Orientation</p>
                                  <select
                                    className={clsx("arrows", styles.selectTag)}
                                    style={{
                                      padding: "7px 15px",
                                      border: "0.2px solid #dddddd",
                                    }}
                                    value={garageOrientation}
                                    name={"garageOrientation"}
                                    onChange={handleChange}
                                  >
                                    <option value="">Select</option>
                                    <option value="Front-facing">Front-facing</option>
                                    <option value="90° inward facing">90° inward facing</option>
                                    <option value="Below the house">Below the house</option>
                                    <option value="Side-of house">Side-of house</option>
                                  </select>
                                </div>
                              </Col>
                            ) : null}

                            {/* Garage Size (sqft) */}
                            {!isCondo ? (
                              <Col sm={"12"} md={"3"} lg={"3"}>
                                <div className={clsx(styles.askingPriceDiv)}>
                                  <p>Garage Size (sqft)</p>
                                  <div className="input-group-meta form-group">
                                    <input
                                      type="number"
                                      className={clsx("form-control", styles.textField)}
                                      value={garageSize}
                                      name="garageSize"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                              </Col>
                            ) : null}
                          </>
                        ) : null}
                        {isCondo ? (
                          <Col lg={"6"} md={"12"} sm={"12"}>
                            <Row className={clsx("", styles.iconBtnDiv)} style={{ background: "#F0F0F0" }}>
                              {/* Storage */}
                              <Col sm={"12"} md={"12"} lg={"6"}>
                                <div className={clsx(styles.askingPriceDiv)}>
                                  <p>Storage</p>
                                  <select
                                    className={clsx("arrows", styles.selectTag)}
                                    style={{
                                      padding: "7px 15px",
                                      border: "0.2px solid #dddddd",
                                    }}
                                    value={storage}
                                    name={"storage"}
                                    onChange={handleChange}
                                  >
                                    <option value="">Select</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                  </select>
                                </div>
                              </Col>
                              <Col sm={"6"} md={"6"} lg={"3"}>
                                <div className={clsx(styles.askingPriceDiv)}>
                                  <p>Width (Feet) </p>
                                  <input
                                    type="number"
                                    className={clsx("form-control", styles.textField)}
                                    value={width}
                                    name="width"
                                    onChange={handleChange}
                                  />
                                </div>
                              </Col>
                              <Col sm={"6"} md={"6"} lg={"3"}>
                                <div className={clsx(styles.askingPriceDiv)}>
                                  <p>Depth (Feet) </p>
                                  <input
                                    type="number"
                                    className={clsx("form-control", styles.textField)}
                                    value={depth}
                                    name="depth"
                                    onChange={handleChange}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        ) : null}
                        <Col sm={"6"} md={"6"} lg={"3"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Bike Locker</p>
                            <select
                              className={clsx("arrows", styles.selectTag)}
                              style={{
                                padding: "7px 15px",
                                border: "0.2px solid #dddddd",
                              }}
                              value={bikeLocker}
                              name={"bikeLocker"}
                              onChange={handleChange}
                            >
                              <option value="">Select</option> <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </Col>
                        {bikeLocker === "Yes" ? (
                          <Col sm={"6"} md={"6"} lg={"3"}>
                            <div className={clsx(styles.askingPriceDiv)}>
                              <p>No. of Bike Lockers</p>
                              <select
                                className={clsx("arrows", styles.selectTag)}
                                style={{
                                  padding: "7px 15px",
                                  border: "0.2px solid #dddddd",
                                }}
                                value={noOfBikeLocker}
                                name={"noOfBikeLocker"}
                                onChange={handleChange}
                              >
                                <option value="">Select</option> <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                              </select>
                            </div>
                          </Col>
                        ) : null}
                      </Row>

                      <hr />

                      <Row>
                        <Col sm={"6"} md={"3"} lg={"3"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Kitchen Stove</p>
                            <select
                              className={clsx("arrows", styles.selectTag)}
                              style={{
                                padding: "7px 15px",
                                border: "0.2px solid #dddddd",
                              }}
                              value={kitcheStoveType}
                              name={"kitcheStoveType"}
                              onChange={(e) => setKitcheStoveType(e.target.value)}
                            >
                              <option value="">Select</option>
                              {factData?.kitchenStoveType?.map((li, i) => (
                                <option key={i} value={li}>
                                  {li}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col sm={"6"} md={"3"} lg={"3"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Laundry</p>
                            <select
                              className={clsx("arrows", styles.selectTag)}
                              style={{
                                padding: "7px 15px",
                                border: "0.2px solid #dddddd",
                              }}
                              value={laundry}
                              name={"laundry"}
                              onChange={(e) => setLaundry(e.target.value)}
                            >
                              <option value="">Select</option>{" "}
                              {factData?.laundry?.map((li, i) => (
                                <option key={i} value={li}>
                                  {li}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Fuel/Heating</p>
                            <div className={styles.PFlex}>
                              <div className="w-100">
                                <RSelect
                                  options={
                                    factData?.fuelHeating?.length
                                      ? factData?.fuelHeating?.map((item, i) =>
                                          !fuelHeatingArray?.includes(item)
                                            ? { label: item, value: item }
                                            : { label: item, value: item, isDisabled: true }
                                        )
                                      : []
                                  }
                                  className="searchSelect"
                                  value={fuelHeating}
                                  onChange={(value) => {
                                    setFuelHeatingArray([...fuelHeatingArray, value.value]);
                                    setFuelHeating("");
                                  }}
                                />
                              </div>
                            </div>
                            <div className={clsx(styles.arrayItemDiv)}>
                              {fuelHeatingArray?.map((item, index) => (
                                <p
                                  key={`${Math.random()}-${index}`}
                                  className={styles.para2}
                                  onClick={() => {
                                    let a = fuelHeatingArray?.indexOf(item);
                                    if (a > -1) {
                                      fuelHeatingArray?.splice(a, 1);
                                      setFuelHeating([...fuelHeating]);
                                    }
                                  }}
                                >
                                  <AiOutlineCloseCircle className={styles.arrayCloseIcon} />
                                  <span>{item}</span>
                                </p>
                              ))}
                            </div>
                          </div>
                        </Col>
                        <Col sm={"6"} md={"3"} lg={"3"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Air Conditioning</p>
                            <select
                              className={clsx("arrows", styles.selectTag)}
                              style={{
                                padding: "7px 15px",
                                border: "0.2px solid #dddddd",
                              }}
                              value={airConditioning}
                              name={"airConditioning"}
                              onChange={(e) => setAirConditioning(e.target.value)}
                            >
                              <option value="">Select</option>
                              {factData?.airConditioning?.map((li, i) => (
                                <option key={`${li}-${i}`} value={li}>
                                  {li}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col sm={"6"} md={"3"} lg={"3"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Centralized Vacuum</p>
                            <select
                              className={clsx("arrows", styles.selectTag)}
                              style={{
                                padding: "7px 15px",
                                border: "0.2px solid #dddddd",
                              }}
                              value={centralizedVacuum}
                              name={"centralizedVacuum"}
                              onChange={(e) => setCentralizedVacuum(e.target.value)}
                            >
                              <option value="">Select</option>
                              {factData?.centralizedVaccum?.map((li, i) => (
                                <option key={`${li}-${i}`} value={li}>
                                  {li}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col sm={"6"} md={"3"} lg={"3"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Fireplace</p>
                            <select
                              className={clsx("arrows", styles.selectTag)}
                              style={{
                                padding: "7px 15px",
                                border: "0.2px solid #dddddd",
                              }}
                              value={firePlace}
                              name={"firePlace"}
                              onChange={(e) => setFirePlace(e.target.value)}
                            >
                              <option value="">Select</option>
                              {factData?.firePlace?.map((li, i) => (
                                <option key={`${li}-${i}`} value={li}>
                                  {li}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col sm={"12"} md={"3"} lg={"3"}>
                          {firePlace === "Yes" ? (
                            <div className={clsx(styles.askingPriceDiv)}>
                              <p>Fireplace Fueled By</p>
                              <select
                                className={clsx("arrows", styles.selectTag)}
                                style={{
                                  padding: "7px 15px",
                                  border: "0.2px solid #dddddd",
                                }}
                                value={firePlaceFuel}
                                name={"firePlaceFuel"}
                                onChange={(e) => setFirePlaceFuel(e.target.value)}
                              >
                                <option value="">Select</option>
                                {factData?.firePlaceFuel?.map((li, i) => (
                                  <option key={`${li}-${i}`} value={li}>
                                    {li}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : null}
                        </Col>
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Services Connected</p>
                            <div className={styles.PFlex}>
                              <div className="w-100">
                                <RSelect
                                  options={
                                    factData?.serviceConnected?.length
                                      ? factData?.serviceConnected?.map((item, i) =>
                                          !serviceConnectedArray?.includes(item)
                                            ? {
                                                label: item,
                                                value: item,
                                              }
                                            : {
                                                label: item,
                                                value: item,
                                                isDisabled: true,
                                              }
                                        )
                                      : []
                                  }
                                  className="searchSelect"
                                  value={serviceConnected}
                                  onChange={(value) => {
                                    setServiceConnectedArray([...serviceConnectedArray, value.value]);
                                  }}
                                />
                              </div>
                            </div>
                            <div className={clsx(styles.arrayItemDiv)}>
                              {serviceConnectedArray?.map((item, index) => (
                                <p
                                  key={`${Math.random()}-${index}`}
                                  className={styles.para2}
                                  onClick={() => {
                                    let a = serviceConnectedArray?.indexOf(item);
                                    if (a > -1) {
                                      serviceConnectedArray?.splice(a, 1);
                                      setServiceConnectedArray([...serviceConnectedArray]);
                                    }
                                  }}
                                >
                                  <AiOutlineCloseCircle className={styles.arrayCloseIcon} />
                                  <span>{item}</span>
                                </p>
                              ))}
                            </div>
                          </div>
                        </Col>
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Flooring</p>
                            <div className={styles.PFlex}>
                              <div className="w-100">
                                <RSelect
                                  options={
                                    factData?.flooring?.length
                                      ? factData?.flooring?.map((item, i) =>
                                          !flooringArray?.includes(item)
                                            ? {
                                                label: item,
                                                value: item,
                                              }
                                            : {
                                                label: item,
                                                value: item,
                                                isDisabled: true,
                                              }
                                        )
                                      : []
                                  }
                                  className="searchSelect"
                                  value={flooring}
                                  onChange={(value) => {
                                    setFlooringArray([...flooringArray, value.value]);
                                  }}
                                />
                              </div>
                            </div>
                            <div className={clsx(styles.arrayItemDiv)}>
                              {flooringArray?.map((item, index) => (
                                <p
                                  key={`${Math.random()}-${index}`}
                                  className={styles.para2}
                                  onClick={() => {
                                    let a = flooringArray?.indexOf(item);
                                    if (a > -1) {
                                      flooringArray?.splice(a, 1);
                                      setFlooringArray([...flooringArray]);
                                    }
                                  }}
                                >
                                  <AiOutlineCloseCircle className={styles.arrayCloseIcon} />
                                  <span>{item}</span>
                                </p>
                              ))}
                            </div>
                          </div>
                        </Col>
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Strata/Maintenance Fee Includes</p>
                            <div className={styles.PFlex}>
                              <div className="w-100">
                                <RSelect
                                  options={
                                    factData?.maintenanceIncludes?.length
                                      ? factData?.maintenanceIncludes?.map((item, i) =>
                                          !maintenanceIncludesArray?.includes(item)
                                            ? {
                                                label: item,
                                                value: item,
                                              }
                                            : {
                                                label: item,
                                                value: item,
                                                isDisabled: true,
                                              }
                                        )
                                      : []
                                  }
                                  className="searchSelect"
                                  value={maintenanceIncludes}
                                  onChange={(value) => {
                                    setMaintenanceIncludesArray([...maintenanceIncludesArray, value.value]);
                                  }}
                                />
                              </div>
                            </div>
                            <div className={clsx(styles.arrayItemDiv, "mt-3")}>
                              {maintenanceIncludesArray?.map((item, index) => (
                                <p
                                  key={`${Math.random()}-${index}`}
                                  className={styles.para2}
                                  onClick={() => {
                                    let a = maintenanceIncludesArray?.indexOf(item);
                                    if (a > -1) {
                                      maintenanceIncludesArray?.splice(a, 1);
                                      setMaintenanceIncludes([...maintenanceIncludesArray]);
                                    }
                                  }}
                                >
                                  <AiOutlineCloseCircle className={styles.arrayCloseIcon} />
                                  <span>{item}</span>
                                </p>
                              ))}
                            </div>
                          </div>
                        </Col>
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Bylaw Restrictions</p>
                            <div className={styles.PFlex}>
                              <div className="w-100">
                                <RSelect
                                  options={
                                    factData?.byLaws?.length
                                      ? factData?.byLaws?.map((item, i) =>
                                          !byLawArray?.includes(item)
                                            ? {
                                                label: item,
                                                value: item,
                                              }
                                            : {
                                                label: item,
                                                value: item,
                                                isDisabled: true,
                                              }
                                        )
                                      : []
                                  }
                                  className="searchSelect"
                                  value={byLaws}
                                  onChange={(value) => {
                                    setByLawArray([...byLawArray, value.value]);
                                  }}
                                />
                              </div>
                            </div>
                            <div className={clsx(styles.arrayItemDiv, "mt-3")}>
                              {byLawArray?.map((item, index) => (
                                <p
                                  key={`${Math.random()}-${index}`}
                                  className={styles.para2}
                                  onClick={() => {
                                    let a = byLawArray?.indexOf(item);
                                    if (a > -1) {
                                      byLawArray?.splice(a, 1);
                                      setByLawArray([...byLawArray]);
                                    }
                                  }}
                                >
                                  <AiOutlineCloseCircle className={styles.arrayCloseIcon} />
                                  <span>{item}</span>
                                </p>
                              ))}
                            </div>
                          </div>
                        </Col>
                        <Col sm={"12"} md={"12"} lg={"12"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Appliances</p>
                            <div className={styles.PFlex}>
                              <div className="w-100">
                                <RSelect
                                  options={
                                    factData?.appliances?.length
                                      ? factData?.appliances?.sort()?.map((item, i) =>
                                          !appliancesArray?.includes(item)
                                            ? {
                                                label: item,
                                                value: item,
                                              }
                                            : {
                                                label: item,
                                                value: item,
                                                isDisabled: true,
                                              }
                                        )
                                      : []
                                  }
                                  className="searchSelect"
                                  value={appliances}
                                  onChange={(value) => {
                                    setAppliancesArray([...appliancesArray, value.value]);
                                  }}
                                />
                              </div>
                            </div>
                            <div className={clsx(styles.arrayItemDiv, "mt-3")}>
                              {appliancesArray?.map((item, index) => (
                                <p
                                  key={`${Math.random()}-${index}`}
                                  className={styles.para2}
                                  onClick={() => {
                                    let a = appliancesArray?.indexOf(item);
                                    if (a > -1) {
                                      appliancesArray?.splice(a, 1);
                                      setAppliancesArray([...appliancesArray]);
                                    }
                                  }}
                                >
                                  <AiOutlineCloseCircle className={styles.arrayCloseIcon} />
                                  <span>{item}</span>
                                </p>
                              ))}
                            </div>
                          </div>
                        </Col>
                        <Col sm={"12"} md={"12"} lg={"12"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Unit Features</p>
                            <div className={styles.PFlex}>
                              <div className="w-100">
                                <RSelect
                                  options={
                                    unitFeaturesData?.length
                                      ? unitFeaturesData?.map((item, i) =>
                                          !unitFeaturesArray?.includes(item?._id)
                                            ? {
                                                label: item?.name,
                                                value: item?._id,
                                              }
                                            : {
                                                label: item?.name,
                                                value: item?._id,
                                                isDisabled: true,
                                              }
                                        )
                                      : []
                                  }
                                  className="searchSelect"
                                  value={unitFeatures}
                                  onChange={(value) => {
                                    setUnitFeaturesArray([...unitFeaturesArray, value.value]);
                                  }}
                                />
                              </div>
                            </div>
                            <div className={clsx(styles.arrayItemDiv, "mt-3")}>
                              {unitFeaturesArray?.map((item, index) => (
                                <p
                                  key={`${Math.random()}-${index}`}
                                  className={styles.para2}
                                  onClick={() => {
                                    let a = unitFeaturesArray?.indexOf(item);
                                    if (a > -1) {
                                      unitFeaturesArray?.splice(a, 1);
                                      setUnitFeaturesArray([...unitFeaturesArray]);
                                    }
                                  }}
                                >
                                  <AiOutlineCloseCircle className={styles.arrayCloseIcon} />
                                  <span>{unitFeaturesData?.filter((li) => li?._id === item)?.[0]?.name}</span>
                                </p>
                              ))}
                            </div>
                          </div>
                        </Col>
                        <Col sm={"6"} md={"3"} lg={"3"}></Col>
                      </Row>
                    </BlockContent>
                  </Block>
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Information Folder (Unit specific documents only)</BlockTitle>
                    </BlockHead>
                    <BlockContent>
                      <Row className="">
                        <div className={clsx(styles.bannerWhite)}>
                          <p className="text-sm-start">
                            Please attach unit specific documents like Floor plans, Site Plan, Feature Sheet, etc. (pdf,
                            png, or jpeg only)
                          </p>
                          {mediaData || mediaName ? (
                            <div className={clsx(styles.bannerDiv)}>
                              <p className={clsx(styles.para1)}>Change Name of the file uploaded</p>
                              <input
                                type="text"
                                id="mediaName"
                                name="profile1"
                                ref={mediaRef}
                                value={mediaName}
                                className={clsx(styles.uploadInput)}
                                onChange={(e) => {
                                  setMediaName(e.target.value);
                                }}
                                onFocus={(e) => e.target.select()}
                                onKeyUp={(e) => {
                                  if (e.key === "Enter") {
                                    if (mediaData) {
                                      let name = `${convertToSlug(assignmentData?.title)}-public-info-${convertToSlug(
                                        mediaName.split(".")[0]
                                      )}.${mediaData?.name?.split(".").pop()}`;

                                      const updatedFile = new File([mediaData], name, {
                                        type: mediaData.type,
                                      });
                                      let formData = new FormData();
                                      formData.append("file", updatedFile);
                                      formData.append("folder", mainPath === "assignment" ? "assignment" : "resale");
                                      uploadImage(formData);
                                    }
                                  } else if (e.key === "Escape") {
                                    setMediaData("");
                                    setMediaName("");
                                  }
                                }}
                              />
                              <button
                                type="file"
                                id="myfile"
                                className={clsx(styles.uploadBtn)}
                                name="myfile"
                                onClick={() => {
                                  if (mediaData) {
                                    let name = `${convertToSlug(assignmentData?.title)}-public-info-${convertToSlug(
                                      mediaName.split(".")[0]
                                    )}.${mediaData?.name?.split(".").pop()}`;

                                    const updatedFile = new File([mediaData], name, {
                                      type: mediaData.type,
                                    });
                                    let formData = new FormData();
                                    formData.append("file", updatedFile);
                                    formData.append("folder", mainPath === "assignment" ? "assignment" : "resale");
                                    uploadImage(formData);
                                  }
                                }}
                              >
                                Change and Upload
                              </button>
                              <Button
                                variant="text"
                                className={clsx(styles.cancelBtn)}
                                onClick={() => {
                                  setMediaData("");
                                  setMediaName("");
                                }}
                              >
                                Cancel
                              </Button>
                            </div>
                          ) : (
                            <div {...getRootProps({ className: styles.bannerDiv })}>
                              <FiUpload className={styles.icon} />

                              <input
                                type="file"
                                id="profile1"
                                name="profile1"
                                style={{ display: "none" }}
                                {...getInputProps()}
                              />
                              <p className={clsx(styles.para1)}>Drag and drop the files here</p>
                              <p className={clsx(styles.para2)}>(Maximum size 5 MB)</p>
                              <button className={clsx(styles.uploadBtn)} type="file" id="myfile" name="myfile">
                                Select and Upload
                              </button>
                            </div>
                          )}
                          <p className={("text-sm-start text-center mt-4 mb-5", styles.para2)}>Uploaded Attachments</p>
                          <div className={styles.arrayUploadItemDiv}>
                            {mediaArray?.length
                              ? mediaArray?.map((item, index) => {
                                  return (
                                    <div key={`${Math.random()}-${index}`} className={styles.para2}>
                                      <div className={""}>
                                        {index + 1}.{" "}
                                        <span
                                          className={clsx(styles.name)}
                                          onClick={() => {
                                            window.open(`${process.env.REACT_APP_IMGURL}${item?.fileUrl}`, "_blank");
                                          }}
                                        >
                                          {item?.label}
                                        </span>
                                      </div>
                                      <span>
                                        <BiEdit
                                          className={styles.arrayCloseIcon}
                                          style={{
                                            marginRight: "8px",
                                          }}
                                          onClick={() => {
                                            setIsEditable(item?._id || item?.editId);
                                            setEdit(item?.label);
                                          }}
                                        />
                                        <AiOutlineCloseCircle
                                          className={styles.arrayCloseIcon}
                                          onClick={() => {
                                            handleDelete(item?.fileUrl);
                                          }}
                                        />
                                      </span>
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                          <Modal
                            isOpen={isEditable ? true : false}
                            toggle={() => {
                              setIsEditable(null);
                            }}
                            centered
                            className={styles.modal}
                          >
                            <ModalHeader
                              toggle={() => {
                                setIsEditable(null);
                              }}
                            >
                              <>Edit Label</>
                            </ModalHeader>
                            <ModalBody>
                              <div>
                                <label htmlFor="edit">New Label</label>
                                <input
                                  type="text"
                                  id={"edit"}
                                  value={edit}
                                  onChange={(e) => {
                                    setEdit(e.target.value);
                                  }}
                                  autoFocus
                                  onKeyUp={(e) => {
                                    if (e.key === "Enter" && e.target.value) {
                                      let a = mediaArray?.map((li) =>
                                        li?._id === isEditable || li?.editId === isEditable
                                          ? { ...li, label: edit }
                                          : li
                                      );
                                      setMediaArray(a);
                                      setIsEditable("");
                                      setEdit("");
                                    } else if (e.key === "Escape") {
                                      setIsEditable("");
                                      setEdit("");
                                    }
                                  }}
                                />
                              </div>
                            </ModalBody>
                            <ModalFooter>
                              <button
                                onClick={() => {
                                  setIsEditable("");
                                  setEdit("");
                                }}
                                className={styles.closeBtn}
                              >
                                Close
                              </button>
                              <button
                                className={styles.submitBtn}
                                onClick={() => {
                                  let a = mediaArray?.map((li) =>
                                    li?._id === isEditable || li?.editId === isEditable ? { ...li, label: edit } : li
                                  );
                                  setMediaArray(a);
                                  setIsEditable("");
                                  setEdit("");
                                }}
                              >
                                Save Changes
                              </button>
                            </ModalFooter>
                          </Modal>
                        </div>
                      </Row>
                    </BlockContent>
                  </Block>
                </div>
              </div>
            </div>
          </Card>
          <div style={{ margin: "15px 0", display: "flex", gap: "1rem" }}>
            <FooterBtn text={"Cancel"} color={"secondary"} outline onClick={handleBackFun} />
            <FooterBtn text={"Next"} color={"info"} onClick={handleNext} />
          </div>
        </Block>
      </Content>
    </>
  );
};

export default Assignment3;
