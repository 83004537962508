import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteMethod, getMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";

export const getAllReviews = createAsyncThunk("reivew/allReviews", async (query) => {
  const response = await getMethod(`/api/admin/review`, query);
  return response;
});

const initialState = {
  loading: false,
  success: false,
  error: false,
  reviews: { loading: false, success: false, error: false, data: null, total: null },
};

const provinceSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    isLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    isSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    isError: (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllReviews.pending, (state) => {
        state.reviews.loading = true;
        state.reviews.success = false;
        state.reviews.error = false;
        state.reviews.data = null;
        state.reviews.total = null;
      })
      .addCase(getAllReviews.fulfilled, (state, action) => {
        state.reviews.loading = false;
        state.reviews.success = true;
        state.reviews.error = false;
        state.reviews.data = action.payload.data;
        state.reviews.total = {
          total: action?.payload?.total,
          total_pages: action?.payload?.total_pages,
          items_per_page: action?.payload?.items_per_page,
        };
      })
      .addCase(getAllReviews.rejected, (state, action) => {
        state.reviews.loading = false;
        state.reviews.success = false;
        state.reviews.data = null;
        state.reviews.total = null;
        state.reviews.error = action?.error?.message;
      });
  },
});

export const { isLoading, isSuccess, isError } = provinceSlice.actions;

export default provinceSlice.reducer;

export const editReview = (id, data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const res = await putMethod(`/api/admin/review/${id}`, data);
  if (res?.success) SuccessCallback(res);
  else ErrorCallback(res);
};

export const archiveReview = (id, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const res = await deleteMethod(`/api/admin/review/archive/${id}`);
  if (res?.success) SuccessCallback(res);
  else ErrorCallback(res);
};
export const restoreReview = (id, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const res = await deleteMethod(`/api/admin/review/restore/${id}`);
  if (res?.success) SuccessCallback(res);
  else ErrorCallback(res);
};
export const deleteReview = (id, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const res = await deleteMethod(`/api/admin/review/delete/${id}`);
  if (res?.success) SuccessCallback(res);
  else ErrorCallback(res);
};
