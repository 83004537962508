import React, { useEffect } from "react";
import Logo from "../../assets/images/logo/white-logo.svg";
import { useSearchParams, useParams, Link } from "react-router-dom";
import Content from "../../layout/content/Content";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
} from "../../components/Component";
import { Spinner } from "reactstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getPaymentById } from "../../redux/Apislices/paymentSlice";
import moment from "moment";
import { USDollar } from "../../utils/Utils";

const user = {
  id: 2,
  orderId: "546H74W",
  name: "Ashley Lawson",
  email: "ashley@softnio.com",
  phone: "+124 394-1787",
  date: "12 Jan 2020, 10:45pm",
  invoiceItem1: "50.00",
  invoiceItem2: "50.00",
  invoiceItem3: "10.00",
  invoiceItem4: "40.00",
  totalAmount: "210.00",
  status: "Pending",
};

const Invoice = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    payment: { data, loading, success },
  } = useSelector((state) => ({ payment: state.payment?.payment }));
  console.log("data:==> ", data);

  useEffect(() => {
    if (id) {
      dispatch(getPaymentById(id));
    }
  }, [id, dispatch]);

  return (
    <>
      <Content>
        <BlockHead>
          <BlockBetween className="g-3">
            <BlockHeadContent>
              <BlockTitle>Invoice</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <Link to={`/invoices`}>
                <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
                  <Icon name="arrow-left"></Icon>
                  <span>Back</span>
                </Button>
              </Link>
              <Link to={`/invoices`}>
                <Button color="light" outline className="btn-icon bg-white d-inline-flex d-sm-none">
                  <Icon name="arrow-left"></Icon>
                </Button>
              </Link>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        {loading ? (
          <div
            style={{
              height: "200px",
              width: "100%",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Spinner type="grow" color="dark" />
          </div>
        ) : data ? (
          <Block>
            <div className="invoice">
              <div className="invoice-action">
                {/* <Link to={`${process.env.PUBLIC_URL}/invoice-print/${user.id}`} target="_blank">
                  <Button size="lg" color="primary" outline className="btn-icon btn-white btn-dim">
                    <Icon name="printer-fill"></Icon>
                  </Button>
                </Link> */}
              </div>
              <div className="invoice-wrap">
                <div className="invoice-brand text-center">
                  <img src={Logo} alt="" />
                </div>

                <div className="invoice-head">
                  <div className="invoice-contact">
                    <span className="overline-title">Invoice To</span>
                    <div className="invoice-contact-info">
                      <h4 className="title">{`${data?.user?.firstName}${
                        data?.user?.lastName ? ` ${data?.user?.lastName}` : ""
                      }`}</h4>
                      <ul className="list-plain">
                        <li>
                          <Icon name="building"></Icon>
                          <span>{`${data?.company?.name}`}</span>
                        </li>
                        <li>
                          <Icon name="map-pin-fill"></Icon>
                          <span>{`${data?.company?.address?.firstLine}`}</span>
                        </li>
                        <li>
                          <Icon name="call-fill"></Icon>
                          <span>{data?.company?.phone}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="invoice-desc" style={{ width: "fit-content" }}>
                    <h3 className="title">Invoice</h3>
                    <ul className="list-plain">
                      <li className="invoice-id">
                        <span>Invoice ID</span>:<span>{data?.invoiceData?.id}</span>
                      </li>
                      <li className="invoice-date">
                        <span>Date</span>:
                        <span>
                          {moment(data?.invoiceData?.status_transitions?.paid_at * 1000).format("DD MMM YYYY")}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="invoice-bills">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th className="w-150px">Item ID</th>
                          <th className="w-60">Description</th>
                          <th>Price</th>
                          <th>Qty</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.invoiceData?.lines?.data?.length
                          ? data?.invoiceData?.lines?.data?.map((li, index) => (
                              <tr key={index}>
                                <td>{li?.id?.slice(-8)}</td>
                                <td>{li?.description}</td>
                                <td>{USDollar(li?.price?.unit_amount / 100, true)}</td>
                                <td>{li?.quantity}</td>
                                <td>{USDollar(li?.amount / 100, true)}</td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan="2"></td>
                          <td colSpan="2">Subtotal</td>
                          <td>{USDollar(data?.invoiceData?.subtotal / 100, true)}</td>
                        </tr>
                        <tr>
                          <td colSpan="2"></td>
                          <td colSpan="2">TAX</td>
                          <td>{USDollar(data?.invoiceData?.tax / 100, true)}</td>
                        </tr>
                        <tr>
                          <td colSpan="2"></td>
                          <td colSpan="2">Grand Total</td>
                          <td>{USDollar(data?.invoiceData?.total / 100, true)}</td>
                        </tr>
                      </tfoot>
                    </table>
                    {/* <div className="nk-notes ff-italic fs-12px text-soft">
                    Invoice was created on a computer and is valid without the signature and seal.
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </Block>
        ) : null}
      </Content>
    </>
  );
};

export default Invoice;
