import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMethod, postMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";

export const getPaymentById = createAsyncThunk("payment/getPaymentById", async (id) => {
  setAuthorizationToken(true);
  const response = await getMethod(`/api/admin/payments/${id}`);
  return response;
});

export const getAllPayments = createAsyncThunk("payment/allPayments", async (id) => {
  setAuthorizationToken(true);
  const response = await getMethod(`/api/admin/payments`);
  return response;
});

const initialState = {
  loading: false,
  success: false,
  error: false,
  payments: {
    loading: false,
    success: false,
    data: null,
    total: null,
    error: false,
  },
  payment: {
    loading: false,
    success: false,
    data: null,
    error: false,
  },
};

const paymentSlice = createSlice({
  name: "notifications",
  initialState,

  reducers: {
    isLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    isPaymentSuccess: (state, action) => {
      state.payment.loading = false;
      state.payment.success = true;
      state.payment.error = false;
      state.payment.data = action.payload;
    },
    isError: (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getPaymentById.pending, (state) => {
      state.payment.loading = true;
      state.payment.success = false;
      state.payment.error = false;
      state.payment.data = null;
    });
    builder.addCase(getPaymentById.fulfilled, (state, action) => {
      state.payment.loading = false;
      state.payment.success = true;
      state.payment.data = action.payload.data;
      state.payment.error = false;
    });
    builder.addCase(getPaymentById.rejected, (state, action) => {
      state.payment.loading = false;
      state.payment.success = false;
      state.payment.data = null;
      state.payment.error = action.error.message;
    });

    builder.addCase(getAllPayments.pending, (state) => {
      state.payments.loading = true;
      state.payments.success = false;
      state.payments.error = false;
      state.payments.data = null;
      state.payments.total = null;
    });
    builder.addCase(getAllPayments.fulfilled, (state, action) => {
      state.payments.loading = false;
      state.payments.success = true;
      state.payments.data = action.payload.data;
      state.payments.total = {
        total: action?.payload?.total,
        items_per_page: action?.payload?.items_per_page || 25,
        total_pages: action?.payload?.total_pages || Math.ceil(action?.payload?.total / 25),
      };
      state.payments.error = false;
    });
    builder.addCase(getAllPayments.rejected, (state, action) => {
      state.payments.loading = false;
      state.payments.success = false;
      state.payments.data = null;
      state.payments.total = null;
      state.payments.error = action.error.message;
    });
  },
});

export const { isLoading, isPaymentSuccess, isError } = paymentSlice.actions;

export default paymentSlice.reducer;

export const editPayment = (id, data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const response = await putMethod(`/api/admin/payments/${id}`, data);
  if (response?.success) {
    SuccessCallback(response);
  } else {
    ErrorCallback(response);
  }
};

export const associateSubscription = (data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const response = await postMethod(`/api/payment/create-associate-subscription`, data);
  if (response?.success) SuccessCallback(response);
  else ErrorCallback(response);
};

export const generateInvoice = (data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const response = await postMethod(`/api/payment/generate-invoice`, data);
  if (response?.success) SuccessCallback(response);
  else ErrorCallback(response);
};
