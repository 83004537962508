import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { LuArchiveRestore } from "react-icons/lu";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import Swal from "sweetalert2";
import PlaceholderImg from "../../assets/profile_avatar.png";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  RSelect,
  Row,
} from "../../components/Component";
import ErrorComponent from "../../components/error/ErrorComponent";
import DotPagination from "../../components/pagination/DotPaginate";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { addAssociate } from "../../redux/Apislices/associate";
import { getcity } from "../../redux/Apislices/citySlice";
import { getneighbourhood } from "../../redux/Apislices/neighbourhoodSlice";
import { associateSubscription, generateInvoice } from "../../redux/Apislices/paymentSlice";
import { getprovince } from "../../redux/Apislices/provinceSlice";
import {
  deleteMultipleUser,
  deleteMultipleUserPermanently,
  deleteUser,
  deleteUserPermanently,
  editUser,
  getUsers,
  restoreUser,
  restoremultipleUser,
} from "../../redux/Apislices/userSlice";
import { getScreenWidth } from "../../utils/Utils";
import axios from "axios";

const statusOptions = [
  { value: "", label: "All" },
  { value: "active", label: "Active" },
  { value: "inative", label: "Inactive" },
  { value: "verified", label: "Verified" },
  { value: "unverified", label: "Unverified" },
];

const itemsPerPageOptions = [
  { value: 25, label: "25" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];

const bulkActionOptions = [
  { value: "", label: "Bulk Action" },
  { value: "archive", label: "Archive" },
  { value: "delete", label: "Delete" },
];

const bulkRestoreOptions = [
  { value: "", label: "Bulk Action" },
  { value: "restore", label: "Restore" },
  { value: "delete", label: "Delete" },
];

const locationOptions = [
  { value: "", label: "Select" },
  { value: "neighbourhood", label: "Neighbourhood" },
  { value: "city", label: "City" },
  { value: "province", label: "Province" },
];

const associateTypeOptions = [
  { value: "Real Estate Agent", label: "Real Estate Agent" },
  { value: "Real Estate Team", label: "Real Estate Team" },
  { value: "Real Estate Brokerage", label: "Real Estate Brokerage" },
];

const crmFields = [
  { label: "First Name", value: "firstName" },
  { label: "Last Name", value: "lastName" },
  { label: "Name", value: "name" },
  { label: "Email", value: "email" },
  { label: "Phone", value: "phone" },
  { label: "Address", value: "address1" },
  { label: "City", value: "city" },
  { label: "State", value: "state" },
  { label: "Postal Code", value: "postalCode" },
  { label: "Website", value: "website" },
  { label: "Notes", value: "notes" },
  { label: "Tags", value: "tags" },
  { label: "Custom Fields", value: "customFields" },
];

const AssociatePage = () => {
  const dispatch = useDispatch();
  const {
    user,
    city: { cities },
    province: { provinces },
    neighbourhood: { neighbourhoods },
    permission,
    profile,
  } = useSelector((state) => ({
    user: state.user,
    city: state.city,
    province: state.province,
    neighbourhood: state.neighbourhood,
    permission: state?.auth?.profile?.permissions?.agent,
    profile: state?.auth?.profile,
  }));

  const { users: userData } = user;

  const [loading, setLoading] = useState(false);

  const [selectedData, setSelectedData] = useState([]);
  const [screenWidth, setScreenWidth] = useState(getScreenWidth());
  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);

  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");

  const [limit, setLimit] = useState({ value: 25, label: "25" });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(25);

  const [sort, setSort] = useState("");
  const [status, setStatus] = useState("");

  const [bulkAction, setBulkAction] = useState("");

  const [view, setView] = useState(false);
  const [editData, setEditData] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [associateType, setAssociateType] = useState(null);
  const [associateTeam, setAssociateTeam] = useState("");

  const [locationOption, setLocationOption] = useState(null);
  const [city, setCity] = useState(null);
  const [province, setProvince] = useState(null);
  const [neighbourhood, setNeighbourhood] = useState(null);

  const [editId, setEditId] = useState("");
  const [deitData, setDeitData] = useState(null);

  const [crmId, setCrmId] = useState("");
  const [editCRM, setEditCRM] = useState(true);

  const [crmType, setCrmType] = useState("");

  const [fixed, setFixed] = useState(null);
  const [fixedFields, setFixedFields] = useState(crmFields);
  const [selectedFixedFields, setSelectedFixedFields] = useState([]);

  const [field, setField] = useState(null);
  const [customFields, setCustomFields] = useState([]);
  const [selectedCustomFields, setSelectedCustomFields] = useState([]);

  const [active, setActive] = useState(false);

  const { errors, register, handleSubmit } = useForm();

  const toggleView = (item) => {
    if (item) setView(item);
    else setView(false);
  };

  const onClose = () => {
    setView(false);
    setFirstName("");
    setLastName("");
    setEmail("");
    setUsername("");
    setPassword("");
    setConfirmPassword("");
    setLocationOption(null);
    setCity(null);
    setProvince(null);
    setNeighbourhood(null);
    setAssociateType(null);
    setAssociateTeam("");
    setEditId("");
    setEditData(null);
    setEditCRM(false);
    setFixed(null);
    setFixedFields(crmFields);
    setSelectedFixedFields([]);
    setField(null);
    setCustomFields([]);
    setSelectedCustomFields([]);
    setCrmId("");
    setCrmType("");
  };

  useEffect(() => {
    if (typeof limit === "object" && limit?.value) setItemPerPage(limit?.value);
    else setItemPerPage(25);
  }, [limit]);

  useEffect(() => {
    dispatch(getcity());
  }, [dispatch]);

  const handleGetData = async () => {
    dispatch(
      getUsers({
        status: typeof status === "object" ? status?.value : status,
        page: currentPage,
        limit: itemPerPage,
        search: onSearchText,
        sort,
        role: "associate",
      })
    );
  };

  //debounce getDevelopmentList for search
  useEffect(() => {
    const timer = setTimeout(
      () => {
        handleGetData();
      },
      onSearchText ? 800 : 200
    );
    return () => clearTimeout(timer);
  }, [dispatch, currentPage, itemPerPage, onSearchText, sort, status]);

  // selects all the products
  const selectorCheck = (e) => {
    // add or remove all the items from development?.development?.data to selectedData
    if (e.currentTarget.checked) {
      setSelectedData(userData?.data?.map((item) => item._id));
    } else {
      setSelectedData([]);
    }
  };

  // selects one product
  const onSelectChange = (e, id) => {
    // add or remove the selected item from selectedData
    if (e.currentTarget.checked) {
      setSelectedData([...selectedData, id]);
    } else {
      setSelectedData(selectedData.filter((item) => item !== id));
    }
  };

  // toggle search input
  const toggle = () => setonSearch(!onSearch);

  // bulk action change
  const onActionText = (e) => {
    setBulkAction(e);
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (locationOption?.value === "city") {
      dispatch(getcity());
    } else if (locationOption?.value === "province") {
      dispatch(getprovince());
    } else if (locationOption?.value === "neighbourhood") {
      dispatch(getneighbourhood());
    }
  }, [locationOption]);

  const onSubmit = async () => {
    setLoading(true);
    let data = {
      firstName,
      lastName,
      email,
      username,
      associateTeam,
      associateType: associateType?.value,
    };

    dispatch(
      addAssociate(
        data,
        (res) => {
          handleGetData();
          toast.success("Associate added successfully");
          onClose();
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          toast.error(err?.message);
        }
      )
    );
  };

  const onEdit = async (data, CB) => {
    dispatch(
      editUser(
        editId,
        { ...data, role: "associate" },
        (res) => {
          toast.success("Updated successfully");
          handleGetData();
          CB(res);
        },
        (res) => {
          toast.error(res?.message);
        }
      )
    );
  };

  const handleMakeActive = (id, payload) => {
    dispatch(
      editUser(
        id,
        payload,
        (res) => {
          toast.success("Associate made active successfully");
          handleGetData();
        },
        (res) => {
          toast.error(res?.message);
        }
      )
    );
  };

  const handleAssignArea = async () => {
    setLoading(true);
    let data = {};
    if (editData) data = { ...data, userId: editData?._id };
    if (locationOption?.value === "city") data = { ...data, area: city?._id, areaModel: "City" };
    else if (locationOption?.value === "province") data = { ...data, area: province?._id, areaModel: "Province" };
    else if (locationOption?.value === "neighbourhood")
      data = { ...data, area: neighbourhood?._id, areaModel: "Neighbourhood" };

    dispatch(
      associateSubscription(
        data,
        (res) => {
          handleGetData();
          toast.success("Area assigned successfully");
          setLoading(false);
          onClose();
        },
        (res) => {
          toast.error(res?.message);
          setLoading(false);
        }
      )
    );
  };

  const handleBulkAction = (e) => {
    if (bulkAction?.value === "archive") {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Archive",
      }).then((result) => {
        if (result.isConfirmed) {
          handleArchiveMultiple(
            () => {
              Swal.fire("Archived!", "Selected Associate has been archived.", "success");
            },
            () => {
              Swal.fire("Error!", "Something went wrong!", "error");
            }
          );
        }
      });
    } else if (bulkAction?.value === "delete") {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.isConfirmed) {
          handleDeleteMultiple(
            () => {
              Swal.fire("Deleted!", "Selected Associate has been deleted.", "success");
            },
            () => {
              Swal.fire("Error!", "Something went wrong!", "error");
            }
          );
        }
      });
    } else if (bulkAction?.value === "restore") {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Restore",
      }).then((result) => {
        if (result.isConfirmed) {
          handleRestoreMultiple(
            () => {
              Swal.fire("Restored!", "Selected Associate has been restored.", "success");
            },
            () => {
              Swal.fire("Error!", "Something went wrong!", "error");
            }
          );
        }
      });
    }
  };

  const handleArchiveMultiple = async (successCB, errorCB) => {
    dispatch(
      deleteMultipleUser(
        { ids: selectedData },
        (res) => {
          if (res?.success) {
            handleGetData();
            setBulkAction("");
            setSelectedData([]);
            successCB();
          }
        },
        (err) => {
          errorCB();
          toast.error(err?.message);
        }
      )
    );
  };

  const handleRestoreMultiple = async (successCB, errorCB) => {
    dispatch(
      restoremultipleUser(
        { ids: selectedData },
        (res) => {
          if (res?.success) {
            handleGetData();
            setBulkAction("");
            setSelectedData([]);
            successCB();
          }
        },
        (err) => {
          errorCB();
          toast.error(err?.message);
        }
      )
    );
  };

  const handleDeleteMultiple = async (successCB, errorCB) => {
    dispatch(
      deleteMultipleUserPermanently(
        { ids: selectedData },
        (res) => {
          if (res?.success) {
            handleGetData();
            setBulkAction("");
            setSelectedData([]);
            successCB();
          }
        },
        (err) => {
          errorCB();
          toast.error(err?.message);
        }
      )
    );
  };

  const handleArchive = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Archive",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deleteUser(
            item?._id,
            (res) => {
              console.log("res: ", res);
              if (res?.success) {
                handleGetData();
              }
              Swal.fire("Archived!", "Associate has been archived.", "success");
            },
            (err) => {
              Swal.fire("Error!", "Something went wrong!", "error");
              toast.error(err?.message);
            }
          )
        );
      }
    });
  };

  const handleRestore = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Restore",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          restoreUser(
            item?._id,
            (res) => {
              if (res?.success) {
                handleGetData();
              }
              Swal.fire("Archived!", "Associate has been restored.", "success");
            },
            (err) => {
              Swal.fire("Error!", "Something went wrong!", "error");
              toast.error(err?.message);
            }
          )
        );
      }
    });
  };

  const handleDelete = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deleteUserPermanently(
            item?._id,
            (res) => {
              if (res?.success) {
                handleGetData();
              }
              Swal.fire("Deleted!", "Associate has been deleted.", "success");
            },
            (err) => {
              Swal.fire("Error!", "Something went wrong!", "error");
              toast.error(err?.message);
            }
          )
        );
      }
    });
  };

  useEffect(() => {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://rest.gohighlevel.com/v1/custom-fields/",
      headers: {
        Authorization: `Bearer ${crmId}`,
      },
    };

    axios(config)
      .then(function (response) {
        setCustomFields(response?.data?.customFields?.map((item) => ({ label: item?.name, value: item?.id })));
      })
      .catch(function (error) {});
  }, [crmId]);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <Head title="Associates"></Head>

      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>Associates</BlockTitle>
              <BlockDes className="text-soft">
                {userData?.data?.length && userData?.success ? (
                  <p>You have total {userData?.total?.total} associates.</p>
                ) : null}
              </BlockDes>
            </BlockHeadContent>

            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand mr-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    updateSm(!sm);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>

                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button
                        className="toggle btn-icon d-md-none"
                        onClick={() => {
                          toggleView("add");
                        }}
                      >
                        <Icon name="plus"></Icon>
                      </Button>

                      <Button
                        className="toggle d-none btn-outline-primary btn-dim d-md-inline-flex"
                        onClick={() => {
                          toggleView("add");
                        }}
                      >
                        <Icon name="plus"></Icon>
                        <span>Add Associate</span>
                      </Button>
                    </li>

                    {(typeof status === "object" && status?.value === "archive") || status === "archive" ? (
                      <li className="nk-block-tools-opt">
                        <Button
                          className="toggle btn-icon btn-outline-info d-md-none"
                          color="primary"
                          onClick={() => {
                            setStatus("");
                          }}
                        >
                          <Icon name="arrow-long-left"></Icon>
                        </Button>

                        <Button
                          className="toggle btn-outline-info btn-dim d-none d-md-inline-flex"
                          onClick={() => {
                            setStatus("");
                          }}
                        >
                          <Icon name="arrow-long-left"></Icon>
                          <span>All Associates</span>
                        </Button>
                      </li>
                    ) : (
                      <li className="nk-block-tools-opt">
                        <Button
                          className="toggle btn-icon d-md-none"
                          color="primary"
                          onClick={() => {
                            setStatus("archive");
                          }}
                        >
                          <Icon name="archive"></Icon>
                        </Button>

                        <Button
                          className="toggle btn-outline-danger btn-dim d-none d-md-inline-flex"
                          onClick={() => {
                            setStatus("archive");
                          }}
                        >
                          <Icon name="archive"></Icon>
                          <span>Archived</span>
                        </Button>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                {/* Bulk Actions */}
                {selectedData.length ? (
                  <div className="card-tools">
                    <div className="form-inline flex-nowrap gx-3">
                      <div className="form-wrap">
                        <RSelect
                          options={
                            (typeof status === "object" && status?.value === "archive") || status === "archive"
                              ? bulkRestoreOptions
                              : bulkActionOptions
                          }
                          className="w-130px"
                          placeholder="Bulk Action"
                          onChange={(e) => onActionText(e)}
                          value={bulkAction}
                        />
                      </div>
                      <div className="btn-wrap">
                        <span className="d-none d-md-block">
                          <Button
                            disabled={selectedData.length && bulkAction?.value ? false : true}
                            color="info"
                            outline
                            className="btn-dim"
                            onClick={(e) => {
                              if (selectedData.length && bulkAction?.value) {
                                handleBulkAction(e);
                              }
                            }}
                          >
                            Apply
                          </Button>
                        </span>
                        <span className="d-md-none">
                          <Button
                            color="info"
                            outline
                            disabled={selectedData.length && bulkAction?.value ? false : true}
                            className="btn-dim btn-icon"
                            onClick={(e) => {
                              if (selectedData.length && bulkAction?.value) {
                                handleBulkAction(e);
                              }
                            }}
                          >
                            <Icon name="arrow-right"></Icon>
                          </Button>
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="card-tools mr-n1">
                  <ul className="btn-toolbar gx-1">
                    {/* Search */}
                    <li>
                      <div
                        className="search-content"
                        style={{
                          border: "1px solid #dbdfea",
                          borderRadius: "4px",
                        }}
                      >
                        <Button
                          className="search-back btn-icon toggle-search active"
                          onClick={() => {
                            setSearchText("");
                            toggle();
                          }}
                        >
                          <Icon name="search"></Icon>
                        </Button>
                        <input
                          type="text"
                          className="border-transparent form-focus-none form-control"
                          placeholder="Search by name or email"
                          value={onSearchText}
                          onChange={(e) => onFilterChange(e)}
                        />
                        {onSearchText ? (
                          <Button
                            className="search-submit btn-icon"
                            onClick={() => {
                              setSearchText("");
                            }}
                          >
                            <Icon name="cross"></Icon>
                          </Button>
                        ) : null}
                      </div>
                    </li>

                    <li className="btn-toolbar-sep"></li>

                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>

                            {/* Filter */}
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  {/* <div className="dot dot-primary"></div> */}
                                  <Icon name="filter-fill"></Icon>
                                </DropdownToggle>
                                <DropdownMenu
                                  right
                                  className="filter-wg dropdown-menu-xl"
                                  style={{ overflow: "visible" }}
                                >
                                  <div className="dropdown-head">
                                    <span className="sub-title dropdown-title">Filter Associates</span>
                                  </div>
                                  <div className="dropdown-body dropdown-body-rg">
                                    <Row className="gx-6 gy-3">
                                      {/* <Col size="6">
                                        <div className="custom-control custom-control-sm custom-checkbox">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input form-control"
                                            id="hasBalance"
                                          />
                                          <label className="custom-control-label" htmlFor="hasBalance">
                                            {" "}
                                            Have Balance
                                          </label>
                                        </div>
                                      </Col>
                                      <Col size="6">
                                        <div className="custom-control custom-control-sm custom-checkbox">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input form-control"
                                            id="hasKYC"
                                          />
                                          <label className="custom-control-label" htmlFor="hasKYC">
                                            {" "}
                                            KYC Verified
                                          </label>
                                        </div>
                                      </Col> */}

                                      <Col size="6">
                                        <FormGroup>
                                          <label className="overline-title overline-title-alt">Status</label>
                                          <RSelect
                                            options={statusOptions}
                                            onChange={(value) => {
                                              setStatus(value);
                                            }}
                                            value={status}
                                            placeholder="Select"
                                          />
                                        </FormGroup>
                                      </Col>

                                      <Col size="6">
                                        <FormGroup>
                                          <label className="overline-title overline-title-alt">Items Per Page</label>
                                          <RSelect
                                            options={itemsPerPageOptions}
                                            onChange={(value) => {
                                              setLimit(value);
                                            }}
                                            value={limit}
                                            placeholder="Select"
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </div>

                                  <div className="dropdown-foot between">
                                    {/* <button type="button" className="btn btn-secondary">
                                      Filter
                                    </button> */}
                                    <span></span>

                                    <a
                                      href="#reset"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        setStatus("");
                                      }}
                                      className="clickable"
                                    >
                                      Reset Filter
                                    </a>
                                  </div>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                            {/* Sort */}
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle color="tranparent" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <Icon name="sort-line"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <ul className="link-list-opt no-bdr">
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSort("recently-updated");
                                        }}
                                        className={sort === "recently-updated" ? "text text-primary" : ""}
                                      >
                                        <span>Recently Updated</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSort("recently-added");
                                        }}
                                        className={sort === "recently-added" ? "text text-primary" : ""}
                                      >
                                        <span>Recently Added</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSort("oldest");
                                        }}
                                        className={sort === "oldest" ? "text text-primary" : ""}
                                      >
                                        <span>Oldest First</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSort("asc");
                                        }}
                                        className={sort === "asc" ? "text text-primary" : ""}
                                      >
                                        <span>0-9, A-Z</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSort("desc");
                                        }}
                                        className={sort === "desc" ? "text text-primary" : ""}
                                      >
                                        <span>9-0, Z-A</span>
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap `}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by user or email"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {userData?.loading ? (
              <div
                style={{
                  height: "200px",
                  width: "100%",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <Spinner type="grow" color="dark" />
              </div>
            ) : userData?.success && userData?.data?.length ? (
              <DataTableBody>
                <DataTableHead>
                  <DataTableRow className="nk-tb-col-check">
                    <div className="custom-control custom-control-sm custom-checkbox notext">
                      <input
                        type="checkbox"
                        className="custom-control-input form-control"
                        id="uid_1"
                        checked={userData?.data?.map((item) => item._id).every((item) => selectedData.includes(item))}
                        onChange={(e) => selectorCheck(e)}
                      />
                      <label className="custom-control-label" htmlFor="uid_1"></label>
                    </div>
                  </DataTableRow>
                  <DataTableRow>
                    <span style={{ fontSize: "16px", color: "#5d5d5d", fontWeight: "600" }}>Name</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span style={{ fontSize: "16px", color: "#5d5d5d", fontWeight: "600" }}>Verified</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span style={{ fontSize: "16px", color: "#5d5d5d", fontWeight: "600" }}>Active</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span style={{ fontSize: "16px", color: "#5d5d5d", fontWeight: "600" }}>Email</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span style={{ fontSize: "16px", color: "#5d5d5d", fontWeight: "600" }}>Service Areas</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span style={{ fontSize: "16px", color: "#5d5d5d", fontWeight: "600" }}>Created On</span>
                  </DataTableRow>
                  <DataTableRow className="nk-tb-col-tools"></DataTableRow>
                </DataTableHead>
                {userData?.data?.length
                  ? userData?.data?.map((item) => {
                      return (
                        <DataTableItem key={item._id}>
                          <DataTableRow className="nk-tb-col-check">
                            <div className="custom-control custom-control-sm custom-checkbox notext">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id={item._id + "uid1"}
                                key={Math.random()}
                                checked={selectedData.includes(item._id)}
                                onChange={(e) => onSelectChange(e, item._id)}
                              />
                              <label className="custom-control-label" htmlFor={item._id + "uid1"}></label>
                            </div>
                          </DataTableRow>
                          <DataTableRow>
                            <span className="tb-product lazy-image">
                              <LazyLoadImage
                                src={
                                  item?.images?.length
                                    ? `${process.env.REACT_APP_IMGURL}${item.profilePhoto}`
                                    : PlaceholderImg
                                }
                                alt="development_image"
                                effect="blur"
                                className="rounded-circle"
                                placeholderSrc={PlaceholderImg}
                                style={{
                                  width: "32px",
                                  height: "32px",
                                  borderRadius: "100px",
                                  objectFit: "cover",
                                  marginRight: "10px",
                                }}
                              />
                              <p
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  fontSize: "12px",
                                }}
                              >
                                <span className="title">
                                  {item?.firstName}
                                  {item?.lastName ? ` ${item?.lastName}` : ""}
                                </span>
                              </p>
                            </span>
                          </DataTableRow>
                          <DataTableRow>
                            <p
                              className="tb-sub"
                              style={{
                                color: item?.isDeleted
                                  ? "#FF2A04"
                                  : item?.status === "active"
                                  ? "#00E200"
                                  : item?.status === "sold"
                                  ? "#FF2A04"
                                  : item?.status === "pending"
                                  ? "#FFAF3D"
                                  : item?.status === "draft"
                                  ? "#7B8089"
                                  : item?.status === "on hold"
                                  ? "#285766"
                                  : "",
                                display: "flex",
                                alignItems: "center",
                                gap: "0",
                                padding: "0",
                                margin: "0",
                                textTransform: "capitalize",
                              }}
                            >
                              <span>{item?.isDeleted ? "Archived" : item?.isVerified ? "Verified" : "Unverified"}</span>
                            </p>
                          </DataTableRow>
                          <DataTableRow>
                            <p
                              className="tb-sub"
                              style={{
                                color: item?.isDeleted
                                  ? "#FF2A04"
                                  : item?.status === "active"
                                  ? "#00E200"
                                  : item?.status === "sold"
                                  ? "#FF2A04"
                                  : item?.status === "pending"
                                  ? "#FFAF3D"
                                  : item?.status === "draft"
                                  ? "#7B8089"
                                  : item?.status === "on hold"
                                  ? "#285766"
                                  : "",
                                display: "flex",
                                alignItems: "center",
                                gap: "0",
                                padding: "0",
                                margin: "0",
                                textTransform: "capitalize",
                              }}
                            >
                              <span>{item?.isDeleted ? "Archived" : item?.isActive ? "Active" : "Inactive"}</span>
                            </p>
                          </DataTableRow>
                          <DataTableRow>
                            <span className="tb-sub">{item?.email}</span>
                          </DataTableRow>
                          <DataTableRow>
                            <div
                              className="tb-sub"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                // alignItems: "center",
                              }}
                            >
                              {item?.assignedArea?.map((area) => {
                                return (
                                  <p
                                    key={area?._id}
                                    style={{
                                      color:
                                        area?.status === "active"
                                          ? "#00E200"
                                          : area?.status === "rejected"
                                          ? "#FF2A04"
                                          : area?.status === "pending"
                                          ? "#FFAF3D"
                                          : area?.status === "on hold"
                                          ? "#FFAF3D"
                                          : area?.status === "expired"
                                          ? "#FF2A04"
                                          : "",
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "0",
                                      padding: "0",
                                      margin: "0",
                                    }}
                                  >
                                    <Icon name={"dot"} style={{ fontSize: "22px" }} />
                                    <span>{area?.area?.name}</span>
                                  </p>
                                );
                              })}
                            </div>
                          </DataTableRow>
                          <DataTableRow>
                            <span className="tb-sub">{moment(item?.createdAt).format("DD/MM/YYYY")}</span>
                          </DataTableRow>
                          <DataTableRow className="nk-tb-col-tools">
                            <ul className="nk-tb-actions gx-1 my-n1">
                              <li className="mr-n1">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    href="#more"
                                    onClick={(ev) => ev.preventDefault()}
                                    className="dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <ul className="link-list-opt no-bdr">
                                      {(typeof status === "object" && status?.value === "archive") ||
                                      status === "archive" ? (
                                        <li>
                                          <DropdownItem
                                            tag="a"
                                            href="#view"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                              handleRestore(item);
                                            }}
                                            disabled={permission && !permission?.edit}
                                          >
                                            <LuArchiveRestore
                                              style={{ fontSize: "18px", marginRight: "15px", color: "#526484" }}
                                            />
                                            <span>Restore</span>
                                          </DropdownItem>
                                        </li>
                                      ) : (
                                        <>
                                          {!item?.isActive && item?.brokerage && item?.mobile && item?.email ? (
                                            <li>
                                              <DropdownItem
                                                tag="a"
                                                href="#view"
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                  handleMakeActive(item?._id, { isActive: true, role: "associate" });
                                                }}
                                                disabled={permission && !permission?.edit}
                                              >
                                                <Icon name="edit-fill"></Icon>
                                                <span>Make it Active</span>
                                              </DropdownItem>
                                            </li>
                                          ) : null}
                                          {item?.isActive ? (
                                            <>
                                              <li>
                                                <DropdownItem
                                                  tag="a"
                                                  href="#view"
                                                  onClick={(ev) => {
                                                    ev.preventDefault();
                                                    setView("serviceArea");
                                                    setEditData(item);
                                                  }}
                                                  disabled={permission && !permission?.edit}
                                                >
                                                  <Icon name="edit-fill"></Icon>
                                                  <span>Assign Area</span>
                                                </DropdownItem>
                                              </li>
                                              {item?.assignedArea?.length ? (
                                                <li>
                                                  <DropdownItem
                                                    tag="a"
                                                    href="#view"
                                                    onClick={(ev) => {
                                                      ev.preventDefault();
                                                      setView("crm");
                                                      setEditId(item?._id);
                                                      setEditData(item);
                                                      if (item?.crmKey) {
                                                        setCrmId(item?.crmKey);
                                                        setEditCRM(false);
                                                      }
                                                    }}
                                                    disabled={permission && !permission?.edit}
                                                  >
                                                    <Icon name="setting-fill"></Icon>
                                                    <span>CRM Details</span>
                                                  </DropdownItem>
                                                </li>
                                              ) : null}
                                            </>
                                          ) : null}
                                          <li>
                                            <DropdownItem
                                              tag="a"
                                              href="#view"
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                                handleArchive(item);
                                              }}
                                              disabled={permission && !permission?.archive}
                                            >
                                              <Icon name="archived-fill"></Icon>
                                              <span>Archive</span>
                                            </DropdownItem>
                                          </li>
                                        </>
                                      )}
                                      <li>
                                        <DropdownItem
                                          tag="a"
                                          href="#view"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            handleDelete(item);
                                          }}
                                          disabled={permission && !permission?.delete}
                                        >
                                          <Icon name="trash"></Icon>
                                          <span>Delete Permanently</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </li>
                            </ul>
                          </DataTableRow>
                        </DataTableItem>
                      );
                    })
                  : null}
              </DataTableBody>
            ) : userData?.success && !userData?.data?.length ? (
              <div className="text-center" style={{ padding: "20px", fontSize: "1rem", fontWeight: "600" }}>
                <span className="text-silent">No Associates found</span>
              </div>
            ) : userData?.error ? (
              <ErrorComponent error={userData?.error} />
            ) : null}

            <div className="card-inner p-0">
              {userData?.success && userData?.data?.length ? (
                <DotPagination
                  itemPerPage={userData?.total?.items_per_page}
                  totalItems={userData?.total?.total}
                  paginate={paginate}
                  currentPage={currentPage}
                  siblingCount={2}
                  screenWidth={screenWidth}
                />
              ) : null}
            </div>
          </DataTable>

          <Modal isOpen={view ? true : false} toggle={() => onClose()} className="modal-dialog-centered" size="lg">
            <ModalBody>
              <a href="#cancel" className="close">
                <Icon name="cross-sm" onClick={() => onClose()}></Icon>
              </a>
              <div className="p-2">
                <h5 className="title">
                  <span>
                    {view === "add"
                      ? "Add Associate"
                      : view === "edit"
                      ? "Edit Associate"
                      : view === "serviceArea"
                      ? "Assign Area"
                      : view === "crm"
                      ? "CRM Details"
                      : ""}
                  </span>
                </h5>
                <div className="mt-4">
                  {["add", "edit"]?.includes(view) ? (
                    <form onSubmit={handleSubmit(view === "add" ? onSubmit : onEdit)}>
                      <Row className="g-3">
                        <Col size="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="product-title">
                              First Name*
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                className="form-control"
                                name="firstName"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                ref={register({
                                  required: "This field is required",
                                })}
                              />
                              {errors.name && <span className="invalid">{errors.name.message}</span>}
                            </div>
                          </div>
                        </Col>
                        <Col size="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="product-title">
                              Last Name*
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                className="form-control"
                                name="lastName"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                ref={register({
                                  required: "This field is required",
                                })}
                              />
                              {errors.name && <span className="invalid">{errors.name.message}</span>}
                            </div>
                          </div>
                        </Col>
                        <Col size="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="product-title">
                              Username*
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                className="form-control"
                                name="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                ref={register({
                                  required: "This field is required",
                                })}
                              />
                              {errors.name && <span className="invalid">{errors.name.message}</span>}
                            </div>
                          </div>
                        </Col>
                        <Col size="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="product-title">
                              Email*
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                ref={register({
                                  required: "This field is required",
                                })}
                              />
                              {errors.name && <span className="invalid">{errors.name.message}</span>}
                            </div>
                          </div>
                        </Col>
                        <Col size="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="product-title">
                              Associate Type*
                            </label>
                            <div className="form-control-wrap">
                              <RSelect
                                options={associateTypeOptions}
                                onChange={(value) => {
                                  setAssociateType(value);
                                }}
                                name={"associateType"}
                                value={associateType}
                                placeholder="Select associate type..."
                              />
                              {errors.name && <span className="invalid">{errors.name.message}</span>}
                            </div>
                          </div>
                        </Col>
                        <Col size="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="product-title">
                              LN Associate Team*
                            </label>
                            <div className="form-control-wrap">
                              <input
                                placeholder="Enter LN associate team name"
                                type="text"
                                className="form-control"
                                name="associateTeam"
                                value={associateTeam}
                                onChange={(e) => setAssociateTeam(e.target.value)}
                                ref={register({
                                  required: "This field is required",
                                })}
                              />
                              {errors.name && <span className="invalid">{errors.name.message}</span>}
                            </div>
                          </div>
                        </Col>

                        <hr style={{ width: "100%", height: "1px", background: "#a0a0a0", color: "#a0a0a0" }} />
                        <Col size="12">
                          <Button color="primary" type="submit">
                            <span>Save</span>
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  ) : view === "serviceArea" ? (
                    <form onSubmit={handleSubmit(handleAssignArea)}>
                      <Row className="g-3">
                        <Col size="12">
                          <FormGroup className="mb-1">
                            <label className="overline-title overline-title-alt mb-0">Select Location</label>
                            <RSelect
                              options={locationOptions}
                              onChange={(value) => {
                                setCity(null);
                                setProvince(null);
                                setNeighbourhood(null);
                                setLocationOption(value);
                              }}
                              name={"locationOption"}
                              value={locationOption}
                              placeholder="Select location..."
                              isClearable
                            />
                          </FormGroup>
                        </Col>
                        {(locationOption?.value === "city" && cities?.loading) ||
                        (locationOption?.value === "neighbourhood" && neighbourhoods?.loading) ||
                        (locationOption?.value === "province" && provinces?.loading) ? (
                          <Col size={"12"} style={{ textAlign: "center" }}>
                            <Spinner type="grow" />
                          </Col>
                        ) : locationOption?.value === "city" && cities?.data?.length ? (
                          <Col size="12">
                            <FormGroup className="mb-1">
                              <label className="overline-title overline-title-alt mb-0">City</label>
                              <RSelect
                                options={cities?.data?.length ? cities?.data : []}
                                onChange={(value) => {
                                  if (value?.associateCost) setCity(value);
                                  else {
                                    setCity(null);
                                    toast.error("Associate cost is not set for this city");
                                  }
                                }}
                                name={"city"}
                                value={city}
                                placeholder="Select city..."
                                isClearable
                                loading={cities?.loading}
                                getOptionLabel={(option) =>
                                  `${option.name} ${option.associateCost ? `(${option.associateCost})` : ""}`
                                }
                                getOptionValue={(option) => option._id}
                                isOptionDisabled={(option) =>
                                  option.associateAssigned && ["Yes", "Pending"]?.includes(option.associateAssigned)
                                }
                              />
                            </FormGroup>
                          </Col>
                        ) : locationOption?.value === "province" && province?.data?.length ? (
                          <Col size="12">
                            <FormGroup className="mb-1">
                              <label className="overline-title overline-title-alt mb-0">Province</label>
                              <RSelect
                                options={provinces?.data}
                                onChange={(value) => {
                                  if (value?.associateCost) setProvince(value);
                                  else {
                                    setProvince(null);
                                    toast.error("Associate cost is not set for this province");
                                  }
                                }}
                                name={"province"}
                                value={province}
                                placeholder="Select province..."
                                isClearable
                                loading={provinces?.loading}
                                getOptionLabel={(option) =>
                                  `${option.name} ${option.associateCost ? `(${option.associateCost})` : ""}`
                                }
                                getOptionValue={(option) => option._id}
                                isOptionDisabled={(option) =>
                                  option.associateAssigned && ["Yes", "Pending"]?.includes(option.associateAssigned)
                                }
                              />
                            </FormGroup>
                          </Col>
                        ) : locationOption?.value === "neighbourhood" && neighbourhoods?.data?.length ? (
                          <Col size="12">
                            <FormGroup className="mb-1">
                              <label className="overline-title overline-title-alt mb-0">Neighbourhood</label>
                              <RSelect
                                options={neighbourhoods?.data}
                                onChange={(value) => {
                                  if (value?.associateCost) setNeighbourhood(value);
                                  else {
                                    setNeighbourhood(null);
                                    toast.error("Associate cost is not set for this neighbourhood");
                                  }
                                }}
                                name={"neighbourhood"}
                                value={neighbourhood}
                                placeholder="Select neighburhood..."
                                isClearable
                                loading={neighbourhoods?.loading}
                                getOptionLabel={(option) =>
                                  `${option.name} ${option.associateCost ? `(${option.associateCost})` : ""}`
                                }
                                getOptionValue={(option) => option._id}
                                isOptionDisabled={(option) =>
                                  option.associateAssigned && ["Yes", "Pending"]?.includes(option.associateAssigned)
                                }
                              />
                            </FormGroup>
                          </Col>
                        ) : null}
                        <hr style={{ width: "100%", height: "1px", background: "#a0a0a0", color: "#a0a0a0" }} />
                        <Col size="12">
                          <Button color="primary" disabled={loading} type="submit">
                            {loading ? <Spinner size={"sm"} /> : <span>Assign</span>}
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  ) : view === "crm" ? (
                    <Row className="g-3">
                      <Col size="12">
                        <div className="form-group">
                          <label className="form-label" htmlFor="product-title">
                            CRM Key
                            <button
                              style={{
                                background: "transparent",
                                color: "#00aeff",
                                fontSize: "12px",
                                border: "none",
                                marginLeft: "5px",
                              }}
                              className="disabled-btn"
                              onClick={() => setEditCRM(!editCRM)}
                            >
                              Change Key
                            </button>
                          </label>
                          <Row>
                            <Col size={10}>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  className="form-control disabled-btn"
                                  name="crmId"
                                  value={crmId}
                                  onChange={(e) => setCrmId(e.target.value)}
                                  autoComplete="off"
                                  disabled={!editCRM}
                                  ref={register({ required: "This field is required" })}
                                />
                                {errors.name && <span className="invalid">{errors.name.message}</span>}
                              </div>
                            </Col>
                            <Col size={2}>
                              <Button
                                color="primary"
                                disabled={loading}
                                onClick={() => {
                                  if (crmId) {
                                    onEdit({ crmKey: crmId }, () => {
                                      setEditCRM(false);
                                    });
                                  }
                                }}
                              >
                                {loading ? <Spinner size={"sm"} /> : <span>Submit</span>}
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      {crmId && (
                        <Col size={12}>
                          <div className="form-group">
                            <label className="form-label" htmlFor="product-title">
                              Form Type*
                            </label>
                            <div className="form-control-wrap">
                              <RSelect
                                options={[
                                  { label: "Home Buyer Form", value: "home-buyer" },
                                  { label: "Home Seller Seller", value: "home-seller" },
                                ]}
                                onChange={(value) => {
                                  setCrmType(value);
                                  if (value?.value === "home-buyer" && editData) {
                                    setSelectedFixedFields(editData?.crmData?.homeBuyer?.fixed);
                                    // setFixedFields(
                                    //   fixedFields?.filter((field) => !editData?.crmData?.homeBuyer?.fixed)
                                    // );
                                    setSelectedCustomFields(editData?.crmData?.homeBuyer?.custom);
                                    // setCustomFields(
                                    //   customFields?.filter((field) => !editData?.crmData?.homeBuyer?.custom)
                                    // );
                                  } else if (value?.value === "home-seller" && editData) {
                                    setSelectedFixedFields(editData?.crmData?.homeSeller?.fixed);
                                    setSelectedCustomFields(editData?.crmData?.homeSeller?.custom);
                                  }
                                }}
                                name={"crmType"}
                                value={crmType}
                                placeholder="Select Form type..."
                              />
                            </div>
                          </div>
                          <hr style={{ width: "100%", height: "1px", background: "#a0a0a0", color: "#a0a0a0" }} />
                        </Col>
                      )}
                      {crmId && crmType && crmType?.value === "home-buyer" ? (
                        <>
                          {fixedFields?.length ? (
                            <Col size={12}>
                              <div className="form-group">
                                <label className="form-label" htmlFor="product-title">
                                  All Fixed Fields
                                </label>
                                <div className="form-control-wrap">
                                  <RSelect
                                    options={fixedFields?.filter((field) => !selectedFixedFields.includes(field))}
                                    onChange={(value) => {
                                      setSelectedFixedFields([...selectedFixedFields, value]);
                                      setFixed("");
                                    }}
                                    name={"fixed"}
                                    value={fixed}
                                    placeholder="Select Fixed fields..."
                                  />
                                </div>
                              </div>
                            </Col>
                          ) : null}
                          {customFields?.length ? (
                            <Col size={12}>
                              <div className="form-group">
                                <label className="form-label" htmlFor="product-title">
                                  All Custom Fields
                                </label>
                                <div className="form-control-wrap">
                                  <RSelect
                                    options={customFields?.filter((field) => !selectedCustomFields.includes(field))}
                                    onChange={(value) => {
                                      setSelectedCustomFields([...selectedCustomFields, value]);
                                      setField("");
                                    }}
                                    name={"field"}
                                    value={field}
                                    placeholder="Select custom fields..."
                                  />
                                </div>
                              </div>
                            </Col>
                          ) : null}
                          {selectedCustomFields?.length || selectedFixedFields?.length ? (
                            <Col size={12}>
                              <div className="crmFormTable">
                                <h5>Form</h5>
                                {selectedFixedFields?.length ? (
                                  <div>
                                    <h6>Fixed Fields</h6>
                                    <ul>
                                      {selectedFixedFields?.map((field) => {
                                        return (
                                          <li key={field?.value}>
                                            <div>{field?.label}</div>
                                            <div>{field?.value}</div>
                                            <span>
                                              <Icon
                                                name="cross-circle"
                                                onClick={() => {
                                                  setSelectedFixedFields(
                                                    selectedFixedFields.filter((item) => item?.value !== field?.value)
                                                  );
                                                }}
                                              ></Icon>
                                            </span>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                ) : null}
                                {selectedCustomFields?.length ? (
                                  <div>
                                    <h6>Custom Fields</h6>
                                    <ul>
                                      {selectedCustomFields?.map((field) => {
                                        return (
                                          <li key={field?.value}>
                                            <div>{field?.label}</div>
                                            <div>{field?.value}</div>
                                            <span>
                                              <Icon
                                                name="cross-circle"
                                                onClick={() => {
                                                  setSelectedCustomFields(
                                                    selectedCustomFields.filter((item) => item?.value !== field?.value)
                                                  );
                                                }}
                                              ></Icon>
                                            </span>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                ) : null}
                              </div>
                            </Col>
                          ) : null}
                        </>
                      ) : null}
                      {crmId && crmType && (selectedCustomFields?.length || selectedFixedFields?.length) ? (
                        <Col size="12">
                          <Button
                            color="primary"
                            disabled={loading}
                            onClick={() => {
                              let payload = {};
                              if (selectedFixedFields?.length) {
                                payload = { ...payload, fixed: selectedFixedFields };
                              }
                              if (selectedCustomFields?.length) {
                                payload = { ...payload, custom: selectedCustomFields };
                              }
                              let data = {};
                              if (crmType?.value === "home-buyer") {
                                data = { ...data, crmData: { homeBuyer: payload } };
                              } else if (crmType?.value === "home-seller") {
                                data = { ...data, crmData: { homeSeller: payload } };
                              }
                              onEdit(data, () => {
                                setEditCRM(false);
                              });
                            }}
                          >
                            {loading ? <Spinner size={"sm"} /> : <span>Submit</span>}
                          </Button>
                        </Col>
                      ) : null}
                    </Row>
                  ) : null}
                </div>
              </div>
            </ModalBody>
          </Modal>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default AssociatePage;
